import React, { useState, useContext } from "react";
import "../../../../styles/MovimientosDeUsuarios.css";
import axios from "axios";
import { Context } from "../../../store/appContext";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);

export const EmpresasDeTransporte = () => {
  const { store } = useContext(Context);
  const [formData, setFormData] = useState({
    company_name: "",
    company_rif: "",
    company_intern_code: "",
  });

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await axios.post(
        process.env.BACKEND_URL + "/api/registerCompany",
        formData,
        {
          headers: {
            Authorization: `Bearer ${store.token}`,
          },
        }
      );

      setFormData({
        company_name: "",
        company_rif: "",
        company_intern_code: "",
      });

      MySwal.fire({
        title: "Empresa Registrada",
        text: "La empresa ha sido registrada exitosamente.",
        icon: "success",
        confirmButtonText: "Cerrar",
      });
    } catch (error) {
      const errorMessage =
        error.response?.data?.message ||
        "Error desconocido al registrar la empresa.";

      MySwal.fire({
        title: "Error",
        text: errorMessage,
        icon: "error",
        confirmButtonText: "Cerrar",
      });
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const fetchCompanies = async () => {
    MySwal.fire({
      title: "Cargando...",
      html: `<p style="text-align:center; margin:20px;">Cargando datos registrados...</p>`,
      allowOutsideClick: false,
      didOpen: () => {
        MySwal.showLoading();
      },
    });

    try {
      const response = await axios.get(
        process.env.BACKEND_URL + "/api/getAllCompanies",
        {
          headers: {
            Authorization: `Bearer ${store.token}`,
          },
        }
      );

      const data = response.data;

      MySwal.close();

      if (!data || data.length === 0) {
        MySwal.fire({
          title: "Empresas Registradas",
          html: `<p style="text-align:center; margin:20px;">No se encontraron datos.</p>`,
          confirmButtonText: "Cerrar",
        });
      } else {
        const content = data
          .map(
            (item) => `
          <div style="margin: 10px; padding: 10px; border: 1px solid #ccc;">
            <p><strong>Nombre de Empresa:</strong> ${item.company_name}</p>
            <p><strong>RIF de Empresa:</strong> ${item.company_rif}</p>
            <p><strong>Código Interno:</strong> ${item.company_intern_code}</p>
            <button class="btn btn-secondary" style="background: #a9a9a9; border: none" id="viewCPSDetails-${item.id}">Ver Detalles de CPS</button>
          </div>
        `
          )
          .join("");

        MySwal.fire({
          title: "Empresas Registradas",
          html: `
            <div id="companies-list">${content}</div>
          `,
          confirmButtonText: "Cerrar",
        });

        // Attach event listeners for the dynamically created buttons
        data.forEach((item) => {
          document
            .getElementById(`viewCPSDetails-${item.id}`)
            .addEventListener("click", () => viewCPSDetails(item));
        });
      }
    } catch (error) {
      console.error("Error al obtener empresas registradas", error);
      MySwal.fire({
        title: "Error",
        html: `<p>Error al obtener empresas registradas. Por favor, inténtelo de nuevo más tarde.</p>`,
        icon: "error",
        confirmButtonText: "Cerrar",
      });
    }
  };

  const viewCPSDetails = (company) => {
    const cpsDetails = company.cps_data
      .map(
        (cps) => `
        <div style="margin: 10px; padding: 10px; border: 1px solid #ccc; justify-content: center; align-items: center; flex-direction: column; display: flex; background-color: ${cps.cps_status === "ACTIVO" ? "#0080001c" : "#ff00001a"}">
          <p><strong>Empresa:</strong> ${cps.cps_company_name}</p>
          <p><strong>Ruta:</strong> ${cps.cps_route}</p>
          <p><strong>Destino Final:</strong> ${cps.cps_final_destination}</p>
        </div>
        `
      )
      .join("");

    MySwal.fire({
      title: `Detalles de CPS para ${company.company_name}`,
      html: `
        <div>${cpsDetails}</div>
      `,
      confirmButtonText: "Cerrar",
    });
  };

  return (
    <div
      className="container-fluid contarinerGeneralUserHomejs"
      style={{ paddingTop: "30px" }}
    >
      <div className="container-fluid contaosijdasd-qw89d77asd2"></div>
      <h2
        className="asjdansdas-d4as6489text w-100"
        style={{ zIndex: "15", marginTop: "-17vh" }}
      >
        Empresas de Transporte
      </h2>
      <div className="container asklnduiashndaijsnxansxsaas">
        <form onSubmit={handleSubmit} className="formularioDeLogin w-100">
          <div className="aoshduiahsdkasnmdasdas">
            <div className="input-group-login">
              <input
                className="inputDeLogin"
                type="text"
                name="company_name"
                required
                onChange={handleChange}
                value={formData.company_name}
              />
              <label>Nombre de Empresa</label>
            </div>
            <div className="input-group-login">
              <input
                className="inputDeLogin"
                type="text"
                name="company_rif"
                required
                onChange={handleChange}
                value={formData.company_rif}
              />
              <label>RIF de Empresa</label>
            </div>
            <div className="input-group-login">
              <input
                className="inputDeLogin"
                type="text"
                name="company_intern_code"
                required
                onChange={handleChange}
                value={formData.company_intern_code}
              />
              <label>Código Interno</label>
            </div>
          </div>

          <button className="buttonCargadeDatosDeLogin" type="submit">
            Registrar <i className="fa-solid fa-arrow-right"></i>
          </button>
        </form>
      </div>
      <div className="w-100 asd9as-da9s84dx9a8qdw-qdw asdqw98d4qv895y4n9r84bvr98ebtnbtn poja65s4d8a9s7d89errg98er7fqwd9849iu9ykj7asj">
        <button
          className="btn asd98q7wd65q1wd8xqw49x8e4wc"
          onClick={fetchCompanies}
        >
          <i
            className="fa-solid fa-route"
            style={{ marginRight: "7px", fontSize: "20px" }}
          ></i>
          Ver Empresas Registradas
        </button>
      </div>
    </div>
  );
};
