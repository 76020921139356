import React, { useContext, useState, useEffect } from "react";
import axios from "axios";
import "../../../../styles/SolicitudActivaciones.css";
import { Context } from "../../../store/appContext";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFViewer,
  Image,
  pdf,
} from "@react-pdf/renderer";
import { PDFDownloadLink, BlobProvider } from "@react-pdf/renderer";
import Joyride from "react-joyride";
import { Tooltip as ReactTooltip } from "react-tooltip";

const tableStyles = StyleSheet.create({
  table: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  table2: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    marginTop: 10,
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row",
    backgroundColor: "#FFF",
  },
  tableColHeader: {
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    backgroundColor: "#f2f2f2",
    alignItems: "center",
    justifyContent: "center",
  },
  tableCol: {
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    alignItems: "center",
    justifyContent: "center",
  },
  tableCellHeader: {
    margin: 5,
    fontSize: 9,
    fontWeight: "bold",
  },
  tableCell: {
    margin: 4,
    fontSize: 9,
  },
});

tableStyles.tableColHeaderOthertaxi = {
  ...tableStyles.tableColHeader,
  width: "50%", // Mayor ancho para los demás
};

tableStyles.tableColOthertaxi = {
  ...tableStyles.tableCol,
  width: "50%", // Mayor ancho para los demás
};

// Modificar anchos específicos aquí
tableStyles.tableColHeaderNumber = {
  ...tableStyles.tableColHeader,
  width: "5%", // Menor ancho para "N°"
};

tableStyles.tableColHeaderOther = {
  ...tableStyles.tableColHeader,
  width: "22.5%", // Mayor ancho para los demás
};

tableStyles.tableColNumber = {
  ...tableStyles.tableCol,
  width: "5%", // Menor ancho para "N°"
};

tableStyles.tableColOther = {
  ...tableStyles.tableCol,
  width: "22.5%", // Mayor ancho para los demás
};

tableStyles.tableColHeaderOthertaxi2 = {
  ...tableStyles.tableColHeader,
  width: "33.3%", // Mayor ancho para los demás
};
tableStyles.tableColHeaderOthertaxi3 = {
  ...tableStyles.tableColHeader,
  width: "100%", // Mayor ancho para los demás
};

tableStyles.tableColOthertaxi2 = {
  ...tableStyles.tableCol,
  width: "33.3%", // Mayor ancho para los demás
};

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    borderBottomColor: "#000",
    borderBottomWidth: 1,
    alignItems: "center",
    height: 15,
    margin: 0,
    padding: 0,
    justifyContent: "center",
    textAlign: "center",
  },
  cell: {
    fontSize: 9,
    textAlign: "center",
    borderRightColor: "#000",
    borderRightWidth: 1,
    flex: 1,
  },
  cell2: {
    fontSize: 9,
    textAlign: "center",
    borderRightColor: "#000",
    borderRightWidth: 1,
    width: 20,
    borderLeftColor: "#000",
    borderLeftWidth: 1,
  },
  header: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  logo: {
    width: 90, // Ajusta el tamaño según tus necesidades
    height: 30, // Ajusta el tamaño según tus necesidades
  },
  logo2: {
    width: 100,
    height: 20,
  },
  page: {
    padding: 20, // Ajusta el valor del margen según tus necesidades
  },
  letter: {
    fontSize: 10,
    padding: 5,
  },
  letter2: {
    fontSize: 10,
  },
  ruta: {
    fontSize: 7,
    padding: 4,
  },
  bottomText: {
    position: "absolute",
    bottom: 0,
    left: 20, // Ajusta según sea necesario
    right: 20, // Ajusta según sea necesario
    textAlign: "center",
    bottom: 30,
    fontSize: 8,
  },
  bottomText2: {
    position: "absolute",
    bottom: 0,
    left: 20, // Ajusta según sea necesario
    right: 20, // Ajusta según sea necesario
    textAlign: "center",
    bottom: 50,
    fontSize: 11,
    fontWeight: "bold",
  },
});

const MySwal = withReactContent(Swal);

export const Elab_listin_rc = () => {
  const { store } = useContext(Context);
  const [companies, setCompanies] = useState([]);
  const [formData, setFormData] = useState({
    numero_listin: "",
    fecha: "",
    tipo_servicio: "",
    unidad: "",
    placa: "",
    parada: "",
    numero_puestos: "",
    destino_final: "",
    referencia: "",
    ruta: "",
    empresa: "",
    rif: "",
    conductor_1: "",
    cedula_1: "",
    grado_licencia_1: "",
    fecha_vencimiento_licencia_1: "",
    precio_bs: "",
    price_dolar: "",
    por_puesto_permission: "NO",
    formas_de_pago: [
      {
        forma_pago: "",
        total_a_cancelar: "",
        referencia: "",
      },
    ],
  });
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [cpsData, setCpsData] = useState([]);
  const [rutas, setRutas] = useState([]);
  const [valorDolarBs, setValorDolarBs] = useState(0);
  const [originalPriceDolar, setOriginalPriceDolar] = useState(0);
  const [run, setRun] = useState(false);

  const handleStartTutorial = () => {
    setRun(true);
  };

  const selectRuta = async () => {
    const rutaOptions = rutas.map((ruta, index) => ({
      id: index,
      text: ruta,
      value: ruta,
    }));
    setFormData((prevData) => ({
      ...prevData,
      tipo_servicio: "", // Reset service type
    }));

    await MySwal.fire({
      title: "Seleccionar Ruta",
      html: `
        <input type="text" id="searchRuta" class="swal2-input" placeholder="Buscar Ruta">
        ${rutaOptions
          .map(
            (option) =>
              `<div style="cursor: pointer; margin: 10px; padding: 10px; border: 1px solid #ccc;" data-id="${option.id}">${option.text}</div>`
          )
          .join("")}
        <div id="ruta-options"></div>
      `,
      showConfirmButton: true,
      confirmButtonText: "Cerrar",
      didRender: () => {
        const searchInput = document.getElementById("searchRuta");
        searchInput.addEventListener("input", (event) => {
          const filter = event.target.value.toLowerCase();
          const elements = document.querySelectorAll("[data-id]");
          elements.forEach((element) => {
            const text = element.textContent.toLowerCase();
            element.style.display = text.includes(filter) ? "block" : "none";
          });
        });

        const elements = document.querySelectorAll("[data-id]");
        elements.forEach((element) => {
          element.addEventListener("click", () => {
            const id = element.getAttribute("data-id");
            MySwal.clickConfirm();
            const ruta = rutaOptions[id].value;
            setFormData((prevData) => ({
              ...prevData,
              ruta: ruta,
            }));
            const selectedCps = cpsData.find((cps) => cps.cps_route === ruta);
            const price_dolar = selectedCps
              ? parseFloat(selectedCps.price_dolar)
              : 0;
            const porPuestoPermission = selectedCps
              ? selectedCps.por_puesto_permission
              : "NO";
            setOriginalPriceDolar(price_dolar); // Set the original price in dollars
            setFormData((prevData) => ({
              ...prevData,
              destino_final: selectedCps.cps_final_destination,
              referencia: selectedCps.cps_shift_time,
              price_dolar: price_dolar.toFixed(2),
              precio_bs: (price_dolar * valorDolarBs).toFixed(2),
              por_puesto_permission: porPuestoPermission,
            }));
          });
        });
      },
    });
  };

  const selectParada = async () => {
    const uniqueParadas = [...new Set(cpsData.map((cps) => cps.cps_parada))];
    const paradaOptions = uniqueParadas.map((parada, index) => ({
      id: index,
      text: parada,
      value: parada,
    }));

    await MySwal.fire({
      title: "Seleccionar Parada",
      html: `
        <input type="text" id="searchParada" class="swal2-input" placeholder="Buscar Parada">
        ${paradaOptions
          .map(
            (option) =>
              `<div style="cursor: pointer; margin: 10px; padding: 10px; border: 1px solid #ccc;" data-id="${option.id}">${option.text}</div>`
          )
          .join("")}
        <div id="parada-options"></div>
      `,
      showConfirmButton: true,
      confirmButtonText: "Cerrar",
      didRender: () => {
        const searchInput = document.getElementById("searchParada");
        searchInput.addEventListener("input", (event) => {
          const filter = event.target.value.toLowerCase();
          const elements = document.querySelectorAll("[data-id]");
          elements.forEach((element) => {
            const text = element.textContent.toLowerCase();
            element.style.display = text.includes(filter) ? "block" : "none";
          });
        });

        const elements = document.querySelectorAll("[data-id]");
        elements.forEach((element) => {
          element.addEventListener("click", () => {
            const id = element.getAttribute("data-id");
            MySwal.clickConfirm();
            setFormData((prevData) => ({
              ...prevData,
              parada: paradaOptions[id].value,
              ruta: "",
              destino_final: "",
              referencia: "",
              price_dolar: "",
              precio_bs: "",
            }));
            const filteredCps = cpsData.filter(
              (cps) => cps.cps_parada === paradaOptions[id].value
            );
            const uniqueRutas = [
              ...new Set(filteredCps.map((cps) => cps.cps_route)),
            ];
            setRutas(uniqueRutas);
          });
        });
      },
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    // Update form data
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    // Special handling for calculations
    if (name === "empresa") {
      const selectedCompany = companies.find(
        (company) => company.company_name === value
      );
      setFormData((prevData) => ({
        ...prevData,
        rif: selectedCompany ? selectedCompany.company_rif : "",
        taquilla: selectedCompany ? selectedCompany.company_intern_code : "",
        link_logo_empresa: selectedCompany ? selectedCompany.link_logo : "",
        parada: "",
        ruta: "",
        destino_final: "",
        referencia: "",
        price_dolar: "",
        precio_bs: "",
        por_puesto_permission: "NO", // Reset permission
        tipo_servicio: "", // Reset service type
      }));
      setCpsData(selectedCompany ? selectedCompany.cps_data : []);
      setRutas([]);
    }

    if (name === "parada") {
      const filteredCps = cpsData.filter((cps) => cps.cps_parada === value);
      const uniqueRutas = [...new Set(filteredCps.map((cps) => cps.cps_route))];
      setRutas(uniqueRutas);
      setFormData((prevData) => ({
        ...prevData,
        ruta: "",
        destino_final: "",
        referencia: "",
        price_dolar: "",
        precio_bs: "",
        por_puesto_permission: "NO", // Reset permission
        tipo_servicio: "", // Reset service type
      }));
    }

    if (name === "ruta") {
      const selectedCps = cpsData.find((cps) => cps.cps_route === value);
      const price_dolar = selectedCps ? parseFloat(selectedCps.price_dolar) : 0;
      const porPuestoPermission = selectedCps
        ? selectedCps.por_puesto_permission
        : "NO";
      setOriginalPriceDolar(price_dolar); // Set the original price in dollars
      setFormData((prevData) => ({
        ...prevData,
        destino_final: selectedCps ? selectedCps.cps_final_destination : "",
        referencia: selectedCps ? selectedCps.cps_shift_time : "",
        price_dolar: price_dolar.toFixed(2),
        precio_bs: (price_dolar * valorDolarBs).toFixed(2),
        por_puesto_permission: porPuestoPermission,
      }));
    }

    // Perform recalculations based on tipo_servicio and numero_puestos
    if (name === "tipo_servicio" || name === "numero_puestos") {
      const tipo_servicio =
        name === "tipo_servicio" ? value : formData.tipo_servicio;
      const numero_puestos =
        name === "numero_puestos"
          ? parseInt(value)
          : parseInt(formData.numero_puestos);
      let adjustedPrice = originalPriceDolar;

      if (!isNaN(adjustedPrice) && tipo_servicio && numero_puestos) {
        if (tipo_servicio === "POR_PUESTO") {
          const adjustedPrice82 = originalPriceDolar * 7 * 0.03;
          adjustedPrice = adjustedPrice82 + 1;
        } else if (numero_puestos <= 33) {
          adjustedPrice = originalPriceDolar * 33 * 0.03;
        } else if (numero_puestos <= 48) {
          adjustedPrice = originalPriceDolar * 48 * 0.03;
        } else if (numero_puestos <= 100) {
          adjustedPrice = originalPriceDolar * 60 * 0.03;
        }

        setFormData((prevData) => ({
          ...prevData,
          price_dolar: adjustedPrice.toFixed(2),
          precio_bs: (adjustedPrice * valorDolarBs).toFixed(2),
        }));
      }
    }
  };

  const handlePaymentChange = (index, e) => {
    const { name, value } = e.target;
    const updatedFormasDePago = formData.formas_de_pago.map((pago, i) =>
      i === index ? { ...pago, [name]: value } : pago
    );
    setFormData((prevData) => ({
      ...prevData,
      formas_de_pago: updatedFormasDePago,
    }));

    // Calculate total_a_pagar and faltante in bolívares
    const total_a_cancelar = updatedFormasDePago.reduce(
      (acc, pago) => acc + parseFloat(pago.total_a_cancelar || 0),
      0
    );
    const faltante = parseFloat(formData.precio_bs || 0) - total_a_cancelar;

    setFormData((prevData) => ({
      ...prevData,
      total_a_pagar: total_a_cancelar.toFixed(2),
      faltante: faltante.toFixed(2),
    }));
  };

  const removePaymentForm = (index) => {
    const updatedFormasDePago = formData.formas_de_pago.filter(
      (_, i) => i !== index
    );
    setFormData((prevData) => ({
      ...prevData,
      formas_de_pago: updatedFormasDePago,
    }));
  };

  const fetchCompanies = async () => {
    try {
      const response = await axios.get(
        process.env.BACKEND_URL + "/api/getAllCompaniesProgramadorRC",
        {
          headers: {
            Authorization: `Bearer ${store.token}`,
          },
        }
      );

      const data = response.data;
      setCompanies(data);
      MySwal.close(); // Close the loading dialog after fetching the companies
      selectCompanyDialog(data); // Show the company selection dialog
    } catch (error) {
      console.error("Error al obtener empresas registradas", error);
      MySwal.fire({
        title: "Error",
        html: `<p>Error al obtener empresas registradas. Por favor, inténtelo de nuevo más tarde.</p>`,
        icon: "error",
        confirmButtonText: "Cerrar",
      });
    }
  };

  const selectCompany = async () => {
    MySwal.fire({
      title: "Cargando...",
      html: `<p style="text-align:center; margin:20px;">Cargando empresas...</p>`,
      allowOutsideClick: false,
      didOpen: () => {
        MySwal.showLoading();
        fetchCompanies();
      },
    });
  };

  const selectCompanyDialog = (companies) => {
    const companyOptions = companies.map((company, index) => ({
      id: index,
      text: `<strong>Nombre:</strong> ${company.company_name}<br/><strong>RIF:</strong> ${company.company_rif}`,
      value: company,
    }));

    const inputValue = { value: "" };
    MySwal.fire({
      title: "Seleccionar Empresa",
      html: `
        <input type="text" id="searchCompany" class="swal2-input" placeholder="Buscar Empresa">
        ${companyOptions
          .map(
            (option) =>
              `<div style="cursor: pointer; margin: 10px; padding: 10px; border: 1px solid #ccc;" data-id="${option.id}">${option.text}</div>`
          )
          .join("")}
        <div id="company-options"></div>
      `,
      showConfirmButton: true,
      confirmButtonText: "Cerrar",
      didRender: () => {
        const searchInput = document.getElementById("searchCompany");
        searchInput.addEventListener("input", (event) => {
          const filter = event.target.value.toLowerCase();
          const elements = document.querySelectorAll("[data-id]");
          elements.forEach((element) => {
            const text = element.textContent.toLowerCase();
            element.style.display = text.includes(filter) ? "block" : "none";
          });
        });

        const elements = document.querySelectorAll("[data-id]");
        elements.forEach((element) => {
          element.addEventListener("click", () => {
            const id = element.getAttribute("data-id");
            MySwal.clickConfirm();
            setFormData((prevData) => ({
              ...prevData,
              empresa: companyOptions[id].value.company_name,
              rif: companyOptions[id].value.company_rif,
              parada: "",
              ruta: "",
              destino_final: "",
              referencia: "",
              price_dolar: "",
              precio_bs: "",
            }));
            setCpsData(companyOptions[id].value.cps_data);
            setValorDolarBs(companyOptions[id].value.valor);
            setRutas([]);
          });
        });
      },
    });
  };

  const addPaymentForm = () => {
    setFormData({
      ...formData,
      formas_de_pago: [
        ...formData.formas_de_pago,
        { forma_pago: "", total_a_cancelar: "", referencia: "" },
      ],
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Calcula el total de los pagos ingresados
    const totalPagos = formData.formas_de_pago.reduce(
      (acc, pago) => acc + parseFloat(pago.total_a_cancelar || 0),
      0
    );

    // Verifica si el total de los pagos coincide con el precio en bolívares
    if (totalPagos.toFixed(2) !== parseFloat(formData.precio_bs).toFixed(2)) {
      MySwal.fire({
        title: "Error",
        html: `<p>El total de los pagos (${totalPagos.toFixed(2)} Bs) no coincide con el precio en bolívares (${formData.precio_bs} Bs). Por favor, verifica los montos.</p>`,
        icon: "error",
        confirmButtonText: "Cerrar",
      });
      return;
    }

    setSaving(true);
    MySwal.fire({
      title: "Creando Listín",
      html: `<p style="text-align:center; margin:20px;">Por favor, espere...</p>`,
      allowOutsideClick: false,
      didOpen: () => {
        MySwal.showLoading();
      },
    });

    const url =
      formData.tipo_servicio === "POR_PUESTO"
        ? "/api/registerListinRC_porpuesto"
        : "/api/registerListinRC";

    try {
      const response = await axios.post(
        process.env.BACKEND_URL + url,
        {
          empresa: formData.empresa,
          rif: formData.rif,
          unidad: formData.unidad,
          placa: formData.placa,
          numero_puestos: formData.numero_puestos,
          ruta: formData.ruta,
          destino: formData.destino_final,
          tipo_servicio: formData.tipo_servicio,
          conductor_1: formData.conductor_1,
          cedula_1: formData.cedula_1,
          grado_licencia_1: formData.grado_licencia_1,
          fecha_vencimiento_licencia_1: formData.fecha_vencimiento_licencia_1,
          formas_de_pago: formData.formas_de_pago,
          precio_bs: formData.precio_bs,
          price_dolar: formData.price_dolar,
        },
        {
          headers: {
            Authorization: `Bearer ${store.token}`,
          },
        }
      );

      const { numero_listin, listin_id, fechayhora } = response.data;

      let pdfContent;
      if (formData.tipo_servicio === "POR_PUESTO") {
        pdfContent = (
          <Document>
            <Page style={styles.page} size="A4">
              {/* Diseño para "POR PUESTO" */}
              <View style={styles.header}>
                <Image
                  src="https://res.cloudinary.com/dirpdlfbu/image/upload/v1701880341/logo/cmyemflfryypewryq6ux.jpg"
                  style={styles.logo}
                />
                <Image
                  src="https://res.cloudinary.com/dirpdlfbu/image/upload/v1711324140/aeezebl5y4pzhcmgm2da.jpg"
                  style={styles.logo2}
                />
              </View>
              <View style={styles.header}>
                <Text style={styles.letter2}>TERMINAL LA BANDERA</Text>
                <View>
                  <Text
                    style={styles.letter2}
                  >{`LISTIN Nº PP-${numero_listin}`}</Text>

                  <Text style={styles.letter2}>Rutas Interurbanas</Text>
                </View>
              </View>

              {formData && (
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                    marginTop: 8,
                    marginBottom: 8,
                  }}
                >
                  {/* Primera columna */}
                  <View>
                    <Text
                      style={styles.letter}
                    >{`Línea: ${formData.empresa}`}</Text>
                    <Text
                      style={styles.letter}
                    >{`Conductor: ${formData.conductor_1}`}</Text>

                    <Text
                      style={styles.letter}
                    >{`Número de Unidad: ${formData.unidad}`}</Text>
                  </View>

                  {/* Segunda columna */}
                  <View>
                    <Text
                      style={styles.letter}
                    >{`Destino Final: ${formData.destino_final}`}</Text>
                    <Text
                      style={styles.letter}
                    >{`Cédula: ${formData.cedula_1}`}</Text>

                    <Text
                      style={styles.letter}
                    >{`Número de Placa: ${formData.placa}`}</Text>
                  </View>

                  {/* Tercera columna */}
                  <View>
                    <Text
                      style={styles.letter}
                    >{`Fecha y hora: ${fechayhora}`}</Text>
                    <Text
                      style={styles.letter}
                    >{`Precio: ${formData.precio_bs}Bs`}</Text>
                  </View>
                </View>
              )}
              <View style={tableStyles.table}>
                <View style={tableStyles.tableRow}>
                  <View style={tableStyles.tableColHeaderNumber}>
                    <Text style={tableStyles.tableCellHeader}>N°</Text>
                  </View>
                  <View style={tableStyles.tableColHeaderOther}>
                    <Text style={tableStyles.tableCellHeader}>
                      Nombre y Apellido
                    </Text>
                  </View>
                  <View style={tableStyles.tableColHeaderOther}>
                    <Text style={tableStyles.tableCellHeader}>
                      Cédula Identidad
                    </Text>
                  </View>
                  <View style={tableStyles.tableColHeaderNumber}>
                    <Text style={tableStyles.tableCellHeader}>N°</Text>
                  </View>
                  <View style={tableStyles.tableColHeaderOther}>
                    <Text style={tableStyles.tableCellHeader}>
                      Nombre y Apellido
                    </Text>
                  </View>
                  <View style={tableStyles.tableColHeaderOther}>
                    <Text style={tableStyles.tableCellHeader}>
                      Cédula Identidad
                    </Text>
                  </View>
                </View>
                {[...Array(4)].map((_, index) => (
                  <View key={index} style={tableStyles.tableRow}>
                    <View style={tableStyles.tableColNumber}>
                      <Text style={tableStyles.tableCell}>{index * 2 + 1}</Text>
                    </View>
                    <View style={tableStyles.tableColOther}>
                      <Text style={tableStyles.tableCell}></Text>
                    </View>
                    <View style={tableStyles.tableColOther}>
                      <Text style={tableStyles.tableCell}></Text>
                    </View>
                    <View style={tableStyles.tableColNumber}>
                      <Text style={tableStyles.tableCell}>{index * 2 + 2}</Text>
                    </View>
                    <View style={tableStyles.tableColOther}>
                      <Text style={tableStyles.tableCell}></Text>
                    </View>
                    <View style={tableStyles.tableColOther}>
                      <Text style={tableStyles.tableCell}></Text>
                    </View>
                  </View>
                ))}
              </View>

              <Text style={{ textAlign: "center", fontSize: 9 }}>
                El valor del listín es el 3% de la tarifa pagada por pasajero,
                de acuerdo a la publicación del Ministerio del Poder Popular
                para el Transporte, en la Gaceta Oficial de la República
                Bolivariana de Venezuela N° 42.462 de fecha 14/09/2022
              </Text>
              <View
                style={{
                  marginTop: 11,
                  marginBottom: 11,
                  width: "100%",
                  height: 1,
                  backgroundColor: "black",
                }}
              ></View>
              <View style={styles.header}>
                <Image
                  src="https://res.cloudinary.com/dirpdlfbu/image/upload/v1701880341/logo/cmyemflfryypewryq6ux.jpg"
                  style={styles.logo}
                />
                <Image
                  src="https://res.cloudinary.com/dirpdlfbu/image/upload/v1711324140/aeezebl5y4pzhcmgm2da.jpg"
                  style={styles.logo2}
                />
              </View>
              <View style={styles.header}>
                <Text style={styles.letter2}>TERMINAL LA BANDERA</Text>
                <View>
                  <Text
                    style={styles.letter2}
                  >{`LISTIN Nº PP-${numero_listin}`}</Text>

                  <Text style={styles.letter2}>Rutas Interurbanas</Text>
                </View>
              </View>

              {formData && (
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                    marginTop: 8,
                    marginBottom: 8,
                  }}
                >
                  {/* Primera columna */}
                  <View>
                    <Text
                      style={styles.letter}
                    >{`Línea: ${formData.empresa}`}</Text>
                    <Text
                      style={styles.letter}
                    >{`Conductor: ${formData.conductor_1}`}</Text>

                    <Text
                      style={styles.letter}
                    >{`Número de Unidad: ${formData.unidad}`}</Text>
                  </View>

                  {/* Segunda columna */}
                  <View>
                    <Text
                      style={styles.letter}
                    >{`Destino Final: ${formData.destino_final}`}</Text>
                    <Text
                      style={styles.letter}
                    >{`Cédula: ${formData.cedula_1}`}</Text>

                    <Text
                      style={styles.letter}
                    >{`Número de Placa: ${formData.placa}`}</Text>
                  </View>

                  {/* Tercera columna */}
                  <View>
                    <Text
                      style={styles.letter}
                    >{`Fecha y hora: ${fechayhora}`}</Text>
                    <Text
                      style={styles.letter}
                    >{`Precio: ${formData.precio_bs}Bs`}</Text>
                  </View>
                </View>
              )}
              <View style={tableStyles.table}>
                <View style={tableStyles.tableRow}>
                  <View style={tableStyles.tableColHeaderNumber}>
                    <Text style={tableStyles.tableCellHeader}>N°</Text>
                  </View>
                  <View style={tableStyles.tableColHeaderOther}>
                    <Text style={tableStyles.tableCellHeader}>
                      Nombre y Apellido
                    </Text>
                  </View>
                  <View style={tableStyles.tableColHeaderOther}>
                    <Text style={tableStyles.tableCellHeader}>
                      Cédula Identidad
                    </Text>
                  </View>
                  <View style={tableStyles.tableColHeaderNumber}>
                    <Text style={tableStyles.tableCellHeader}>N°</Text>
                  </View>
                  <View style={tableStyles.tableColHeaderOther}>
                    <Text style={tableStyles.tableCellHeader}>
                      Nombre y Apellido
                    </Text>
                  </View>
                  <View style={tableStyles.tableColHeaderOther}>
                    <Text style={tableStyles.tableCellHeader}>
                      Cédula Identidad
                    </Text>
                  </View>
                </View>
                {[...Array(4)].map((_, index) => (
                  <View key={index} style={tableStyles.tableRow}>
                    <View style={tableStyles.tableColNumber}>
                      <Text style={tableStyles.tableCell}>{index * 2 + 1}</Text>
                    </View>
                    <View style={tableStyles.tableColOther}>
                      <Text style={tableStyles.tableCell}></Text>
                    </View>
                    <View style={tableStyles.tableColOther}>
                      <Text style={tableStyles.tableCell}></Text>
                    </View>
                    <View style={tableStyles.tableColNumber}>
                      <Text style={tableStyles.tableCell}>{index * 2 + 2}</Text>
                    </View>
                    <View style={tableStyles.tableColOther}>
                      <Text style={tableStyles.tableCell}></Text>
                    </View>
                    <View style={tableStyles.tableColOther}>
                      <Text style={tableStyles.tableCell}></Text>
                    </View>
                  </View>
                ))}
              </View>

              <Text style={{ textAlign: "center", fontSize: 9 }}>
                El valor del listín es el 3% de la tarifa pagada por pasajero,
                de acuerdo a la publicación del Ministerio del Poder Popular
                para el Transporte, en la Gaceta Oficial de la República
                Bolivariana de Venezuela N° 42.462 de fecha 14/09/2022
              </Text>
              <View
                style={{
                  marginTop: 11,
                  marginBottom: 11,
                  width: "100%",
                  height: 1,
                  backgroundColor: "black",
                }}
              ></View>
              <View style={styles.header}>
                <Image
                  src="https://res.cloudinary.com/dirpdlfbu/image/upload/v1701880341/logo/cmyemflfryypewryq6ux.jpg"
                  style={styles.logo}
                />
                <Image
                  src="https://res.cloudinary.com/dirpdlfbu/image/upload/v1711324140/aeezebl5y4pzhcmgm2da.jpg"
                  style={styles.logo2}
                />
              </View>
              <View style={styles.header}>
                <Text style={styles.letter2}>TERMINAL LA BANDERA</Text>
                <View>
                  <Text
                    style={styles.letter2}
                  >{`LISTIN Nº ${numero_listin}`}</Text>

                  <Text style={styles.letter2}>Rutas Interurbanas</Text>
                </View>
              </View>

              {formData && (
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                    marginTop: 8,
                    marginBottom: 8,
                  }}
                >
                  {/* Primera columna */}
                  <View>
                    <Text
                      style={styles.letter}
                    >{`Línea: ${formData.empresa}`}</Text>
                    <Text
                      style={styles.letter}
                    >{`Conductor: ${formData.conductor_1}`}</Text>

                    <Text
                      style={styles.letter}
                    >{`Número de Unidad: ${formData.unidad}`}</Text>
                  </View>

                  {/* Segunda columna */}
                  <View>
                    <Text
                      style={styles.letter}
                    >{`Destino Final: ${formData.destino_final}`}</Text>
                    <Text
                      style={styles.letter}
                    >{`Cédula: ${formData.cedula_1}`}</Text>

                    <Text
                      style={styles.letter}
                    >{`Número de Placa: ${formData.placa}`}</Text>
                  </View>

                  {/* Tercera columna */}
                  <View>
                    <Text
                      style={styles.letter}
                    >{`Fecha y hora: ${fechayhora}`}</Text>
                    <Text
                      style={styles.letter}
                    >{`Precio: ${formData.precio_bs}Bs`}</Text>
                  </View>
                </View>
              )}
              <View style={tableStyles.table}>
                <View style={tableStyles.tableRow}>
                  <View style={tableStyles.tableColHeaderNumber}>
                    <Text style={tableStyles.tableCellHeader}>N°</Text>
                  </View>
                  <View style={tableStyles.tableColHeaderOther}>
                    <Text style={tableStyles.tableCellHeader}>
                      Nombre y Apellido
                    </Text>
                  </View>
                  <View style={tableStyles.tableColHeaderOther}>
                    <Text style={tableStyles.tableCellHeader}>
                      Cédula Identidad
                    </Text>
                  </View>
                  <View style={tableStyles.tableColHeaderNumber}>
                    <Text style={tableStyles.tableCellHeader}>N°</Text>
                  </View>
                  <View style={tableStyles.tableColHeaderOther}>
                    <Text style={tableStyles.tableCellHeader}>
                      Nombre y Apellido
                    </Text>
                  </View>
                  <View style={tableStyles.tableColHeaderOther}>
                    <Text style={tableStyles.tableCellHeader}>
                      Cédula Identidad
                    </Text>
                  </View>
                </View>
                {[...Array(4)].map((_, index) => (
                  <View key={index} style={tableStyles.tableRow}>
                    <View style={tableStyles.tableColNumber}>
                      <Text style={tableStyles.tableCell}>{index * 2 + 1}</Text>
                    </View>
                    <View style={tableStyles.tableColOther}>
                      <Text style={tableStyles.tableCell}></Text>
                    </View>
                    <View style={tableStyles.tableColOther}>
                      <Text style={tableStyles.tableCell}></Text>
                    </View>
                    <View style={tableStyles.tableColNumber}>
                      <Text style={tableStyles.tableCell}>{index * 2 + 2}</Text>
                    </View>
                    <View style={tableStyles.tableColOther}>
                      <Text style={tableStyles.tableCell}></Text>
                    </View>
                    <View style={tableStyles.tableColOther}>
                      <Text style={tableStyles.tableCell}></Text>
                    </View>
                  </View>
                ))}
              </View>

              <Text style={{ textAlign: "center", fontSize: 9 }}>
                El valor del listín es el 3% de la tarifa pagada por pasajero,
                de acuerdo a la publicación del Ministerio del Poder Popular
                para el Transporte, en la Gaceta Oficial de la República
                Bolivariana de Venezuela N° 42.462 de fecha 14/09/2022
              </Text>
            </Page>
          </Document>
        );
        // ListinesBusesaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa
      } else {
        pdfContent = (
          <Document>
            <Page style={styles.page} size="A4">
              {/* Encabezado con dos logos */}
              <View style={styles.header}>
                <Image
                  src="https://res.cloudinary.com/dirpdlfbu/image/upload/v1701880341/logo/cmyemflfryypewryq6ux.jpg"
                  style={styles.logo}
                />
                <Image
                  src="https://res.cloudinary.com/dirpdlfbu/image/upload/v1711324140/aeezebl5y4pzhcmgm2da.jpg"
                  style={styles.logo2}
                />
              </View>
              <View style={styles.header}>
                <Text style={styles.letter2}>TERMINAL LA BANDERA</Text>
                <View>
                  <Text
                    style={styles.letter2}
                  >{`LISTIN Nº ${numero_listin}`}</Text>

                  <Text style={styles.letter2}>Rutas Interurbanas</Text>
                </View>
              </View>

              {formData && (
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                    borderWidth: 1,
                    borderColor: "#000",
                  }}
                >
                  {/* Primera columna */}
                  <View>
                    <Text
                      style={styles.letter}
                    >{`Línea: ${formData.empresa}`}</Text>
                    <Text
                      style={styles.letter}
                    >{`Destino Final: ${formData.destino_final}`}</Text>
                    <Text
                      style={styles.letter}
                    >{`Número de Unidad: ${formData.unidad}`}</Text>
                    <Text
                      style={styles.letter}
                    >{`Número de Placa: ${formData.placa}`}</Text>
                    <Text
                      style={styles.letter}
                    >{`Número de Puestos: ${formData.numero_puestos}`}</Text>
                  </View>

                  {/* Segunda columna */}
                  <View>
                    {/* <Text
                  style={styles.letter}
                >{`Plataforma: ${formData.platform}`}</Text> */}
                    <Text
                      style={styles.letter}
                    >{`Fecha y hora: ${fechayhora}`}</Text>
                    <Text
                      style={styles.letter}
                    >{`Servicio: ${formData.tipo_servicio}`}</Text>
                    <Text
                      style={styles.letter}
                    >{`Conductor: ${formData.conductor_1}`}</Text>
                    <Text
                      style={styles.letter}
                    >{`Cédula: ${formData.cedula_1}`}</Text>
                    <Text
                      style={styles.letter}
                    >{`Precio: ${formData.precio_bs}Bs`}</Text>
                  </View>

                  {/* Tercera columna */}
                </View>
              )}
              <View style={{ marginTop: 10 }}></View>
              <View style={tableStyles.table}>
                <View style={tableStyles.tableRow}>
                  <View style={tableStyles.tableColHeaderNumber}>
                    <Text style={tableStyles.tableCellHeader}>N°</Text>
                  </View>
                  <View style={tableStyles.tableColHeaderOther}>
                    <Text style={tableStyles.tableCellHeader}>
                      Nombre y Apellido
                    </Text>
                  </View>
                  <View style={tableStyles.tableColHeaderOther}>
                    <Text style={tableStyles.tableCellHeader}>
                      Cédula Identidad
                    </Text>
                  </View>
                  <View style={tableStyles.tableColHeaderNumber}>
                    <Text style={tableStyles.tableCellHeader}>N°</Text>
                  </View>
                  <View style={tableStyles.tableColHeaderOther}>
                    <Text style={tableStyles.tableCellHeader}>
                      Nombre y Apellido
                    </Text>
                  </View>
                  <View style={tableStyles.tableColHeaderOther}>
                    <Text style={tableStyles.tableCellHeader}>
                      Cédula Identidad
                    </Text>
                  </View>
                </View>
                {[...Array(30)].map(
                  (
                    _,
                    index // Para crear 30 filas
                  ) => (
                    <View key={index} style={tableStyles.tableRow}>
                      <View style={tableStyles.tableColNumber}>
                        <Text style={tableStyles.tableCell}>
                          {index * 2 + 1}
                        </Text>
                      </View>
                      <View style={tableStyles.tableColOther}>
                        <Text style={tableStyles.tableCell}></Text>
                      </View>
                      <View style={tableStyles.tableColOther}>
                        <Text style={tableStyles.tableCell}></Text>
                      </View>
                      <View style={tableStyles.tableColNumber}>
                        <Text style={tableStyles.tableCell}>
                          {index * 2 + 2}
                        </Text>
                      </View>
                      <View style={tableStyles.tableColOther}>
                        <Text style={tableStyles.tableCell}></Text>
                      </View>
                      <View style={tableStyles.tableColOther}>
                        <Text style={tableStyles.tableCell}></Text>
                      </View>
                    </View>
                  )
                )}
              </View>

              <Text style={styles.bottomText2}>ORIGINAL: INTRAVIALCA</Text>

              <Text style={styles.bottomText}>
                El valor del listín es el 3% de la tarifa pagada por pasajero,
                de acuerdo a la publicación del Ministerio del Poder Popular
                para el Transporte, en la Gaceta Oficial de la República
                Bolivariana de Venezuela N° 42.462 de fecha 14/09/2022
              </Text>
            </Page>
            <Page style={styles.page} size="A4">
              {/* Encabezado con dos logos */}
              <View style={styles.header}>
                <Image
                  src="https://res.cloudinary.com/dirpdlfbu/image/upload/v1701880341/logo/cmyemflfryypewryq6ux.jpg"
                  style={styles.logo}
                />
                <Image
                  src="https://res.cloudinary.com/dirpdlfbu/image/upload/v1711324140/aeezebl5y4pzhcmgm2da.jpg"
                  style={styles.logo2}
                />
              </View>
              <View style={styles.header}>
                <Text style={styles.letter2}>TERMINAL LA BANDERA</Text>
                <View>
                  <Text
                    style={styles.letter2}
                  >{`LISTIN Nº ${numero_listin}`}</Text>

                  <Text style={styles.letter2}>Rutas Interurbanas</Text>
                </View>
              </View>

              {formData && (
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                    borderWidth: 1,
                    borderColor: "#000",
                  }}
                >
                  {/* Primera columna */}
                  <View>
                    <Text
                      style={styles.letter}
                    >{`Línea: ${formData.empresa}`}</Text>
                    <Text
                      style={styles.letter}
                    >{`Destino Final: ${formData.destino_final}`}</Text>
                    <Text
                      style={styles.letter}
                    >{`Número de Unidad: ${formData.unidad}`}</Text>
                    <Text
                      style={styles.letter}
                    >{`Número de Placa: ${formData.placa}`}</Text>
                    <Text
                      style={styles.letter}
                    >{`Número de Puestos: ${formData.numero_puestos}`}</Text>
                  </View>

                  {/* Segunda columna */}
                  <View>
                    {/* <Text
                  style={styles.letter}
                >{`Plataforma: ${formData.platform}`}</Text> */}
                    <Text
                      style={styles.letter}
                    >{`Fecha y hora: ${fechayhora}`}</Text>
                    <Text
                      style={styles.letter}
                    >{`Servicio: ${formData.tipo_servicio}`}</Text>
                    <Text
                      style={styles.letter}
                    >{`Conductor: ${formData.conductor_1}`}</Text>
                    <Text
                      style={styles.letter}
                    >{`Cédula: ${formData.cedula_1}`}</Text>
                    <Text
                      style={styles.letter}
                    >{`Precio: ${formData.precio_bs}Bs`}</Text>
                  </View>

                  {/* Tercera columna */}
                </View>
              )}
              <View style={{ marginTop: 10 }}></View>
              <View style={tableStyles.table}>
                <View style={tableStyles.tableRow}>
                  <View style={tableStyles.tableColHeaderNumber}>
                    <Text style={tableStyles.tableCellHeader}>N°</Text>
                  </View>
                  <View style={tableStyles.tableColHeaderOther}>
                    <Text style={tableStyles.tableCellHeader}>
                      Nombre y Apellido
                    </Text>
                  </View>
                  <View style={tableStyles.tableColHeaderOther}>
                    <Text style={tableStyles.tableCellHeader}>
                      Cédula Identidad
                    </Text>
                  </View>
                  <View style={tableStyles.tableColHeaderNumber}>
                    <Text style={tableStyles.tableCellHeader}>N°</Text>
                  </View>
                  <View style={tableStyles.tableColHeaderOther}>
                    <Text style={tableStyles.tableCellHeader}>
                      Nombre y Apellido
                    </Text>
                  </View>
                  <View style={tableStyles.tableColHeaderOther}>
                    <Text style={tableStyles.tableCellHeader}>
                      Cédula Identidad
                    </Text>
                  </View>
                </View>
                {[...Array(30)].map(
                  (
                    _,
                    index // Para crear 30 filas
                  ) => (
                    <View key={index} style={tableStyles.tableRow}>
                      <View style={tableStyles.tableColNumber}>
                        <Text style={tableStyles.tableCell}>
                          {index * 2 + 1}
                        </Text>
                      </View>
                      <View style={tableStyles.tableColOther}>
                        <Text style={tableStyles.tableCell}></Text>
                      </View>
                      <View style={tableStyles.tableColOther}>
                        <Text style={tableStyles.tableCell}></Text>
                      </View>
                      <View style={tableStyles.tableColNumber}>
                        <Text style={tableStyles.tableCell}>
                          {index * 2 + 2}
                        </Text>
                      </View>
                      <View style={tableStyles.tableColOther}>
                        <Text style={tableStyles.tableCell}></Text>
                      </View>
                      <View style={tableStyles.tableColOther}>
                        <Text style={tableStyles.tableCell}></Text>
                      </View>
                    </View>
                  )
                )}
              </View>

              <Text style={styles.bottomText2}>COPIA: LÍNEA DE TRANSPORTE</Text>

              <Text style={styles.bottomText}>
                El valor del listín es el 3% de la tarifa pagada por pasajero,
                de acuerdo a la publicación del Ministerio del Poder Popular
                para el Transporte, en la Gaceta Oficial de la República
                Bolivariana de Venezuela N° 42.462 de fecha 14/09/2022
              </Text>
            </Page>
            <Page style={styles.page} size="A4">
              {/* Encabezado con dos logos */}
              <View style={styles.header}>
                <Image
                  src="https://res.cloudinary.com/dirpdlfbu/image/upload/v1701880341/logo/cmyemflfryypewryq6ux.jpg"
                  style={styles.logo}
                />
                <Image
                  src="https://res.cloudinary.com/dirpdlfbu/image/upload/v1711324140/aeezebl5y4pzhcmgm2da.jpg"
                  style={styles.logo2}
                />
              </View>
              <View style={styles.header}>
                <Text style={styles.letter2}>TERMINAL LA BANDERA</Text>
                <View>
                  <Text
                    style={styles.letter2}
                  >{`LISTIN Nº ${numero_listin}`}</Text>

                  <Text style={styles.letter2}>Rutas Interurbanas</Text>
                </View>
              </View>

              {formData && (
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                    borderWidth: 1,
                    borderColor: "#000",
                  }}
                >
                  {/* Primera columna */}
                  <View>
                    <Text
                      style={styles.letter}
                    >{`Línea: ${formData.empresa}`}</Text>
                    <Text
                      style={styles.letter}
                    >{`Destino Final: ${formData.destino_final}`}</Text>
                    <Text
                      style={styles.letter}
                    >{`Número de Unidad: ${formData.unidad}`}</Text>
                    <Text
                      style={styles.letter}
                    >{`Número de Placa: ${formData.placa}`}</Text>
                    <Text
                      style={styles.letter}
                    >{`Número de Puestos: ${formData.numero_puestos}`}</Text>
                  </View>

                  {/* Segunda columna */}
                  <View>
                    {/* <Text
                  style={styles.letter}
                >{`Plataforma: ${formData.platform}`}</Text> */}
                    <Text
                      style={styles.letter}
                    >{`Fecha y hora: ${fechayhora}`}</Text>
                    <Text
                      style={styles.letter}
                    >{`Servicio: ${formData.tipo_servicio}`}</Text>
                    <Text
                      style={styles.letter}
                    >{`Conductor: ${formData.conductor_1}`}</Text>
                    <Text
                      style={styles.letter}
                    >{`Cédula: ${formData.cedula_1}`}</Text>
                    <Text
                      style={styles.letter}
                    >{`Precio: ${formData.precio_bs}Bs`}</Text>
                  </View>

                  {/* Tercera columna */}
                </View>
              )}
              <View style={{ marginTop: 10 }}></View>
              <View style={tableStyles.table}>
                <View style={tableStyles.tableRow}>
                  <View style={tableStyles.tableColHeaderNumber}>
                    <Text style={tableStyles.tableCellHeader}>N°</Text>
                  </View>
                  <View style={tableStyles.tableColHeaderOther}>
                    <Text style={tableStyles.tableCellHeader}>
                      Nombre y Apellido
                    </Text>
                  </View>
                  <View style={tableStyles.tableColHeaderOther}>
                    <Text style={tableStyles.tableCellHeader}>
                      Cédula Identidad
                    </Text>
                  </View>
                  <View style={tableStyles.tableColHeaderNumber}>
                    <Text style={tableStyles.tableCellHeader}>N°</Text>
                  </View>
                  <View style={tableStyles.tableColHeaderOther}>
                    <Text style={tableStyles.tableCellHeader}>
                      Nombre y Apellido
                    </Text>
                  </View>
                  <View style={tableStyles.tableColHeaderOther}>
                    <Text style={tableStyles.tableCellHeader}>
                      Cédula Identidad
                    </Text>
                  </View>
                </View>
                {[...Array(30)].map(
                  (
                    _,
                    index // Para crear 30 filas
                  ) => (
                    <View key={index} style={tableStyles.tableRow}>
                      <View style={tableStyles.tableColNumber}>
                        <Text style={tableStyles.tableCell}>
                          {index * 2 + 1}
                        </Text>
                      </View>
                      <View style={tableStyles.tableColOther}>
                        <Text style={tableStyles.tableCell}></Text>
                      </View>
                      <View style={tableStyles.tableColOther}>
                        <Text style={tableStyles.tableCell}></Text>
                      </View>
                      <View style={tableStyles.tableColNumber}>
                        <Text style={tableStyles.tableCell}>
                          {index * 2 + 2}
                        </Text>
                      </View>
                      <View style={tableStyles.tableColOther}>
                        <Text style={tableStyles.tableCell}></Text>
                      </View>
                      <View style={tableStyles.tableColOther}>
                        <Text style={tableStyles.tableCell}></Text>
                      </View>
                    </View>
                  )
                )}
              </View>

              <Text style={styles.bottomText2}>COPIA: INTT</Text>

              <Text style={styles.bottomText}>
                El valor del listín es el 3% de la tarifa pagada por pasajero,
                de acuerdo a la publicación del Ministerio del Poder Popular
                para el Transporte, en la Gaceta Oficial de la República
                Bolivariana de Venezuela N° 42.462 de fecha 14/09/2022
              </Text>
            </Page>
          </Document>
        );
      }

      const blob = await pdf(pdfContent).toBlob();

      const pdfFormData = new FormData();
      pdfFormData.append("file", blob, "listin.pdf");

      // Paso 3: Subir el PDF a Cloudinary
      const uploadResponse = await axios.post(
        `https://api.cloudinary.com/v1_1/${process.env.CLOUDINARY_CLOUD_NAME}/raw/upload`,
        pdfFormData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          params: {
            upload_preset: process.env.CLOUDINARY_UPLOAD_PRESET,
            folder: `LISTINES/CSS-TLB_rc`,
          },
        }
      );

      const linkListin = uploadResponse.data.secure_url;

      // Paso 4: Actualizar el registro con el enlace del PDF
      await axios.post(
        process.env.BACKEND_URL + "/api/updateListinRC",
        {
          listin_id,
          link_listin: linkListin,
        },
        {
          headers: {
            Authorization: `Bearer ${store.token}`,
            "Content-Type": "application/json",
          },
        }
      );

      setFormData({
        numero_listin: "",
        fecha: "",
        tipo_servicio: "",
        unidad: "",
        placa: "",
        parada: "",
        numero_puestos: "",
        destino_final: "",
        anden: "",
        referencia: "",
        ruta: "",
        empresa: "",
        rif: "",
        conductor_1: "",
        cedula_1: "",
        grado_licencia_1: "",
        fecha_vencimiento_licencia_1: "",
        precio_bs: "",
        price_dolar: "",
        por_puesto_permission: "NO",
        formas_de_pago: [
          {
            forma_pago: "",
            total_a_cancelar: "",
            referencia: "",
          },
        ],
      });

      MySwal.close();

      MySwal.fire({
        title: "Registro de Listín Exitoso",
        text: "El listín ha sido registrado exitosamente.",
        icon: "success",
        confirmButtonText: "Cerrar",
      });

      window.open(linkListin, "Listín");
    } catch (error) {
      console.error("Error al registrar el listín", error);
      MySwal.fire({
        title: "Error",
        html: `<p>Error al registrar el listín. Por favor, vuelve a intentarlo.</p>`,
        icon: "error",
        confirmButtonText: "Cerrar",
      });
    } finally {
      setSaving(false);
    }
  };

  const fetchTodayListines = async () => {
    MySwal.fire({
      title: "Cargando...",
      html: `<p style="text-align:center; margin:20px;">Cargando listines de hoy...</p>`,
      allowOutsideClick: false,
      didOpen: () => {
        MySwal.showLoading();
      },
    });

    try {
      const response = await axios.get(
        process.env.BACKEND_URL + "/api/getTodayListinesRC",
        {
          headers: {
            Authorization: `Bearer ${store.token}`,
          },
        }
      );

      const data = response.data;

      MySwal.close();

      if (!data || data.length === 0) {
        MySwal.fire({
          title: "Listines de Hoy",
          html: `<p style="text-align:center; margin:20px;">No se encontraron listines.</p>`,
          confirmButtonText: "Cerrar",
        });
      } else {
        const tableHeaders = `
          <tr>
            <th>Número Listín</th>
            <th>Fecha</th>
            <th>Tipo de Servicio</th>
            <th>Unidad</th>
            <th>Placa</th>
            <th>Número de Puestos</th>
            <th>Destino</th>
            <th>Ruta</th>
            <th>Conductor 1</th>
            <th>Cédula 1</th>
            <th>Grado Licencia 1</th>
            <th>Fecha Venc. Licencia 1</th>
            <th>Precio Bs</th>
            <th>Registrado por</th>
            <th>Estatus</th>
            <th>Acciones</th>
          </tr>
        `;

        const tableRows = data
          .map(
            (item) => `
              <tr style="background-color: ${item.estatus === "ACTIVO" ? "#d4edda" : "#f8d7da"};">
                <td>${item.n_listin}</td>
                <td>${item.fecha}</td>
                <td>${item.tipo_de_servicio}</td>
                <td>${item.n_unidad}</td>
                <td>${item.placa}</td>
                <td>${item.n_puestos}</td>
                <td>${item.destino_final}</td>
                <td>${item.ruta}</td>
                <td>${item.conductor_1}</td>
                <td>${item.cedula_1}</td>
                <td>${item.grado_licencia_1}</td>
                <td>${item.fecha_vencimiento_licencia_1}</td>
                <td>${item.precio_bs}</td>
                <td>${item.registrado_por}</td>
                <td>${item.estatus}</td>
                <td>
                  <a href="${item.link_listin}" target="_blank" class="btn btn-primary btn-sm">Ver PDF</a>
                  <button class="btn btn-secondary btn-sm" data-id="${item.id}">
                    ${item.estatus === "ACTIVO" ? "Anular" : "Activar"}
                  </button>
                </td>
              </tr>
            `
          )
          .join("");

        const table = `
          <input type="text" id="search-listines" placeholder="Buscar..." class="swal2-input" style="margin-bottom: 10px;">
          <div style="max-height: 400px; overflow-y: auto;">
            <table style="width: 2500px; border-collapse: collapse; font-size: 12px;">
              <thead>
                ${tableHeaders}
              </thead>
              <tbody id="listines-list">
                ${tableRows}
              </tbody>
            </table>
          </div>
        `;

        MySwal.fire({
          title: "Listines de Hoy",
          html: table,
          showConfirmButton: true,
          confirmButtonText: "Cerrar",
          didRender: () => {
            const searchInput = document.getElementById("search-listines");
            searchInput.addEventListener("input", (event) => {
              const searchTerm = event.target.value.toLowerCase();
              const listinesList = document.getElementById("listines-list");
              const filteredRows = data
                .filter(
                  (item) =>
                    item.n_listin.toLowerCase().includes(searchTerm) ||
                    item.fecha.toLowerCase().includes(searchTerm) ||
                    item.tipo_de_servicio.toLowerCase().includes(searchTerm) ||
                    item.empresa.toLowerCase().includes(searchTerm) ||
                    item.placa.toLowerCase().includes(searchTerm) ||
                    item.n_unidad.toLowerCase().includes(searchTerm) ||
                    item.conductor_1.toLowerCase().includes(searchTerm) ||
                    item.cedula_1.toLowerCase().includes(searchTerm) ||
                    item.destino_final.toLowerCase().includes(searchTerm) ||
                    item.precio_bs.toLowerCase().includes(searchTerm)
                )
                .map(
                  (item) => `
                    <tr style="background-color: ${item.estatus === "ACTIVO" ? "#d4edda" : "#f8d7da"};">
                      <td>${item.n_listin}</td>
                      <td>${item.fecha}</td>
                      <td>${item.tipo_de_servicio}</td>
                      <td>${item.n_unidad}</td>
                      <td>${item.placa}</td>
                      <td>${item.n_puestos}</td>
                      <td>${item.destino_final}</td>
                      <td>${item.ruta}</td>
                      <td>${item.conductor_1}</td>
                      <td>${item.cedula_1}</td>
                      <td>${item.grado_licencia_1}</td>
                      <td>${item.fecha_vencimiento_licencia_1}</td>
                      <td>${item.precio_bs}</td>
                      <td>${item.registrado_por}</td>
                      <td>${item.estatus}</td>
                      <td>
                        <a href="${item.link_listin}" target="_blank" class="btn btn-primary btn-sm">Ver PDF</a>
                        <button class="btn btn-secondary btn-sm" data-id="${item.id}">
                          ${item.estatus === "ACTIVO" ? "Anular" : "Activar"}
                        </button>
                      </td>
                    </tr>
                  `
                )
                .join("");
              listinesList.innerHTML = filteredRows;
            });

            document.querySelectorAll(".btn-secondary").forEach((button) => {
              button.addEventListener("click", async (event) => {
                const listinId = event.currentTarget.getAttribute("data-id");
                const newStatus =
                  event.currentTarget.innerText === "Anular"
                    ? "ANULADO"
                    : "ACTIVO";

                try {
                  await axios.post(
                    `${process.env.BACKEND_URL}/api/updateListinStatus`,
                    { id: listinId, estatus: newStatus },
                    {
                      headers: {
                        Authorization: `Bearer ${store.token}`,
                        "Content-Type": "application/json",
                      },
                    }
                  );

                  MySwal.fire({
                    title: `Listín ${newStatus}`,
                    text: `El listín ha sido ${newStatus.toLowerCase()} exitosamente.`,
                    icon: "success",
                    confirmButtonText: "Cerrar",
                  });

                  fetchTodayListines(); // Recargar los listines después de actualizar el estatus
                } catch (error) {
                  MySwal.fire({
                    title: "Error",
                    text: "Hubo un error al actualizar el estatus del listín.",
                    icon: "error",
                    confirmButtonText: "Cerrar",
                  });
                }
              });
            });
          },
        });
      }
    } catch (error) {
      console.error("Error al obtener listines de hoy", error);
      MySwal.fire({
        title: "Error",
        html: `<p>Error al obtener listines de hoy. Por favor, inténtelo de nuevo más tarde.</p>`,
        icon: "error",
        confirmButtonText: "Cerrar",
      });
    }
  };

  const fetchTodayListinesPP = async () => {
    MySwal.fire({
      title: "Cargando...",
      html: `<p style="text-align:center; margin:20px;">Cargando listines de hoy...</p>`,
      allowOutsideClick: false,
      didOpen: () => {
        MySwal.showLoading();
      },
    });

    try {
      const response = await axios.get(
        process.env.BACKEND_URL + "/api/getTodayListinesRCPP",
        {
          headers: {
            Authorization: `Bearer ${store.token}`,
          },
        }
      );

      const data = response.data;

      MySwal.close();

      if (!data || data.length === 0) {
        MySwal.fire({
          title: "Listines de Hoy",
          html: `<p style="text-align:center; margin:20px;">No se encontraron listines.</p>`,
          confirmButtonText: "Cerrar",
        });
      } else {
        const tableHeaders = `
          <tr>
            <th>Número Listín</th>
            <th>Fecha</th>
            <th>Tipo de Servicio</th>
            <th>Unidad</th>
            <th>Placa</th>
            <th>Número de Puestos</th>
            <th>Destino</th>
            <th>Ruta</th>
            <th>Conductor 1</th>
            <th>Cédula 1</th>
            <th>Grado Licencia 1</th>
            <th>Fecha Venc. Licencia 1</th>
            <th>Precio Bs</th>
            <th>Registrado por</th>
            <th>Estatus</th>
            <th>Acciones</th>
          </tr>
        `;

        const tableRows = data
          .map(
            (item) => `
              <tr style="background-color: ${item.estatus === "ACTIVO" ? "#d4edda" : "#f8d7da"};">
                <td>${item.n_listin}</td>
                <td>${item.fecha}</td>
                <td>${item.tipo_de_servicio}</td>
                <td>${item.n_unidad}</td>
                <td>${item.placa}</td>
                <td>${item.n_puestos}</td>
                <td>${item.destino_final}</td>
                <td>${item.ruta}</td>
                <td>${item.conductor_1}</td>
                <td>${item.cedula_1}</td>
                <td>${item.grado_licencia_1}</td>
                <td>${item.fecha_vencimiento_licencia_1}</td>
                <td>${item.precio_bs}</td>
                <td>${item.registrado_por}</td>
                <td>${item.estatus}</td>
                <td>
                  <a href="${item.link_listin}" target="_blank" class="btn btn-primary btn-sm">Ver PDF</a>
                  <button class="btn btn-secondary btn-sm" data-id="${item.id}">
                    ${item.estatus === "ACTIVO" ? "Anular" : "Activar"}
                  </button>
                </td>
              </tr>
            `
          )
          .join("");

        const table = `
          <input type="text" id="search-listines" placeholder="Buscar..." class="swal2-input" style="margin-bottom: 10px;">
          <div style="max-height: 400px; overflow-y: auto;">
            <table style="width: 2500px; border-collapse: collapse; font-size: 12px;">
              <thead>
                ${tableHeaders}
              </thead>
              <tbody id="listines-list">
                ${tableRows}
              </tbody>
            </table>
          </div>
        `;

        MySwal.fire({
          title: "Listines de Hoy",
          html: table,
          showConfirmButton: true,
          confirmButtonText: "Cerrar",
          didRender: () => {
            const searchInput = document.getElementById("search-listines");
            searchInput.addEventListener("input", (event) => {
              const searchTerm = event.target.value.toLowerCase();
              const listinesList = document.getElementById("listines-list");
              const filteredRows = data
                .filter(
                  (item) =>
                    item.n_listin.toLowerCase().includes(searchTerm) ||
                    item.fecha.toLowerCase().includes(searchTerm) ||
                    item.tipo_de_servicio.toLowerCase().includes(searchTerm) ||
                    item.empresa.toLowerCase().includes(searchTerm) ||
                    item.placa.toLowerCase().includes(searchTerm) ||
                    item.n_unidad.toLowerCase().includes(searchTerm) ||
                    item.conductor_1.toLowerCase().includes(searchTerm) ||
                    item.cedula_1.toLowerCase().includes(searchTerm) ||
                    item.destino_final.toLowerCase().includes(searchTerm) ||
                    item.precio_bs.toLowerCase().includes(searchTerm)
                )
                .map(
                  (item) => `
                    <tr style="background-color: ${item.estatus === "ACTIVO" ? "#d4edda" : "#f8d7da"};">
                      <td>${item.n_listin}</td>
                      <td>${item.fecha}</td>
                      <td>${item.tipo_de_servicio}</td>
                      <td>${item.n_unidad}</td>
                      <td>${item.placa}</td>
                      <td>${item.n_puestos}</td>
                      <td>${item.destino_final}</td>
                      <td>${item.ruta}</td>
                      <td>${item.conductor_1}</td>
                      <td>${item.cedula_1}</td>
                      <td>${item.grado_licencia_1}</td>
                      <td>${item.fecha_vencimiento_licencia_1}</td>
                      <td>${item.precio_bs}</td>
                      <td>${item.registrado_por}</td>
                      <td>${item.estatus}</td>
                      <td>
                        <a href="${item.link_listin}" target="_blank" class="btn btn-primary btn-sm">Ver PDF</a>
                        <button class="btn btn-secondary btn-sm" data-id="${item.id}">
                          ${item.estatus === "ACTIVO" ? "Anular" : "Activar"}
                        </button>
                      </td>
                    </tr>
                  `
                )
                .join("");
              listinesList.innerHTML = filteredRows;
            });

            document.querySelectorAll(".btn-secondary").forEach((button) => {
              button.addEventListener("click", async (event) => {
                const listinId = event.currentTarget.getAttribute("data-id");
                const newStatus =
                  event.currentTarget.innerText === "Anular"
                    ? "ANULADO"
                    : "ACTIVO";

                try {
                  await axios.post(
                    `${process.env.BACKEND_URL}/api/updateListinStatus`,
                    { id: listinId, estatus: newStatus },
                    {
                      headers: {
                        Authorization: `Bearer ${store.token}`,
                        "Content-Type": "application/json",
                      },
                    }
                  );

                  MySwal.fire({
                    title: `Listín ${newStatus}`,
                    text: `El listín ha sido ${newStatus.toLowerCase()} exitosamente.`,
                    icon: "success",
                    confirmButtonText: "Cerrar",
                  });

                  fetchTodayListines(); // Recargar los listines después de actualizar el estatus
                } catch (error) {
                  MySwal.fire({
                    title: "Error",
                    text: "Hubo un error al actualizar el estatus del listín.",
                    icon: "error",
                    confirmButtonText: "Cerrar",
                  });
                }
              });
            });
          },
        });
      }
    } catch (error) {
      console.error("Error al obtener listines de hoy", error);
      MySwal.fire({
        title: "Error",
        html: `<p>Error al obtener listines de hoy. Por favor, inténtelo de nuevo más tarde.</p>`,
        icon: "error",
        confirmButtonText: "Cerrar",
      });
    }
  };

  const [tourSteps, setTourSteps] = useState([
    {
      target: '.inputDeLogin[name="empresa"]',
      content: "Aquí seleccionas la empresa.",
    },
    {
      target: '.inputDeLogin[name="rif"]',
      content: "Aquí se colocará el RIF de la empresa escogida.",
    },
    {
      target: '.inputDeLogin[name="parada"]',
      content: "Selecciona si sale desde tu terminal o es un token.",
    },
    {
      target: '.inputDeLogin[name="ruta"]',
      content: "Selecciona la ruta a utilizar.",
    },
    {
      target: '.inputDeLogin[name="tipo_servicio"]',
      content:
        "Selecciona el tipo de servicio aquí. La opción (POR PUESTO) solo estará disponible cuando esté autorizada. En caso de no estarlo contactar a la Directora o quien autorice",
    },
    {
      target: '.inputDeLogin[name="precio_bs"]',
      content:
        "A medida que vayas completando el formulario se irá colocando el total a pagar en Bs, y su referencia al lado en dólares. Recuerda siempre verificar el total, en caso de error por favor contáctanos",
    },
    {
      target: '.inputDeLogin[name="forma_pago"]',
      content:
        "Selecciona la forma de pago, puedes agregar cuantas formas de pago como quieras. En el botón inferior (Agregar forma de pago)",
    },
  ]);

  return (
    <div
      className="container-fluid contarinerGeneralUserHomejs"
      style={{ paddingTop: "30px" }}
    >
      <div className="container-fluid contaosijdasd-qw89d77asd223"></div>
      <h2
        className="asjdansdas-d4as6489text w-100"
        style={{ zIndex: "15", marginTop: "-17vh" }}
      >
        Registro de Listines
      </h2>
      <button
        data-tip
        data-for="tutorial-tooltip"
        className="sdyyge98btntbtntntbt9nt789d49q8wequestion"
        style={{
          position: "absolute",
          right: "30px",
          zIndex: 20,
        }}
        onClick={handleStartTutorial}
      >
        <i className="fa-solid fa-question"></i>
      </button>
      <ReactTooltip id="tutorial-tooltip" place="top" effect="solid">
        Click aquí para iniciar el tutorial
      </ReactTooltip>
      <div className="container asklnduiashndaijsnxansxsaas">
        <Joyride
          steps={tourSteps}
          continuous
          showProgress
          showSkipButton
          disableScrolling={false} // Permite el desplazamiento
          run={run} // Controla el inicio del tutorial
          styles={{
            options: {
              zIndex: 10000, // Asegura que el tour esté por encima de otros elementos
            },
            spotlight: {
              borderRadius: "5px",
            },
          }}
          callback={(data) => {
            if (data.status === "finished" || data.status === "skipped") {
              setRun(false); // Detener el tutorial cuando se haya completado o saltado
            }
          }}
        />
        <form
          className="d-flex sd98f4e8wf4cwe987cwe984cwe"
          onSubmit={handleSubmit}
        >
          <div className="aoshduiahsdkasnmdasdas">
            <div className="input-group-login2">
              <input
                className="inputDeLogin"
                type="text"
                name="empresa"
                value={formData.empresa}
                readOnly
                onClick={selectCompany}
              />
              <label>Empresa</label>
            </div>
            <div className="input-group-login2">
              <input
                className="inputDeLogin"
                type="text"
                name="rif"
                value={formData.rif}
                required
              />
              <label>RIF</label>
            </div>
            <div className="input-group-login2">
              <input
                className="inputDeLogin"
                type="text"
                name="parada"
                value={formData.parada}
                readOnly
                required
                onClick={selectParada}
              />
              <label>Parada</label>
            </div>
          </div>
          <div className="aoshduiahsdkasnmdasdas">
            <div className="input-group-login2">
              <textarea
                className="inputDeLogin"
                type="text"
                name="ruta"
                value={formData.ruta}
                readOnly
                required
                onClick={selectRuta}
              />
              <label>Ruta</label>
            </div>
            <div className="input-group-login2">
              <input
                className="inputDeLogin"
                type="text"
                name="destino_final"
                value={formData.destino_final}
                required
              />
              <label>Destino Final</label>
            </div>
          </div>

          <div className="aoshduiahsdkasnmdasdas">
            <div className="input-group-login2">
              <select
                className="inputDeLogin"
                name="tipo_servicio"
                onChange={handleChange}
                value={formData.tipo_servicio}
                required
              >
                <option value="" disabled>
                  Selecciona un Servicio
                </option>
                <option value="MINIBUS">MINIBUS</option>
                <option value="YUTONG">YUTONG</option>
                <option value="AUTOBUS">AUTOBUS</option>
                <option
                  style={{ background: "#9aa7b4" }}
                  value="POR_PUESTO"
                  disabled={formData.por_puesto_permission === "NO"}
                >
                  POR PUESTO
                </option>
              </select>
              <label>Tipo de Servicio</label>
            </div>
            <div className="input-group-login2">
              <input
                className="inputDeLogin"
                type="text"
                name="unidad"
                value={formData.unidad}
                required
                onChange={handleChange}
                style={{ textTransform: "uppercase" }}
              />
              <label>Número de Unidad</label>
            </div>
            <div className="input-group-login2">
              <input
                className="inputDeLogin"
                type="text"
                name="placa"
                value={formData.placa}
                required
                style={{ textTransform: "uppercase" }}
                onChange={handleChange}
              />
              <label>Placa</label>
            </div>
            <div className="input-group-login2">
              <input
                className="inputDeLogin"
                type="number"
                name="numero_puestos"
                value={formData.numero_puestos}
                required
                onChange={handleChange}
              />
              <label>Número de Puestos</label>
            </div>
          </div>

          <div className="aoshduiahsdkasnmdasdas">
            <div className="input-group-login2">
              <input
                className="inputDeLogin"
                type="text"
                name="conductor_1"
                value={formData.conductor_1}
                required
                onChange={handleChange}
                style={{ textTransform: "uppercase" }}
              />
              <label>Conductor</label>
            </div>
            <div className="input-group-login2">
              <input
                className="inputDeLogin"
                type="text"
                name="cedula_1"
                value={formData.cedula_1}
                required
                onChange={handleChange}
              />
              <label>Cédula</label>
            </div>
            <div className="input-group-login2">
              <select
                name="grado_licencia_1"
                value={formData.grado_licencia_1}
                className="inputDeLogin"
                required
                onChange={handleChange}
              >
                <option value="" disabled>
                  Selecciona
                </option>
                <option value="QUINTA(5)">QUINTA(5)</option>
                <option value="TITULO PROFESIONAL">TITULO PROFESIONAL</option>
              </select>
              <label>Grado Licencia</label>
            </div>
            <div className="input-group-login2">
              <input
                className="inputDeLogin"
                type="date"
                name="fecha_vencimiento_licencia_1"
                value={formData.fecha_vencimiento_licencia_1}
                required
                onChange={handleChange}
              />
              <label>Fecha Venc. Licencia</label>
            </div>
          </div>

          <div className="aoshduiahsdkasnmdasdas">
            <div className="input-group-login2">
              <input
                className="inputDeLogin"
                type="text"
                name="precio_bs"
                value={formData.precio_bs}
                readOnly
              />
              <label>Precio Bs</label>
            </div>
            <div className="input-group-login2">
              <input
                className="inputDeLogin"
                type="text"
                name="price_dolar"
                value={formData.price_dolar}
                readOnly
                required
              />
              <label>Precio $ (Referencia)</label>
            </div>
          </div>
          {formData.formas_de_pago.map((pago, index) => (
            <div key={index} style={{ width: "100%" }}>
              <div
                className="aoshduiahsdkasnmdasdas223"
                style={{ padding: "10px", alignItems: "center" }}
              >
                <div>{index + 1}</div>
                <div className="input-group-login2" style={{ margin: 0 }}>
                  <select
                    name="forma_pago"
                    value={pago.forma_pago}
                    className="inputDeLogin"
                    onChange={(e) => handlePaymentChange(index, e)}
                    required
                  >
                    <option value="" disabled>
                      forma de pago
                    </option>
                    <option value="efectivo">Efectivo</option>
                    <option value="punto_de_venta">Punto de Venta</option>
                    <option value="me">ME</option>
                  </select>
                </div>
                <div className="input-group-login2" style={{ margin: 0 }}>
                  <input
                    name="total_a_cancelar"
                    type="number"
                    className="inputDeLogin"
                    value={pago.total_a_cancelar}
                    onChange={(e) => handlePaymentChange(index, e)}
                    required
                  />
                  <label>Total a Pagar en Bs</label>
                </div>
                <div className="input-group-login2" style={{ margin: 0 }}>
                  <input
                    name="referencia"
                    className="inputDeLogin"
                    value={pago.referencia}
                    onChange={(e) => handlePaymentChange(index, e)}
                  />
                  <label>Referencia (Opcional)</label>
                </div>
                <button
                  type="button"
                  onClick={() => removePaymentForm(index)}
                  className="btn sdf54er9fe89rf7wq9e8f4er98w7f98qew4dq9w7ycicontrash"
                  style={{ marginLeft: "10px" }}
                >
                  <i className="fa-solid fa-trash-can"></i>
                </button>
              </div>
            </div>
          ))}
          <button
            className="btn as56d4d9d8wef7we89f49t8j76uytbtntbtntb"
            onClick={addPaymentForm}
            type="button"
          >
            Agregar Forma de Pago
          </button>
          <div className="ipy4gvwe98f7we98f4ert78ghr8eh4we98f7w98r4et9r8">
            <h5>Total a pagar: Bs {formData.total_a_pagar}</h5>
            <h5>Faltante: Bs {formData.faltante}</h5>
          </div>

          <div className="w-100 asd9as-da9s84dx9a8qdw-qdw asdqw98d4qv895y4n9r84bvr98ebtnbtn">
            <button
              type="submit"
              className="btn buttonCargadeDatosDeAddDriver"
              style={{ marginBottom: "30px" }}
              disabled={saving}
            >
              <div className="d-flex">
                <p style={{ marginBottom: 0 }}>Guardar Datos</p>
                <i className="fa-solid fa-arrow-right flechaHomePresentation2"></i>
              </div>
            </button>
          </div>
        </form>
      </div>
      <div className="w-100 asd9as-da9s84dx9a8qdw-qdw asdqw98d4qv895y4n9r84bvr98ebtnbtn poja65s4d8a9s7d89errg98er7fqwd9849iu9ykj7asj">
        <button
          className="btn asd98q7wd65q1wd8xqw49x8e4wc"
          onClick={fetchTodayListines}
        >
          <i
            className="fa-solid fa-bus"
            style={{ marginRight: "7px", fontSize: "20px" }}
          ></i>
          Ver Listines De Buses
        </button>
        <button
          className="btn asd98q7wd65q1wd8xqw49x8e4wc s98d4s98f498t4hj69ui84ukjfg4er987w98f7we98"
          onClick={fetchTodayListinesPP}
        >
          <i
            className="fa-solid fa-car-rear"
            style={{ marginRight: "7px", fontSize: "20px" }}
          ></i>
          Ver Listines de Por Puesto
        </button>
      </div>
    </div>
  );
};
