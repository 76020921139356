import React, { useState, useEffect, useContext } from "react";
import { Context } from "./store/appContext";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import ScrollToTop from "./component/scrollToTop";
import { BackendURL } from "./component/backendURL";
import { Redirect } from "react-router-dom";
import "../styles/AddDriverForm.css";
import "../styles/UserHome.css";

import LoadingOverlay from "../js/pages/LadingOverlay";

import { Front2 } from "../js/pages/Front2";
import { Navbar } from "./Navbar";
import { Navbar_sup } from "./Navbar_sup";
import { UserHome } from "./userHome";
import { RegistroUserTerminal } from "./RegistroUserTerminal";

export const Layoutadmin29640206 = () => {
  const { store } = useContext(Context);
  const [isLoading, setIsLoading] = useState(false);

  const toggleLoading = (loading) => {
    setIsLoading(loading);
  };

  const handleRouteChange = (event) => {
    localStorage.setItem("lastVisitedRoute", event.url);
  };

  const basename = process.env.BASENAME || "";

  if (!process.env.BACKEND_URL || process.env.BACKEND_URL === "")
    return <BackendURL />;

  return (
    <div style={{ display: "flex" }}>
      <BrowserRouter basename={basename}>
        <ScrollToTop>
          <Navbar />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              alignItems: "center",
            }}
          >
            <Navbar_sup />
            <Routes onChange={handleRouteChange}>
              <Route element={<UserHome />} path="/" />
              <Route
                element={<RegistroUserTerminal />}
                path="/RegistroUserTerminal"
              />
              <Route element={<Front2 />} path="/Configuraciones" />
              <Route path="*" element={<Navigate to="/" />} />
            </Routes>
          </div>
        </ScrollToTop>
      </BrowserRouter>
      {isLoading && <LoadingOverlay />}
    </div>
  );
};
