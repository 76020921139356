import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import { Context } from "../../../store/appContext";
import { useNavigate } from "react-router-dom";
import "../../../../styles/MovimientosDeUsuarios.css";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import Big from "big.js";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFViewer,
  Image,
  pdf,
} from "@react-pdf/renderer";
import { PDFDownloadLink, BlobProvider } from "@react-pdf/renderer";

const tableStyles = StyleSheet.create({
  anuladoRow: {
    backgroundColor: "#ffcccc", // Rojo clarito
  },
  table: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  table2: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    marginTop: 10,
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row",
    backgroundColor: "#FFF",
  },
  tableColHeader: {
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    backgroundColor: "#f2f2f2",
    alignItems: "center",
    justifyContent: "center",
  },
  tableCol: {
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    justifyContent: "center",
  },
  tableCellHeader: {
    margin: 5,
    fontSize: 9,
    fontWeight: "bold",
    textAlign: "center",
  },
  tableCell: {
    margin: 4,
    fontSize: 9,
  },
  tableCellstart: {
    margin: 4,
    fontSize: 9,
    textAlign: "center",
  },
});

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    borderBottomColor: "#000",
    borderBottomWidth: 1,
    alignItems: "center",
    height: 15,
    margin: 0,
    padding: 0,
    justifyContent: "center",
    textAlign: "center",
  },
  cell: {
    fontSize: 9,
    textAlign: "center",
    borderRightColor: "#000",
    borderRightWidth: 1,
    flex: 1,
  },
  cell2: {
    fontSize: 9,
    textAlign: "center",
    borderRightColor: "#000",
    borderRightWidth: 1,
    width: 20,
    borderLeftColor: "#000",
    borderLeftWidth: 1,
  },
  header: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  header2: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  logo: {
    width: 90, // Ajusta el tamaño según tus necesidades
    height: 30, // Ajusta el tamaño según tus necesidades
  },
  logo2: {
    width: 100,
    height: 20,
  },
  page: {
    padding: 20, // Ajusta el valor del margen según tus necesidades
  },
  letter: {
    fontSize: 7,
    padding: 5,
  },
  letter2: {
    fontSize: 10,
  },
  ruta: {
    fontSize: 7,
    padding: 4,
  },
  bottomText: {
    position: "absolute",
    bottom: 0,
    left: 20, // Ajusta según sea necesario
    right: 20, // Ajusta según sea necesario
    textAlign: "center",
    bottom: 30,
    fontSize: 8,
  },
  bottomText2: {
    position: "absolute",
    bottom: 0,
    left: 20, // Ajusta según sea necesario
    right: 20, // Ajusta según sea necesario
    textAlign: "center",
    bottom: 50,
    fontSize: 11,
    fontWeight: "bold",
  },
});

export const Cierre_diario = () => {
  const [showPreview, setShowPreview] = useState(false);
  const [listinesbusesfilter, setListinesbusesfilter] = useState();
  const [listinesporpuestofilter, setListinesporpuestofilter] = useState();
  const { actions, store } = useContext(Context);
  const [isLoading, setIsLoading] = useState(true); // Estado para manejar la carga
  const [formData, setFormData] = useState({
    fechaCierre: "",
    userEmail: "",
    userFirstName: "",
    turno: "",
    totalEfectivo: "",
    totalPuntoDeVenta: "",
    totalMe: "",
    totalGeneral: "",
    documento: "",
  });
  const MyDocument = ({ formData }) => {
    return (
      <Document>
        <Page style={styles.page} size="A4">
          {/* Encabezado con dos logos */}
          <View style={styles.header}>
            <Image
              src="https://res.cloudinary.com/dirpdlfbu/image/upload/v1701880341/logo/cmyemflfryypewryq6ux.jpg"
              style={styles.logo}
            />
            <Image
              src="https://res.cloudinary.com/dirpdlfbu/image/upload/v1711324140/aeezebl5y4pzhcmgm2da.jpg"
              style={styles.logo2}
            />
          </View>
          <View style={styles.header2}>
            <Text style={styles.letter2}>TERMINAL SOCIALISTA LA BANDERA</Text>
          </View>
        </Page>
      </Document>
    );
  };

  const handlePreview = async () => {
    const blob = await pdf(<MyDocument formData={formData} />).toBlob();
    const url = URL.createObjectURL(blob);
    window.open(url, "_blank");
  };

  return (
    <div
      className="container-fluid contarinerGeneralUserHomejs"
      style={{ paddingTop: "30px", paddingBottom: "70px" }}
    >
      <div className="container-fluid contaosijdasd-qw89d77asd2"></div>
      <h2
        className="asjdansdas-d4as6489text w-100"
        style={{ zIndex: "15", marginTop: "-17vh" }}
      >
        Cierre Diario
      </h2>
      <div className="container movimientoss9a87d89qwdqw98d4qw">
        <div className="aoshduiahsdkasnmdasdas">
          <div className="input-group-login2" style={{ margin: 0 }}>
            <input
              className="inputDeLogin"
              type="text"
              readOnly
              style={{ cursor: "pointer" }}
            />
            <label>Fecha y hora cierre</label>
          </div>
          <button
            className="btn btn-primary"
            onClick={handlePreview}
            style={{
              minWidth: "250px",
              height: "6vh",
              background: "#0a306a",
              margin: 0,
            }}
          >
            Vista Cierre
          </button>
          <button
            className="btn btn-primary"
            style={{ minWidth: "250px", height: "6vh", background: "#ff9900" }}
          >
            Cerrar
          </button>
        </div>

        <div className="table-responsive">
          <table>
            <thead>
              <tr className="aoshduishdihwe89f78w9e7we7we7fcw">
                <th className="desktop-only">Fecha y hora de registro</th>
                <th>Fecha</th>
                <th>Nombre</th>
                <th>PDF</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="desktop-only"></td>
                <td></td>
                <td></td>
                <td>
                  <button className="btn a98s7d49q8wd198qw7dqw98d7q9w84dqw984">
                    <i className="fa-solid kjahsdasodjasdqwdqwdxqdw245 fa-file-pdf"></i>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
