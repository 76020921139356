import React, { useContext, useState, useEffect } from "react";
import { Context } from "../../store/appContext";
import { Link, Navigate } from "react-router-dom";
import "../../../styles/companyAdminNavbarSuperior.css";
import axios from "axios";
import { motion } from "framer-motion";

export const Navbar_sup = () => {
  const { store, actions } = useContext(Context);
  const [userData2, setUserData2] = useState(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const [selectedMenu, setSelectedMenu] = useState(null);
  const [currentDateTime, setCurrentDateTime] = useState(new Date());

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentDateTime(new Date());
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  const formattedTime = currentDateTime.toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  });
  const formattedDate = currentDateTime.toLocaleDateString();

  const handleClick = (menu) => {
    setSelectedMenu(menu);
  };

  const sidebarVariants = {
    open: {
      opacity: 1,
      x: 0, // Esto hace que el menú se desplace desde el lado izquierdo.
      transition: {
        opacity: { duration: 0.3 },
        x: { type: "ease", stiffness: 100 },
      },
    },
    closed: {
      opacity: 0,
      x: "-100%", // Esto mueve el menú fuera de la vista hacia la izquierda.
      transition: {
        opacity: { duration: 0.3 },
        x: { type: "ease", stiffness: 100 },
      },
    },
  };

  useEffect(() => {
    // Obtén el pathname de la ubicación actual
    const pathname = location.pathname;

    // Mapea el pathname a un nombre de menú y establece el menú seleccionado
    const menuMapping = {
      "/": "/",
      "/Inicio": "Inicio",
      "/RegistroConductores": "RegistroConductores",
      "/RegistroAutobuses": "RegistroAutobuses",
      "/SolicitudActivacion": "SolicitudActivacion",
      "/RegistroUsuario": "RegistroUsuario",
      "/MovimientosDeUsuarios": "MovimientosDeUsuarios",
      "/CierreListin": "CierreListin",
      "/Estadisticas": "Estadisticas",
      "/Configuraciones": "Configuraciones",
      "/RegistroCps": "Carrito",
    };

    const menu = menuMapping[pathname];
    if (menu) {
      setSelectedMenu(menu);
    }
  }, [location.pathname]);

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("userData");
    window.location.reload();
  };

  const fetchUserData = async () => {
    const options = {
      headers: {
        Authorization: "Bearer " + store.token,
      },
    };
    try {
      const response = await axios.get(
        process.env.BACKEND_URL + "/api/protected",
        options
      );
      setUserData2(response.data);
    } catch (error) {
      console.error("Error fetching user data", error);
    }
  };

  const handleMenuToggle = () => {
    setMenuOpen(!menuOpen);
  };

  useEffect(() => {
    fetchUserData();
  }, []);

  return (
    <div className="container-fluid containerCompanyAdminNavbarSuperior">
      <div
        className="container"
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          border: "rgba(128, 128, 128, 0.205) 1px solid",
          boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
          borderRadius: "15px",
          padding: "13px",
          backgroundColor: "white",
          alignItems: "center",
        }}
      >
        <div>
          {userData2 ? (
            <img
              src="https://res.cloudinary.com/dirpdlfbu/image/upload/v1713118957/INTRAVIALCA/vtznsflpii9d8n825c5n.png"
              style={{ width: "200px" }}
              className="letterPrincipalWhereSuperior"
            ></img>
          ) : (
            // <h2 className="letterPrincipalWhereSuperior">
            //   <strong style={{ color: "#0a306a" }}>
            //     {userData2.company}
            //   </strong>
            // </h2>
            <p>Cargando...</p>
          )}
          <i
            className="fa-solid fa-bars qpwjaisodnasdasdalsdaspqowm"
            onClick={handleMenuToggle}
          ></i>
        </div>
        <div className="asojhdqihso-qw6d498s4asas6x">
          <p className="live-time m-0" style={{ paddingRight: "7px" }}>
            {formattedTime}
          </p>
          <p className="live-date m-0">{formattedDate}</p>
        </div>
        <div className="d-flex justify-content-center align-items-center">
          {userData2 ? (
            <div>
              <p
                className="pNavbarSuperior"
                style={{
                  display: "flex",
                  justifyContent: "end",
                  color: "rgb(33, 33, 39)",
                }}
              >
                Hey,
                <strong style={{ marginLeft: "5px" }}>
                  {userData2.first_name}
                </strong>
              </p>
              <p
                className="pNavbarSuperior"
                style={{
                  display: "flex",
                  justifyContent: "end",
                  color: "rgb(33, 33, 39)",
                }}
              >
                ¿Que tal tu día?
              </p>
            </div>
          ) : (
            <p>Cargando...</p>
          )}
          <div
            style={{
              width: "40px",
              height: "40px",
              border: "solid 0.1px #006AA1",
              borderRadius: "50%",
              marginLeft: "20px",
              backgroundImage:
                "url(https://static.vecteezy.com/system/resources/previews/014/194/232/non_2x/avatar-icon-human-a-person-s-badge-social-media-profile-symbol-the-symbol-of-a-person-vector.jpg)",
              backgroundSize: "130%",
              backgroundPosition: "center",
            }}
          ></div>
        </div>
        {menuOpen && (
          <div
            className={`menu-overlay ${menuOpen ? "menu-open" : ""}`}
            onClick={handleMenuToggle}
          >
            <div
              className={`menu-content ${menuOpen ? "menu-content-open" : ""}`}
            >
              <div className="qwijeqwesdmkms123">
                <div>
                  <img
                    src="https://res.cloudinary.com/dirpdlfbu/image/upload/v1713119110/INTRAVIALCA/truxqodtlg1vzishxhot.png"
                    className="qwinqsdasdkjlnloqwn"
                  ></img>
                </div>
                <div onClick={handleMenuToggle}>
                  <i
                    style={{ fontSize: "30px" }}
                    className="fa-regular fa-circle-xmark icon-98716598"
                  ></i>
                </div>
              </div>
              <div className="asdw-w9d84c9w8e4dcd2"></div>
              <div className="louqjwnedjsdas">
                <div className="oqwqwdqksdms">
                  <Link
                    className={`subtitulosMenuUserHomeCompany ${
                      selectedMenu === "/" ? "selected-menu" : ""
                    }`}
                    to="/"
                    onClick={() => handleClick("/")}
                  >
                    <i
                      className="fa-solid seraUnMarinsupre fa-globe"
                      style={{ fontSize: "26px" }}
                    ></i>
                    <div
                      className="LetterInvisible"
                      style={{ fontSize: "16px" }}
                    >
                      Inicio
                    </div>
                  </Link>
                  <Link
                    className={`subtitulosMenuUserHomeCompany ${
                      selectedMenu === "ListinesRutasCortasIntravialca"
                        ? "selected-menu"
                        : ""
                    }`}
                    onClick={() =>
                      handleClick("ListinesRutasCortasIntravialca")
                    }
                    to="/ListinesRutasCortasIntravialca"
                  >
                    <i
                      className="fa-solid seraUnMarinsupre fa-id-card"
                      style={{ fontSize: "26px" }}
                    ></i>
                    <div
                      className="LetterInvisible"
                      style={{ fontSize: "16px" }}
                    >
                      Listines
                    </div>
                  </Link>
                  <Link
                    className={`subtitulosMenuUserHomeCompany ${
                      selectedMenu === "Carrito" ? "selected-menu" : ""
                    }`}
                    onClick={() => handleClick("Carrito")}
                    to="/RegistroCps"
                  >
                    <i
                      className="fa-solid seraUnMarinsupre fa-id-card"
                      style={{ fontSize: "26px" }}
                    ></i>
                    <div
                      className="LetterInvisible"
                      style={{ fontSize: "16px" }}
                    >
                      Listines (MiTicketDigital)
                    </div>
                  </Link>
                  <Link
                    className={`subtitulosMenuUserHomeCompany ${
                      selectedMenu === "Anulados" ? "selected-menu" : ""
                    }`}
                    onClick={() => handleClick("Anulados")}
                    to="/Anulados"
                  >
                    <i
                      className="fa-solid seraUnMarinsupre fa-bus"
                      style={{ fontSize: "26px" }}
                    ></i>
                    <div
                      className="LetterInvisible"
                      style={{ fontSize: "16px" }}
                    >
                      Anulados
                    </div>
                  </Link>
                  <Link
                    className={`subtitulosMenuUserHomeCompany ${
                      selectedMenu === "CierreTaquillaRutasCortas"
                        ? "selected-menu"
                        : ""
                    }`}
                    onClick={() => handleClick("CierreTaquillaRutasCortas")}
                    to="/CierreTaquillaRutasCortas"
                  >
                    <i
                      className="fa-solid seraUnMarinsupre fa-file-circle-check"
                      style={{ fontSize: "26px" }}
                    ></i>
                    <div
                      className="LetterInvisible"
                      style={{ fontSize: "16px" }}
                    >
                      Cierre de caja
                    </div>
                  </Link>
                  <div className="oqjsndoajsndoqwdmasdkmas"></div>
                  <Link
                    className={`subtitulosMenuUserHomeCompany ${
                      selectedMenu === "Configuraciones" ? "selected-menu" : ""
                    }`}
                    onClick={() => handleClick("Configuraciones")}
                    to="/Configuraciones"
                  >
                    <i
                      className="fa-solid seraUnMarinsupre fa-gear"
                      style={{ fontSize: "26px" }}
                    ></i>
                    <div
                      to="/Configuraciones"
                      className="LetterInvisible"
                      style={{ fontSize: "16px" }}
                    >
                      Configuraciones
                    </div>
                  </Link>
                </div>
                <a
                  className="subtitulosMenuUserHomeCompany logoutbuttonCompany"
                  onClick={handleLogout}
                  href="/"
                >
                  <i
                    className="fa-solid seraUnMarinsupre fa-right-from-bracket"
                    style={{ fontSize: "26px" }}
                  ></i>
                  <div
                    href="#"
                    className="LetterInvisible"
                    style={{ fontSize: "16px" }}
                  >
                    Cerrar sesión
                  </div>
                </a>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
