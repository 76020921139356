import React, { useState, useContext } from "react";
import "../../../../styles/MovimientosDeUsuarios.css";
import axios from "axios";
import { Context } from "../../../store/appContext";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);

export const Valordolardiario = () => {
  const { store } = useContext(Context);
  const [formData, setFormData] = useState({
    fecha: "",
    valor: "",
  });

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await axios.post(
        process.env.BACKEND_URL + "/api/registerpreciodolar",
        formData,
        {
          headers: {
            Authorization: `Bearer ${store.token}`,
          },
        }
      );

      setFormData({
        fecha: "",
        valor: "",
      });

      MySwal.fire({
        title: "Valor del dolar Registrado",
        text: "El Valor ha sido registrado exitosamente.",
        icon: "success",
        confirmButtonText: "Cerrar",
      });
    } catch (error) {
      const errorMessage =
        error.response?.data?.message ||
        "Error desconocido al registrar el valor del dolar.";

      MySwal.fire({
        title: "Error",
        text: errorMessage,
        icon: "error",
        confirmButtonText: "Cerrar",
      });
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const fetchdolar = async () => {
    MySwal.fire({
      title: "Cargando...",
      html: `<p style="text-align:center; margin:20px;">Cargando datos registrados...</p>`,
      allowOutsideClick: false,
      didOpen: () => {
        MySwal.showLoading();
      },
    });

    try {
      const response = await axios.get(
        process.env.BACKEND_URL + "/api/precioDolarDiariotoday",
        {
          headers: {
            Authorization: `Bearer ${store.token}`,
          },
        }
      );

      const data = response.data;

      MySwal.close();

      if (!data || data.length === 0) {
        MySwal.fire({
          title: "Valor Dolar Registrados",
          html: `<p style="text-align:center; margin:20px;">No se encontraron datos.</p>`,
          confirmButtonText: "Cerrar",
        });
      } else {
        const content = data
          .map(
            (item) => `
          <div style="margin: 10px; padding: 10px; border: 1px solid #ccc;">
          <p><strong>Fecha y hora de Registro:</strong> ${item.fecha_hora_registro}</p>
            <p><strong>Fecha de Aplicación:</strong> ${item.fecha_aplica_dolar}</p>
            <p><strong>Valor Bs:</strong> ${item.valor}</p>
          </div>
        `
          )
          .join("");

        MySwal.fire({
          title: "Valor Dolar Registrados",
          html: `
            <div id="companies-list">${content}</div>
          `,
          confirmButtonText: "Cerrar",
        });
      }
    } catch (error) {
      console.error("Error al obtener valores registrados", error);
      MySwal.fire({
        title: "Error",
        html: `<p>Error al obtener valores registrados. Por favor, inténtelo de nuevo más tarde.</p>`,
        icon: "error",
        confirmButtonText: "Cerrar",
      });
    }
  };

  return (
    <div
      className="container-fluid contarinerGeneralUserHomejs"
      style={{ paddingTop: "30px" }}
    >
      <div className="container-fluid contaosijdasd-qw89d77asd2"></div>
      <h2
        className="asjdansdas-d4as6489text w-100"
        style={{ zIndex: "15", marginTop: "-17vh" }}
      >
        Valor del dolar diario
      </h2>
      <div className="container asklnduiashndaijsnxansxsaas">
        <form onSubmit={handleSubmit} className="formularioDeLogin w-100">
          <div className="aoshduiahsdkasnmdasdas">
            <div className="input-group-login2">
              <input
                className="inputDeLogin"
                type="date"
                name="fecha"
                required
                onChange={handleChange}
                value={formData.fecha}
              />
              <label>Fecha de Aplicación</label>
            </div>
            <div className="input-group-login2">
              <input
                className="inputDeLogin"
                type="number"
                name="valor"
                required
                onChange={handleChange}
                value={formData.valor}
              />
              <label>Valor del dolar (Bs.)</label>
            </div>
          </div>

          <button className="buttonCargadeDatosDeLogin" type="submit">
            Registrar <i className="fa-solid fa-arrow-right"></i>
          </button>
        </form>
      </div>
      <div className="w-100 asd9as-da9s84dx9a8qdw-qdw asdqw98d4qv895y4n9r84bvr98ebtnbtn poja65s4d8a9s7d89errg98er7fqwd9849iu9ykj7asj">
        <button
          className="btn asd98q7wd65q1wd8xqw49x8e4wc"
          onClick={fetchdolar}
        >
          <i
            className="fa-solid fa-route"
            style={{ marginRight: "7px", fontSize: "20px" }}
          ></i>
          Ver Registros
        </button>
      </div>
    </div>
  );
};
