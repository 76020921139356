import React, { useState } from "react";
import axios from "axios";
import swal from "sweetalert";

export const RegistroEmpresa = () => {
  const [formData, setFormData] = useState({
    company_name: "",
    company_rif: "",
    company_email: "",
    company_number: "",
    president_company_name: "",
    president_company_lastname: "",
    president_company_email: "",
    president_company_phone: "",
    permission_route_type_largas: "",
    permission_route_type_cortas: "",
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        process.env.BACKEND_URL + "/api/register_company",
        formData,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      // console.log("Registro exitoso", response.data);
      mostrarAlerta1();
    } catch (error) {
      mostrarAlerta2();
      console.error("Error al registrar la empresa", error);
    }
  };

  const mostrarAlerta1 = () => {
    swal({
      title: "Registro exitoso",
      icon: "success",
      timer: "3000",
      buttons: {
        yes: {
          text: "Ok",
          value: true,
          className: "custom-button-yes",
        },
      },
      customClass: {
        modal: "custom-modal",
      },
    });
  };

  const mostrarAlerta2 = () => {
    swal({
      title: "La empresa ya está registrada",
      icon: "error",
      timer: "3000",
      buttons: {
        yes: {
          text: "Ok",
          value: true,
          className: "custom-button-yes",
        },
      },
      customClass: {
        modal: "custom-modal",
      },
    });
  };

  return (
    <div
      className="container containerDeRegistrojsSegundo"
      style={{ marginTop: "120px" }}
    >
      <h4>Registro de Empresa</h4>
      <form onSubmit={handleSubmit} className="formularioDeRegistroDeUsuario">
        <input
          className="inputRegistroDeUsuario"
          type="text"
          name="company_name"
          placeholder="Nombre de la empresa"
          value={formData.company_name}
          onChange={handleChange}
          required
        />
        <input
          className="inputRegistroDeUsuario"
          type="text"
          name="company_rif"
          placeholder="RIF de la empresa"
          value={formData.company_rif}
          onChange={handleChange}
          required
        />
        <input
          className="inputRegistroDeUsuario"
          type="email"
          name="company_email"
          placeholder="Correo electrónico de la empresa"
          value={formData.company_email}
          onChange={handleChange}
          required
        />
        <input
          className="inputRegistroDeUsuario"
          type="text"
          name="company_number"
          placeholder="Número de la empresa"
          value={formData.company_number}
          onChange={handleChange}
          required
        />
        <input
          className="inputRegistroDeUsuario"
          type="text"
          name="president_company_name"
          placeholder="Nombre del presidente"
          value={formData.president_company_name}
          onChange={handleChange}
          required
        />
        <input
          className="inputRegistroDeUsuario"
          type="text"
          name="president_company_lastname"
          placeholder="Apellido del presidente"
          value={formData.president_company_lastname}
          onChange={handleChange}
          required
        />
        <input
          className="inputRegistroDeUsuario"
          type="email"
          name="president_company_email"
          placeholder="Correo electrónico del presidente"
          value={formData.president_company_email}
          onChange={handleChange}
          required
        />
        <input
          className="inputRegistroDeUsuario"
          type="text"
          name="president_company_phone"
          placeholder="Teléfono del presidente"
          value={formData.president_company_phone}
          onChange={handleChange}
          required
        />
        <input
          className="inputRegistroDeUsuario"
          type="text"
          name="permission_route_type_largas"
          placeholder="Permiso para rutas largas"
          value={formData.permission_route_type_largas}
          onChange={handleChange}
          required
        />
        <input
          className="inputRegistroDeUsuario"
          type="text"
          name="permission_route_type_cortas"
          placeholder="Permiso para rutas cortas"
          value={formData.permission_route_type_cortas}
          onChange={handleChange}
          required
        />
        <button type="submit">Registrar Empresa</button>
      </form>
    </div>
  );
};
