import React, { useState, useEffect } from "react";
import axios from "axios";
import { format } from "date-fns";
import { es } from "date-fns/locale";
import { formatInTimeZone, toDate } from "date-fns-tz";
import differenceInMinutes from "date-fns/differenceInMinutes";
import "../../styles/PublicBillboard.css";

const estatusColors = {
  ACTIVO: "#28a745", // Verde
  ANULADO: "#dc3545", // Rojo
  "EN PISTA": "#ffc107", // Amarillo
  CARGANDO: "#17a2b8", // Azul
  SALIENDO: "#f8f9fa", // Blanco
  SALIO: "#6c757d", // Gris
  RETRASADO: "#ff6347", // Rojo claro
};

export const PublicBillboard = () => {
  const [records, setRecords] = useState([]);
  const [currentTime, setCurrentTime] = useState(new Date());
  const timeZone = "America/Caracas";

  useEffect(() => {
    const interval = setInterval(() => {
      fetchCurrentTime();
      fetchTodayRecords();
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const fetchCurrentTime = async () => {
    try {
      const response = await axios.get(
        process.env.BACKEND_URL + "/api/public/venezuela-time"
      );
      const zonedTime = toDate(new Date(response.data.currentTime), {
        timeZone,
      });
      setCurrentTime(zonedTime);
    } catch (error) {
      console.error("Error al obtener la hora actual de Venezuela", error);
    }
  };

  const fetchTodayRecords = async () => {
    try {
      const response = await axios.get(
        process.env.BACKEND_URL + "/api/public/getTodayActivacionPista"
      );
      const data = response.data;

      const updatedData = data.map((record) => {
        const recordTime = toDate(
          `${record.fecha_viaje.split("-").reverse().join("-")}T${record.hora_salida}:00`,
          { timeZone }
        );
        let newStatus = record.estatus;
        if (record.estatus !== "SALIO" && recordTime < currentTime) {
          const delayMinutes = differenceInMinutes(currentTime, recordTime);
          newStatus = `${record.estatus} - RETRASADO ${delayMinutes} minutos`;
        }
        return { ...record, estatus: newStatus };
      });

      const sortedData = updatedData.sort((a, b) =>
        a.hora_salida.localeCompare(b.hora_salida)
      );
      setRecords(sortedData.slice(0, 15));
    } catch (error) {
      console.error("Error al obtener registros de hoy", error);
    }
  };

  const formatDateTime = (date) => {
    return formatInTimeZone(date, timeZone, "dd-MM-yyyy HH:mm:ss", {
      locale: es,
    });
  };

  const handleFullScreen = async () => {
    const elem = document.documentElement;
    if (elem.requestFullscreen) {
      await elem.requestFullscreen();
    } else if (elem.mozRequestFullScreen) {
      await elem.mozRequestFullScreen();
    } else if (elem.webkitRequestFullscreen) {
      await elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) {
      await elem.msRequestFullscreen();
    }

    if (screen.orientation && screen.orientation.lock) {
      try {
        await screen.orientation.lock("landscape");
      } catch (err) {
        console.error("Error locking screen orientation:", err);
      }
    }
  };

  return (
    <div className="container-fluid public-billboard">
      <div className="d-flex sd5654fdg89wr7ef98e4gry98r7thgf align-items-center mb-4">
        <h1 className="mty68he7r9w7ed89q7dqw">
          PROGRAMACIÓN TERMINAL LA BANDERA
        </h1>
        <div className="current-time">
          <h4 className="n56nr48er9v7ew98m7uy9t8erwe7fq">
            {formatDateTime(currentTime)}
          </h4>
          <span onClick={handleFullScreen} className="fullscreen-icon">
            &#x26F6;
          </span>
        </div>
      </div>
      <div className="w-100 mb-3 d-flex" style={{ alignItems: "center" }}>
        <div
          className="as564d8978iujn98brv4wecwecreodun"
          style={{ marginRight: "10px" }}
        ></div>
        <h5 style={{ margin: 0, color: "#28a745" }}>TERMINAL EN VIVO</h5>
      </div>
      <div className="table-responsive">
        <table className="table as65d48sd9f798fh7f98gbv4d98vt7y98tt7r9e8qw table-dark table-striped table-bordered">
          <thead>
            <tr>
              <th style={{ textAlign: "center" }}>Hora</th>
              <th style={{ textAlign: "center" }}>Empresa de Transporte</th>
              <th style={{ textAlign: "center" }}>Destino Final</th>
              <th style={{ textAlign: "center" }}>Andén</th>
              <th style={{ textAlign: "center" }}>Estatus</th>
            </tr>
          </thead>
          <tbody>
            {records.map((item, index) => (
              <tr
                key={item.id}
                className={index % 2 === 0 ? "text-white" : "text-light"}
              >
                <td
                  style={{
                    color: "#FFE500",
                    textAlign: "center",
                    fontSize: "16px",
                  }}
                >
                  {item.hora_salida}
                </td>
                <td
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    fontSize: "16px",
                  }}
                >
                  <div className="i56df4u8ty9h7gwedd">
                    {item.link_logo_empresa ? (
                      <img
                        src={item.link_logo_empresa}
                        alt={item.nombre_empresa}
                        style={{ height: "40px" }}
                      />
                    ) : (
                      <span
                        style={{
                          color: "black",
                          textAlign: "center",
                        }}
                      >
                        {item.empresa}
                      </span>
                    )}
                  </div>
                </td>
                <td style={{ textAlign: "center", fontSize: "16px" }}>
                  {item.destino_final}
                </td>
                <td style={{ textAlign: "center", fontSize: "16px" }}>
                  {item.anden}
                </td>
                <td style={{ textAlign: "center", fontSize: "16px" }}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      height: "100%",
                      justifyContent: "center",
                      marginTop: "9px",
                    }}
                  >
                    <span
                      className="estatus-circle"
                      style={{
                        backgroundColor:
                          estatusColors[item.estatus.split(" - ")[0]],
                      }}
                    ></span>
                    <span
                      style={{
                        color: estatusColors[item.estatus.split(" - ")[0]],
                      }}
                    >
                      {item.estatus}
                    </span>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
