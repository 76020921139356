import React, { useState, useContext } from "react";
import axios from "axios";
import "../../../../styles/Registro.css";
import { Link, useNavigate } from "react-router-dom";
import { Context } from "../../../store/appContext";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFDownloadLink,
} from "@react-pdf/renderer";

const MySwal = withReactContent(Swal);

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "#ffffff",
    padding: 20,
  },
  section: {
    margin: 10,
    padding: 10,
    borderBottomWidth: 1,
    borderBottomColor: "#cccccc",
    borderBottomStyle: "solid",
  },
  header: {
    fontSize: 18,
    marginBottom: 10,
  },
  body: {
    fontSize: 12,
  },
  userDetails: {
    fontSize: 14,
    marginBottom: 10,
  },
});

const AuditDocument = ({ audits, userDetails }) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <Text style={styles.header}>Movimientos de Auditoría</Text>
      <View style={styles.userDetails}>
        <Text>
          <Text style={{ fontWeight: "bold" }}>Nombre:</Text> {userDetails.name}
        </Text>
        <Text>
          <Text style={{ fontWeight: "bold" }}>Email:</Text> {userDetails.email}
        </Text>
        <Text>
          <Text style={{ fontWeight: "bold" }}>Cédula:</Text>{" "}
          {userDetails.cedula}
        </Text>
      </View>
      {audits.map((audit, index) => (
        <View key={index} style={styles.section}>
          <Text style={styles.body}>
            <Text style={{ fontWeight: "bold" }}>Fecha y Hora:</Text>{" "}
            {audit.date_and_hour_register}
          </Text>
          <Text style={styles.body}>
            <Text style={{ fontWeight: "bold" }}>Movimiento:</Text>{" "}
            {audit.movement}
          </Text>
        </View>
      ))}
    </Page>
  </Document>
);

const AuditModalContent = ({ audits, userDetails }) => (
  <div>
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginBottom: 20,
        marginTop: "10px",
      }}
    >
      <h6 style={{ marginRight: "10px" }}>Descargar:</h6>
      <PDFDownloadLink
        document={<AuditDocument audits={audits} userDetails={userDetails} />}
        fileName="auditoria.pdf"
      >
        {({ blob, url, loading, error }) =>
          loading ? (
            "Cargando documento..."
          ) : (
            <i
              className="fa-solid fa-file-pdf"
              style={{ fontSize: "24px", cursor: "pointer" }}
            />
          )
        }
      </PDFDownloadLink>
    </div>
    <div id="audit-list">
      {audits.map((item, index) => (
        <div
          key={index}
          style={{ margin: 10, padding: 10, border: "1px solid #ccc" }}
        >
          <p>
            <strong>Fecha y Hora:</strong> {item.date_and_hour_register}
          </p>
          <p>
            <strong>Movimiento:</strong> {item.movement}
          </p>
        </div>
      ))}
    </div>
  </div>
);

export const RegistroUsuarios = () => {
  const navigate = useNavigate();
  const { store } = useContext(Context);
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    rol: "",
    user_cedula: "",
    user_phone: "",
  });
  const [audits, setAudits] = useState([]);
  const [userDetails, setUserDetails] = useState({});

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await axios.post(
        process.env.BACKEND_URL + "/api/signupUserTerminal",
        formData,
        {
          headers: {
            Authorization: "Bearer " + store.token,
          },
        }
      );
      setFormData({
        first_name: "",
        last_name: "",
        email: "",
        rol: "",
        user_cedula: "",
        user_phone: "",
      });
      mostrarAlerta1();
    } catch (error) {
      const errorMessage =
        error.response?.data?.message ||
        "Error desconocido al registrar al usuario";
      mostrarAlerta2(errorMessage);
      console.error("Error al registrar", error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const fetchUsers = async () => {
    MySwal.fire({
      title: "Cargando...",
      html: `<p style="text-align:center; margin:20px;">Cargando datos registrados...</p>`,
      allowOutsideClick: false,
      didOpen: () => {
        MySwal.showLoading();
      },
    });

    try {
      const response = await axios.get(
        process.env.BACKEND_URL + "/api/getUsers",
        {
          headers: {
            Authorization: `Bearer ${store.token}`,
          },
        }
      );

      const data = response.data;
      MySwal.close();

      if (!data || data.length === 0) {
        MySwal.fire({
          title: "Usuarios Registrados",
          html: `<p style="text-align:center; margin:20px;">No se encontraron datos.</p>`,
          confirmButtonText: "Cerrar",
        });
      } else {
        const content = data
          .map(
            (user) => `
          <div style="margin: 10px; padding: 10px; border: 1px solid #ccc;">
            <p><strong>Nombre:</strong> ${user.first_name} ${user.last_name}</p>
            <p><strong>Email:</strong> ${user.email}</p>
            <button class="btn btn-secondary" style="background: #a9a9a9; border: none" id="viewUserAudit-${user.id}">Ver Movimientos</button>
          </div>
        `
          )
          .join("");

        MySwal.fire({
          title: "Usuarios Registrados",
          html: `<div id="users-list">${content}</div>`,
          confirmButtonText: "Cerrar",
        });

        data.forEach((user) => {
          document
            .getElementById(`viewUserAudit-${user.id}`)
            .addEventListener("click", () => viewUserAudit(user.id, user));
        });
      }
    } catch (error) {
      console.error("Error al obtener usuarios", error);
      MySwal.fire({
        title: "Error",
        text: "Error al obtener usuarios registrados. Por favor, inténtelo de nuevo más tarde.",
        icon: "error",
        confirmButtonText: "Cerrar",
      });
    }
  };

  const viewUserAudit = async (userId, user) => {
    MySwal.fire({
      title: "Cargando...",
      html: `<p style="text-align:center; margin:20px;">Cargando movimientos de auditoría...</p>`,
      allowOutsideClick: false,
      didOpen: () => {
        MySwal.showLoading();
      },
    });

    try {
      const response = await axios.get(
        process.env.BACKEND_URL + `/api/getUserAudit/${userId}`,
        {
          headers: {
            Authorization: `Bearer ${store.token}`,
          },
        }
      );

      const data = response.data;

      // Ensure user details are set correctly
      const userDetails = {
        name: `${user.first_name} ${user.last_name}`,
        email: user.email,
        cedula: user.user_cedula,
      };

      setUserDetails(userDetails);

      MySwal.close();

      if (!data || data.length === 0) {
        MySwal.fire({
          title: "Movimientos de Auditoría",
          html: `<p style="text-align:center; margin:20px;">No se encontraron movimientos de auditoría.</p>`,
          confirmButtonText: "Cerrar",
        });
      } else {
        MySwal.fire({
          title: "Auditoría",
          html: `<div id="audit-modal-content"></div>`,
          confirmButtonText: "Cerrar",
        });

        const AuditModalContentWithProps = () => (
          <AuditModalContent audits={data} userDetails={userDetails} />
        );

        MySwal.update({
          html: <AuditModalContentWithProps />,
        });
      }
    } catch (error) {
      console.error("Error al obtener movimientos de auditoría", error);
      MySwal.fire({
        title: "Error",
        text: "Error al obtener movimientos de auditoría. Por favor, inténtelo de nuevo más tarde.",
        icon: "error",
        confirmButtonText: "Cerrar",
      });
    }
  };

  const mostrarAlerta1 = () => {
    MySwal.fire({
      title: <p>Usuario Registrado con éxito</p>,
      icon: "success",
      position: "center",
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
    });
  };

  const mostrarAlerta2 = (errorMessage) => {
    MySwal.fire({
      title: <p>Error al Registrar al usuario</p>,
      html: <p>{errorMessage}</p>, // Se muestra el mensaje de error del servidor
      icon: "error",
      position: "center",
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
    });
  };

  return (
    <div
      className="container-fluid contarinerGeneralUserHomejs"
      style={{ paddingTop: "30px" }}
    >
      <div className="container-fluid contaosijdasd-qw89d77asd2"></div>
      <h2
        className="asjdansdas-d4as6489text w-100"
        style={{ zIndex: "15", marginTop: "-17vh" }}
      >
        Agrega Tus Usuarios
      </h2>
      <div className="container asklnduiashndaijsnxansxsaas">
        <div className="i-fewf5c9ew5ce9wdc1w4c1s"></div>
        <p style={{ color: "#0a306a" }}>
          Registra tus Usuarios en intravialca-ve.com
        </p>
        <form onSubmit={handleSubmit} className="formularioDeLogin w-100">
          <div className="aoshduiahsdkasnmdasdas">
            <div className="input-group-login">
              <input
                className="inputDeLogin"
                type="text"
                name="first_name"
                required
                onChange={handleChange}
                value={formData.first_name}
              />
              <label>Nombre</label>
            </div>
            <div className="input-group-login">
              <input
                className="inputDeLogin"
                type="text"
                name="last_name"
                required
                onChange={handleChange}
                value={formData.last_name}
              />
              <label>Apellido</label>
            </div>
            <div className="input-group-login">
              <input
                className="inputDeLogin"
                type="email"
                name="email"
                required
                onChange={handleChange}
                value={formData.email}
              />
              <label>Correo Electrónico</label>
            </div>
          </div>

          <div className="aoshduiahsdkasnmdasdas">
            <div className="input-group-login2">
              <select
                className="inputDeLogin"
                name="rol"
                onChange={handleChange}
                value={formData.rol}
              >
                <option value="" disabled>
                  Selecciona un rol
                </option>
                <option value="ASIST_DIR">ASISTENTE DE DIRECCIÓN</option>
                <option value="SUP_PISTA">PROGRAMADOR</option>
                <option value="SUP_TAQ_LARGAS">LISTINES RUTAS LARGAS</option>
                <option value="SUP_TAQ_RCORTA">LISTINES RUTAS CORTAS</option>
                <option value="SUP_EST">ESTADÍSTICAS</option>
              </select>
              <label>Rol Usuario</label>
            </div>
            <div className="input-group-login">
              <input
                className="inputDeLogin"
                type="number"
                name="user_cedula"
                onChange={handleChange}
                value={formData.user_cedula}
                required
              />
              <label>Cédula Usuario</label>
            </div>
            <div className="input-group-login">
              <input
                className="inputDeLogin"
                type="number"
                name="user_phone"
                onChange={handleChange}
                value={formData.user_phone}
                required
              />
              <label>Teléfono Usuario</label>
            </div>
          </div>
          <button className="buttonCargadeDatosDeLogin" type="submit">
            Registrarse <i className="fa-solid fa-arrow-right"></i>
          </button>
          <div className="d-flex" style={{ marginTop: "12px" }}>
            <p>¿Alguna duda?,</p>
            <Link
              style={{
                marginLeft: "5px",
                textDecoration: "none",
                color: "#0a306a",
              }}
              to="#"
            >
              <strong>Contáctanos</strong>
            </Link>
          </div>
        </form>
      </div>
      <div className="w-100 asd9as-da9s84dx9a8qdw-qdw asdqw98d4qv895y4n9r84bvr98ebtnbtn poja65s4d8a9s7d89errg98er7fqwd9849iu9ykj7asj">
        <button
          className="btn asd98q7wd65q1wd8xqw49x8e4wc"
          onClick={fetchUsers}
        >
          <i
            className="fa-solid fa-users"
            style={{ marginRight: "7px", fontSize: "20px" }}
          ></i>
          Ver Usuarios Registrados
        </button>
      </div>
    </div>
  );
};
