import React, { useState, useEffect, useContext } from "react";
import { Context } from "../../store/appContext";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import ScrollToTop from "../../component/scrollToTop";
import { BackendURL } from "../../component/backendURL";
import { Redirect } from "react-router-dom";
import "../../../styles/AddDriverForm.css";

import LoadingOverlay from "../../pages/LadingOverlay";

import { Front2 } from "../../pages/Front2";
import { Navbar_listin_rl } from "./Navbar_listin_rl";
import { Navbar_sup_listin_rl } from "./Navbar_sup_listin_rl";
import { UserHome_listin_rl } from "./userHome_listin_rl";
import { Elab_listin_rl } from "./EMPLOYER_TAQ_LARGAS/elab_listin_rl";
import { Cierre_control_diario } from "./EMPLOYER_TAQ_LARGAS/Cierre_control_diario";

export const Layout_listin_rl = () => {
  const { store } = useContext(Context);
  const [isLoading, setIsLoading] = useState(false);

  const toggleLoading = (loading) => {
    setIsLoading(loading);
  };

  const handleRouteChange = (event) => {
    localStorage.setItem("lastVisitedRoute", event.url);
  };

  const basename = process.env.BASENAME || "";

  if (!process.env.BACKEND_URL || process.env.BACKEND_URL === "")
    return <BackendURL />;

  return (
    <div style={{ display: "flex" }}>
      <BrowserRouter basename={basename}>
        <ScrollToTop>
          <Navbar_listin_rl />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              alignItems: "center",
            }}
          >
            <Navbar_sup_listin_rl />
            <Routes onChange={handleRouteChange}>
              <Route element={<UserHome_listin_rl />} path="/" />
              <Route element={<Elab_listin_rl />} path="/elab_listin_rl" />
              <Route
                element={<Cierre_control_diario />}
                path="/cierre_control_diario_rl"
              />
              <Route element={<Front2 />} path="/Configuraciones" />
              <Route path="*" element={<Navigate to="/" />} />
            </Routes>
          </div>
        </ScrollToTop>
      </BrowserRouter>
      {isLoading && <LoadingOverlay />}
    </div>
  );
};
