import React, { useState, useEffect } from "react";
import axios from "axios";
import "../../styles/Registro.css";
import { Link, useNavigate } from "react-router-dom";
import swal from "sweetalert";

export const RegistroSasha = () => {
  const navigate = useNavigate();
  const [terminalUbications, setTerminalUbications] = useState([]);
  const [formData, setFormData] = useState({
    user_name: "",
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    terminal_ubication: "",
    company: "",
    company_rif: "",
    rol: "",
    user_cedula: "",
    user_phone: "",
  });
  const [selectedCompany, setSelectedCompany] = useState("");
  const [companies, setCompanies] = useState([]);
  const [roles, setRoles] = useState([]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const handleChange = (e) => {
      const { name, value } = e.target;
      // Actualizar el estado dependiendo del campo cambiado
      if (name === "company") {
        setSelectedCompany(value);
      }
      // Actualizar el formData
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    };
    try {
      const response = await axios.post(
        process.env.BACKEND_URL + "/api/signup",
        formData,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      mostrarAlerta1();
      navigate("/Login");
    } catch (error) {
      mostrarAlerta2();
      console.error("Error al registrar", error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "company") {
      // Cuando se selecciona una empresa, actualizar el RIF correspondiente
      const selectedCompany = companies.find(
        (company) => company.company_name === value
      );
      const selectedCompanyRif = selectedCompany
        ? selectedCompany.company_rif
        : "";
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
        company_rif: selectedCompanyRif,
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const mostrarAlerta1 = () => {
    swal({
      title: "Registro exitoso",
      icon: "success",
      timer: "3000",
      buttons: {
        yes: {
          text: "Ok",
          value: true,
          className: "custom-button-yes",
        },
      },
      customClass: {
        modal: "custom-modal",
      },
    });
  };

  const mostrarAlerta2 = () => {
    swal({
      title: "Usuario o Correo ya existente",
      icon: "success",
      timer: "3000",
      buttons: {
        yes: {
          text: "Ok",
          value: true,
          className: "custom-button-yes",
        },
      },
      customClass: {
        modal: "custom-modal",
      },
    });
  };

  return (
    <div className="aq4-asd56sa54s">
      <div className="container asda8s9d4qw9d74q9w8e4dwe98fc4we9f8c4wec">
        <div className="i-fewf5c9ew5ce9wdc1w4c1s"></div>
        <p style={{ color: "#0a306a" }}>Regístrate en MiTicketDigital</p>
        <form onSubmit={handleSubmit} className="formularioDeLogin w-100">
          <div className="aoshduiahsdkasnmdasdas">
            <div className="input-group-login">
              <input
                className="inputDeLogin"
                type="text"
                name="user_name"
                required
                onChange={handleChange}
              />
              <label>Nombre de Usuario</label>
            </div>
            <div className="input-group-login">
              <input
                className="inputDeLogin"
                type="text"
                name="first_name"
                required
                onChange={handleChange}
              />
              <label>Nombre</label>
            </div>
            <div className="input-group-login">
              <input
                className="inputDeLogin"
                type="text"
                name="last_name"
                required
                onChange={handleChange}
              />
              <label>Apellido</label>
            </div>
            <div className="input-group-login">
              <input
                className="inputDeLogin"
                type="email"
                name="email"
                required
                onChange={handleChange}
              />
              <label>Correo Electrónico</label>
            </div>
          </div>

          {/* Aqiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiii */}

          <div className="aoshduiahsdkasnmdasdas">
            <div className="input-group-login2">
              <select
                className="inputDeLogin"
                name="terminal_ubication"
                onChange={handleChange}
                value={formData.terminal_ubication}
                required
              >
                <option value="" disabled>
                  Selecciona una ubicación de la terminal
                </option>
                <option value="NO-OPTION">Todos</option>
              </select>
              <label>Ubicación Terminal</label>
            </div>
            <div className="input-group-login2">
              <select
                className="inputDeLogin"
                name="company"
                onChange={handleChange}
                value={formData.company}
                required
              >
                <option value="" disabled>
                  Selecciona una empresa
                </option>
                <option value="SASHATECH">Sasha Tech</option>
              </select>
              <label>Empresa</label>
            </div>
            <div className="input-group-login2">
              <select
                className="inputDeLogin"
                name="rol"
                onChange={handleChange}
                value={formData.rol}
              >
                <option value="" disabled>
                  Selecciona un rol
                </option>
                <option value="ADMIN-SASHA">Admin</option>
              </select>
              <label>Rol Usuario</label>
            </div>
          </div>

          {/* Aquiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiii */}

          <div className="aoshduiahsdkasnmdasdas">
            <div className="input-group-login">
              <input
                className="inputDeLogin"
                type="text"
                name="user_cedula"
                placeholder="Cédula del usuario"
                onChange={handleChange}
              />
              <label>Cédula Usuario</label>
            </div>
            <div className="input-group-login">
              <input
                className="inputDeLogin"
                type="text"
                name="user_phone"
                placeholder="Teléfono del usuario"
                onChange={handleChange}
              />
              <label>Teléfono Usuario</label>
            </div>
          </div>
          <button className="buttonCargadeDatosDeLogin" type="submit">
            Registrarse <i className="fa-solid fa-arrow-right"></i>
          </button>
          <div className="d-flex" style={{ marginTop: "12px" }}>
            <p>¿Alguna duda?,</p>
            <Link
              style={{
                marginLeft: "5px",
                textDecoration: "none",
                color: "#0a306a",
              }}
              to="#"
            >
              <strong>Contáctanos</strong>
            </Link>
          </div>
        </form>

        <div className="d-flex containerdeo">
          <div className="lineaalladodelo"></div>
          <p
            style={{ whiteSpace: "nowrap", display: "inline-block" }}
            className="d-flex"
          >
            o
          </p>
          <div className="lineaalladodelo"></div>
        </div>
        <Link to="/Login">
          <button className="btnPrimerContactoImportant2">
            Iniciar Sesión{" "}
            <i className="fa-solid fa-arrow-right flechaHomePresentation2"></i>
          </button>
        </Link>
      </div>
    </div>
  );
};
