import React, { useState, useEffect, useContext, Fragment } from "react";
import axios from "axios";
import { Context } from "../store/appContext";
import "../../styles/CierreListinTerminal.css";

export const Front1 = () => {
  return (
    <div
      className="container-fluid contarinerGeneralUserHomejs"
      style={{ paddingTop: "30px" }}
    >
      <div className="container-fluid contaosijdasd-qw89d77asd2"></div>
      <h2
        className="asjdansdas-d4as6489text w-100"
        style={{ zIndex: "15", marginTop: "-17vh" }}
      >
        Listines Anulados
      </h2>

      <div className="container asklnduiashndaijsnxansxsaas">
        <i
          className="fa-solid fa-circle-exclamation"
          style={{ fontSize: "100px" }}
        ></i>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            textAlign: "center",
          }}
        >
          <h1>Muy pronto!</h1>
          <p>Empieza a llevar un mejor control de tus listines Anulados!</p>
        </div>
      </div>
    </div>
  );
};
