import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from "date-fns/locale/es";
import { Context } from "../../../store/appContext";
import { Tooltip as ReactTooltip } from "react-tooltip";
import "../../../../styles/ActivacionesCalendar.css";

registerLocale("es", es);

const estatusColors = {
  ACTIVO: "#d4edda", // Verde claro
  ANULADO: "#f8d7da", // Rojo claro
  "EN PISTA": "#fff3cd", // Amarillo claro
  CARGANDO: "#d1ecf1", // Azul claro
  SALIENDO: "#fefefe", // Blanco
  SALIO: "#e2e3e5", // Gris claro
};

export const ActivacionesCalendar = () => {
  const { store } = useContext(Context);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [searchTerm, setSearchTerm] = useState("");
  const [activaciones, setActivaciones] = useState([]);
  const [filteredActivaciones, setFilteredActivaciones] = useState([]);

  useEffect(() => {
    fetchActivaciones();
  }, [selectedDate]);

  const fetchActivaciones = async () => {
    try {
      const response = await axios.get(
        process.env.BACKEND_URL + "/api/getActivacionesTODOS",
        {
          headers: {
            Authorization: `Bearer ${store.token}`,
          },
        }
      );
      setActivaciones(response.data);
      filterActivaciones(response.data);
    } catch (error) {
      console.error("Error al obtener activaciones", error);
    }
  };

  const filterActivaciones = (data) => {
    const searchTermLower = searchTerm.toLowerCase();
    const filtered = data.filter((activacion) => {
      const dateMatch =
        new Date(
          activacion.fecha_viaje.split("-").reverse().join("-")
        ).toDateString() === selectedDate.toDateString();
      const searchMatch =
        activacion.empresa.toLowerCase().includes(searchTermLower) ||
        activacion.destino_final.toLowerCase().includes(searchTermLower);
      return dateMatch && searchMatch;
    });
    setFilteredActivaciones(filtered);
  };

  useEffect(() => {
    filterActivaciones(activaciones);
  }, [searchTerm]);

  const renderCalendar = () => {
    const intervals = [];
    for (let i = 5; i < 22; i++) {
      intervals.push(`${i}:00`);
      intervals.push(`${i}:30`);
    }

    const days = [];
    for (let i = 0; i < 7; i++) {
      const date = new Date(selectedDate);
      date.setDate(date.getDate() + i);
      days.push(
        date.toLocaleDateString("es-ES", {
          weekday: "short",
          day: "2-digit",
          month: "short",
        })
      );
    }

    const cells = intervals.map((time, rowIndex) => (
      <div className="row" key={rowIndex}>
        <div className="cell time">{time}</div>
        {days.map((day, colIndex) => {
          const activacionesForCell = filteredActivaciones.filter(
            (activacion) => {
              const activacionDate = new Date(
                activacion.fecha_viaje.split("-").reverse().join("-")
              );
              const cellDate = new Date(selectedDate);
              cellDate.setDate(cellDate.getDate() + colIndex);
              const activacionTime = new Date(
                `1970-01-01T${activacion.hora_salida}:00`
              );
              const cellTime = new Date(`1970-01-01T${time}:00`);

              const isWithinInterval = (timeString, cellStart) => {
                const [hours, minutes] = timeString.split(":").map(Number);
                const [cellHours, cellMinutes] = cellStart
                  .split(":")
                  .map(Number);

                return (
                  (hours === cellHours &&
                    minutes >= cellMinutes &&
                    minutes < cellMinutes + 30) ||
                  (hours === cellHours + 1 && minutes < cellMinutes)
                );
              };

              return (
                activacionDate.toDateString() === cellDate.toDateString() &&
                isWithinInterval(activacion.hora_salida, time)
              );
            }
          );

          return (
            <div className="cell" key={colIndex}>
              {activacionesForCell.map((activacion, index) => (
                <div
                  key={index}
                  className="activacion"
                  data-tooltip-id={`tooltip-${activacion.id}`}
                  data-tooltip-content={`
                    <p><strong>Empresa:</strong> ${activacion.empresa}</p>
                    <p><strong>Hora de Salida:</strong> ${activacion.hora_salida}</p>
                    <p><strong>Destino:</strong> ${activacion.destino_final}</p>
                  `}
                  style={{
                    backgroundColor:
                      estatusColors[activacion.estatus] || "#ffffff",
                  }}
                >
                  {activacion.empresa}
                  <br />
                  <small>{activacion.destino_final}</small>
                  <ReactTooltip
                    id={`tooltip-${activacion.id}`}
                    place="top"
                    effect="solid"
                  >
                    <p>
                      <strong>Empresa:</strong> {activacion.empresa}
                    </p>
                    <p>
                      <strong>Hora de Salida:</strong> {activacion.hora_salida}
                    </p>
                    <p>
                      <strong>Destino:</strong> {activacion.destino_final}
                    </p>
                  </ReactTooltip>
                </div>
              ))}
            </div>
          );
        })}
      </div>
    ));

    return (
      <div className="calendar">
        <div className="row header">
          <div className="cell"></div>
          {days.map((day, index) => (
            <div className="cell" key={index}>
              {day}
            </div>
          ))}
        </div>
        {cells}
      </div>
    );
  };

  return (
    <div className="container-fluid p-0">
      <div className="controls">
        <DatePicker
          selected={selectedDate}
          onChange={(date) => setSelectedDate(date)}
          dateFormat="dd-MM-yyyy"
          className="date-picker"
          locale="es"
        />
        <input
          type="text"
          placeholder="Buscar..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="search-input"
        />
      </div>
      {renderCalendar()}
    </div>
  );
};
