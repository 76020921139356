import React, { useContext, useState, useEffect } from "react";
import { Context } from "./store/appContext";
import { Link, Navigate } from "react-router-dom";
import "../styles/CompanyAdminNavbar.css";

export const Navbar = () => {
  const { store, actions } = useContext(Context);
  const [selectedMenu, setSelectedMenu] = useState(null);

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("userData");
    window.location.reload();
  };
  const handleClick = (menu) => {
    setSelectedMenu(menu);
  };

  useEffect(() => {
    // Obtén el pathname de la ubicación actual
    const pathname = location.pathname;

    // Mapea el pathname a un nombre de menú y establece el menú seleccionado
    const menuMapping = {
      "/": "/",
      "/RegistroUserTerminal": "RegistroUserTerminal",
    };

    const menu = menuMapping[pathname];
    if (menu) {
      setSelectedMenu(menu);
    }
  }, [location.pathname]);

  return (
    <div className="ideaAdminCompany-container2">
      <div className="ideaAdminCompany-container" style={{ position: "fixed" }}>
        <nav className="ideaAdminCompany-navbar">
          <Link
            to="/Inicio"
            className="ideaAdminCompany-logo"
            style={{ marginLeft: "10px" }}
          >
            <img
              src="https://res.cloudinary.com/dirpdlfbu/image/upload/v1713119110/INTRAVIALCA/truxqodtlg1vzishxhot.png"
              alt="Logo"
              style={{ height: "65px" }}
            />
          </Link>
          <div className="asdw-w9d84c9w8e4dcd2"></div>
        </nav>
        <div className="ideaAdminCompany-menu">
          <div>
            <Link
              className={`subtitulosMenuUserHomeCompany ${
                selectedMenu === "/" ? "selected-menu" : ""
              }`}
              to="/"
              onClick={() => handleClick("/")}
            >
              <i className="fa-solid seraUnMarinsupre fa-globe"></i>
              <div className="LetterInvisible2" style={{ fontSize: "12px" }}>
                Inicio
              </div>
            </Link>
            <Link
              className={`subtitulosMenuUserHomeCompany ${
                selectedMenu === "RegistroUserTerminal" ? "selected-menu" : ""
              }`}
              onClick={() => handleClick("RegistroUserTerminal")}
              to="/RegistroUserTerminal"
            >
              <i className="fa-solid seraUnMarinsupre fa-id-card"></i>
              <div className="LetterInvisible2" style={{ fontSize: "12px" }}>
                Registro Usuarios Terminal
              </div>
            </Link>
            <Link
              className={`subtitulosMenuUserHomeCompany ${
                selectedMenu === "RegistroCps" ? "selected-menu" : ""
              }`}
              onClick={() => handleClick("RegistroCps")}
              to="/RegistroCps"
            >
              <i className="fa-solid seraUnMarinsupre fa-id-card"></i>
              <div className="LetterInvisible2" style={{ fontSize: "12px" }}>
                Registro Cps
              </div>
            </Link>

            <Link
              className={`subtitulosMenuUserHomeCompany ${
                selectedMenu === "Configuraciones" ? "selected-menu" : ""
              }`}
              onClick={() => handleClick("Configuraciones")}
              to="/Configuraciones"
            >
              <i className="fa-solid seraUnMarinsupre fa-gear"></i>
              <div className="LetterInvisible2" style={{ fontSize: "12px" }}>
                Configuraciones
              </div>
            </Link>
          </div>
          <a
            className="subtitulosMenuUserHomeCompany logoutbuttonCompany"
            style={{ marginBottom: "20px" }}
            onClick={handleLogout}
            href="/"
          >
            <i className="fa-solid seraUnMarinsupre fa-right-from-bracket"></i>
            <div
              href="#"
              className="LetterInvisible2"
              style={{ fontSize: "12px" }}
            >
              Cerrar sesión
            </div>
          </a>
        </div>
      </div>
    </div>
  );
};
