import React, { useState, useEffect, useContext } from "react";
import { Context } from "./store/appContext";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import ScrollToTop from "./component/scrollToTop";
import { BackendURL } from "./component/backendURL";
import { Redirect, useNavigate } from "react-router-dom";
import axios from "axios";

import { Login } from "./pages/Login";
import injectContext from "./store/appContext";

import { AddTerminalUbication } from "./pages/AddTerminalUbication";
import { RegistroEmpresa } from "./pages/RegistroEmpresa";
import { RegistroDolarBcv } from "./pages/RegistroDolarBcv";
import LoadingOverlay from "./pages/LadingOverlay";
import { AvisosCobro } from "./pages/AvisosCobro";
import { jwtDecode } from "jwt-decode";
import { Layout_pista } from "./Rol/Activacion_pista/layout_pista";
import { Layout_listin_rl } from "./Rol/taquillero_listin_rutas_largas/layout_listin_rl";
import { Layout_avisos_cobro } from "./Rol/Avisos_cobro_recaudacion/layout_aviso_cobro";
import { Layout_listin_rc } from "./Rol/taquillero_listin_rutas_cortas/layout_listin_rc";
import { Layout_est } from "./Rol/ESTADISTICAS/layout_est";
import { Layout_dir_terminal } from "./Rol/Dir/Layout_dir_terminal";
import { Login2 } from "./pages/Login2";
import { RegistroSasha } from "./pages/RegistroSashaTech";
import { Layoutadmin29640206 } from "./layoutadmin29640206";
import { Layout_asis_dir } from "./Rol/ASIST_DIR/Layout_asis_dir";
import { PublicBillboard } from "./pages/PublicBillBoard";

const Layout = () => {
  const [isMounted, setIsMounted] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const basename = process.env.BASENAME || "";

  useEffect(() => {
    return () => {
      setIsMounted(false);
    };
  }, []);

  const toggleLoading = (loading) => {
    setIsLoading(loading);
  };

  const token = localStorage.getItem("token");
  let userRole = null;
  if (token) {
    const decoded = jwtDecode(token);
    userRole = decoded.rol; // Aquí obtienes el rol del token
  }

  const renderLayout = () => {
    if (isLoading) {
      return <div>{isLoading && <LoadingOverlay />}</div>;
    }
    switch (userRole) {
      case "SUP_PISTA":
        return <Layout_pista toggleLoading={toggleLoading} />;
      case "SUP_TAQ_LARGAS":
        return <Layout_listin_rl toggleLoading={toggleLoading} />;
      case "SUP_AVISOS":
        return <Layout_avisos_cobro toggleLoading={toggleLoading} />;
      case "SUP_TAQ_RCORTA":
        return <Layout_listin_rc toggleLoading={toggleLoading} />;
      case "SUP_EST":
        return <Layout_est toggleLoading={toggleLoading} />;
      case "DIR_TERMINAL":
        return <Layout_dir_terminal toggleLoading={toggleLoading} />;
      case "ADMIN-SASHA":
        return <Layoutadmin29640206 toggleLoading={toggleLoading} />;
      case "ASIST_DIR":
        return <Layout_asis_dir toggleLoading={toggleLoading} />;
      // case "EMPLOYEE_SASHATECH":
      //   return <SashaTechEmployeeLayout />;
      default:
        return (
          <div>
            <BrowserRouter basename={basename}>
              <ScrollToTop>
                {/* <BarraSup /> */}
                {/* <Navbar /> */}
                <Routes>
                  {/* <Route element={<Home2 />} path="/" /> */}
                  <Route element={<Login />} path="/" />
                  <Route element={<Login2 />} path="/Login29640206" />
                  <Route element={<PublicBillboard />} path="/CarteleraPista" />
                  <Route
                    element={<AvisosCobro />}
                    path="/AvisosCobroas6d4q8w9498erv74er98v4er98v7er98"
                  />
                  {/* <Route
                    element={<SetPassword />}
                    path="/set-password/:token"
                  /> */}
                  {/* <Route element={<Nosotros />} path="/Nosotros" /> */}
                  {/* <Route element={<RegistroDolarBcv />} path="/RegistroDolar" /> */}
                  {/* <Route
                    element={<RegistroPreciosYDestinos />}
                    path="/RegistroPrecioDestino"
                  /> */}
                  {/* <Route
                    element={<BlogSugerencias />}
                    path="/BlogParaUstedes"
                  /> */}
                  {/* <Route element={<BlogNoticias />} path="/Blog" />
                  <Route element={<BlogDetalle />} path="/blog/detalle/:id" /> */}
                  {/* <Route element={<RegistroSasha />} path="/Registro" /> */}
                  {/* <Route
                    element={<RegistroUsuario />}
                    path="/RegistroUsuario"
                  /> */}
                  {/* <Route
                    element={<ActivacionPista />}
                    path="/ActivacionPista"
                  /> */}
                  {/* <Route element={<Single />} path="/single/:theid" /> */}
                  {/* <Route element={<UserHome />} path="/Inicio" /> */}
                  {/* <Route element={<Listines />} path="/ListinesLaBandera" /> */}
                  {/* <Route
                    path="/ListinesLaBandera/Listin:id"
                    element={<PDFViewer2 />}
                  /> */}
                  {/* <Route
                    element={<CierreListinCompany />}
                    path="/CierreListin"
                  />
                  <Route
                    element={<SolicitudActivacion />}
                    path="/SolicitudActivacion"
                  />
                  <Route
                    element={<MovimientosDeUsuarios />}
                    path="/MovimientosDeUsuarios"
                  /> */}
                  {/* <Route element={<Reserva />} path="/Reservas" />
                  <Route element={<Boutique />} path="/Boutique" /> */}
                  {/* <Route element={<Prueba />} path="/prueba" /> */}
                  {/* <Route
                    element={<AddTerminalUbication />}
                    path="/AddTerminalUbication"
                  />
                  <Route
                    element={<RegistroEmpresa />}
                    path="/RegistroEmpresa"
                  /> */}
                  {/* <Route path="/a404" element={<ActivationRest />} /> */}
                  <Route path="*" element={<Navigate to="/" replace />} />
                </Routes>
                {/* <NavbarInterior /> */}
                {/* <Footer /> */}
              </ScrollToTop>
            </BrowserRouter>
          </div>
        );
    }
  };
  return renderLayout();
};

export default injectContext(Layout);
