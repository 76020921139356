import React, { useContext, useState } from "react";
import axios from "axios";
import "../../../../styles/SolicitudActivaciones.css";
import { Context } from "../../../store/appContext";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);

export const Carga_porpuesto_rc = ({ toggleLoading }) => {
  const { store } = useContext(Context);
  const [formData, setFormData] = useState({
    numero_listin: "",
    destino_final: "",
    empresa: "",
    unidad: "",
    placa: "",
    estatus: "",
    cortesias: "0",
    ninos_4_o_menos_anos: "0",
    adultos: "0",
    tercera_edad: "0",
    discapacitados: "0",
  });

  const fetchListines = async () => {
    MySwal.fire({
      title: "Cargando...",
      html: `<p style="text-align:center; margin:20px;">Cargando Listines...</p>`,
      allowOutsideClick: false,
      didOpen: () => {
        MySwal.showLoading();
      },
    });

    try {
      const response = await axios.get(
        process.env.BACKEND_URL + "/api/listines_for_estpp",
        {
          headers: {
            Authorization: `Bearer ${store.token}`,
          },
        }
      );
      MySwal.close();
      return response.data.listines;
    } catch (error) {
      console.error("Error al obtener listines", error);
      MySwal.fire({
        title: "Error",
        text: "Error al obtener listines. Por favor, inténtelo de nuevo más tarde.",
        icon: "error",
        confirmButtonText: "Cerrar",
      });
      return [];
    }
  };

  const selectListinDialog = async () => {
    const listines = await fetchListines();
    const listinOptions = listines.map((listin, index) => ({
      id: index,
      text: listin.n_listin,
      value: listin,
    }));

    MySwal.fire({
      title: "Seleccionar Listín",
      html: `
        <input type="text" id="searchListin" class="swal2-input" placeholder="Buscar Listín">
        ${listinOptions
          .map(
            (option) =>
              `<div style="cursor: pointer; margin: 10px; padding: 10px; border: 1px solid #ccc;" data-id="${option.id}">${option.text}</div>`
          )
          .join("")}
        <div id="listin-options"></div>
      `,
      showConfirmButton: true,
      confirmButtonText: "Cerrar",
      didRender: () => {
        const searchInput = document.getElementById("searchListin");
        searchInput.addEventListener("input", (event) => {
          const filter = event.target.value.toLowerCase();
          const elements = document.querySelectorAll("[data-id]");
          elements.forEach((element) => {
            const text = element.textContent.toLowerCase();
            element.style.display = text.includes(filter) ? "block" : "none";
          });
        });

        const elements = document.querySelectorAll("[data-id]");
        elements.forEach((element) => {
          element.addEventListener("click", () => {
            const id = element.getAttribute("data-id");
            MySwal.clickConfirm();
            const selectedListin = listinOptions[id].value;
            setFormData((prevData) => ({
              ...prevData,
              numero_listin: selectedListin.n_listin,
              destino_final: selectedListin.destino_final,
              empresa: selectedListin.empresa,
              unidad: selectedListin.n_unidad,
              placa: selectedListin.placa,
              estatus: selectedListin.estatus,
            }));
          });
        });
      },
    });
  };

  const viewEstadisticas = async () => {
    MySwal.fire({
      title: "Cargando...",
      html: `<p style="text-align:center; margin:20px;">Cargando estadísticas...</p>`,
      allowOutsideClick: false,
      didOpen: () => {
        MySwal.showLoading();
      },
    });

    try {
      const response = await axios.get(
        process.env.BACKEND_URL + "/api/getEstadisticasListinespp",
        {
          headers: {
            Authorization: `Bearer ${store.token}`,
          },
        }
      );

      const data = response.data.estadisticas;

      MySwal.close();

      if (!data || data.length === 0) {
        MySwal.fire({
          title: "Estadísticas",
          html: `<p style="text-align:center; margin:20px;">No se encontraron estadísticas.</p>`,
          confirmButtonText: "Cerrar",
        });
      } else {
        const tableHeaders = `
          <tr>
            <th>Listin</th>
            <th>Adultos</th>
            <th>Tercera Edad</th>
            <th>Discapacitados</th>
            <th>Infantes</th>
            <th>Cortesías</th>
            <th>Total Pasajeros</th>
            <th>Registrado por</th>
          </tr>
        `;

        const tableRows = data
          .map(
            (item) => `
              <tr>
                <td>${item.n_listin}</td>
                <td>${item.adultos}</td>
                <td>${item.tercera_edad}</td>
                <td>${item.discapacitados}</td>
                <td>${item.ninos_4_o_menos_anos}</td>
                <td>${item.cortesias}</td>
                <td>${item.total_pasajeros}</td>
                <td>${item.registrado_por}</td>
              </tr>
            `
          )
          .join("");

        const table = `
          <div style="max-height: 400px; overflow-y: auto; min-width: 900px">
            <table style="width: 100%; border-collapse: collapse; font-size: 12px;">
              <thead>
                ${tableHeaders}
              </thead>
              <tbody id="estadisticas-list">
                ${tableRows}
              </tbody>
            </table>
          </div>
        `;

        MySwal.fire({
          title: "Estadísticas",
          html: table,
          showConfirmButton: true,
          confirmButtonText: "Cerrar",
        });
      }
    } catch (error) {
      console.error("Error al obtener estadísticas", error);
      MySwal.fire({
        title: "Error",
        html: `<p>Error al obtener estadísticas. Por favor, inténtelo de nuevo más tarde.</p>`,
        icon: "error",
        confirmButtonText: "Cerrar",
      });
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const total_pasajeros =
      Number(formData.adultos) +
      Number(formData.tercera_edad) +
      Number(formData.discapacitados) +
      Number(formData.ninos_4_o_menos_anos) +
      Number(formData.cortesias);

    const dataToSend = {
      ...formData,
      total_pasajeros: total_pasajeros,
    };

    try {
      await axios.post(
        process.env.BACKEND_URL + "/api/registerEstadisticapp",
        dataToSend,
        {
          headers: {
            Authorization: `Bearer ${store.token}`,
          },
        }
      );
      MySwal.fire({
        title: "Registro Exitoso",
        text: `Las estadísticas de pasajeros han sido registradas exitosamente. Total pasajeros: ${total_pasajeros}`,
        icon: "success",
        confirmButtonText: "Cerrar",
      });
      setFormData({
        numero_listin: "",
        destino_final: "",
        empresa: "",
        unidad: "",
        placa: "",
        estatus: "",
        cortesias: "0",
        ninos_4_o_menos_anos: "0",
        adultos: "0",
        tercera_edad: "0",
        discapacitados: "0",
      });
    } catch (error) {
      console.error("Error al registrar estadísticas", error);
      MySwal.fire({
        title: "Error",
        text: "Error al registrar las estadísticas. Por favor, inténtelo de nuevo más tarde.",
        icon: "error",
        confirmButtonText: "Cerrar",
      });
    }
  };

  return (
    <div
      className="container-fluid contarinerGeneralUserHomejs"
      style={{ paddingTop: "30px", paddingBottom: "70px" }}
    >
      <div className="container-fluid contaosijdasd-qw89d77asd223"></div>
      <h2
        className="asjdansdas-d4as6489text w-100"
        style={{ zIndex: "15", marginTop: "-17vh" }}
      >
        Autobuses Rutas Cortas
      </h2>
      <div className="container asklnduiashndaijsnxansxsaas">
        <form
          className="d-flex sd98f4e8wf4cwe987cwe984cwe"
          onSubmit={handleSubmit}
        >
          <div className="aoshduiahsdkasnmdasdas">
            <div className="input-group-login2">
              <input
                className="inputDeLogin"
                type="text"
                name="numero_listin"
                value={formData.numero_listin}
                onClick={selectListinDialog}
                readOnly
                required
              />
              <label>N° Listín</label>
            </div>
            <div className="input-group-login2">
              <input
                className="inputDeLogin"
                type="text"
                name="empresa"
                value={formData.empresa}
                readOnly
                required
              />
              <label>Empresa</label>
            </div>
            <div className="input-group-login2">
              <input
                className="inputDeLogin"
                type="text"
                name="destino_final"
                value={formData.destino_final}
                readOnly
                required
              />
              <label>Destino Final</label>
            </div>
          </div>

          <div className="aoshduiahsdkasnmdasdas">
            <div className="input-group-login2">
              <input
                className="inputDeLogin"
                type="text"
                name="unidad"
                value={formData.unidad}
                onChange={handleChange}
                required
                readOnly
              />
              <label>N° Unidad</label>
            </div>
            <div className="input-group-login2">
              <input
                className="inputDeLogin"
                type="text"
                name="placa"
                value={formData.placa}
                onChange={handleChange}
                required
                style={{ textTransform: "uppercase" }}
                readOnly
              />
              <label>Placa</label>
            </div>
            <div className="input-group-login2">
              <input
                className="inputDeLogin"
                type="text"
                name="estatus"
                value={formData.estatus}
                onChange={handleChange}
                required
                readOnly
              />
              <label>Estatus</label>
            </div>
          </div>

          <div className="aoshduiahsdkasnmdasdas">
            <div className="input-group-login2">
              <input
                className="inputDeLogin"
                type="number"
                name="adultos"
                value={formData.adultos}
                onChange={handleChange}
                required
              />
              <label>Adultos</label>
            </div>
            <div className="input-group-login2">
              <input
                className="inputDeLogin"
                type="number"
                name="tercera_edad"
                value={formData.tercera_edad}
                onChange={handleChange}
                required
              />
              <label>Tercera Edad</label>
            </div>
            <div className="input-group-login2">
              <input
                className="inputDeLogin"
                type="text"
                name="discapacitados"
                value={formData.discapacitados}
                onChange={handleChange}
                required
              />
              <label>Discapacitados</label>
            </div>
            <div className="input-group-login2">
              <input
                className="inputDeLogin"
                type="text"
                name="ninos_4_o_menos_anos"
                value={formData.ninos_4_o_menos_anos}
                onChange={handleChange}
                required
              />
              <label>Infantes (4 o menos años)</label>
            </div>
            <div className="input-group-login2">
              <input
                className="inputDeLogin"
                type="text"
                name="cortesias"
                value={formData.cortesias}
                onChange={handleChange}
                required
              />
              <label>Cortesías</label>
            </div>
          </div>

          <div className="w-100 asd9as-da9s84dx9a8qdw-qdw asdqw98d4qv895y4n9r84bvr98ebtnbtn">
            <button
              type="submit"
              className="btn buttonCargadeDatosDeAddDriver"
              style={{ marginBottom: "30px" }}
            >
              <div className="d-flex">
                <p style={{ marginBottom: 0 }}>Guardar Datos</p>
                <i className="fa-solid fa-arrow-right flechaHomePresentation2"></i>
              </div>
            </button>
          </div>
        </form>

        <div className="w-100 asd9as-da9s84dx9a8qdw-qdw asdqw98d4qv895y4n9r84bvr98ebtnbtn">
          <button
            className="btn secondary ioyu98jty7tr98v4er98f7er89w4d"
            onClick={viewEstadisticas}
          >
            <i
              className="fa-solid fa-car"
              style={{ marginRight: "7px", fontSize: "20px" }}
            ></i>
            Ver Estadísticas
          </button>
        </div>
      </div>
    </div>
  );
};
