import React, { useContext, useState, useEffect } from "react";
import { Context } from "../../store/appContext";
import { Link, Navigate } from "react-router-dom";
import "../../../styles/CompanyAdminNavbar.css";

export const Navbar = () => {
  const { store, actions } = useContext(Context);
  const [selectedMenu, setSelectedMenu] = useState(null);

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("userData");
    window.location.reload();
  };
  const handleClick = (menu) => {
    setSelectedMenu(menu);
  };

  useEffect(() => {
    // Obtén el pathname de la ubicación actual
    const pathname = location.pathname;

    // Mapea el pathname a un nombre de menú y establece el menú seleccionado

    const menuMapping = {
      "": "",
      "/": "/",
      "/Inicio": "Inicio",
      "/carga_autobuses_rc": "carga_autobuses_rc",
      "/Carga_porpuesto_rc": "Carga_porpuesto_rc",
      "/Carga_rl": "Carga_rl",
      "/Cierre_diario": "Cierre_diario",
      "/Cierre_entre_fechas": "Cierre_entre_fechas",
      "/Cierre_semanal": "Cierre_semanal",
      "/Cierre_mensual": "Cierre_mensual",
    };

    const menu = menuMapping[pathname];
    if (menu) {
      setSelectedMenu(menu);
    }
  }, [location.pathname]);

  return (
    <div className="ideaAdminCompany-container2">
      <div className="ideaAdminCompany-container" style={{ position: "fixed" }}>
        <div className="as56d4s9f87er98fwe4fwr987fwee98d4qw987">
          <img
            src="https://res.cloudinary.com/dirpdlfbu/image/upload/v1713119110/INTRAVIALCA/truxqodtlg1vzishxhot.png"
            alt="Logo"
            style={{ height: "35px" }}
            className="d65f4sd9f8w7ef98we7qw654er987fw"
          />
          <i
            className="fa-solid a6s54d89f7v98wedq89wre98we87icneassa9d87 fa-bars"
            style={{ color: "white" }}
          ></i>
        </div>
        <div className="sdsdf98u7yt9htg7wfqe987879467434">
          {/* A partir de aqui coloca el nuevo menu aqui dentro de este div */}
          <Link
            className={`subtitulosMenuUserHomeCompany ${
              selectedMenu === "/" ? "selected-menu" : ""
            }`}
            to="/"
            onClick={() => handleClick("/")}
          >
            <i className="fa-solid seraUnMarinsupre fa-globe"></i>
            <div className="LetterInvisible2" style={{ fontSize: "12px" }}>
              Inicio
            </div>
          </Link>
          <Link
            className={`subtitulosMenuUserHomeCompany ${
              selectedMenu === "carga_autobuses_rc" ? "selected-menu" : ""
            }`}
            to="/carga_autobuses_rc"
            onClick={() => handleClick("carga_autobuses_rc")}
          >
            <i className="fa-solid seraUnMarinsupre fa-bus"></i>
            <div className="LetterInvisible2" style={{ fontSize: "12px" }}>
              Buses Rutas Cortas
            </div>
          </Link>
          <Link
            className={`subtitulosMenuUserHomeCompany ${
              selectedMenu === "carga_porpuesto_rc" ? "selected-menu" : ""
            }`}
            to="/carga_porpuesto_rc"
            onClick={() => handleClick("carga_porpuesto_rc")}
          >
            <i className="fa-solid seraUnMarinsupre fa-car"></i>
            <div className="LetterInvisible2" style={{ fontSize: "12px" }}>
              Por Puesto Rutas Cortas
            </div>
          </Link>

          <div className="s547"></div>
        </div>
        <div className="d56f4d89g7wfq98d7et98re4f6t5er1eq56w64">
          <Link
            className={`subtitulosMenuUserHomeCompany ${
              selectedMenu === "Configuraciones" ? "selected-menu" : ""
            }`}
            onClick={() => handleClick("Configuraciones")}
            to="/Configuraciones"
          >
            <i className="fa-solid seraUnMarinsupre fa-gear"></i>
            <div className="LetterInvisible2" style={{ fontSize: "12px" }}>
              Configuraciones
            </div>
          </Link>
        </div>
        <div className="d56f4d89g7wfq98d7et98re4f6t5er1eq56w64">
          <a
            className="subtitulosMenuUserHomeCompany logoutbuttonCompany"
            onClick={handleLogout}
            href="/"
          >
            <i className="fa-solid seraUnMarinsupre fa-right-from-bracket"></i>
            <div
              href="#"
              className="LetterInvisible2"
              style={{ fontSize: "12px" }}
            >
              Cerrar sesión
            </div>
          </a>
        </div>
      </div>
    </div>
  );
};
