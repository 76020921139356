// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.poja65s4d8a9s7d89errg98er7fqwd9849iu9ykj7asj {
  padding: 50px 0px;
}
/* Asegúrate de que este CSS está cargado en tu proyecto */
body.swal2-shown {
  overflow: hidden !important;
  padding-right: 0 !important;
}
.iasbduiqnwdqwjhndiquwhds {
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center !important;
}
`, "",{"version":3,"sources":["webpack://./src/front/styles/RegistroCps.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;AACnB;AACA,0DAA0D;AAC1D;EACE,2BAA2B;EAC3B,2BAA2B;AAC7B;AACA;EACE,wBAAwB;EACxB,iCAAiC;EACjC,kCAAkC;EAClC,8BAA8B;AAChC","sourcesContent":[".poja65s4d8a9s7d89errg98er7fqwd9849iu9ykj7asj {\n  padding: 50px 0px;\n}\n/* Asegúrate de que este CSS está cargado en tu proyecto */\nbody.swal2-shown {\n  overflow: hidden !important;\n  padding-right: 0 !important;\n}\n.iasbduiqnwdqwjhndiquwhds {\n  display: flex !important;\n  flex-direction: column !important;\n  justify-content: center !important;\n  align-items: center !important;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
