// Importa React y createRoot
import React from "react";
import { createRoot } from "react-dom/client";

// Incluye tu archivo index.scss en el paquete
import "../styles/index.css";

// Importa tus propios componentes
import Layout from "./layout";

// Encuentra el elemento en el que quieres montar tu aplicación de React
const container = document.querySelector("#app");

// Crea una raíz para tu aplicación React
const root = createRoot(container);

// Renderiza tu aplicación React utilizando la raíz creada
root.render(<Layout />);
