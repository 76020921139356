import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

export const AddTerminalUbication = () => {
  const navigate = useNavigate();
  const [terminalName, setTerminalName] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        process.env.BACKEND_URL + "/api/add_terminal_ubication",
        { terminal_name: terminalName },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );

      console.log("TerminalUbication created successfully", response.data);
    } catch (error) {
      console.error("Error creating TerminalUbication", error);
    }
  };

  return (
    <div className="container containerDeRegistrojsSegundo" style={{marginTop: "120px"}}>
      <h2>Add Terminal Ubication</h2>
      <form onSubmit={handleSubmit}>
        <label>
          Terminal Name:
          <input
            className="inputRegistroDeUsuario"
            type="text"
            value={terminalName}
            onChange={(e) => setTerminalName(e.target.value)}
          />
        </label>
        <button type="submit">Create TerminalUbication</button>
      </form>
    </div>
  );
};
