import React, { useContext, useState, useEffect } from "react";
import axios from "axios";
import "../../../../styles/SolicitudActivaciones.css";
import { Context } from "../../../store/appContext";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import Big from "big.js";
import Select from "react-select";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFViewer,
  Image,
  pdf,
} from "@react-pdf/renderer";
import { PDFDownloadLink, BlobProvider } from "@react-pdf/renderer";
const MySwal = withReactContent(Swal);

export const Carga_rl = ({ toggleLoading }) => {
  return (
    <div
      className="container-fluid contarinerGeneralUserHomejs "
      style={{ paddingTop: "30px", paddingBottom: "70px" }}
    >
      <div className="container-fluid contaosijdasd-qw89d77asd223"></div>
      <h2
        className="asjdansdas-d4as6489text w-100"
        style={{ zIndex: "15", marginTop: "-17vh" }}
      >
        Rutas Largas
      </h2>
      <div className="container asklnduiashndaijsnxansxsaas">
        <form className="d-flex sd98f4e8wf4cwe987cwe984cwe">
          <div className="aoshduiahsdkasnmdasdas">
            <div className="input-group-login2">
              <input
                className="inputDeLogin"
                type="text"
                name="numero_listin"
                required
                readOnly
              />
              <label>N° Listín</label>
            </div>
            <div className="input-group-login2">
              <input
                className="inputDeLogin"
                type="text"
                name="tipo_servicio"
                required
                readOnly
                style={{ cursor: "pointer" }}
              />
              <label>Empresa</label>
            </div>
            <div className="input-group-login2">
              <input
                className="inputDeLogin"
                type="text"
                name="empresa"
                required
                readOnly
                style={{ cursor: "pointer" }}
              />
              <label>Destino Final</label>
            </div>
          </div>

          <div className="aoshduiahsdkasnmdasdas">
            <div className="input-group-login2">
              <input
                className="inputDeLogin"
                type="text"
                name="unidad"
                required
              />
              <label>N° Unidad</label>
            </div>
            <div className="input-group-login2">
              <input
                className="inputDeLogin"
                type="text"
                name="placa"
                required
                style={{ textTransform: "uppercase" }}
              />
              <label>Placa</label>
            </div>
            <div className="input-group-login2">
              <input
                className="inputDeLogin"
                type="text"
                name="numero_puestos"
                required
              />
              <label>Hora de Salida</label>
            </div>
          </div>

          <div className="aoshduiahsdkasnmdasdas">
            <div className="input-group-login2">
              <input
                className="inputDeLogin"
                type="text"
                name="cedula"
                required
              />
              <label>Adultos</label>
            </div>
            <div className="input-group-login2">
              <input
                className="inputDeLogin"
                type="text"
                name="conductor"
                required
              />
              <label>Tercera Edad</label>
            </div>
            <div className="input-group-login2">
              <input
                className="inputDeLogin"
                type="text"
                name="precio_bs"
                required
                readOnly
                style={{ cursor: "auto" }}
              />
              <label>Discapacitados</label>
            </div>
            <div className="input-group-login2">
              <input
                className="inputDeLogin"
                type="text"
                name="precio_bs"
                required
                readOnly
                style={{ cursor: "auto" }}
              />
              <label>Infantes (4 o menos años)</label>
            </div>
            <div className="input-group-login2">
              <input
                className="inputDeLogin"
                type="text"
                name="precio_bs"
                required
                readOnly
                style={{ cursor: "auto" }}
              />
              <label>Cortesías</label>
            </div>
          </div>

          <div className="w-100 asd9as-da9s84dx9a8qdw-qdw asdqw98d4qv895y4n9r84bvr98ebtnbtn">
            <button
              type="submit"
              className="btn buttonCargadeDatosDeAddDriver"
              style={{ marginBottom: "30px" }}
            >
              <div className="d-flex">
                <p style={{ marginBottom: 0 }}>Guardar Datos</p>
                <i className="fa-solid fa-arrow-right flechaHomePresentation2"></i>
              </div>
            </button>
          </div>
        </form>

        <div className="w-100 asd9as-da9s84dx9a8qdw-qdw asdqw98d4qv895y4n9r84bvr98ebtnbtn">
          <button className="btn secondary ioyu98jty7tr98v4er98f7er89w4d">
            <i
              className="fa-solid fa-car"
              style={{ marginRight: "7px", fontSize: "20px" }}
            ></i>
            Ver Activaciones
          </button>
        </div>
      </div>
    </div>
  );
};
