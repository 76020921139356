import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import { Context } from "../../../store/appContext";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  pdf,
} from "@react-pdf/renderer";

const MySwal = withReactContent(Swal);

const tableStyles = StyleSheet.create({
  anuladoRow: {
    backgroundColor: "#ffcccc", // Rojo clarito
  },
  table: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  table2: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    marginTop: 10,
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row",
    backgroundColor: "#FFF",
  },
  tableColHeader: {
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    backgroundColor: "#f2f2f2",
    alignItems: "center",
    justifyContent: "center",
  },
  tableCol: {
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    justifyContent: "center",
  },
  tableCellHeader: {
    margin: 5,
    fontSize: 9,
    fontWeight: "bold",
    textAlign: "center",
  },
  tableCell: {
    margin: 4,
    fontSize: 7,
  },
  tableCellstart: {
    margin: 4,
    fontSize: 8,
    textAlign: "center",
  },
});

tableStyles.tableColHeaderOthertaxi = {
  ...tableStyles.tableColHeader,
  width: "50%", // Mayor ancho para los demás
};

tableStyles.tableColOthertaxi = {
  ...tableStyles.tableCol,
  width: "50%", // Mayor ancho para los demás
};

// Modificar anchos específicos aquí
tableStyles.tableColHeaderNumber = {
  ...tableStyles.tableColHeader,
  width: "15%", // Menor ancho para "N°"
};

tableStyles.tableColHeaderOther = {
  ...tableStyles.tableColHeader,
  width: "21.3%", // Mayor ancho para los demás
};

tableStyles.tableColNumber = {
  ...tableStyles.tableCol,
  width: "15%", // Menor ancho para "N°"
};

tableStyles.tableColOther = {
  ...tableStyles.tableCol,
  width: "21.3%", // Mayor ancho para los demás
};

tableStyles.tableColHeaderOthertaxi2 = {
  ...tableStyles.tableColHeader,
  width: "33.3%", // Mayor ancho para los demás
};
tableStyles.tableColHeaderOthertaxi3 = {
  ...tableStyles.tableColHeader,
  width: "100%", // Mayor ancho para los demás
};

tableStyles.tableColOthertaxi2 = {
  ...tableStyles.tableCol,
  width: "33.3%", // Mayor ancho para los demás
};

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    borderBottomColor: "#000",
    borderBottomWidth: 1,
    alignItems: "center",
    height: 15,
    margin: 0,
    padding: 0,
    justifyContent: "center",
    textAlign: "center",
  },
  cell: {
    fontSize: 9,
    textAlign: "center",
    borderRightColor: "#000",
    borderRightWidth: 1,
    flex: 1,
  },
  cell2: {
    fontSize: 9,
    textAlign: "center",
    borderRightColor: "#000",
    borderRightWidth: 1,
    width: 20,
    borderLeftColor: "#000",
    borderLeftWidth: 1,
  },
  header: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  header2: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  logo: {
    width: 90, // Ajusta el tamaño según tus necesidades
    height: 30, // Ajusta el tamaño según tus necesidades
  },
  logo2: {
    width: 100,
    height: 20,
  },
  page: {
    padding: 20, // Ajusta el valor del margen según tus necesidades
  },
  letter: {
    fontSize: 7,
    padding: 5,
  },
  letter2: {
    fontSize: 10,
  },
  ruta: {
    fontSize: 7,
    padding: 4,
  },
  bottomText: {
    position: "absolute",
    bottom: 0,
    left: 20, // Ajusta según sea necesario
    right: 20, // Ajusta según sea necesario
    textAlign: "center",
    bottom: 30,
    fontSize: 8,
  },
  bottomText2: {
    position: "absolute",
    bottom: 0,
    left: 20, // Ajusta según sea necesario
    right: 20, // Ajusta según sea necesario
    textAlign: "center",
    bottom: 50,
    fontSize: 11,
    fontWeight: "bold",
  },
});

export const Cierre_listin_rc = () => {
  const { store } = useContext(Context);
  const [listines, setListines] = useState([]);
  const [totalListines, setTotalListines] = useState({});
  const [totalListinescount, setTotalListinescount] = useState({});
  const [listinespp, setListinespp] = useState([]);
  const [totalListinespp, setTotalListinespp] = useState({});
  const [totalListinesppcount, setTotalListinesppcount] = useState({});
  const [tickets, settickets] = useState([]);
  const [TotalesTickets, setTotalesTickets] = useState({});
  const [TotalesTicketscount, setTotalesTicketscount] = useState({});
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [user, setUser] = useState({});
  const [formData, setFormData] = useState({});

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(
          `${process.env.BACKEND_URL}/api/protect`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setUser({
          firstName: response.data.first_name,
          lastName: response.data.last_name,
          greeting: response.data.greeting,
          email: response.data.email,
        });
      } catch (error) {
        if (error.response && error.response.status === 401) {
          localStorage.removeItem("token");
          alert("Su sesión ha expirado, por favor vuelva a iniciar sesión");
          window.location.reload();
        } else {
          console.log("Axios error:", error.message);
        }
      }
    };

    fetchUserData();
  }, [store.token]);

  useEffect(() => {
    fetchListinesrc();
    fetchListinespp();
    fetchticketstaxi();
  }, []);

  const fetchticketstaxi = async () => {
    try {
      const response = await axios.get(
        process.env.BACKEND_URL + "/api/pregetTodayTicketsTaxi",
        {
          headers: {
            Authorization: `Bearer ${store.token}`,
          },
        }
      );
      settickets(response.data.tickets);
      setTotalesTickets(response.data.totales);
      setTotalesTicketscount(response.data.count);
    } catch (error) {
      console.error("Error fetching listines", error);
      MySwal.fire({
        title: "Error",
        text: "Hubo un error al obtener los listines.",
        icon: "error",
        confirmButtonText: "Cerrar",
      });
    }
  };

  const fetchListinesrc = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        process.env.BACKEND_URL + "/api/pregetTodayListinesRC",
        {
          headers: {
            Authorization: `Bearer ${store.token}`,
          },
        }
      );
      setListines(response.data.listines);
      setTotalListines(response.data.totales);
      setTotalListinescount(response.data.count);
    } catch (error) {
      console.error("Error fetching listines", error);
      MySwal.fire({
        title: "Error",
        text: "Hubo un error al obtener los listines.",
        icon: "error",
        confirmButtonText: "Cerrar",
      });
    } finally {
      setLoading(false);
    }
  };

  const fetchListinespp = async () => {
    try {
      const response = await axios.get(
        process.env.BACKEND_URL + "/api/pregetTodayListinesRCPP",
        {
          headers: {
            Authorization: `Bearer ${store.token}`,
          },
        }
      );
      setListinespp(response.data.listines);
      setTotalListinespp(response.data.totales);
      setTotalListinesppcount(response.data.count);
    } catch (error) {
      console.error("Error fetching listines", error);
      MySwal.fire({
        title: "Error",
        text: "Hubo un error al obtener los listines.",
        icon: "error",
        confirmButtonText: "Cerrar",
      });
    }
  };

  const fetchCierres = async () => {
    MySwal.fire({
      title: "Cargando...",
      html: `<p style="text-align:center; margin:20px;">Cargando cierres realizados...</p>`,
      allowOutsideClick: false,
      didOpen: () => {
        MySwal.showLoading();
      },
    });

    try {
      const response = await axios.get(
        process.env.BACKEND_URL + "/api/Cierre_RC_Usuario",
        {
          headers: {
            Authorization: `Bearer ${store.token}`,
          },
        }
      );

      const data = response.data;

      MySwal.close();

      if (!data || data.length === 0) {
        MySwal.fire({
          title: "Cierres Realizados",
          html: `<p style="text-align:center; margin:20px;">No se encontraron cierres.</p>`,
          confirmButtonText: "Cerrar",
        });
      } else {
        const content = `
          <table style="width: 2200px; border-collapse: collapse;">
            <thead>
              <tr>
                <th style="border: 1px solid #ccc; padding: 4px; font-size: 14px">Usuario</th>
                <th style="border: 1px solid #ccc; padding: 4px; font-size: 14px">Fecha y Hora de Registro</th>
                <th style="border: 1px solid #ccc; padding: 4px; font-size: 14px">Turno</th>
                <th style="border: 1px solid #ccc; padding: 4px; font-size: 14px">Total Listines Aprobados</th>
                <th style="border: 1px solid #ccc; padding: 4px; font-size: 14px">Total Listines PP Aprobados</th>
                <th style="border: 1px solid #ccc; padding: 4px; font-size: 14px">Total Tickets Aprobados</th>
                <th style="border: 1px solid #ccc; padding: 4px; font-size: 14px">Total Aprobados</th>
                <th style="border: 1px solid #ccc; padding: 4px; font-size: 14px">Total Listines Anulados</th>
                <th style="border: 1px solid #ccc; padding: 4px; font-size: 14px">Total Listines PP Anulados</th>
                <th style="border: 1px solid #ccc; padding: 4px; font-size: 14px">Total Tickets Anulados</th>
                <th style="border: 1px solid #ccc; padding: 4px; font-size: 14px">Total Anulados</th>
                <th style="border: 1px solid #ccc; padding: 4px; font-size: 14px">Total General</th>
                <th style="border: 1px solid #ccc; padding: 4px; font-size: 14px">Ver PDF</th>
              </tr>
            </thead>
            <tbody>
              ${data
                .map(
                  (cierre) => `
                    <tr>
                      <td style="border: 1px solid #ccc; padding: 3px;">${cierre.usuario}</td>
                      <td style="border: 1px solid #ccc; padding: 3px;">${cierre.fecha_hora_registro}</td>
                      <td style="border: 1px solid #ccc; padding: 3px;">${cierre.turno}</td>
                      <td style="border: 1px solid #ccc; padding: 3px;">${cierre.total_n_listines_aprobado}</td>
                      <td style="border: 1px solid #ccc; padding: 3px;">${cierre.total_n_listinespp_aprobado}</td>
                      <td style="border: 1px solid #ccc; padding: 3px;">${cierre.total_n_tickets_aprobado}</td>
                      <td style="border: 1px solid #ccc; padding: 3px;">${cierre.total_n_aprobados}</td>
                      <td style="border: 1px solid #ccc; padding: 3px;">${cierre.total_n_listines_anulado}</td>
                      <td style="border: 1px solid #ccc; padding: 3px;">${cierre.total_n_listinespp_anulado}</td>
                      <td style="border: 1px solid #ccc; padding: 3px;">${cierre.total_n_tickets_anulado}</td>
                      <td style="border: 1px solid #ccc; padding: 3px;">${cierre.total_n_anulados}</td>
                      <td style="border: 1px solid #ccc; padding: 3px;">${cierre.total_n_total}</td>
                      <td style="border: 1px solid #ccc; padding: 3px; text-align: center;">
                        <a href="${cierre.link_document}" target="_blank" class="btn btn-primary">
                          <i class="fa-regular fa-file-pdf"></i> Ver PDF
                        </a>
                      </td>
                    </tr>
                  `
                )
                .join("")}
            </tbody>
          </table>`;

        MySwal.fire({
          title: "Cierres Realizados",
          html: `<div style="text-align:left; max-height:400px; overflow-y:auto;">${content}</div>`,
          confirmButtonText: "Cerrar",
        });
      }
    } catch (error) {
      console.error("Error fetching cierres", error);
      MySwal.fire({
        title: "Error",
        text: "Hubo un error al obtener los cierres.",
        icon: "error",
        confirmButtonText: "Cerrar",
      });
    }
  };

  const MyDocument = ({ listines, id, fecha_hora_registro, turno }) => (
    <Document>
      <Page style={styles.page} size="LETTER">
        {/* Encabezado con dos logos */}
        <View style={styles.header}>
          <Image
            src="https://res.cloudinary.com/dirpdlfbu/image/upload/v1701880341/logo/cmyemflfryypewryq6ux.jpg"
            style={styles.logo}
          />
          <Image
            src="https://res.cloudinary.com/dirpdlfbu/image/upload/v1711324140/aeezebl5y4pzhcmgm2da.jpg"
            style={styles.logo2}
          />
        </View>
        <View style={styles.header2}>
          <Text style={styles.letter2}>CONTROL DE VENTA RUTAS CORTAS</Text>
        </View>

        <View
          style={{
            display: "flex",
            flexDirection: "row",
            marginBottom: "20px",
            marginTop: "20px",
          }}
        >
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              borderWidth: 1,
              borderColor: "#000",
              width: "20%",
              borderRightWidth: 0,
            }}
          >
            <Text style={styles.letter}>TAQUILLERO(A)</Text>
          </View>
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              borderWidth: 1,
              borderColor: "#000",
              width: "27%",
            }}
          >
            <Text style={styles.letter}>{user.email}</Text>
          </View>
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              borderWidth: 1,
              borderColor: "#000",
              width: "13%",
              marginLeft: "20px",
            }}
          >
            <Text style={styles.letter}>TURNO: {turno}</Text>
          </View>
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              borderWidth: 1,
              borderColor: "#000",
              width: "13%",
              marginLeft: "20px",
              borderRightWidth: 0,
            }}
          >
            <Text style={styles.letter}>FECHA:</Text>
          </View>
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              borderWidth: 1,
              borderColor: "#000",
              width: "22%",
            }}
          >
            <Text style={styles.letter}>{fecha_hora_registro}</Text>
          </View>
        </View>

        {/* Aqui van los Totales */}
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            marginBottom: "20px",
          }}
        >
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              borderWidth: 1,
              borderColor: "#000",
              width: "18%",
              borderRightWidth: 0,
            }}
          >
            <Text style={styles.letter}>TOTAL PUNTO DE VENTA APROBADO</Text>
          </View>
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              borderWidth: 1,
              borderColor: "#000",
              width: "15%",
              marginRight: "20px",
              alignItems: "center",
            }}
          >
            <Text style={styles.letter}>
              {(
                totalListines.total_punto_de_venta +
                totalListinespp.total_punto_de_venta +
                TotalesTickets.total_punto_de_venta
              ).toFixed(2)}
            </Text>
          </View>
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              borderWidth: 1,
              borderColor: "#000",
              width: "15%",
              borderRightWidth: 0,
              alignItems: "center",
            }}
          >
            <Text style={styles.letter}>TOTAL EFECTIVO APROBADO</Text>
          </View>
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              borderWidth: 1,
              borderColor: "#000",
              width: "15%",
              marginRight: "20px",
              alignItems: "center",
            }}
          >
            <Text style={styles.letter}>
              {(
                totalListines.total_efectivo +
                totalListinespp.total_efectivo +
                TotalesTickets.total_efectivo
              ).toFixed(2)}
            </Text>
          </View>
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              borderWidth: 1,
              borderColor: "#000",
              width: "15%",
              borderRightWidth: 0,
              alignItems: "center",
            }}
          >
            <Text style={styles.letter}>TOTAL NE APROBADO</Text>
          </View>
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              borderWidth: 1,
              borderColor: "#000",
              width: "15%",
              alignItems: "center",
            }}
          >
            <Text style={styles.letter}>
              {(
                totalListines.total_me +
                totalListinespp.total_me +
                TotalesTickets.total_me
              ).toFixed(2)}
            </Text>
          </View>
        </View>

        {/* Aqui van los Totales contados */}
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            marginBottom: "20px",
          }}
        >
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              borderWidth: 1,
              borderColor: "#000",
              width: "23%",
              borderRightWidth: 0,
            }}
          >
            <Text style={styles.letter}>TOTAL LISTINES BUSES APROBADOS</Text>
          </View>
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              borderWidth: 1,
              borderColor: "#000",
              width: "10%",
              marginRight: "20px",
            }}
          >
            <Text style={styles.letter}>{totalListinescount.activo}</Text>
          </View>
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              borderWidth: 1,
              borderColor: "#000",
              width: "20%",
              borderRightWidth: 0,
            }}
          >
            <Text style={styles.letter}>
              TOTAL LISTINES POR PUESTO APROBADOS
            </Text>
          </View>
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              borderWidth: 1,
              borderColor: "#000",
              width: "10%",
              marginRight: "20px",
            }}
          >
            <Text style={styles.letter}>{totalListinesppcount.activo}</Text>
          </View>
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              borderWidth: 1,
              borderColor: "#000",
              width: "20%",
              borderRightWidth: 0,
            }}
          >
            <Text style={styles.letter}>TOTAL TICKETS TAXI APROBADOS</Text>
          </View>
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              borderWidth: 1,
              borderColor: "#000",
              width: "10%",
            }}
          >
            <Text style={styles.letter}>{TotalesTicketscount.activo}</Text>
          </View>
        </View>

        {/* Total General */}

        <View
          style={{
            justifyContent: "center",
            display: "flex",
            alignContent: "center",
            alignItems: "center",
            marginBottom: "20px",
            flexDirection: "row",
          }}
        >
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              borderWidth: 1,
              borderColor: "#000",
              width: "38%",
              borderRightWidth: 0,
            }}
          >
            <Text style={{ padding: "5", fontSize: "11" }}>
              TOTAL VENTA DE LISTINES Y TICKETS
            </Text>
          </View>
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              borderWidth: 1,
              borderColor: "#000",
              width: "30%",
            }}
          >
            <Text style={{ padding: "5", fontSize: "11" }}>
              {(
                totalListines.total_general +
                totalListinespp.total_general +
                TotalesTickets.total_general
              ).toFixed(2)}
            </Text>
          </View>
        </View>

        {/* Total General anulados */}

        <View
          style={{
            justifyContent: "center",
            display: "flex",
            alignContent: "center",
            alignItems: "center",
            marginBottom: "20px",
            flexDirection: "row",
          }}
        >
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              borderWidth: 1,
              borderColor: "#000",
              width: "15%",
              borderRightWidth: 0,
              backgroundColor: "#ffcccc",
            }}
          >
            <Text style={styles.letter}>ANULADOS</Text>
          </View>
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              borderWidth: 1,
              borderColor: "#000",
              width: "15%",
              backgroundColor: "#ffcccc",
            }}
          >
            <Text style={styles.letter}>
              {totalListinescount.anulado +
                totalListinesppcount.anulado +
                TotalesTicketscount.anulado}
            </Text>
          </View>
        </View>

        {/* Detallado Venta buses */}
        <Text
          style={{
            textAlign: "center",
            fontSize: "10px",
            marginBottom: "10px",
          }}
        >
          VENTA BUSES
        </Text>
        <View style={tableStyles.table}>
          <View style={tableStyles.tableRow}>
            <View style={tableStyles.tableColHeaderNumber}>
              <Text style={tableStyles.tableCellHeader}>Numero Listín</Text>
            </View>
            <View style={tableStyles.tableColHeaderOther}>
              <Text style={tableStyles.tableCellHeader}>
                Empresa Transporte
              </Text>
            </View>
            <View style={tableStyles.tableColHeaderOther}>
              <Text style={tableStyles.tableCellHeader}>Destino</Text>
            </View>
            <View style={tableStyles.tableColHeaderOther}>
              <Text style={tableStyles.tableCellHeader}>Total Pagado</Text>
            </View>
            <View style={tableStyles.tableColHeaderOther}>
              <Text style={tableStyles.tableCellHeader}>Estatus</Text>
            </View>
          </View>
          {listines.map((listin) => {
            const rowStyle = [
              { display: "flex", flexDirection: "row" },
              listin.estatus === "ANULADO" ? tableStyles.anuladoRow : {},
            ];
            return (
              <View style={rowStyle} key={listin.id}>
                <View style={tableStyles.tableColNumber}>
                  <Text style={tableStyles.tableCellstart}>
                    {listin.n_listin}
                  </Text>
                </View>
                <View style={tableStyles.tableColOther}>
                  <Text style={tableStyles.tableCell}>{listin.empresa}</Text>
                </View>
                <View style={tableStyles.tableColOther}>
                  <Text style={tableStyles.tableCell}>
                    {listin.destino_final}
                  </Text>
                </View>
                <View style={tableStyles.tableColOther}>
                  <Text
                    style={tableStyles.tableCell}
                  >{`${listin.total_pagado}`}</Text>
                </View>
                <View style={tableStyles.tableColOther}>
                  <Text style={tableStyles.tableCell}>
                    {listin.estatus === "ACTIVO" ? "APROBADO" : listin.estatus}
                  </Text>
                </View>
              </View>
            );
          })}
        </View>
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            marginBottom: "20px",
            marginTop: "10px",
          }}
        >
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              borderWidth: 1,
              borderColor: "#000",
              width: "18%",
              borderRightWidth: 0,
            }}
          >
            <Text style={styles.letter}>TOTAL PUNTO DE VENTA</Text>
          </View>
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              borderWidth: 1,
              borderColor: "#000",
              width: "15%",
              marginRight: "20px",
            }}
          >
            <Text style={styles.letter}>
              {totalListines.total_punto_de_venta}
            </Text>
          </View>
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              borderWidth: 1,
              borderColor: "#000",
              width: "15%",
              borderRightWidth: 0,
            }}
          >
            <Text style={styles.letter}>TOTAL EFECTIVO</Text>
          </View>
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              borderWidth: 1,
              borderColor: "#000",
              width: "15%",
              marginRight: "20px",
            }}
          >
            <Text style={styles.letter}>{totalListines.total_efectivo}</Text>
          </View>
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              borderWidth: 1,
              borderColor: "#000",
              width: "15%",
              borderRightWidth: 0,
            }}
          >
            <Text style={styles.letter}>TOTAL NE</Text>
          </View>
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              borderWidth: 1,
              borderColor: "#000",
              width: "15%",
            }}
          >
            <Text style={styles.letter}>{totalListines.total_me}</Text>
          </View>
        </View>
        <View
          style={{
            justifyContent: "center",
            display: "flex",
            alignContent: "center",
            alignItems: "center",
            marginBottom: "10px",
            flexDirection: "row",
          }}
        >
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              borderWidth: 1,
              borderColor: "#000",
              width: "38%",
              borderRightWidth: 0,
            }}
          >
            <Text style={styles.letter}>TOTAL VENTA DE BUSES</Text>
          </View>
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              borderWidth: 1,
              borderColor: "#000",
              width: "30%",
            }}
          >
            <Text style={styles.letter}>{totalListines.total_general}</Text>
          </View>
        </View>

        {/* Detallado Venta Por puesto */}
        <Text
          style={{
            textAlign: "center",
            fontSize: "10px",
            marginBottom: "10px",
          }}
        >
          VENTA POR PUESTO
        </Text>
        <View style={tableStyles.table}>
          <View style={tableStyles.tableRow}>
            <View style={tableStyles.tableColHeaderNumber}>
              <Text style={tableStyles.tableCellHeader}>Numero Listín</Text>
            </View>
            <View style={tableStyles.tableColHeaderOther}>
              <Text style={tableStyles.tableCellHeader}>
                Empresa Transporte
              </Text>
            </View>
            <View style={tableStyles.tableColHeaderOther}>
              <Text style={tableStyles.tableCellHeader}>Destino</Text>
            </View>
            <View style={tableStyles.tableColHeaderOther}>
              <Text style={tableStyles.tableCellHeader}>Total Pagado</Text>
            </View>
            <View style={tableStyles.tableColHeaderOther}>
              <Text style={tableStyles.tableCellHeader}>Estatus</Text>
            </View>
          </View>
          {listinespp.map((listin) => {
            const rowStyle = [
              { display: "flex", flexDirection: "row" },
              listin.estatus === "ANULADO" ? tableStyles.anuladoRow : {},
            ];
            return (
              <View style={rowStyle} key={listin.id}>
                <View style={tableStyles.tableColNumber}>
                  <Text style={tableStyles.tableCellstart}>
                    {listin.n_listin}
                  </Text>
                </View>
                <View style={tableStyles.tableColOther}>
                  <Text style={tableStyles.tableCell}>{listin.empresa}</Text>
                </View>
                <View style={tableStyles.tableColOther}>
                  <Text style={tableStyles.tableCell}>
                    {listin.destino_final}
                  </Text>
                </View>
                <View style={tableStyles.tableColOther}>
                  <Text
                    style={tableStyles.tableCell}
                  >{`${listin.total_pagado}`}</Text>
                </View>
                <View style={tableStyles.tableColOther}>
                  <Text style={tableStyles.tableCell}>
                    {listin.estatus === "ACTIVO" ? "APROBADO" : listin.estatus}
                  </Text>
                </View>
              </View>
            );
          })}
        </View>
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            marginBottom: "20px",
            marginTop: "10px",
          }}
        >
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              borderWidth: 1,
              borderColor: "#000",
              width: "18%",
              borderRightWidth: 0,
            }}
          >
            <Text style={styles.letter}>TOTAL PUNTO DE VENTA</Text>
          </View>
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              borderWidth: 1,
              borderColor: "#000",
              width: "15%",
              marginRight: "20px",
            }}
          >
            <Text style={styles.letter}>
              {totalListinespp.total_punto_de_venta}
            </Text>
          </View>
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              borderWidth: 1,
              borderColor: "#000",
              width: "15%",
              borderRightWidth: 0,
            }}
          >
            <Text style={styles.letter}>TOTAL EFECTIVO</Text>
          </View>
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              borderWidth: 1,
              borderColor: "#000",
              width: "15%",
              marginRight: "20px",
            }}
          >
            <Text style={styles.letter}>{totalListinespp.total_efectivo}</Text>
          </View>
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              borderWidth: 1,
              borderColor: "#000",
              width: "15%",
              borderRightWidth: 0,
            }}
          >
            <Text style={styles.letter}>TOTAL NE</Text>
          </View>
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              borderWidth: 1,
              borderColor: "#000",
              width: "15%",
            }}
          >
            <Text style={styles.letter}>{totalListinespp.total_me}</Text>
          </View>
        </View>

        <View
          style={{
            justifyContent: "center",
            display: "flex",
            alignContent: "center",
            alignItems: "center",
            marginBottom: "10px",
            flexDirection: "row",
          }}
        >
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              borderWidth: 1,
              borderColor: "#000",
              width: "38%",
              borderRightWidth: 0,
            }}
          >
            <Text style={styles.letter}>TOTAL VENTA DE POR PUESTO</Text>
          </View>
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              borderWidth: 1,
              borderColor: "#000",
              width: "30%",
            }}
          >
            <Text style={styles.letter}>{totalListinespp.total_general}</Text>
          </View>
        </View>

        {/* Detallado Venta TICKETS */}
        <Text
          style={{
            textAlign: "center",
            fontSize: "10px",
            marginBottom: "10px",
          }}
        >
          VENTA TICKETS TAXI
        </Text>
        <View style={tableStyles.table}>
          <View style={tableStyles.tableRow}>
            <View style={tableStyles.tableColHeaderNumber}>
              <Text style={tableStyles.tableCellHeader}>Ticket (1)</Text>
            </View>
            <View style={tableStyles.tableColHeaderOther}>
              <Text style={tableStyles.tableCellHeader}>Empresa</Text>
            </View>
            <View style={tableStyles.tableColHeaderOther}>
              <Text style={tableStyles.tableCellHeader}>Nombre</Text>
            </View>
            <View style={tableStyles.tableColHeaderOther}>
              <Text style={tableStyles.tableCellHeader}>Total Pagado</Text>
            </View>
            <View style={tableStyles.tableColHeaderOther}>
              <Text style={tableStyles.tableCellHeader}>Estatus</Text>
            </View>
          </View>
          {tickets.map((listin) => {
            const rowStyle = [
              { display: "flex", flexDirection: "row" },
              listin.estatus === "ANULADO" ? tableStyles.anuladoRow : {},
            ];
            return (
              <View style={rowStyle} key={listin.id_registro}>
                <View style={tableStyles.tableColNumber}>
                  <Text style={tableStyles.tableCellstart}>
                    {listin.n_ticket_1}
                  </Text>
                </View>
                <View style={tableStyles.tableColOther}>
                  <Text style={tableStyles.tableCell}>
                    {listin.company_name}
                  </Text>
                </View>
                <View style={tableStyles.tableColOther}>
                  <Text style={tableStyles.tableCell}>
                    {listin.nombre_apellido}
                  </Text>
                </View>
                <View style={tableStyles.tableColOther}>
                  <Text
                    style={tableStyles.tableCell}
                  >{`${listin.total_pagado}`}</Text>
                </View>
                <View style={tableStyles.tableColOther}>
                  <Text style={tableStyles.tableCell}>
                    {listin.estatus === "ACTIVO" ? "APROBADO" : listin.estatus}
                  </Text>
                </View>
              </View>
            );
          })}
        </View>
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            marginBottom: "20px",
            marginTop: "10px",
          }}
        >
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              borderWidth: 1,
              borderColor: "#000",
              width: "18%",
              borderRightWidth: 0,
            }}
          >
            <Text style={styles.letter}>TOTAL PUNTO DE VENTA</Text>
          </View>
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              borderWidth: 1,
              borderColor: "#000",
              width: "15%",
              marginRight: "20px",
            }}
          >
            <Text style={styles.letter}>
              {TotalesTickets.total_punto_de_venta}
            </Text>
          </View>
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              borderWidth: 1,
              borderColor: "#000",
              width: "15%",
              borderRightWidth: 0,
            }}
          >
            <Text style={styles.letter}>TOTAL EFECTIVO</Text>
          </View>
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              borderWidth: 1,
              borderColor: "#000",
              width: "15%",
              marginRight: "20px",
            }}
          >
            <Text style={styles.letter}>{TotalesTickets.total_efectivo}</Text>
          </View>
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              borderWidth: 1,
              borderColor: "#000",
              width: "15%",
              borderRightWidth: 0,
            }}
          >
            <Text style={styles.letter}>TOTAL NE</Text>
          </View>
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              borderWidth: 1,
              borderColor: "#000",
              width: "15%",
            }}
          >
            <Text style={styles.letter}>{TotalesTickets.total_me}</Text>
          </View>
        </View>
        <View
          style={{
            justifyContent: "center",
            display: "flex",
            alignContent: "center",
            alignItems: "center",
            marginBottom: "10px",
            flexDirection: "row",
          }}
        >
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              borderWidth: 1,
              borderColor: "#000",
              width: "38%",
              borderRightWidth: 0,
            }}
          >
            <Text style={styles.letter}>TOTAL VENTA DE TAXI</Text>
          </View>
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              borderWidth: 1,
              borderColor: "#000",
              width: "30%",
            }}
          >
            <Text style={styles.letter}>{TotalesTickets.total_general}</Text>
          </View>
        </View>
      </Page>
    </Document>
  );

  const handlePreview = async () => {
    MySwal.fire({
      title: "Generando PDF...",
      text: "Por favor, espere mientras se genera el PDF.",
      allowOutsideClick: false,
      didOpen: () => {
        MySwal.showLoading();
      },
    });

    const blob = await pdf(<MyDocument listines={listines} />).toBlob();
    const url = URL.createObjectURL(blob);
    MySwal.close();
    window.open(url, "_blank");
  };

  const handleCierre = async () => {
    if (listines.length === 0) {
      MySwal.fire({
        title: "No se puede realizar el cierre",
        text: "Registra algún listín para hacer el cierre.",
        icon: "warning",
        confirmButtonText: "Cerrar",
      });
      return;
    }

    const result = await MySwal.fire({
      title: "¿Está seguro?",
      text: "¿Está seguro que quiere cerrar el listín?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Sí, cerrar",
      cancelButtonText: "No, cancelar",
    });

    if (result.isConfirmed) {
      setSaving(true);
      MySwal.fire({
        title: "Cerrando listines...",
        text: "Por favor, espere mientras se cierra los tickets y listines, esto puede tardar entre 1-4 minutos.",
        allowOutsideClick: false,
        didOpen: () => {
          MySwal.showLoading();
        },
      });

      try {
        const response = await axios.post(
          process.env.BACKEND_URL + "/api/cierre_listinesticket_rc",
          {
            turno: "A",
          },
          {
            headers: {
              Authorization: `Bearer ${store.token}`,
            },
          }
        );

        const { id, fecha_hora_registro, turno } = response.data.cierre;

        const blob = await pdf(
          <MyDocument
            listines={listines}
            id={id}
            fecha_hora_registro={fecha_hora_registro}
            turno={turno}
          />
        ).toBlob();
        const pdfFormData = new FormData();
        pdfFormData.append("file", blob, "cierre_listines_rl.pdf");

        const uploadResponse = await axios.post(
          `https://api.cloudinary.com/v1_1/${process.env.CLOUDINARY_CLOUD_NAME}/raw/upload`,
          pdfFormData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
            params: {
              upload_preset: process.env.CLOUDINARY_UPLOAD_PRESET,
              folder: `LISTINES/CIERRES/CCS-TLB_rc`,
            },
          }
        );

        const linkDocument = uploadResponse.data.secure_url;

        await axios.post(
          process.env.BACKEND_URL + "/api/updateCierreRC",
          {
            id,
            link_document: linkDocument,
          },
          {
            headers: {
              Authorization: `Bearer ${store.token}`,
            },
          }
        );

        MySwal.close();
        MySwal.fire({
          title: "Cierre exitoso",
          text: "El cierre de listines se ha realizado con éxito.",
          icon: "success",
          confirmButtonText: "Cerrar",
        });

        fetchListinesrc();
        fetchListinespp();
        fetchticketstaxi();
      } catch (error) {
        console.error("Error al cerrar listines", error);
        MySwal.fire({
          title: "Error",
          text: "Hubo un error al cerrar los listines.",
          icon: "error",
          confirmButtonText: "Cerrar",
        });
      } finally {
        setSaving(false);
      }
    }
  };

  return (
    <div
      className="container-fluid contarinerGeneralUserHomejs"
      style={{ paddingTop: "30px", paddingBottom: "70px" }}
    >
      <div className="container-fluid contaosijdasd-qw89d77asd2"></div>
      <h2
        className="asjdansdas-d4as6489text w-100"
        style={{ zIndex: "15", marginTop: "-17vh" }}
      >
        Cierre Listines Rutas Largas
      </h2>
      <div className="container movimientoss9a87d89qwdqw98d4qw">
        <div className="aoshduiahsdkasnmdasdas">
          <button
            className="btn btn-primary"
            onClick={handlePreview}
            style={{
              height: "4vh",
              background: "rgba(128, 128, 128, 0.463)",
              margin: 0,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              border: "none",
            }}
            disabled={loading || saving}
          >
            Vista Cierre Actual
            <i className="fa-regular fa-eye" style={{ marginLeft: "8px" }}></i>
          </button>
          <button
            className="btn btn-primary"
            onClick={fetchCierres}
            style={{
              height: "4vh",
              background: "#151B26",
              margin: 0,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              border: "none",
            }}
            disabled={loading || saving}
          >
            Visualiza tus Cierres
            <i
              className="fa-solid fa-list-ul"
              style={{ marginLeft: "8px" }}
            ></i>
          </button>
          <button
            className="btn btn-primary"
            onClick={handleCierre}
            style={{
              minWidth: "180px",
              height: "4vh",
              background: "#24827C",
              margin: 0,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              border: "none",
            }}
            disabled={loading || saving}
          >
            Hacer Cierre
            <i
              className="fa-solid fa-cloud-arrow-down"
              style={{ marginLeft: "8px" }}
            ></i>
          </button>
        </div>
        <h5 style={{ textAlign: "center", marginTop: "20px" }}>
          Listines Sin Cerrar Buses
        </h5>
        <div className="table-responsive">
          <table>
            <thead>
              <tr className="aoshduishdihwe89f78w9e7we7we7fcw">
                <th>Número Listín</th>
                <th>Fecha</th>
                <th>Empresa</th>
                <th>Destino</th>
                <th>Unidad</th>
                <th>Precio Bs</th>
                <th>Pagado</th>
                <th>Registrado por</th>
              </tr>
            </thead>
            <tbody>
              {listines.map((item) => (
                <tr
                  key={item.id}
                  style={{
                    backgroundColor:
                      item.estatus === "ANULADO" ? "#ffcccc" : "transparent",
                  }}
                >
                  <td>{item.n_listin}</td>
                  <td>{item.fecha}</td>
                  <td>{item.empresa}</td>
                  <td>{item.destino_final}</td>
                  <td>{item.n_unidad}</td>
                  <td>{item.precio_bs}</td>
                  <td>{`PV: ${item.total_punto_de_venta}, Ef: ${item.total_efectivo}, NE: ${item.total_me}`}</td>
                  <td>{item.registrado_por}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <h5 style={{ textAlign: "center", marginTop: "20px" }}>
          Listines Sin Cerrar Por Puesto
        </h5>
        <div className="table-responsive">
          <table>
            <thead>
              <tr className="aoshduishdihwe89f78w9e7we7we7fcw">
                <th>Número Listín</th>
                <th>Fecha</th>
                <th>Empresa</th>
                <th>Destino</th>
                <th>Unidad</th>
                <th>Precio Bs</th>
                <th>Pagado</th>
                <th>Registrado por</th>
              </tr>
            </thead>
            <tbody>
              {listinespp.map((item) => (
                <tr
                  key={item.id}
                  style={{
                    backgroundColor:
                      item.estatus === "ANULADO" ? "#ffcccc" : "transparent",
                  }}
                >
                  <td>{item.n_listin}</td>
                  <td>{item.fecha}</td>
                  <td>{item.empresa}</td>
                  <td>{item.destino_final}</td>
                  <td>{item.n_unidad}</td>
                  <td>{item.precio_bs}</td>
                  <td>{`PV: ${item.total_punto_de_venta}, Ef: ${item.total_efectivo}, NE: ${item.total_me}`}</td>
                  <td>{item.registrado_por}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <h5 style={{ textAlign: "center", marginTop: "20px" }}>
          Tickets Sin cerrar Taxi
        </h5>
        <div className="table-responsive">
          <table>
            <thead>
              <tr className="aoshduishdihwe89f78w9e7we7we7fcw">
                <th>Número Ticket</th>
                <th>Fecha</th>
                <th>Empresa</th>
                <th>Nombre y Apellido</th>
                <th>Precio Bs</th>
                <th>Pagado</th>
                <th>Registrado por</th>
              </tr>
            </thead>
            <tbody>
              {tickets.map((item) => (
                <tr
                  key={item.id}
                  style={{
                    backgroundColor:
                      item.estatus === "ANULADO" ? "#ffcccc" : "transparent",
                  }}
                >
                  <td>{item.n_ticket_1}</td>
                  <td>{item.fecha_hora_registro}</td>
                  <td>{item.company_name}</td>
                  <td>{item.nombre_apellido}</td>
                  <td>{item.precio_bs}</td>
                  <td>{`PV: ${item.total_punto_de_venta}, Ef: ${item.total_efectivo}, NE: ${item.total_me}`}</td>
                  <td>{item.registrado_por}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
