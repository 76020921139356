import React, { useState } from "react";
import axios from "axios";
import "../../styles/RegistroDolarBcv.css";

export const RegistroDolarBcv = () => {
  const [date, setDate] = useState("");
  const [valor, setValor] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        process.env.BACKEND_URL + "/api/agregar_dato_dolar",
        {
          date,
          valor,
        }
      );

      setMessage(response.data.message);
    } catch (error) {
      setMessage("Error al agregar dato del dólar");
      console.error("Error:", error);
    }
  };
  return (
    <div>
      <div className="form-container container as-d481q5wd6198qew5y9u8ty4r">
        <h2>Agregar Dato del Dólar</h2>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="date">Fecha:</label>
            <input
              type="date"
              id="date"
              value={date}
              onChange={(e) => setDate(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="valor">Valor:</label>
            <input
              type="text"
              id="valor"
              value={valor}
              onChange={(e) => setValor(e.target.value)}
              required
            />
          </div>
          <button type="submit">Agregar Dato</button>
        </form>
        {message && <p>{message}</p>}
      </div>
    </div>
  );
};
