import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { Context } from "./store/appContext";
import { Link, useNavigate } from "react-router-dom";
import Chart from "chart.js/auto";
import ApexCharts from "react-apexcharts";

export const UserHome = () => {
  const { actions, store } = useContext(Context);
  const navigate = useNavigate();
  const { token } = useContext(Context);
  const [userData, setUserData] = useState(null);
  const [isMounted, setIsMounted] = useState(true);
  const [usdRate, setUsdRate] = useState(null);
  const [chartData, setChartData] = useState([]);
  const [showPeople, setShowPeople] = useState(false);
  const [chartHeight, setChartHeight] = useState("3vh");
  const [chartWidth, setChartWidth] = useState("5vh");
  const [pointsData, setPointsData] = useState(null);

  const newsData = [
    {
      title: "¡Nueva actualización!",
      content:
        "Hemos lanzado una emocionante nueva actualización. ¡Descúbrelo ahora!",
    },
    {
      title: "Conéctate con nosotros",
      content:
        "Estamos emocionados de conectarnos contigo. Mantente actualizado con nuestras últimas noticias y características.",
    },
  ];

  const maxLines = 3;

  const areaChartOptions2 = {
    chart: {
      height: 350,
      toolbar: {
        show: false,
      },
    },
    xaxis: {
      labels: {
        style: {
          colors: "white",
        },
      },
      type: "category",
      categories: chartData.map((data) => data.date),
    },
    yaxis: {
      title: {
        text: "",
        style: {
          color: "#ffffff",
        },
      },
      labels: {
        style: {
          colors: "#ffffff",
        },
      },
      lines: Array.from({ length: maxLines }, (_, i) => ({
        value: i + 1, // Ajusta los valores de referencia según tus necesidades
        borderColor: "#ffffff",
        label: {
          borderColor: "#ffffff",
          style: {
            color: "#ffffff",
          },
          text: `Línea ${i + 1}`,
        },
      })),
    },
    colors: ["#1D9D86", "#FF6384"],
    fill: {
      opacity: 0.7,
    },
    legend: {
      labels: {
        colors: "#ffffff",
      },
    },
  };

  const areaChartOptions = {
    chart: {
      height: 350,
      toolbar: {
        show: false,
      },
    },
    xaxis: {
      labels: {
        style: {
          colors: "white",
        },
      },
      type: "category",
      categories: chartData.map((data) => data.date),
    },
    yaxis: {
      title: {
        text: "",
        style: {
          color: "#ffffff",
        },
      },
      labels: {
        style: {
          colors: "#ffffff",
        },
      },
      lines: Array.from({ length: maxLines }, (_, i) => ({
        value: i + 1, // Ajusta los valores de referencia según tus necesidades
        borderColor: "#ffffff",
        label: {
          borderColor: "#ffffff",
          style: {
            color: "#ffffff",
          },
          text: `Línea ${i + 1}`,
        },
      })),
    },
    colors: ["#1D9D86", "#FF6384"],
    fill: {
      opacity: 0.7,
    },
    legend: {
      labels: {
        colors: "#ffffff",
      },
    },
  };

  const areaChartSeries = [
    {
      name: "Usuarios",
      data: chartData.map((data) => data.dailyPeople),
    },
  ];

  const areaChartSeries2 = [
    {
      name: "Autobuses",
      data: chartData.map((data) => data.dailyBuses),
    },
  ];

  const lineChartOptions = {
    chart: {
      height: 350,
      toolbar: {
        show: false,
      },
    },
    xaxis: {
      type: "category",
      categories: chartData.map((data) => data.date),
      labels: {
        style: {
          colors: "#ffffff", // Color blanco
        },
      },
    },
    yaxis: {
      title: {
        text: "",
        style: {
          color: "#ffffff", // Color blanco
        },
      },
      labels: {
        style: {
          colors: "#ffffff", // Color blanco
        },
      },
    },
    colors: ["#1D9D86", "#FF6384"],
    stroke: {
      curve: "smooth",
    },
    grid: {
      show: false, // Oculta las líneas de fondo
    },
    legend: {
      labels: {
        colors: "#ffffff", // Color blanco en la leyenda
      },
    },
  };

  const lineChartSeries = [
    {
      name: "Autobuses",
      data: chartData.map((data) => data.dailyBuses),
    },
    {
      name: "Usuarios",
      data: chartData.map((data) => data.dailyPeople),
    },
  ];

  const options = {
    chart: {
      type: "bar",
      height: 350,
      stacked: true,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      type: "category",
      categories: chartData.map((data) => data.date),
    },
    yaxis: {
      title: {
        text: "Count",
      },
    },
    colors: ["#1D9D86", "#FF6384"], // Replace with your desired colors
  };

  const series = [
    {
      name: "Autobuses",
      data: chartData.map((data) => data.dailyBuses),
    },
    {
      name: "Usuarios",
      data: chartData.map((data) => data.dailyPeople),
    },
  ];

  useEffect(() => {
    const handleResize = () => {
      const isWideScreen = window.innerWidth <= 850;

      setChartHeight(isWideScreen ? "6vh" : "3vh");
      setChartWidth("5vh");
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const obtenerTasaDeCambio = async () => {
    try {
      const respuesta = await fetch(
        process.env.BACKEND_URL + "/api/tasa_dolar_actual"
      );

      if (
        !respuesta.ok ||
        !respuesta.headers.get("content-type")?.includes("application/json")
      ) {
        console.error("La respuesta del servidor no es un JSON válido.");

        return;
      }

      const datos = await respuesta.json();

      if (datos.tasa_dolar) {
        // Reemplazar los puntos por comas en el valor de la tasa
        const tasaFormateada = datos.tasa_dolar.replace(/\./g, ",");
        setUsdRate(tasaFormateada);
      } else {
        console.error(
          "La propiedad tasa_dolar no está presente en la respuesta del servidor."
        );
      }
    } catch (error) {
      console.error("Error al obtener la tasa de cambio:", error);
    }
  };

  // useEffect(() => {
  //   const fetchUserPoints = async () => {
  //     try {
  //       const options = {
  //         headers: {
  //           Authorization: `Bearer ${store.token}`,
  //         },
  //       };

  //       const response = await axios.get(
  //         `${process.env.BACKEND_URL}/api/verificar_puntos_usuario/${userData.email}`,
  //         options
  //       );
  //       setPointsData(response.data.puntos);
  //     } catch (error) {
  //       console.error("Error fetching user points", error);
  //     }
  //   };
  //   if (userData) {
  //     fetchUserPoints();
  //   }
  // }, [userData]);

  // const fetchUserData = async () => {
  //   const options = {
  //     headers: {
  //       Authorization: "Bearer " + store.token,
  //     },
  //   };
  //   try {
  //     const response = await axios.get(
  //       process.env.BACKEND_URL + "/api/protected",
  //       options
  //     );
  //     setUserData(response.data);
  //   } catch (error) {
  //     console.error("Error fetching user data", error);
  //   }
  // };
  // useEffect(() => {
  //   fetchUserData();
  // }, []);

  useEffect(() => {
    actions.checkLogin(navigate);
  }, []);

  const generateRandomData = () => {
    const currentDate = new Date();
    const last7Days = Array.from({ length: 7 }, (_, i) => {
      const date = new Date();
      date.setDate(currentDate.getDate() - (7 - i));

      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const day = date.getDate().toString().padStart(2, "0");
      const year = date.getFullYear();
      return `${day}/${month}`;
    });

    const data = last7Days.map((date) => ({
      date,
      dailyIncomes: getRandomNumber(1, 10),
      dailyExpenses: getRandomNumber(1, 10),
      // Nuevos datos ficticios para personas
      dailyPeople: getRandomNumber(100, 200),
      dailyBuses: getRandomNumber(1, 10),
    }));

    setChartData(data);
  };

  useEffect(() => {
    generateRandomData();
  }, [showPeople]);

  const formatDate = (date) => {
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    const year = date.getFullYear();
    return `${month}/${day}/${year}`;
  };

  const getRandomNumber = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  };
  return (
    <div
      className="container-fluid contarinerGeneralUserHomejs"
      style={{ flexDirection: "column" }}
    >
      {/* <Link className="container containerDeUsreHomejsonelinea" to="/">
        <div style={{ color: "#212529" }}>
          <p style={{ margin: 0, fontSize: "15px" }}>Tus Puntos</p>
          <h4>
            <strong>
              {pointsData !== null ? `${pointsData} pts` : "Cargando..."}
            </strong>
          </h4>
        </div>
        <div className="progress">
          <svg>
            <circle cx="38" cy="38" r="36"></circle>
          </svg>
          <div className="percentage" style={{ color: "#212529" }}>
            +23%
          </div>
        </div>
      </Link>
      <Link className="container containerDeUsreHomejsonelinea" to="/">
        <div style={{ color: "#212529" }}>
          <p style={{ margin: 0, fontSize: "15px" }}>Nivel de eficiencia</p>
          <h4>
            <strong>ALTO</strong>
          </h4>
        </div>
        <div className="progress saldo">
          <svg>
            <circle cx="38" cy="38" r="36"></circle>
          </svg>
          <div className="percentage" style={{ color: "#212529" }}>
            +23%
          </div>
        </div>
      </Link>
      <Link className="container containerDeUsreHomejsonelinea" to="/">
        <div style={{ color: "#212529" }}>
          <div>
            <p style={{ margin: 0, fontSize: "15px" }}>Cambio $ BCV</p>
            <h4>
              <strong>{usdRate}</strong>
            </h4>
          </div>
        </div>
        <div className="progress bcv">
          <svg>
            <circle cx="38" cy="38" r="36"></circle>
          </svg>
          <div className="percentage" style={{ color: "#212529" }}>
            +0,12%
          </div>
        </div>
      </Link> */}
      <div className="container suiahdisodm-we98f4wec">
        <div className="container d-flex p-0 s98d7fa1sd9c8a4sc9as4ca-s5ca4s89s">
          <div className="container asune-rv56er48v9wec-s">
            <div className="container aoshdnuqiwhnd897">
              <div className="container ds9f848dsfwef498we4fwd-5798">
                <div className="sad8qw9d4asas6x4a89s4">
                  <i className="fa-solid asd98asdas894xsa fa-couch"></i>
                </div>
              </div>
              <div className="container ds897fq4w9dc9w8c4-we89c7w">
                <p style={{ margin: "0" }}>Tus Puntos</p>
                <h5 style={{ color: "#12499a" }}>
                  {pointsData !== null ? `${pointsData} pts` : "Muy Pronto..."}
                </h5>
              </div>
            </div>
            <div className="asdw-w9d84c9w8e4dcd"></div>
            <div className="container aoshdnuqiwhnd8972">
              <div style={{ display: "flex", flexDirection: "row" }}>
                <p style={{ margin: "0" }} className="asd98as79d4sd98as4x">
                  <strong>+0%</strong>
                </p>
                <p style={{ margin: "0", marginLeft: "7px" }}> Esta semana</p>
              </div>
            </div>
          </div>
          <div className="container asune-rv56er48v9wec-s mgjmnh98-sdf9w8e7f9wewefwes">
            <div className="container aoshdnuqiwhnd897 ">
              <div className="container ds9f848dsfwef498we4fwd-5798">
                <div className="sad8qw9d4asas6x4a89s42">
                  <i className="fa-solid asd98asdas894xsa fa-chart-simple"></i>
                </div>
              </div>
              <div className="container ds897fq4w9dc9w8c4-we89c7w">
                <p style={{ margin: "0" }}>Nivel Eficiencia</p>
                <h5 style={{ color: "#ff9900" }}>ALTO</h5>
              </div>
            </div>
            <div className="asdw-w9d84c9w8e4dcd"></div>
            <div className="container aoshdnuqiwhnd8972">
              <div style={{ display: "flex", flexDirection: "row" }}>
                <p style={{ margin: "0" }} className="asd98as79d4sd98as4x">
                  <strong>+0%</strong>
                </p>
                <p style={{ margin: "0", marginLeft: "7px" }}> Este mes</p>
              </div>
            </div>
          </div>
        </div>
        <div className="container d-flex as98d798qwd465qe-ew9f8qw8e9d s98d7fa1sd9c8a4sc9as4ca-s5ca4s89s">
          <div className="container asune-rv56er48v9wec-s ">
            <div className="container aoshdnuqiwhnd897">
              <div className="container ds9f848dsfwef498we4fwd-5798">
                <div className="sad8qw9d4asas6x4a89s43">
                  <i className="fa-solid asd98asdas894xsa fa-dollar-sign"></i>
                </div>
              </div>
              <div className="container ds897fq4w9dc9w8c4-we89c7w">
                <p style={{ margin: "0" }}>Dolar BCV</p>
                <h5 style={{ color: "#7341b4" }}>{usdRate} Bs</h5>
              </div>
            </div>
            <div className="asdw-w9d84c9w8e4dcd"></div>
            <div className="container aoshdnuqiwhnd8972">
              <div style={{ display: "flex", flexDirection: "row" }}>
                <p style={{ margin: "0" }} className="asd98as79d4sd98as4x">
                  <strong>0,2%</strong>
                </p>
                <p style={{ margin: "0", marginLeft: "7px" }}> Esta semana</p>
              </div>
            </div>
          </div>
          <div className="container asune-rv56er48v9wec-s mgjmnh98-sdf9w8e7f9wewefwes">
            <div className="container aoshdnuqiwhnd897">
              <div className="container ds9f848dsfwef498we4fwd-5798">
                <div className="sad8qw9d4asas6x4a89s44">
                  <i className="fa-solid asd98asdas894xsa fa-calendar-days"></i>
                </div>
              </div>
              <div className="container ds897fq4w9dc9w8c4-we89c7w">
                <p style={{ margin: "0" }}>Cierres</p>
                <h5 style={{ color: "#ff3a4b" }}>0</h5>
              </div>
            </div>
            <div className="asdw-w9d84c9w8e4dcd"></div>
            <div className="container aoshdnuqiwhnd8972">
              <div style={{ display: "flex", flexDirection: "row" }}>
                <p style={{ margin: "0" }} className="asd98as79d4sd98as4x">
                  <strong>+11%</strong>
                </p>
                <p style={{ margin: "0", marginLeft: "7px" }}> Esta semana</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container containerDeChartsUserHome">
        <div className="container asdasc987asc8a98dsc4sdv8e-v65sd4">
          <div className="asodhnoiuashnxiuasnx1">
            <ApexCharts
              options={areaChartOptions}
              series={areaChartSeries}
              type="area"
              height="100%"
              width="100%"
            />
          </div>

          <p className="letrasPequenasEnUserHomeDetalles">
            Últimos 7 días de movimientos (Usuarios)
          </p>
        </div>
        <div className="container asdasc987asc8a98dsc4sdv8e-v65sd42">
          <div className="asodhnoiuashnxiuasnx2">
            <ApexCharts
              options={areaChartOptions2}
              series={areaChartSeries2}
              type="area"
              height="100%"
              width="100%"
            />
          </div>
          <p className="letrasPequenasEnUserHomeDetalles">
            Últimos 7 días de movimientos (Buses)
          </p>
        </div>
      </div>
      {/* <div className="container asdasc987asc8a98dsc4sdv8e-v65sd4">
        <ApexCharts
          options={options}
          series={series}
          type="bar"
          height="300"
          width="100%"
        />
        <p className="letrasPequenasEnUserHomeDetalles">
          Últimos 7 días de movimientos
        </p>
      </div> */}
      <div className="container containerDeChartsUserHome2">
        <div className="container ohasoidjqw-d48w9d8as7">
          <h5>Tus Salidas (Últimos 7 días)</h5>
          <table className="table table-bordered">
            <thead>
              <tr style={{ textAlign: "center" }}>
                <th>Fecha</th>
                <th>Autobuses</th>
                <th>Usuarios</th>
              </tr>
            </thead>
            <tbody>
              {chartData.map((data) => (
                <tr key={data.date}>
                  <td>{data.date}</td>
                  <td style={{ textAlign: "center" }}>{data.dailyBuses}</td>
                  <td style={{ textAlign: "center" }}>{data.dailyPeople}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="container ohasoidjqw-d48w9d8as72">
          <ul className="list-group">
            {newsData.map((news, index) => (
              <li key={index} className="list-group-item">
                <div className="accordion" id={`newsAccordion${index}`}>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id={`heading${index}`}>
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target={`#collapse${index}`}
                        aria-expanded="false"
                        aria-controls={`collapse${index}`}
                      >
                        {news.title}
                      </button>
                    </h2>
                    <div
                      id={`collapse${index}`}
                      className="accordion-collapse collapse"
                      aria-labelledby={`heading${index}`}
                      data-bs-parent={`#newsAccordion${index}`}
                    >
                      <div className="accordion-body">{news.content}</div>
                    </div>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
      {/* <div className="container containerDechartHomejs">
        <canvas id="myChart" width={chartWidth} height={chartHeight}></canvas>
        <p className="letrasPequenasEnUserHomeDetalles">
          Últimos 7 días de movimientos
        </p>
      </div> */}
    </div>
  );
};
