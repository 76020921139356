import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { Context } from "../../store/appContext";
import { Link, useNavigate } from "react-router-dom";
import Chart from "chart.js/auto";
import ApexCharts from "react-apexcharts";
import "../../../styles/home4sup.css";
import "../../../styles/SolicitudActivaciones.css";

export const UserHome_listin_rl = () => {
  return (
    <div
      className="container-fluid contarinerGeneralUserHomejs"
      style={{ paddingTop: "30px" }}
    >
      <div className="container-fluid contaosijdasd-qw89d77asd2"></div>
      <h2
        className="asjdansdas-d4as6489text w-100"
        style={{ zIndex: "15", marginTop: "-17vh" }}
      >
        Mi Inicio
      </h2>

      <div className="container asklnduiashndaijsnxansxsaas">
        <i
          className="fa-solid fa-circle-exclamation"
          style={{ fontSize: "100px" }}
        ></i>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            textAlign: "center",
          }}
        >
          <h1 style={{ color: "rgba(128, 128, 128, 0.697)" }}>Muy pronto!</h1>
          <p style={{ color: "rgba(128, 128, 128, 0.697)" }}>
            Ve tus estadísticas de resgistro, gana puntos, enterate de las
            últimas actualizaciones y mucho mas
          </p>
        </div>
      </div>
    </div>
  );
};
