import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import "../../styles/login.css";
import { Context } from "./../store/appContext";

export const Login2 = () => {
  const { actions, store } = useContext(Context);
  const navigate = useNavigate();
  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = {
      email: e.target.email.value,
      password: e.target.password.value,
    };

    try {
      const response = await axios.post(
        process.env.BACKEND_URL + "/api/login",
        formData,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );

      if (response.status === 200) {
        const userData = response.data;
        actions.savetoken(response.data.token, userData);

        // Obtén el rol del usuario desde userData
        const userRole = userData?.rol;

        // mostrarAlerta1();
        navigate("/Inicio");
      } else {
        // mostrarAlerta2();
        console.error("Error al iniciar sesión", response.data);
      }
    } catch (error) {
      mostrarAlerta2();
      console.error("Error al iniciar sesión", error);
    }
  };

  const mostrarAlerta1 = () => {
    swal({
      title: "Inicio de sesión exitoso",
      icon: "success",
      timer: "3000",
      buttons: {
        yes: {
          text: "Ok",
          value: true,
          className: "custom-button-yes",
        },
      },
      customClass: {
        modal: "custom-modal",
      },
    });
  };

  const mostrarAlerta2 = () => {
    swal({
      title: "Correo o Contraseña Invalidad",
      icon: "success",
      timer: "3000",
      buttons: {
        yes: {
          text: "Ok",
          value: true,
          className: "custom-button-yes",
        },
      },
      customClass: {
        modal: "custom-modal",
      },
    });
  };

  return (
    <div className="aq4-asd56sa54s">
      {/* <div className="as-dq9s54da9sq-sd5qs-dasxasx51q-sd5asd"> */}
      {/* <div className="aosniaosnxas123a2" style={{ height: "65vh" }}>
          <svg
            className="waves"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            viewBox="0 24 150 40"
            preserveAspectRatio="none"
            shapeRendering="auto"
          >
            <defs>
              <path
                id="gentle-wave"
                d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"
              />
            </defs>
            <g className="parallax">
              <use
                xlinkHref="#gentle-wave"
                x="48"
                y="-1"
                fill="rgba(255,255,255,0.40)"
              />
              <use
                xlinkHref="#gentle-wave"
                x="48"
                y="3"
                fill="rgba(255,255,255,0.35)"
              />
              <use
                xlinkHref="#gentle-wave"
                x="48"
                y="5"
                fill="rgba(255,255,255,0.25)"
              />
              <use
                xlinkHref="#gentle-wave"
                x="48"
                y="8"
                fill="rgba(255,255,255,0.20)"
              />
              <use
                xlinkHref="#gentle-wave"
                x="48"
                y="13"
                fill="rgba(255,255,255,0.15)"
              />
              <use
                xlinkHref="#gentle-wave"
                x="48"
                y="16"
                fill="rgba(255,255,255,0.95)"
              />
              <use
                xlinkHref="#gentle-wave"
                x="48"
                y="7"
                fill="rgba(242, 249, 255, 1)"
              />
            </g>
          </svg>
        </div>
        <div
          className="home-homesup"
          style={{ backdropFilter: "blur(10px)", height: "60vh" }}
        >
          <div>
            <div
              className="circle-98765"
              style={{ marginTop: "100px", marginLeft: "40px" }}
            ></div>
            <div
              className="circle-987652"
              style={{ marginTop: "200px", marginLeft: "20px" }}
            ></div>
            <div
              className="circle-987651"
              style={{ marginTop: "400px", marginLeft: "80px" }}
            ></div>
            <div
              className="circle-9876512"
              style={{ marginTop: "200px", marginLeft: "150px" }}
            ></div>
            <div
              className="circle-9876513"
              style={{ marginTop: "70px", marginLeft: "50vw" }}
            ></div>
            <div
              className="circle-9876514"
              style={{ marginTop: "70px", marginLeft: "40vw" }}
            ></div>
          </div>
        </div>
        <div className="asdas9d87qdasdasdasd7as">
          <div
            className="container aosniaosnxas123a"
            style={{ height: "60vh" }}
          >
            <div style={{ zIndex: "20" }}>
              <div
                style={{
                  zIndex: "9999",
                  color: "white",
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <div className="asda-sd4qw98d-qsd4asd">
                  <h1>Iniciar Sesión</h1>
                  <p>
                    Accede a tu cuenta para disfrutar de una gestión eficiente y
                    segura de tus viajes.
                  </p>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginTop: "20px",
                  zIndex: "1",
                }}
              ></div>
            </div>
          </div>
        </div>
      </div> */}
      <div
        className="container containerDeLoginjssegundo"
        style={{
          zIndex: "60",
          position: "sticky",
          height: "70vh",
          marginTop: "5vh",
        }}
      >
        <div className="i-fewf5c9ew5ce9wdc1w4c1s"></div>
        {/* <h3 id="tituloDeLogin">
          <i className="fa-solid fa-user"></i> Inicia Sesión
        </h3> */}
        <p style={{ color: "#0a306a" }}>Inicia Sesión en Intravialca</p>
        <form onSubmit={handleSubmit} className="formularioDeLogin">
          <div className="input-group-login">
            <input className="inputDeLogin" type="text" name="email" required />
            <label htmlFor="email">Correo electrónico</label>
          </div>
          <div className="input-group-login">
            <input
              className="inputDeLogin"
              type="password"
              name="password"
              required
            />
            <label htmlFor="text">Contraseña</label>
          </div>
          <button className="buttonCargadeDatosDeLogin" type="submit">
            Iniciar sesión <i className="fa-solid fa-arrow-right"></i>
          </button>
        </form>
      </div>
    </div>
  );
};
