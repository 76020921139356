import React, { useState, useContext } from "react";
import "../../../../styles/MovimientosDeUsuarios.css";
import axios from "axios";
import { Context } from "../../../store/appContext";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);

export const KmDistancia = () => {
  const { store } = useContext(Context);
  const [distancias, setDistancias] = useState([]);
  const [formData, setFormData] = useState({
    destiny_final: "",
    km_distancia: "",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const fetchDestinos = async () => {
    MySwal.fire({
      title: "Cargando...",
      html: `<p style="text-align:center; margin:20px;">Cargando destinos finales...</p>`,
      allowOutsideClick: false,
      didOpen: () => {
        MySwal.showLoading();
      },
    });

    try {
      const response = await axios.get(
        process.env.BACKEND_URL + "/api/getDestinosFinales",
        {
          headers: {
            Authorization: `Bearer ${store.token}`,
          },
        }
      );

      setDistancias(response.data);
      MySwal.close();
      selectDestinoDialog(response.data);
    } catch (error) {
      console.error("Error al obtener destinos finales", error);
      MySwal.fire({
        title: "Error",
        html: `<p>Error al obtener destinos finales. Por favor, inténtelo de nuevo más tarde.</p>`,
        icon: "error",
        confirmButtonText: "Cerrar",
      });
    }
  };

  const selectDestinoDialog = (destinos) => {
    const destinoOptions = destinos.map((destino, index) => ({
      id: index,
      text: destino.destino,
      value: destino.destino,
      km_registrado: destino.km_registrado,
    }));

    MySwal.fire({
      title: "Seleccionar Destino Final",
      html: `
        <input type="text" id="searchDestino" class="swal2-input" placeholder="Buscar Destino">
        ${destinoOptions
          .map(
            (option) =>
              `<div style="cursor: pointer; margin: 10px; padding: 10px; border: 1px solid #ccc; background-color: ${
                option.km_registrado ? "#0080001c" : "#ff00001a"
              };" data-id="${option.id}">${option.text}</div>`
          )
          .join("")}
        <div id="destino-options"></div>
      `,
      showConfirmButton: true,
      confirmButtonText: "Cerrar",
      didRender: () => {
        const searchInput = document.getElementById("searchDestino");
        searchInput.addEventListener("input", (event) => {
          const filter = event.target.value.toLowerCase();
          const elements = document.querySelectorAll("[data-id]");
          elements.forEach((element) => {
            const text = element.textContent.toLowerCase();
            element.style.display = text.includes(filter) ? "block" : "none";
          });
        });

        const elements = document.querySelectorAll("[data-id]");
        elements.forEach((element) => {
          element.addEventListener("click", () => {
            const id = element.getAttribute("data-id");
            MySwal.clickConfirm();
            setFormData((prevData) => ({
              ...prevData,
              destiny_final: destinoOptions[id].value,
            }));
          });
        });
      },
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    MySwal.fire({
      title: "Registrando...",
      html: `<p style="text-align:center; margin:20px;">Registrando distancia...</p>`,
      allowOutsideClick: false,
      didOpen: () => {
        MySwal.showLoading();
      },
    });

    try {
      const response = await axios.post(
        process.env.BACKEND_URL + "/api/registerDistanciaDestino",
        formData,
        {
          headers: {
            Authorization: `Bearer ${store.token}`,
          },
        }
      );
      MySwal.fire({
        title: "Éxito",
        text: "La distancia ha sido registrada exitosamente.",
        icon: "success",
        confirmButtonText: "Cerrar",
      });
      setFormData({
        destiny_final: "",
        km_distancia: "",
      });
    } catch (error) {
      console.error("Error al registrar la distancia", error);
      MySwal.fire({
        title: "Error",
        text: "Error al registrar la distancia. Por favor, inténtelo de nuevo.",
        icon: "error",
        confirmButtonText: "Cerrar",
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const fetchDistancias = async () => {
    MySwal.fire({
      title: "Cargando...",
      html: `<p style="text-align:center; margin:20px;">Cargando distancias...</p>`,
      allowOutsideClick: false,
      didOpen: () => {
        MySwal.showLoading();
      },
    });

    try {
      const response = await axios.get(
        process.env.BACKEND_URL + "/api/getDistanciasPorDestinoFinal",
        {
          headers: {
            Authorization: `Bearer ${store.token}`,
          },
        }
      );

      setDistancias(response.data);
      MySwal.fire({
        title: "Distancias por Destino Final",
        html: `
          <input type="text" id="searchDistancia" class="swal2-input" placeholder="Buscar Distancia">
          <div style="max-height: 300px; overflow-y: auto;" id="distanciaList">
            ${response.data
              .map(
                (distancia) =>
                  `<div style="margin: 10px; padding: 10px; border: 1px solid #ccc;">
                    <strong>Destino:</strong> ${distancia.destino_final} <br />
                    <strong>Kilómetros:</strong> ${distancia.km_distancia}
                  </div>`
              )
              .join("")}
          </div>
        `,
        showConfirmButton: true,
        confirmButtonText: "Cerrar",
        didRender: () => {
          const searchInput = document.getElementById("searchDistancia");
          searchInput.addEventListener("input", (event) => {
            const filter = event.target.value.toLowerCase();
            const elements = document.querySelectorAll("#distanciaList > div");
            elements.forEach((element) => {
              const text = element.textContent.toLowerCase();
              element.style.display = text.includes(filter) ? "block" : "none";
            });
          });
        },
      });
    } catch (error) {
      console.error("Error al obtener distancias por destino final", error);
      MySwal.fire({
        title: "Error",
        html: `<p>Error al obtener distancias por destino final. Por favor, inténtelo de nuevo más tarde.</p>`,
        icon: "error",
        confirmButtonText: "Cerrar",
      });
    }
  };

  return (
    <div
      className="container-fluid contarinerGeneralUserHomejs"
      style={{ paddingTop: "30px" }}
    >
      <div className="container-fluid contaosijdasd-qw89d77asd2"></div>
      <h2
        className="asjdansdas-d4as6489text w-100"
        style={{ zIndex: "15", marginTop: "-17vh" }}
      >
        Precios Destino Final Rutas Largas
      </h2>
      <div className="container asklnduiashndaijsnxansxsaas">
        <form className="formularioDeLogin w-100" onSubmit={handleSubmit}>
          <div className="aoshduiahsdkasnmdasdas">
            <div className="input-group-login2">
              <input
                className="inputDeLogin"
                type="text"
                name="destiny_final"
                value={formData.destiny_final}
                onClick={fetchDestinos}
                readOnly
                required
              />
              <label>Destino Final</label>
            </div>
            <div className="input-group-login">
              <input
                className="inputDeLogin"
                type="number"
                name="km_distancia"
                value={formData.km_distancia}
                onChange={handleChange}
                required
              />
              <label>Km Distancia</label>
            </div>
          </div>

          <button
            className="buttonCargadeDatosDeLogin"
            type="submit"
            disabled={isSubmitting}
          >
            Registrar <i className="fa-solid fa-arrow-right"></i>
          </button>
        </form>
      </div>
      <div className="w-100 asd9as-da9s84dx9a8qdw-qdw asdqw98d4qv895y4n9r84bvr98ebtnbtn poja65s4d8a9s7d89errg98er7fqwd9849iu9ykj7asj">
        <button
          className="btn asd98q7wd65q1wd8xqw49x8e4wc"
          onClick={fetchDistancias}
        >
          <i
            className="fa-solid fa-route"
            style={{ marginRight: "7px", fontSize: "20px" }}
          ></i>
          Ver Distancias por Destino Final
        </button>
      </div>
    </div>
  );
};
