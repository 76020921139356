import React, { useState, useContext } from "react";
import "../../../../styles/MovimientosDeUsuarios.css";
import axios from "axios";
import { Context } from "../../../store/appContext";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFViewer,
  Image,
  pdf,
} from "@react-pdf/renderer";

const MySwal = withReactContent(Swal);

const tableStyles = StyleSheet.create({
  table: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  table2: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    marginTop: 10,
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row",
    backgroundColor: "#FFF",
  },
  tableColHeader: {
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    backgroundColor: "#f2f2f2",
    alignItems: "center",
    justifyContent: "center",
  },
  tableCol: {
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    alignItems: "center",
    justifyContent: "center",
  },
  tableCellHeader: {
    margin: 5,
    fontSize: 9,
    fontWeight: "bold",
  },
  tableCell: {
    margin: 4,
    fontSize: 9,
  },
});

tableStyles.tableColHeaderOthertaxi = {
  ...tableStyles.tableColHeader,
  width: "50%", // Mayor ancho para los demás
};

tableStyles.tableColOthertaxi = {
  ...tableStyles.tableCol,
  width: "50%", // Mayor ancho para los demás
};

// Modificar anchos específicos aquí
tableStyles.tableColHeaderNumber = {
  ...tableStyles.tableColHeader,
  width: "5%", // Menor ancho para "N°"
};

tableStyles.tableColHeaderOther = {
  ...tableStyles.tableColHeader,
  width: "22.5%", // Mayor ancho para los demás
};

tableStyles.tableColNumber = {
  ...tableStyles.tableCol,
  width: "5%", // Menor ancho para "N°"
};

tableStyles.tableColOther = {
  ...tableStyles.tableCol,
  width: "22.5%", // Mayor ancho para los demás
};

tableStyles.tableColHeaderOthertaxi2 = {
  ...tableStyles.tableColHeader,
  width: "33.3%", // Mayor ancho para los demás
};
tableStyles.tableColHeaderOthertaxi3 = {
  ...tableStyles.tableColHeader,
  width: "100%", // Mayor ancho para los demás
};

tableStyles.tableColOthertaxi2 = {
  ...tableStyles.tableCol,
  width: "33.3%", // Mayor ancho para los demás
};

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    borderBottomColor: "#000",
    borderBottomWidth: 1,
    alignItems: "center",
    height: 15,
    margin: 0,
    padding: 0,
    justifyContent: "center",
    textAlign: "center",
  },
  cell: {
    fontSize: 9,
    textAlign: "center",
    borderRightColor: "#000",
    borderRightWidth: 1,
    flex: 1,
  },
  cell2: {
    fontSize: 9,
    textAlign: "center",
    borderRightColor: "#000",
    borderRightWidth: 1,
    width: 20,
    borderLeftColor: "#000",
    borderLeftWidth: 1,
  },
  header: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  logo: {
    width: 90, // Ajusta el tamaño según tus necesidades
    height: 30, // Ajusta el tamaño según tus necesidades
  },
  logo2: {
    width: 100,
    height: 20,
  },
  page: {
    padding: 20, // Ajusta el valor del margen según tus necesidades
  },
  letter: {
    fontSize: 10,
    padding: 5,
  },
  letter2: {
    fontSize: 10,
  },
  ruta: {
    fontSize: 7,
    padding: 4,
  },
  bottomText: {
    position: "absolute",
    bottom: 0,
    left: 20, // Ajusta según sea necesario
    right: 20, // Ajusta según sea necesario
    textAlign: "center",
    bottom: 30,
    fontSize: 8,
  },
  bottomText2: {
    position: "absolute",
    bottom: 0,
    left: 20, // Ajusta según sea necesario
    right: 20, // Ajusta según sea necesario
    textAlign: "center",
    bottom: 50,
    fontSize: 11,
    fontWeight: "bold",
  },
});

export const TicketDeTaxi = () => {
  const { store } = useContext(Context);
  const [saving, setSaving] = useState(false);
  const [taxistas, setTaxistas] = useState([]);
  const [valorDolar, setValorDolar] = useState(0);
  const [formData, setFormData] = useState({
    company_name: "",
    nombre_apellido: "",
    cedula: "",
    placa: "",
    precio_bs: "",
    precio_dolar: 4,
    formas_de_pago: [{ forma_pago: "", total_a_cancelar: "", referencia: "" }],
  });

  const fetchCompanies = async () => {
    MySwal.fire({
      title: "Cargando...",
      html: `<p style="text-align:center; margin:20px;">Cargando empresas...</p>`,
      allowOutsideClick: false,
      didOpen: () => {
        MySwal.showLoading();
      },
    });

    try {
      const response = await axios.get(
        process.env.BACKEND_URL + "/api/getTaxistas",
        {
          headers: {
            Authorization: `Bearer ${store.token}`,
          },
        }
      );

      const { taxistas, valor } = response.data;
      const uniqueCompanies = [
        ...new Set(taxistas.map((taxista) => taxista.company_name)),
      ];
      setTaxistas(taxistas);
      setValorDolar(valor);
      MySwal.close();
      selectCompanyDialog(uniqueCompanies);
    } catch (error) {
      console.error("Error al obtener empresas", error);
      MySwal.fire({
        title: "Error",
        html: `<p>Error al obtener empresas. Por favor, inténtelo de nuevo más tarde.</p>`,
        icon: "error",
        confirmButtonText: "Cerrar",
      });
    }
  };

  const selectCompanyDialog = (companies) => {
    const companyOptions = companies.map((company, index) => ({
      id: index,
      text: company,
      value: company,
    }));

    MySwal.fire({
      title: "Seleccionar Empresa",
      html: `
        <input type="text" id="searchCompany" class="swal2-input" placeholder="Buscar Empresa">
        ${companyOptions
          .map(
            (option) =>
              `<div style="cursor: pointer; margin: 10px; padding: 10px; border: 1px solid #ccc;" data-id="${option.id}">${option.text}</div>`
          )
          .join("")}
        <div id="company-options"></div>
      `,
      showConfirmButton: true,
      confirmButtonText: "Cerrar",
      didRender: () => {
        const searchInput = document.getElementById("searchCompany");
        searchInput.addEventListener("input", (event) => {
          const filter = event.target.value.toLowerCase();
          const elements = document.querySelectorAll("[data-id]");
          elements.forEach((element) => {
            const text = element.textContent.toLowerCase();
            element.style.display = text.includes(filter) ? "block" : "none";
          });
        });

        const elements = document.querySelectorAll("[data-id]");
        elements.forEach((element) => {
          element.addEventListener("click", () => {
            const id = element.getAttribute("data-id");
            MySwal.clickConfirm();
            const selectedCompany = companyOptions[id].value;
            setFormData((prevData) => ({
              ...prevData,
              company_name: selectedCompany,
              nombre_apellido: "",
              cedula: "",
              placa: "",
            }));
          });
        });
      },
    });
  };

  const selectNombreApellidoDialog = () => {
    const filteredTaxistas = taxistas.filter(
      (taxista) => taxista.company_name === formData.company_name
    );
    const uniqueNames = [
      ...new Set(filteredTaxistas.map((taxista) => taxista.nombre_apellido)),
    ];

    const nameOptions = uniqueNames.map((name, index) => ({
      id: index,
      text: name,
      value: name,
    }));

    MySwal.fire({
      title: "Seleccionar Nombre y Apellido",
      html: `
        <input type="text" id="searchName" class="swal2-input" placeholder="Buscar Nombre y Apellido">
        ${nameOptions
          .map(
            (option) =>
              `<div style="cursor: pointer; margin: 10px; padding: 10px; border: 1px solid #ccc;" data-id="${option.id}">${option.text}</div>`
          )
          .join("")}
        <div id="name-options"></div>
      `,
      showConfirmButton: true,
      confirmButtonText: "Cerrar",
      didRender: () => {
        const searchInput = document.getElementById("searchName");
        searchInput.addEventListener("input", (event) => {
          const filter = event.target.value.toLowerCase();
          const elements = document.querySelectorAll("[data-id]");
          elements.forEach((element) => {
            const text = element.textContent.toLowerCase();
            element.style.display = text.includes(filter) ? "block" : "none";
          });
        });

        const elements = document.querySelectorAll("[data-id]");
        elements.forEach((element) => {
          element.addEventListener("click", () => {
            const id = element.getAttribute("data-id");
            MySwal.clickConfirm();
            const selectedName = nameOptions[id].value;
            const selectedTaxista = filteredTaxistas.find(
              (taxista) => taxista.nombre_apellido === selectedName
            );
            setFormData((prevData) => ({
              ...prevData,
              nombre_apellido: selectedName,
              cedula: selectedTaxista.cedula,
              placa: "",
            }));
          });
        });
      },
    });
  };

  const selectPlacaDialog = () => {
    const filteredTaxistas = taxistas.filter(
      (taxista) =>
        taxista.company_name === formData.company_name &&
        taxista.nombre_apellido === formData.nombre_apellido
    );

    const placaOptions = filteredTaxistas.map((taxista, index) => ({
      id: index,
      text: taxista.placa,
      value: taxista.placa,
    }));

    MySwal.fire({
      title: "Seleccionar Placa",
      html: `
        <input type="text" id="searchPlaca" class="swal2-input" placeholder="Buscar Placa">
        ${placaOptions
          .map(
            (option) =>
              `<div style="cursor: pointer; margin: 10px; padding: 10px; border: 1px solid #ccc;" data-id="${option.id}">${option.text}</div>`
          )
          .join("")}
        <div id="placa-options"></div>
      `,
      showConfirmButton: true,
      confirmButtonText: "Cerrar",
      didRender: () => {
        const searchInput = document.getElementById("searchPlaca");
        searchInput.addEventListener("input", (event) => {
          const filter = event.target.value.toLowerCase();
          const elements = document.querySelectorAll("[data-id]");
          elements.forEach((element) => {
            const text = element.textContent.toLowerCase();
            element.style.display = text.includes(filter) ? "block" : "none";
          });
        });

        const elements = document.querySelectorAll("[data-id]");
        elements.forEach((element) => {
          element.addEventListener("click", () => {
            const id = element.getAttribute("data-id");
            MySwal.clickConfirm();
            const selectedPlaca = placaOptions[id].value;
            setFormData((prevData) => ({
              ...prevData,
              placa: selectedPlaca,
              precio_bs: (4 * valorDolar).toFixed(2),
              precio_dolar: 4,
            }));
          });
        });
      },
    });
  };

  const handlePaymentChange = (index, e) => {
    const { name, value } = e.target;
    const updatedFormasDePago = formData.formas_de_pago.map((pago, i) =>
      i === index ? { ...pago, [name]: value } : pago
    );
    setFormData((prevData) => ({
      ...prevData,
      formas_de_pago: updatedFormasDePago,
    }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const totalPagos = formData.formas_de_pago.reduce(
      (acc, pago) => acc + parseFloat(pago.total_a_cancelar || 0),
      0
    );

    if (totalPagos.toFixed(2) !== parseFloat(formData.precio_bs).toFixed(2)) {
      MySwal.fire({
        title: "Error",
        html: `<p>El total de los pagos (${totalPagos.toFixed(
          2
        )} Bs) no coincide con el precio en bolívares (${
          formData.precio_bs
        } Bs). Por favor, verifica los montos.</p>`,
        icon: "error",
        confirmButtonText: "Cerrar",
      });
      return;
    }

    setSaving(true);

    MySwal.fire({
      title: "Registrando Ticket",
      html: `<p style="text-align:center; margin:20px;">Por favor, espere...</p>`,
      allowOutsideClick: false,
      didOpen: () => {
        MySwal.showLoading();
      },
    });

    try {
      const response = await axios.post(
        process.env.BACKEND_URL + "/api/registerTicketTaxi",
        formData,
        {
          headers: {
            Authorization: `Bearer ${store.token}`,
          },
        }
      );

      const {
        n_ticket_1,
        n_ticket_2,
        n_ticket_3,
        n_ticket_4,
        ticket_id,
        fechayhora,
      } = response.data;

      const pdfContent = (
        <Document>
          <Page style={styles.page} size="LETTER">
            <View
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <View style={{ width: "49%" }}>
                <View style={styles.header}>
                  <Image
                    src="https://res.cloudinary.com/dirpdlfbu/image/upload/v1701880341/logo/cmyemflfryypewryq6ux.jpg"
                    style={styles.logo}
                  />
                  <Image
                    src="https://res.cloudinary.com/dirpdlfbu/image/upload/v1711324140/aeezebl5y4pzhcmgm2da.jpg"
                    style={styles.logo2}
                  />
                </View>
                <View style={{ padding: "10px 0px" }}>
                  <Text style={{ textAlign: "center" }}>TICKET TAXI</Text>
                  <Text style={{ textAlign: "center" }}>
                    N° Ticket {n_ticket_1}
                  </Text>
                </View>
                <View style={tableStyles.table}>
                  <View style={tableStyles.tableRow}>
                    <View style={tableStyles.tableColHeaderOthertaxi}>
                      <Text style={tableStyles.tableCellHeader}>
                        CONDUCTOR:
                      </Text>
                    </View>
                    <View style={tableStyles.tableColHeaderOthertaxi}>
                      <Text style={tableStyles.tableCellHeader}>C.I:</Text>
                    </View>
                  </View>
                  {/* Otra parte */}
                  <View style={tableStyles.tableRow}>
                    <View style={tableStyles.tableColOthertaxi}>
                      <Text style={tableStyles.tableCell}>
                        {formData.nombre_apellido}
                      </Text>
                    </View>
                    <View style={tableStyles.tableColOthertaxi}>
                      <Text style={tableStyles.tableCell}>
                        {formData.cedula}
                      </Text>
                    </View>
                  </View>
                </View>
                <View style={tableStyles.table2}>
                  <View style={tableStyles.tableRow}>
                    <View style={tableStyles.tableColHeaderOthertaxi2}>
                      <Text style={tableStyles.tableCellHeader}>
                        VALOR DE LA TASA
                      </Text>
                    </View>
                    <View style={tableStyles.tableColHeaderOthertaxi2}>
                      <Text style={tableStyles.tableCellHeader}>
                        FECHA DE EMISIÓN
                      </Text>
                    </View>
                    <View style={tableStyles.tableColHeaderOthertaxi2}>
                      <Text style={tableStyles.tableCellHeader}>
                        PLACA DE VEHÍCULO
                      </Text>
                    </View>
                  </View>
                  {/* Otra parte */}
                  <View style={tableStyles.tableRow}>
                    <View style={tableStyles.tableColOthertaxi2}>
                      <Text style={tableStyles.tableCell}>{valorDolar}</Text>
                    </View>
                    <View style={tableStyles.tableColOthertaxi2}>
                      <Text style={tableStyles.tableCell}>{fechayhora}</Text>
                    </View>
                    <View style={tableStyles.tableColOthertaxi2}>
                      <Text style={tableStyles.tableCell}>
                        {formData.placa}
                      </Text>
                    </View>
                  </View>
                </View>
                <View style={tableStyles.table2}>
                  <View style={tableStyles.tableRow}>
                    <View style={tableStyles.tableColHeaderOthertaxi2}>
                      <Text style={tableStyles.tableCellHeader}>
                        FECHA DEL VIAJE
                      </Text>
                    </View>
                    <View style={tableStyles.tableColHeaderOthertaxi2}>
                      <Text style={tableStyles.tableCellHeader}>
                        FIRMA DEL CONDUCTOR
                      </Text>
                    </View>
                    <View style={tableStyles.tableColHeaderOthertaxi2}>
                      <Text style={tableStyles.tableCellHeader}>
                        INSPECTOR INTRAVIALCA
                      </Text>
                    </View>
                  </View>
                  {/* Otra parte */}
                  <View style={tableStyles.tableRow}>
                    <View style={tableStyles.tableColOthertaxi2}>
                      <Text style={tableStyles.tableCell}>.</Text>
                    </View>
                    <View style={tableStyles.tableColOthertaxi2}>
                      <Text style={tableStyles.tableCell}></Text>
                    </View>
                    <View style={tableStyles.tableColOthertaxi2}>
                      <Text style={tableStyles.tableCell}></Text>
                    </View>
                  </View>
                </View>
                <View style={tableStyles.table2}>
                  <View style={tableStyles.tableRow}>
                    <View style={tableStyles.tableColHeaderOthertaxi3}>
                      <Text style={tableStyles.tableCellHeader}>
                        Nota: Los tickets vencen a los 5 días de emitidos.
                      </Text>
                    </View>
                  </View>
                </View>
              </View>
              <View style={{ width: "49%" }}>
                <View style={styles.header}>
                  <Image
                    src="https://res.cloudinary.com/dirpdlfbu/image/upload/v1701880341/logo/cmyemflfryypewryq6ux.jpg"
                    style={styles.logo}
                  />
                  <Image
                    src="https://res.cloudinary.com/dirpdlfbu/image/upload/v1711324140/aeezebl5y4pzhcmgm2da.jpg"
                    style={styles.logo2}
                  />
                </View>
                <View style={{ padding: "10px 0px" }}>
                  <Text style={{ textAlign: "center" }}>TICKET TAXI</Text>
                  <Text style={{ textAlign: "center" }}>
                    N° Ticket {n_ticket_2}
                  </Text>
                </View>
                <View style={tableStyles.table}>
                  <View style={tableStyles.tableRow}>
                    <View style={tableStyles.tableColHeaderOthertaxi}>
                      <Text style={tableStyles.tableCellHeader}>
                        CONDUCTOR:
                      </Text>
                    </View>
                    <View style={tableStyles.tableColHeaderOthertaxi}>
                      <Text style={tableStyles.tableCellHeader}>C.I:</Text>
                    </View>
                  </View>
                  {/* Otra parte */}
                  <View style={tableStyles.tableRow}>
                    <View style={tableStyles.tableColOthertaxi}>
                      <Text style={tableStyles.tableCell}>
                        {formData.nombre_apellido}
                      </Text>
                    </View>
                    <View style={tableStyles.tableColOthertaxi}>
                      <Text style={tableStyles.tableCell}>
                        {formData.cedula}
                      </Text>
                    </View>
                  </View>
                </View>
                <View style={tableStyles.table2}>
                  <View style={tableStyles.tableRow}>
                    <View style={tableStyles.tableColHeaderOthertaxi2}>
                      <Text style={tableStyles.tableCellHeader}>
                        VALOR DE LA TASA
                      </Text>
                    </View>
                    <View style={tableStyles.tableColHeaderOthertaxi2}>
                      <Text style={tableStyles.tableCellHeader}>
                        FECHA DE EMISIÓN
                      </Text>
                    </View>
                    <View style={tableStyles.tableColHeaderOthertaxi2}>
                      <Text style={tableStyles.tableCellHeader}>
                        PLACA DE VEHÍCULO
                      </Text>
                    </View>
                  </View>
                  {/* Otra parte */}
                  <View style={tableStyles.tableRow}>
                    <View style={tableStyles.tableColOthertaxi2}>
                      <Text style={tableStyles.tableCell}>{valorDolar}</Text>
                    </View>
                    <View style={tableStyles.tableColOthertaxi2}>
                      <Text style={tableStyles.tableCell}>{fechayhora}</Text>
                    </View>
                    <View style={tableStyles.tableColOthertaxi2}>
                      <Text style={tableStyles.tableCell}>
                        {formData.placa}
                      </Text>
                    </View>
                  </View>
                </View>
                <View style={tableStyles.table2}>
                  <View style={tableStyles.tableRow}>
                    <View style={tableStyles.tableColHeaderOthertaxi2}>
                      <Text style={tableStyles.tableCellHeader}>
                        FECHA DEL VIAJE
                      </Text>
                    </View>
                    <View style={tableStyles.tableColHeaderOthertaxi2}>
                      <Text style={tableStyles.tableCellHeader}>
                        FIRMA DEL CONDUCTOR
                      </Text>
                    </View>
                    <View style={tableStyles.tableColHeaderOthertaxi2}>
                      <Text style={tableStyles.tableCellHeader}>
                        INSPECTOR INTRAVIALCA
                      </Text>
                    </View>
                  </View>
                  {/* Otra parte */}
                  <View style={tableStyles.tableRow}>
                    <View style={tableStyles.tableColOthertaxi2}>
                      <Text style={tableStyles.tableCell}>.</Text>
                    </View>
                    <View style={tableStyles.tableColOthertaxi2}>
                      <Text style={tableStyles.tableCell}></Text>
                    </View>
                    <View style={tableStyles.tableColOthertaxi2}>
                      <Text style={tableStyles.tableCell}></Text>
                    </View>
                  </View>
                </View>
                <View style={tableStyles.table2}>
                  <View style={tableStyles.tableRow}>
                    <View style={tableStyles.tableColHeaderOthertaxi3}>
                      <Text style={tableStyles.tableCellHeader}>
                        Nota: Los tickets vencen a los 5 días de emitidos.
                      </Text>
                    </View>
                  </View>
                </View>
              </View>
            </View>
            <View
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                marginTop: 30,
              }}
            >
              <View style={{ width: "49%" }}>
                <View style={styles.header}>
                  <Image
                    src="https://res.cloudinary.com/dirpdlfbu/image/upload/v1701880341/logo/cmyemflfryypewryq6ux.jpg"
                    style={styles.logo}
                  />
                  <Image
                    src="https://res.cloudinary.com/dirpdlfbu/image/upload/v1711324140/aeezebl5y4pzhcmgm2da.jpg"
                    style={styles.logo2}
                  />
                </View>
                <View style={{ padding: "10px 0px" }}>
                  <Text style={{ textAlign: "center" }}>TICKET TAXI</Text>
                  <Text style={{ textAlign: "center" }}>
                    N° Ticket {n_ticket_3}
                  </Text>
                </View>
                <View style={tableStyles.table}>
                  <View style={tableStyles.tableRow}>
                    <View style={tableStyles.tableColHeaderOthertaxi}>
                      <Text style={tableStyles.tableCellHeader}>
                        CONDUCTOR:
                      </Text>
                    </View>
                    <View style={tableStyles.tableColHeaderOthertaxi}>
                      <Text style={tableStyles.tableCellHeader}>C.I:</Text>
                    </View>
                  </View>
                  {/* Otra parte */}
                  <View style={tableStyles.tableRow}>
                    <View style={tableStyles.tableColOthertaxi}>
                      <Text style={tableStyles.tableCell}>
                        {formData.nombre_apellido}
                      </Text>
                    </View>
                    <View style={tableStyles.tableColOthertaxi}>
                      <Text style={tableStyles.tableCell}>
                        {formData.cedula}
                      </Text>
                    </View>
                  </View>
                </View>
                <View style={tableStyles.table2}>
                  <View style={tableStyles.tableRow}>
                    <View style={tableStyles.tableColHeaderOthertaxi2}>
                      <Text style={tableStyles.tableCellHeader}>
                        VALOR DE LA TASA
                      </Text>
                    </View>
                    <View style={tableStyles.tableColHeaderOthertaxi2}>
                      <Text style={tableStyles.tableCellHeader}>
                        FECHA DE EMISIÓN
                      </Text>
                    </View>
                    <View style={tableStyles.tableColHeaderOthertaxi2}>
                      <Text style={tableStyles.tableCellHeader}>
                        PLACA DE VEHÍCULO
                      </Text>
                    </View>
                  </View>
                  {/* Otra parte */}
                  <View style={tableStyles.tableRow}>
                    <View style={tableStyles.tableColOthertaxi2}>
                      <Text style={tableStyles.tableCell}>{valorDolar}</Text>
                    </View>
                    <View style={tableStyles.tableColOthertaxi2}>
                      <Text style={tableStyles.tableCell}>{fechayhora}</Text>
                    </View>
                    <View style={tableStyles.tableColOthertaxi2}>
                      <Text style={tableStyles.tableCell}>
                        {formData.placa}
                      </Text>
                    </View>
                  </View>
                </View>
                <View style={tableStyles.table2}>
                  <View style={tableStyles.tableRow}>
                    <View style={tableStyles.tableColHeaderOthertaxi2}>
                      <Text style={tableStyles.tableCellHeader}>
                        FECHA DEL VIAJE
                      </Text>
                    </View>
                    <View style={tableStyles.tableColHeaderOthertaxi2}>
                      <Text style={tableStyles.tableCellHeader}>
                        FIRMA DEL CONDUCTOR
                      </Text>
                    </View>
                    <View style={tableStyles.tableColHeaderOthertaxi2}>
                      <Text style={tableStyles.tableCellHeader}>
                        INSPECTOR INTRAVIALCA
                      </Text>
                    </View>
                  </View>
                  {/* Otra parte */}
                  <View style={tableStyles.tableRow}>
                    <View style={tableStyles.tableColOthertaxi2}>
                      <Text style={tableStyles.tableCell}>.</Text>
                    </View>
                    <View style={tableStyles.tableColOthertaxi2}>
                      <Text style={tableStyles.tableCell}></Text>
                    </View>
                    <View style={tableStyles.tableColOthertaxi2}>
                      <Text style={tableStyles.tableCell}></Text>
                    </View>
                  </View>
                </View>
                <View style={tableStyles.table2}>
                  <View style={tableStyles.tableRow}>
                    <View style={tableStyles.tableColHeaderOthertaxi3}>
                      <Text style={tableStyles.tableCellHeader}>
                        Nota: Los tickets vencen a los 5 días de emitidos.
                      </Text>
                    </View>
                  </View>
                </View>
              </View>
              <View style={{ width: "49%" }}>
                <View style={styles.header}>
                  <Image
                    src="https://res.cloudinary.com/dirpdlfbu/image/upload/v1701880341/logo/cmyemflfryypewryq6ux.jpg"
                    style={styles.logo}
                  />
                  <Image
                    src="https://res.cloudinary.com/dirpdlfbu/image/upload/v1711324140/aeezebl5y4pzhcmgm2da.jpg"
                    style={styles.logo2}
                  />
                </View>
                <View style={{ padding: "10px 0px" }}>
                  <Text style={{ textAlign: "center" }}>TICKET TAXI</Text>
                  <Text style={{ textAlign: "center" }}>
                    N° Ticket {n_ticket_4}
                  </Text>
                </View>
                <View style={tableStyles.table}>
                  <View style={tableStyles.tableRow}>
                    <View style={tableStyles.tableColHeaderOthertaxi}>
                      <Text style={tableStyles.tableCellHeader}>
                        CONDUCTOR:
                      </Text>
                    </View>
                    <View style={tableStyles.tableColHeaderOthertaxi}>
                      <Text style={tableStyles.tableCellHeader}>C.I:</Text>
                    </View>
                  </View>
                  {/* Otra parte */}
                  <View style={tableStyles.tableRow}>
                    <View style={tableStyles.tableColOthertaxi}>
                      <Text style={tableStyles.tableCell}>
                        {formData.nombre_apellido}
                      </Text>
                    </View>
                    <View style={tableStyles.tableColOthertaxi}>
                      <Text style={tableStyles.tableCell}>
                        {formData.cedula}
                      </Text>
                    </View>
                  </View>
                </View>
                <View style={tableStyles.table2}>
                  <View style={tableStyles.tableRow}>
                    <View style={tableStyles.tableColHeaderOthertaxi2}>
                      <Text style={tableStyles.tableCellHeader}>
                        VALOR DE LA TASA
                      </Text>
                    </View>
                    <View style={tableStyles.tableColHeaderOthertaxi2}>
                      <Text style={tableStyles.tableCellHeader}>
                        FECHA DE EMISIÓN
                      </Text>
                    </View>
                    <View style={tableStyles.tableColHeaderOthertaxi2}>
                      <Text style={tableStyles.tableCellHeader}>
                        PLACA DE VEHÍCULO
                      </Text>
                    </View>
                  </View>
                  {/* Otra parte */}
                  <View style={tableStyles.tableRow}>
                    <View style={tableStyles.tableColOthertaxi2}>
                      <Text style={tableStyles.tableCell}>{valorDolar}</Text>
                    </View>
                    <View style={tableStyles.tableColOthertaxi2}>
                      <Text style={tableStyles.tableCell}>{fechayhora}</Text>
                    </View>
                    <View style={tableStyles.tableColOthertaxi2}>
                      <Text style={tableStyles.tableCell}>
                        {formData.placa}
                      </Text>
                    </View>
                  </View>
                </View>
                <View style={tableStyles.table2}>
                  <View style={tableStyles.tableRow}>
                    <View style={tableStyles.tableColHeaderOthertaxi2}>
                      <Text style={tableStyles.tableCellHeader}>
                        FECHA DEL VIAJE
                      </Text>
                    </View>
                    <View style={tableStyles.tableColHeaderOthertaxi2}>
                      <Text style={tableStyles.tableCellHeader}>
                        FIRMA DEL CONDUCTOR
                      </Text>
                    </View>
                    <View style={tableStyles.tableColHeaderOthertaxi2}>
                      <Text style={tableStyles.tableCellHeader}>
                        INSPECTOR INTRAVIALCA
                      </Text>
                    </View>
                  </View>
                  {/* Otra parte */}
                  <View style={tableStyles.tableRow}>
                    <View style={tableStyles.tableColOthertaxi2}>
                      <Text style={tableStyles.tableCell}>.</Text>
                    </View>
                    <View style={tableStyles.tableColOthertaxi2}>
                      <Text style={tableStyles.tableCell}></Text>
                    </View>
                    <View style={tableStyles.tableColOthertaxi2}>
                      <Text style={tableStyles.tableCell}></Text>
                    </View>
                  </View>
                </View>
                <View style={tableStyles.table2}>
                  <View style={tableStyles.tableRow}>
                    <View style={tableStyles.tableColHeaderOthertaxi3}>
                      <Text style={tableStyles.tableCellHeader}>
                        Nota: Los tickets vencen a los 5 días de emitidos.
                      </Text>
                    </View>
                  </View>
                </View>
              </View>
            </View>
            {/* Más elementos del PDF */}
          </Page>
        </Document>
      );

      const blob = await pdf(pdfContent).toBlob();

      const pdfFormData = new FormData();
      pdfFormData.append("file", blob, "ticket.pdf");

      const uploadResponse = await axios.post(
        `https://api.cloudinary.com/v1_1/${process.env.CLOUDINARY_CLOUD_NAME}/raw/upload`,
        pdfFormData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          params: {
            upload_preset: process.env.CLOUDINARY_UPLOAD_PRESET,
            folder: `TICKETS/CSS-TLB_taxi`,
          },
        }
      );

      const linkTicket = uploadResponse.data.secure_url;

      await axios.post(
        process.env.BACKEND_URL + "/api/updateTicketTaxi",
        {
          ticket_id,
          link_ticket: linkTicket,
        },
        {
          headers: {
            Authorization: `Bearer ${store.token}`,
            "Content-Type": "application/json",
          },
        }
      );

      setFormData({
        company_name: "",
        nombre_apellido: "",
        cedula: "",
        placa: "",
        precio_bs: "",
        precio_dolar: 4,
        formas_de_pago: [
          { forma_pago: "", total_a_cancelar: "", referencia: "" },
        ],
      });

      MySwal.close();

      MySwal.fire({
        title: "Registro de Ticket Exitoso",
        text: "El ticket ha sido registrado exitosamente.",
        icon: "success",
        confirmButtonText: "Cerrar",
      });

      window.open(linkTicket, "Ticket");
    } catch (error) {
      console.error("Error al registrar el ticket", error);
      MySwal.fire({
        title: "Error",
        html: `<p>Error al registrar el ticket. Por favor, vuelve a intentarlo.</p>`,
        icon: "error",
        confirmButtonText: "Cerrar",
      });
    } finally {
      setSaving(false);
    }
  };

  const fetchTodayTickets = async () => {
    MySwal.fire({
      title: "Cargando...",
      html: `<p style="text-align:center; margin:20px;">Cargando tickets de hoy...</p>`,
      allowOutsideClick: false,
      didOpen: () => {
        MySwal.showLoading();
      },
    });

    try {
      const response = await axios.get(
        process.env.BACKEND_URL + "/api/getTodayTicketsTaxi",
        {
          headers: {
            Authorization: `Bearer ${store.token}`,
          },
        }
      );

      const data = response.data;

      MySwal.close();

      if (!data || data.length === 0) {
        MySwal.fire({
          title: "Tickets de Hoy",
          html: `<p style="text-align:center; margin:20px;">No se encontraron tickets.</p>`,
          confirmButtonText: "Cerrar",
        });
      } else {
        const tableHeaders = `
          <tr>
            <th>Número Ticket</th>
            <th>Fecha</th>
            <th>Empresa</th>
            <th>Nombre y Apellido</th>
            <th>Cédula</th>
            <th>Placa</th>
            <th>Precio Bs</th>
            <th>Precio $</th>
            <th>Registrado por</th>
            <th>Acciones</th>
          </tr>
        `;

        const tableRows = data
          .map(
            (item) => `
              <tr style="background-color: ${item.estatus === "ACTIVO" ? "#d4edda" : "#f8d7da"};">
                <td>${item.n_ticket_1}</td>
                <td>${item.fecha_hora_registro}</td>
                <td>${item.company_name}</td>
                <td>${item.nombre_apellido}</td>
                <td>${item.cedula}</td>
                <td>${item.placa}</td>
                <td>${item.precio_bs}</td>
                <td>${item.precio_dolar}</td>
                <td>${item.registrado_por}</td>
                <td>
                  <a href="${item.link_ticket}" target="_blank" class="btn btn-primary btn-sm">Ver PDF</a>
                  <button class="btn btn-secondary btn-sm" data-id="${item.id}">
                    ${item.estatus === "ACTIVO" ? "Anular" : "Activar"}
                  </button>
                </td>
              </tr>
            `
          )
          .join("");

        const table = `
          <input type="text" id="search-tickets" placeholder="Buscar..." class="swal2-input" style="margin-bottom: 10px;">
          <div style="max-height: 400px; overflow-y: auto;">
            <table style="width: 2000px; border-collapse: collapse; font-size: 12px;">
              <thead>
                ${tableHeaders}
              </thead>
              <tbody id="tickets-list">
                ${tableRows}
              </tbody>
            </table>
          </div>
        `;

        MySwal.fire({
          title: "Tickets de Hoy",
          html: table,
          showConfirmButton: true,
          confirmButtonText: "Cerrar",
          didRender: () => {
            const searchInput = document.getElementById("search-tickets");
            searchInput.addEventListener("input", (event) => {
              const searchTerm = event.target.value.toLowerCase();
              const ticketsList = document.getElementById("tickets-list");
              const filteredRows = data
                .filter(
                  (item) =>
                    item.n_ticket_1.toLowerCase().includes(searchTerm) ||
                    item.fecha_hora_registro
                      .toLowerCase()
                      .includes(searchTerm) ||
                    item.company_name.toLowerCase().includes(searchTerm) ||
                    item.nombre_apellido.toLowerCase().includes(searchTerm) ||
                    item.cedula.toLowerCase().includes(searchTerm) ||
                    item.placa.toLowerCase().includes(searchTerm)
                )
                .map(
                  (item) => `
                    <tr style="background-color: ${item.estatus === "ACTIVO" ? "#d4edda" : "#f8d7da"};">
                      <td>${item.n_ticket_1}</td>
                      <td>${item.fecha_hora_registro}</td>
                      <td>${item.company_name}</td>
                      <td>${item.nombre_apellido}</td>
                      <td>${item.cedula}</td>
                      <td>${item.placa}</td>
                      <td>${item.precio_bs}</td>
                      <td>${item.precio_dolar}</td>
                      <td>${item.registrado_por}</td>
                      <td>
                        <a href="${item.link_ticket}" target="_blank" class="btn btn-primary btn-sm">Ver PDF</a>
                        <button class="btn btn-secondary btn-sm" data-id="${item.id}">
                          ${item.estatus === "ACTIVO" ? "Anular" : "Activar"}
                        </button>
                      </td>
                    </tr>
                  `
                )
                .join("");
              ticketsList.innerHTML = filteredRows;
            });

            document.querySelectorAll(".btn-secondary").forEach((button) => {
              button.addEventListener("click", async (event) => {
                const ticketId = event.currentTarget.getAttribute("data-id");
                const newStatus =
                  event.currentTarget.innerText === "Anular"
                    ? "ANULADO"
                    : "ACTIVO";

                try {
                  await axios.post(
                    `${process.env.BACKEND_URL}/api/updateTicketStatus`,
                    { id: ticketId, estatus: newStatus },
                    {
                      headers: {
                        Authorization: `Bearer ${store.token}`,
                        "Content-Type": "application/json",
                      },
                    }
                  );

                  MySwal.fire({
                    title: `Ticket ${newStatus}`,
                    text: `El ticket ha sido ${newStatus.toLowerCase()} exitosamente.`,
                    icon: "success",
                    confirmButtonText: "Cerrar",
                  });

                  fetchTodayTickets(); // Recargar los tickets después de actualizar el estatus
                } catch (error) {
                  MySwal.fire({
                    title: "Error",
                    text: "Hubo un error al actualizar el estatus del ticket.",
                    icon: "error",
                    confirmButtonText: "Cerrar",
                  });
                }
              });
            });
          },
        });
      }
    } catch (error) {
      console.error("Error al obtener tickets de hoy", error);
      MySwal.fire({
        title: "Error",
        html: `<p>Error al obtener tickets de hoy. Por favor, inténtelo de nuevo más tarde.</p>`,
        icon: "error",
        confirmButtonText: "Cerrar",
      });
    }
  };

  return (
    <div
      className="container-fluid contarinerGeneralUserHomejs"
      style={{ paddingTop: "30px" }}
    >
      <div className="container-fluid contaosijdasd-qw89d77asd2"></div>
      <h2
        className="asjdansdas-d4as6489text w-100"
        style={{ zIndex: "15", marginTop: "-17vh" }}
      >
        Registro de Ticket de Taxi
      </h2>
      <div className="container asklnduiashndaijsnxansxsaas">
        <form className="formularioDeLogin w-100" onSubmit={handleSubmit}>
          <div className="aoshduiahsdkasnmdasdas">
            <div className="input-group-login2">
              <input
                className="inputDeLogin"
                type="text"
                name="company_name"
                value={formData.company_name}
                onClick={fetchCompanies}
                readOnly
                required
              />
              <label>Empresa</label>
            </div>
            <div className="input-group-login2">
              <input
                className="inputDeLogin"
                type="text"
                name="nombre_apellido"
                value={formData.nombre_apellido}
                onClick={selectNombreApellidoDialog}
                readOnly
                required
              />
              <label>Nombre y Apellido</label>
            </div>
            <div className="input-group-login2">
              <input
                className="inputDeLogin"
                type="text"
                name="cedula"
                value={formData.cedula}
                readOnly
                required
              />
              <label>Cédula</label>
            </div>
            <div className="input-group-login2">
              <input
                className="inputDeLogin"
                type="text"
                name="placa"
                value={formData.placa}
                onClick={selectPlacaDialog}
                readOnly
                required
              />
              <label>Placa</label>
            </div>
          </div>
          <div className="aoshduiahsdkasnmdasdas">
            <div className="input-group-login2">
              <input
                className="inputDeLogin"
                type="text"
                name="precio_bs"
                value={formData.precio_bs}
                readOnly
              />
              <label>Precio Bs</label>
            </div>
            <div className="input-group-login2">
              <input
                className="inputDeLogin"
                type="text"
                name="precio_dolar"
                value={formData.precio_dolar}
                readOnly
                required
              />
              <label>Precio $</label>
            </div>
          </div>
          {formData.formas_de_pago.map((pago, index) => (
            <div key={index} style={{ width: "100%" }}>
              <div
                className="aoshduiahsdkasnmdasdas223"
                style={{ padding: "10px", alignItems: "center" }}
              >
                <div>{index + 1}</div>
                <div className="input-group-login2" style={{ margin: 0 }}>
                  <select
                    name="forma_pago"
                    value={pago.forma_pago}
                    className="inputDeLogin"
                    onChange={(e) => handlePaymentChange(index, e)}
                    required
                  >
                    <option value="" disabled>
                      Forma de pago
                    </option>
                    <option value="efectivo">Efectivo</option>
                    <option value="punto_de_venta">Punto de Venta</option>
                    <option value="me">ME</option>
                  </select>
                </div>
                <div className="input-group-login2" style={{ margin: 0 }}>
                  <input
                    name="total_a_cancelar"
                    type="number"
                    className="inputDeLogin"
                    value={pago.total_a_cancelar}
                    onChange={(e) => handlePaymentChange(index, e)}
                    placeholder="Total a Cancelar"
                    required
                  />
                </div>
                <div className="input-group-login2" style={{ margin: 0 }}>
                  <input
                    name="referencia"
                    className="inputDeLogin"
                    value={pago.referencia}
                    onChange={(e) => handlePaymentChange(index, e)}
                    placeholder="Referencia (opcional)"
                  />
                </div>
                <button
                  type="button"
                  onClick={() =>
                    setFormData((prevData) => ({
                      ...prevData,
                      formas_de_pago: prevData.formas_de_pago.filter(
                        (_, i) => i !== index
                      ),
                    }))
                  }
                  className="btn sdf54er9fe89rf7wq9e8f4er98w7f98qew4dq9w7ycicontrash"
                  style={{ marginLeft: "10px" }}
                >
                  <i className="fa-solid fa-trash-can"></i>
                </button>
              </div>
            </div>
          ))}
          <button
            className="btn as56d4d9d8wef7we89f49t8j76uytbtntbtntb"
            type="button"
            onClick={() =>
              setFormData({
                ...formData,
                formas_de_pago: [
                  ...formData.formas_de_pago,
                  { forma_pago: "", total_a_cancelar: "", referencia: "" },
                ],
              })
            }
          >
            Agregar Forma de Pago
          </button>
          <div className="ipy4gvwe98f7we98f4ert78ghr8eh4we98f7w98r4et9r8">
            <h5>
              Total a pagar: Bs{" "}
              {formData.formas_de_pago
                .reduce(
                  (acc, pago) => acc + parseFloat(pago.total_a_cancelar || 0),
                  0
                )
                .toFixed(2)}
            </h5>
            <h5>
              Faltante: Bs{" "}
              {(
                parseFloat(formData.precio_bs) -
                formData.formas_de_pago.reduce(
                  (acc, pago) => acc + parseFloat(pago.total_a_cancelar || 0),
                  0
                )
              ).toFixed(2)}
            </h5>
          </div>
          <div className="w-100 asd9as-da9s84dx9a8qdw-qdw asdqw98d4qv895y4n9r84bvr98ebtnbtn">
            <button
              type="submit"
              className="btn buttonCargadeDatosDeAddDriver"
              style={{ marginBottom: "30px" }}
              disabled={saving}
            >
              <div className="d-flex">
                <p style={{ marginBottom: 0 }}>Guardar Datos</p>
                <i className="fa-solid fa-arrow-right flechaHomePresentation2"></i>
              </div>
            </button>
          </div>
        </form>
      </div>
      <div className="w-100 asd9as-da9s84dx9a8qdw-qdw asdqw98d4qv895y4n9r84bvr98ebtnbtn poja65s4d8a9s7d89errg98er7fqwd9849iu9ykj7asj">
        <button
          className="btn asd98q7wd65q1wd8xqw49x8e4wc"
          onClick={fetchTodayTickets}
        >
          <i
            className="fa-solid fa-taxi"
            style={{ marginRight: "7px", fontSize: "20px" }}
          ></i>
          Ver Registro de Tickets
        </button>
      </div>
    </div>
  );
};
