import React, { useState, useEffect, useContext } from "react";
import { Context } from "../../store/appContext";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import ScrollToTop from "../../component/scrollToTop";
import { BackendURL } from "../../component/backendURL";
import { Redirect } from "react-router-dom";
import "../../../styles/AddDriverForm.css";

import LoadingOverlay from "../../pages/LadingOverlay";

import { Front2 } from "../../pages/Front2";
import { Navbar } from "./Navbar";
import { Navbar_sup } from "./Navbar_sup";
import { UserHome } from "./userHome";
import { RegistroCps } from "./DIR_TERMINAL/RegistroCps";
import { EmpresasDeTransporte } from "./DIR_TERMINAL/EmpresasDeTransporte";
import { RegistroUsuarios } from "./DIR_TERMINAL/RegistroUsuarios";
import { Destinos } from "./DIR_TERMINAL/Destinos";
import { Activacion_pista } from "./DIR_TERMINAL/Activacion_pista";
import { ActivacionesCalendar } from "./DIR_TERMINAL/ActivacionesCalendar";
import { PreciosDestinosFinalRC } from "./DIR_TERMINAL/PreciosDestinosFinalRC";
import { PreciosDestinosFinalRL } from "./DIR_TERMINAL/PreciosDestinosFinalRL";
import { KmDistancia } from "./DIR_TERMINAL/KmDistancia";
import { Valordolardiario } from "./DIR_TERMINAL/valordolardiario";
import { AutorizacionPorPuesto } from "./DIR_TERMINAL/AutorizacionPorPuesto";

export const Layout_dir_terminal = () => {
  const { store } = useContext(Context);
  const [isLoading, setIsLoading] = useState(false);

  const toggleLoading = (loading) => {
    setIsLoading(loading);
  };

  const handleRouteChange = (event) => {
    localStorage.setItem("lastVisitedRoute", event.url);
  };

  const basename = process.env.BASENAME || "";

  if (!process.env.BACKEND_URL || process.env.BACKEND_URL === "")
    return <BackendURL />;

  return (
    <div style={{ display: "flex" }}>
      <BrowserRouter basename={basename}>
        <ScrollToTop>
          <Navbar />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              alignItems: "center",
            }}
          >
            <Navbar_sup />
            <Routes onChange={handleRouteChange}>
              <Route element={<UserHome />} path="/" />
              <Route element={<RegistroCps />} path="/RegistroCps" />
              <Route element={<Destinos />} path="/Destinos" />
              <Route
                element={<ActivacionesCalendar />}
                path="/ActivacionCalendario"
              />
              <Route
                element={<EmpresasDeTransporte />}
                path="/EmpresasDeTransporte"
              />
              <Route element={<Activacion_pista />} path="/Programacion" />
              <Route element={<RegistroUsuarios />} path="/RegistroUsuarios" />
              <Route
                element={<PreciosDestinosFinalRC />}
                path="/PrecioDestinoFinalRC"
              />
              <Route
                element={<PreciosDestinosFinalRL />}
                path="/PrecioDestinoFinalRL"
              />
              <Route element={<KmDistancia />} path="/KmDistancia" />
              <Route element={<Valordolardiario />} path="/Valordolardiario" />
              <Route
                element={<AutorizacionPorPuesto />}
                path="/Autorizacionporpuesto"
              />
              <Route element={<Front2 />} path="/Configuraciones" />
              <Route path="*" element={<Navigate to="/" />} />
            </Routes>
          </div>
        </ScrollToTop>
      </BrowserRouter>
      {isLoading && <LoadingOverlay />}
    </div>
  );
};
