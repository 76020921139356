import React, { useContext, useState, useEffect } from "react";
import axios from "axios";
import { Context } from "../../../store/appContext";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import {
  Document,
  Page,
  Text,
  View,
  Image,
  StyleSheet,
  pdf,
} from "@react-pdf/renderer";

const MySwal = withReactContent(Swal);

const styles = StyleSheet.create({
  page: { padding: 20 },
  header: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  logo: { width: 90, height: 30 },
  logo2: { width: 100, height: 20 },
  letter: { fontSize: 9, padding: 3 },
  letter2: { fontSize: 10 },
  letter3: { fontSize: 8.5, padding: 2 },
  table: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  tableRow: { margin: "auto", flexDirection: "row" },
  tableColHeaderNumber: {
    width: "5%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    backgroundColor: "#f2f2f2",
    alignItems: "center",
    justifyContent: "center",
  },
  tableColHeaderOther: {
    width: "22.5%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    backgroundColor: "#f2f2f2",
    alignItems: "center",
    justifyContent: "center",
  },
  tableColHeaderOther2: {
    width: "11.25%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    backgroundColor: "#f2f2f2",
    alignItems: "center",
    justifyContent: "center",
  },
  tableColNumber: {
    width: "5%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    alignItems: "center",
    justifyContent: "center",
  },
  tableColOther: {
    width: "22.5%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    alignItems: "center",
    justifyContent: "center",
  },
  tableColOther2: {
    width: "11.25%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    alignItems: "center",
    justifyContent: "center",
  },
  tableCellHeader: { margin: 5, fontSize: 9, fontWeight: "bold" },
  tableCell: { margin: 4, fontSize: 9 },
  bottomText: {
    position: "absolute",
    bottom: 30,
    left: 20,
    right: 20,
    textAlign: "center",
    fontSize: 8,
  },
  bottomText2: {
    position: "absolute",
    bottom: 50,
    left: 20,
    right: 20,
    textAlign: "center",
    fontSize: 11,
    fontWeight: "bold",
  },
});

export const Elab_listin_rl = () => {
  const { store } = useContext(Context);
  const [formData, setFormData] = useState({
    numero_listin: "",
    fecha: "",
    tipo_servicio: "",
    unidad: "",
    placa: "",
    numero_puestos: "",
    destino: "",
    anden: "",
    hora_salida: "",
    ruta: "",
    empresa: "",
    rif: "",
    conductor_1: "",
    cedula_1: "",
    grado_licencia_1: "",
    fecha_vencimiento_licencia_1: "",
    conductor_2: "",
    cedula_2: "",
    grado_licencia_2: "",
    fecha_vencimiento_licencia_2: "",
  });
  const [activaciones, setActivaciones] = useState([]);
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    fetchActivaciones();
  }, []);

  const fetchActivaciones = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        process.env.BACKEND_URL + "/api/getTodayActivacionPistarl",
        {
          headers: {
            Authorization: `Bearer ${store.token}`,
          },
        }
      );
      const data = Array.isArray(response.data) ? response.data : [];
      setActivaciones(data);
    } catch (error) {
      console.error("Error al obtener las activaciones de hoy", error);
      setActivaciones([]);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const formatDateString = (dateString) => {
    const [day, month, year] = dateString.split("-");
    return `${year}-${month}-${day}`;
  };

  const handleActivacionChange = (selectedActivacion) => {
    if (selectedActivacion) {
      setFormData({
        activacion: selectedActivacion.id,
        numero_listin: "",
        fecha: formatDateString(selectedActivacion.fecha_viaje),
        tipo_servicio: "",
        unidad: selectedActivacion.numero_unidad,
        placa: selectedActivacion.placa,
        numero_puestos: "",
        destino: selectedActivacion.destino_final,
        anden: selectedActivacion.anden,
        hora_salida: selectedActivacion.hora_salida,
        ruta: selectedActivacion.ruta,
        empresa: selectedActivacion.empresa,
        rif: selectedActivacion.rif,
        conductor_1: selectedActivacion.conductor_1 || "AUN NO DISPONIBLE",
        cedula_1: selectedActivacion.cedula_1 || "AUN NO DISPONIBLE",
        grado_licencia_1: selectedActivacion.grado_licencia_1 || "",
        fecha_vencimiento_licencia_1:
          selectedActivacion.fecha_vencimiento_licencia_1 || "",
        conductor_2: selectedActivacion.conductor_2 || "AUN NO DISPONIBLE",
        cedula_2: selectedActivacion.cedula_2 || "AUN NO DISPONIBLE",
        grado_licencia_2: selectedActivacion.grado_licencia_2 || "",
        fecha_vencimiento_licencia_2:
          selectedActivacion.fecha_vencimiento_licencia_2 || "",
      });
    }
  };

  const showActivacionesModal = async () => {
    MySwal.fire({
      title: "Cargando...",
      html: `<p style="text-align:center; margin:20px;">Cargando activaciones de hoy...</p>`,
      allowOutsideClick: false,
      didOpen: () => {
        MySwal.showLoading();
      },
    });

    await fetchActivaciones();

    MySwal.close();

    const content = activaciones
      .map(
        (item) => `
          <div style="margin: 10px; padding: 10px; border: 1px solid #ccc;">
            <p><strong>Empresa:</strong> ${item.empresa}</p>
            <p><strong>Destino Final:</strong> ${item.destino_final}</p>
            <p><strong>Hora de Salida:</strong> ${item.hora_salida}</p>
            <button class="btn btn-info" data-id='${JSON.stringify(item)}'>Seleccionar</button>
          </div>
        `
      )
      .join("");

    MySwal.fire({
      title: "Programación para Hoy",
      html: `
        <input type="text" id="search-activacion" placeholder="Buscar..." class="swal2-input">
        <div id="activaciones-list" style="max-height: 300px; overflow-y: auto;">${content}</div>
      `,
      showConfirmButton: true,
      confirmButtonText: "Cerrar",
      didOpen: () => {
        const searchInput = document.getElementById("search-activacion");
        searchInput.addEventListener("input", (event) => {
          const searchTerm = event.target.value.toLowerCase();
          const activacionesDiv = document.getElementById("activaciones-list");
          activacionesDiv.innerHTML = activaciones
            .filter(
              (item) =>
                item.empresa.toLowerCase().includes(searchTerm) ||
                item.destino_final.toLowerCase().includes(searchTerm) ||
                item.hora_salida.includes(searchTerm)
            )
            .map(
              (item) => `
                <div style="margin: 10px; padding: 10px; border: 1px solid #ccc;">
                  <p><strong>Empresa:</strong> ${item.empresa}</p>
                  <p><strong>Destino Final:</strong> ${item.destino_final}</p>
                  <p><strong>Hora de Salida:</strong> ${item.hora_salida}</p>
                  <button class="btn btn-info" data-id='${JSON.stringify(item)}'>Seleccionar</button>
                </div>
              `
            )
            .join("");
        });

        document.querySelectorAll(".btn-info").forEach((button) => {
          button.addEventListener("click", (event) => {
            const activacion = JSON.parse(
              event.currentTarget.getAttribute("data-id")
            );
            handleActivacionChange(activacion);
            MySwal.close();
          });
        });
      },
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSaving(true);
    MySwal.fire({
      title: "Creando Listín",
      html: `<p style="text-align:center; margin:20px;">Por favor, espere...</p>`,
      allowOutsideClick: false,
      didOpen: () => {
        MySwal.showLoading();
      },
    });

    try {
      // Paso 1: Registrar los datos iniciales y obtener el número de listín
      const response = await axios.post(
        process.env.BACKEND_URL + "/api/registerListinRL",
        {
          ...formData,
        },
        {
          headers: {
            Authorization: `Bearer ${store.token}`,
            "Content-Type": "application/json",
          },
        }
      );

      const { numero_listin, listin_id } = response.data;

      // Paso 2: Generar el PDF utilizando el número de listín obtenido
      const pdfContent = (
        <Document>
          <Page style={styles.page} size="A4">
            <View style={styles.header}>
              <Image
                src="https://res.cloudinary.com/dirpdlfbu/image/upload/v1701880341/logo/cmyemflfryypewryq6ux.jpg"
                style={styles.logo}
              />
              <Image
                src="https://res.cloudinary.com/dirpdlfbu/image/upload/v1711324140/aeezebl5y4pzhcmgm2da.jpg"
                style={styles.logo2}
              />
            </View>
            <View style={styles.header}>
              <Text style={styles.letter2}>TERMINAL LA BANDERA</Text>
              <View>
                <Text
                  style={styles.letter2}
                >{`LISTIN Nº ${numero_listin}`}</Text>
                <Text style={styles.letter2}>Rutas Interurbanas</Text>
              </View>
            </View>
            <View
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
                borderWidth: 1,
                borderColor: "#000",
              }}
            >
              <View>
                <Text
                  style={styles.letter}
                >{`Línea: ${formData.empresa}`}</Text>
                <Text
                  style={styles.letter}
                >{`Destino: ${formData.destino}`}</Text>
                <Text
                  style={styles.letter}
                >{`Unidad: ${formData.unidad}`}</Text>
                <Text style={styles.letter}>{`Placa: ${formData.placa}`}</Text>
              </View>

              <View>
                <Text style={styles.letter}>{`Anden: ${formData.anden}`}</Text>
                <Text
                  style={styles.letter}
                >{`Servicio: ${formData.tipo_servicio}`}</Text>
                <Text
                  style={styles.letter}
                >{`Conductor 1: ${formData.conductor_1}`}</Text>
                <Text
                  style={styles.letter}
                >{`Conductor 2: ${formData.conductor_2}`}</Text>
              </View>
              <View>
                <Text style={styles.letter}>{`Fecha: ${formData.fecha}`}</Text>
                <Text
                  style={styles.letter}
                >{`Hora de salida: ${formData.hora_salida}`}</Text>
                <Text
                  style={styles.letter}
                >{`Cédula 1: ${formData.cedula_1}`}</Text>
                <Text
                  style={styles.letter}
                >{`Cédula 2: ${formData.cedula_2}`}</Text>
              </View>
            </View>
            <View
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
                borderWidth: 1,
                borderColor: "#000",
              }}
            >
              <Text style={styles.letter3}>{`Ruta: ${formData.ruta}`}</Text>
            </View>
            <View style={{ marginTop: 10 }}></View>
            <View style={styles.table}>
              <View style={styles.tableRow}>
                <View style={styles.tableColHeaderNumber}>
                  <Text style={styles.tableCellHeader}>N°</Text>
                </View>
                <View style={styles.tableColHeaderOther}>
                  <Text style={styles.tableCellHeader}>Nombre y Apellido</Text>
                </View>
                <View style={styles.tableColHeaderOther2}>
                  <Text style={styles.tableCellHeader}>Cédula</Text>
                </View>
                <View style={styles.tableColHeaderOther2}>
                  <Text style={styles.tableCellHeader}>Destino</Text>
                </View>
                <View style={styles.tableColHeaderNumber}>
                  <Text style={styles.tableCellHeader}>N°</Text>
                </View>
                <View style={styles.tableColHeaderOther}>
                  <Text style={styles.tableCellHeader}>Nombre y Apellido</Text>
                </View>
                <View style={styles.tableColHeaderOther2}>
                  <Text style={styles.tableCellHeader}>Cédula</Text>
                </View>
                <View style={styles.tableColHeaderOther2}>
                  <Text style={styles.tableCellHeader}>Destino</Text>
                </View>
              </View>
              {[...Array(30)].map((_, index) => (
                <View key={index} style={styles.tableRow}>
                  <View style={styles.tableColNumber}>
                    <Text style={styles.tableCell}>{index * 2 + 1}</Text>
                  </View>
                  <View style={styles.tableColOther}>
                    <Text style={styles.tableCell}></Text>
                  </View>
                  <View style={styles.tableColOther2}>
                    <Text style={styles.tableCell}></Text>
                  </View>
                  <View style={styles.tableColOther2}>
                    <Text style={styles.tableCell}></Text>
                  </View>
                  <View style={styles.tableColNumber}>
                    <Text style={styles.tableCell}>{index * 2 + 2}</Text>
                  </View>
                  <View style={styles.tableColOther}>
                    <Text style={styles.tableCell}></Text>
                  </View>
                  <View style={styles.tableColOther2}>
                    <Text style={styles.tableCell}></Text>
                  </View>
                  <View style={styles.tableColOther2}>
                    <Text style={styles.tableCell}></Text>
                  </View>
                </View>
              ))}
            </View>
            <Text style={styles.bottomText2}>ORIGINAL: INTRAVIALCA</Text>
            <Text style={styles.bottomText}>
              El valor del listín es el 3% de la tarifa pagada por pasajero, de
              acuerdo a la publicación del Ministerio del Poder Popular para el
              Transporte, en la Gaceta Oficial de la República Bolivariana de
              Venezuela N° 42.462 de fecha 14/09/2022
            </Text>
          </Page>
          <Page style={styles.page} size="A4">
            <View style={styles.header}>
              <Image
                src="https://res.cloudinary.com/dirpdlfbu/image/upload/v1701880341/logo/cmyemflfryypewryq6ux.jpg"
                style={styles.logo}
              />
              <Image
                src="https://res.cloudinary.com/dirpdlfbu/image/upload/v1711324140/aeezebl5y4pzhcmgm2da.jpg"
                style={styles.logo2}
              />
            </View>
            <View style={styles.header}>
              <Text style={styles.letter2}>TERMINAL LA BANDERA</Text>
              <View>
                <Text
                  style={styles.letter2}
                >{`LISTIN Nº ${numero_listin}`}</Text>
                <Text style={styles.letter2}>Rutas Interurbanas</Text>
              </View>
            </View>
            <View
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
                borderWidth: 1,
                borderColor: "#000",
              }}
            >
              <View>
                <Text
                  style={styles.letter}
                >{`Línea: ${formData.empresa}`}</Text>
                <Text
                  style={styles.letter}
                >{`Destino: ${formData.destino}`}</Text>
                <Text
                  style={styles.letter}
                >{`Unidad: ${formData.unidad}`}</Text>
                <Text style={styles.letter}>{`Placa: ${formData.placa}`}</Text>
              </View>

              <View>
                <Text style={styles.letter}>{`Anden: ${formData.anden}`}</Text>
                <Text
                  style={styles.letter}
                >{`Servicio: ${formData.tipo_servicio}`}</Text>
                <Text
                  style={styles.letter}
                >{`Conductor 1: ${formData.conductor_1}`}</Text>
                <Text
                  style={styles.letter}
                >{`Conductor 2: ${formData.conductor_2}`}</Text>
              </View>
              <View>
                <Text style={styles.letter}>{`Fecha: ${formData.fecha}`}</Text>
                <Text
                  style={styles.letter}
                >{`Hora de salida: ${formData.hora_salida}`}</Text>
                <Text
                  style={styles.letter}
                >{`Cédula 1: ${formData.cedula_1}`}</Text>
                <Text
                  style={styles.letter}
                >{`Cédula 2: ${formData.cedula_2}`}</Text>
              </View>
            </View>
            <View
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
                borderWidth: 1,
                borderColor: "#000",
              }}
            >
              <Text style={styles.letter3}>{`Ruta: ${formData.ruta}`}</Text>
            </View>
            <View style={{ marginTop: 10 }}></View>
            <View style={styles.table}>
              <View style={styles.tableRow}>
                <View style={styles.tableColHeaderNumber}>
                  <Text style={styles.tableCellHeader}>N°</Text>
                </View>
                <View style={styles.tableColHeaderOther}>
                  <Text style={styles.tableCellHeader}>Nombre y Apellido</Text>
                </View>
                <View style={styles.tableColHeaderOther2}>
                  <Text style={styles.tableCellHeader}>Cédula</Text>
                </View>
                <View style={styles.tableColHeaderOther2}>
                  <Text style={styles.tableCellHeader}>Destino</Text>
                </View>
                <View style={styles.tableColHeaderNumber}>
                  <Text style={styles.tableCellHeader}>N°</Text>
                </View>
                <View style={styles.tableColHeaderOther}>
                  <Text style={styles.tableCellHeader}>Nombre y Apellido</Text>
                </View>
                <View style={styles.tableColHeaderOther2}>
                  <Text style={styles.tableCellHeader}>Cédula</Text>
                </View>
                <View style={styles.tableColHeaderOther2}>
                  <Text style={styles.tableCellHeader}>Destino</Text>
                </View>
              </View>
              {[...Array(30)].map((_, index) => (
                <View key={index} style={styles.tableRow}>
                  <View style={styles.tableColNumber}>
                    <Text style={styles.tableCell}>{index * 2 + 1}</Text>
                  </View>
                  <View style={styles.tableColOther}>
                    <Text style={styles.tableCell}></Text>
                  </View>
                  <View style={styles.tableColOther2}>
                    <Text style={styles.tableCell}></Text>
                  </View>
                  <View style={styles.tableColOther2}>
                    <Text style={styles.tableCell}></Text>
                  </View>
                  <View style={styles.tableColNumber}>
                    <Text style={styles.tableCell}>{index * 2 + 2}</Text>
                  </View>
                  <View style={styles.tableColOther}>
                    <Text style={styles.tableCell}></Text>
                  </View>
                  <View style={styles.tableColOther2}>
                    <Text style={styles.tableCell}></Text>
                  </View>
                  <View style={styles.tableColOther2}>
                    <Text style={styles.tableCell}></Text>
                  </View>
                </View>
              ))}
            </View>
            <Text style={styles.bottomText2}>COPIA: LÍNEA TRANSPORTE</Text>
            <Text style={styles.bottomText}>
              El valor del listín es el 3% de la tarifa pagada por pasajero, de
              acuerdo a la publicación del Ministerio del Poder Popular para el
              Transporte, en la Gaceta Oficial de la República Bolivariana de
              Venezuela N° 42.462 de fecha 14/09/2022
            </Text>
          </Page>
          <Page style={styles.page} size="A4">
            <View style={styles.header}>
              <Image
                src="https://res.cloudinary.com/dirpdlfbu/image/upload/v1701880341/logo/cmyemflfryypewryq6ux.jpg"
                style={styles.logo}
              />
              <Image
                src="https://res.cloudinary.com/dirpdlfbu/image/upload/v1711324140/aeezebl5y4pzhcmgm2da.jpg"
                style={styles.logo2}
              />
            </View>
            <View style={styles.header}>
              <Text style={styles.letter2}>TERMINAL LA BANDERA</Text>
              <View>
                <Text
                  style={styles.letter2}
                >{`LISTIN Nº ${numero_listin}`}</Text>
                <Text style={styles.letter2}>Rutas Interurbanas</Text>
              </View>
            </View>
            <View
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
                borderWidth: 1,
                borderColor: "#000",
              }}
            >
              <View>
                <Text
                  style={styles.letter}
                >{`Línea: ${formData.empresa}`}</Text>
                <Text
                  style={styles.letter}
                >{`Destino: ${formData.destino}`}</Text>
                <Text
                  style={styles.letter}
                >{`Unidad: ${formData.unidad}`}</Text>
                <Text style={styles.letter}>{`Placa: ${formData.placa}`}</Text>
              </View>

              <View>
                <Text style={styles.letter}>{`Anden: ${formData.anden}`}</Text>
                <Text
                  style={styles.letter}
                >{`Servicio: ${formData.tipo_servicio}`}</Text>
                <Text
                  style={styles.letter}
                >{`Conductor 1: ${formData.conductor_1}`}</Text>
                <Text
                  style={styles.letter}
                >{`Conductor 2: ${formData.conductor_2}`}</Text>
              </View>
              <View>
                <Text style={styles.letter}>{`Fecha: ${formData.fecha}`}</Text>
                <Text
                  style={styles.letter}
                >{`Hora de salida: ${formData.hora_salida}`}</Text>
                <Text
                  style={styles.letter}
                >{`Cédula 1: ${formData.cedula_1}`}</Text>
                <Text
                  style={styles.letter}
                >{`Cédula 2: ${formData.cedula_2}`}</Text>
              </View>
            </View>
            <View
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
                borderWidth: 1,
                borderColor: "#000",
              }}
            >
              <Text style={styles.letter3}>{`Ruta: ${formData.ruta}`}</Text>
            </View>
            <View style={{ marginTop: 10 }}></View>
            <View style={styles.table}>
              <View style={styles.tableRow}>
                <View style={styles.tableColHeaderNumber}>
                  <Text style={styles.tableCellHeader}>N°</Text>
                </View>
                <View style={styles.tableColHeaderOther}>
                  <Text style={styles.tableCellHeader}>Nombre y Apellido</Text>
                </View>
                <View style={styles.tableColHeaderOther2}>
                  <Text style={styles.tableCellHeader}>Cédula</Text>
                </View>
                <View style={styles.tableColHeaderOther2}>
                  <Text style={styles.tableCellHeader}>Destino</Text>
                </View>
                <View style={styles.tableColHeaderNumber}>
                  <Text style={styles.tableCellHeader}>N°</Text>
                </View>
                <View style={styles.tableColHeaderOther}>
                  <Text style={styles.tableCellHeader}>Nombre y Apellido</Text>
                </View>
                <View style={styles.tableColHeaderOther2}>
                  <Text style={styles.tableCellHeader}>Cédula</Text>
                </View>
                <View style={styles.tableColHeaderOther2}>
                  <Text style={styles.tableCellHeader}>Destino</Text>
                </View>
              </View>
              {[...Array(30)].map((_, index) => (
                <View key={index} style={styles.tableRow}>
                  <View style={styles.tableColNumber}>
                    <Text style={styles.tableCell}>{index * 2 + 1}</Text>
                  </View>
                  <View style={styles.tableColOther}>
                    <Text style={styles.tableCell}></Text>
                  </View>
                  <View style={styles.tableColOther2}>
                    <Text style={styles.tableCell}></Text>
                  </View>
                  <View style={styles.tableColOther2}>
                    <Text style={styles.tableCell}></Text>
                  </View>
                  <View style={styles.tableColNumber}>
                    <Text style={styles.tableCell}>{index * 2 + 2}</Text>
                  </View>
                  <View style={styles.tableColOther}>
                    <Text style={styles.tableCell}></Text>
                  </View>
                  <View style={styles.tableColOther2}>
                    <Text style={styles.tableCell}></Text>
                  </View>
                  <View style={styles.tableColOther2}>
                    <Text style={styles.tableCell}></Text>
                  </View>
                </View>
              ))}
            </View>
            <Text style={styles.bottomText2}>COPIA: INTT</Text>
            <Text style={styles.bottomText}>
              El valor del listín es el 3% de la tarifa pagada por pasajero, de
              acuerdo a la publicación del Ministerio del Poder Popular para el
              Transporte, en la Gaceta Oficial de la República Bolivariana de
              Venezuela N° 42.462 de fecha 14/09/2022
            </Text>
          </Page>
        </Document>
      );

      const blob = await pdf(pdfContent).toBlob();

      const pdfFormData = new FormData();
      pdfFormData.append("file", blob, "listin.pdf");

      // Paso 3: Subir el PDF a Cloudinary
      const uploadResponse = await axios.post(
        `https://api.cloudinary.com/v1_1/${process.env.CLOUDINARY_CLOUD_NAME}/raw/upload`,
        pdfFormData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          params: {
            upload_preset: process.env.CLOUDINARY_UPLOAD_PRESET,
            folder: `LISTINES/CSS-TLB_rl`,
          },
        }
      );

      const linkListin = uploadResponse.data.secure_url;

      // Paso 4: Actualizar el registro con el enlace del PDF
      await axios.post(
        process.env.BACKEND_URL + "/api/updateListinRL",
        {
          listin_id,
          link_listin: linkListin,
        },
        {
          headers: {
            Authorization: `Bearer ${store.token}`,
            "Content-Type": "application/json",
          },
        }
      );

      setFormData({
        activacion: "",
        numero_listin: "",
        fecha: "",
        tipo_servicio: "",
        unidad: "",
        placa: "",
        numero_puestos: "",
        destino: "",
        anden: "",
        hora_salida: "",
        ruta: "",
        empresa: "",
        rif: "",
        conductor_1: "",
        cedula_1: "",
        grado_licencia_1: "",
        fecha_vencimiento_licencia_1: "",
        conductor_2: "",
        cedula_2: "",
        grado_licencia_2: "",
        fecha_vencimiento_licencia_2: "",
      });

      MySwal.close();

      MySwal.fire({
        title: "Registro de Listín Exitoso",
        text: "El listín ha sido registrado exitosamente.",
        icon: "success",
        confirmButtonText: "Cerrar",
      });
    } catch (error) {
      const errorMessage =
        error.response?.data?.message ||
        "Error desconocido al registrar el listín.";

      MySwal.fire({
        title: "Error",
        text: errorMessage + "Vuelve a intentarlo",
        icon: "error",
        confirmButtonText: "Cerrar",
      });
    } finally {
      setSaving(false);
    }
  };

  const fetchTodayListines = async () => {
    MySwal.fire({
      title: "Cargando...",
      html: `<p style="text-align:center; margin:20px;">Cargando listines de hoy...</p>`,
      allowOutsideClick: false,
      didOpen: () => {
        MySwal.showLoading();
      },
    });

    try {
      const response = await axios.get(
        process.env.BACKEND_URL + "/api/getTodayListinesRL",
        {
          headers: {
            Authorization: `Bearer ${store.token}`,
          },
        }
      );

      const data = response.data;

      MySwal.close();

      if (!data || data.length === 0) {
        MySwal.fire({
          title: "Listines de Hoy",
          html: `<p style="text-align:center; margin:20px;">No se encontraron listines.</p>`,
          confirmButtonText: "Cerrar",
        });
      } else {
        const content = data
          .map(
            (item) => `
              <div style="margin: 10px; padding: 10px; border: 1px solid #ccc; background-color: ${item.estatus === "ACTIVO" ? "#0080001c" : "#ff00001a"};">
                <p><strong>Número Listín:</strong> ${item.n_listin}</p>
                <p><strong>Fecha:</strong> ${item.fecha}</p>
                <p><strong>Tipo de Servicio:</strong> ${item.tipo_de_servicio}</p>
                <p><strong>Unidad:</strong> ${item.n_unidad}</p>
                <p><strong>Placa:</strong> ${item.placa}</p>
                <p><strong>Número de Puestos:</strong> ${item.n_puestos}</p>
                <p><strong>Destino:</strong> ${item.destino_final}</p>
                <p><strong>Andén:</strong> ${item.anden}</p>
                <p><strong>Hora de Salida:</strong> ${item.hora_salida}</p>
                <p><strong>Ruta:</strong> ${item.ruta}</p>
                <p><strong>Conductor 1:</strong> ${item.conductor_1}</p>
                <p><strong>Cédula 1:</strong> ${item.cedula_1}</p>
                <p><strong>Grado Licencia 1:</strong> ${item.grado_licencia_1}</p>
                <p><strong>Fecha Vencimiento Licencia 1:</strong> ${item.fecha_vencimiento_licencia_1}</p>
                <p><strong>Conductor 2:</strong> ${item.conductor_2}</p>
                <p><strong>Cédula 2:</strong> ${item.cedula_2}</p>
                <p><strong>Grado Licencia 2:</strong> ${item.grado_licencia_2}</p>
                <p><strong>Fecha Vencimiento Licencia 2:</strong> ${item.fecha_vencimiento_licencia_2}</p>
                <p><strong>Registrado por:</strong> ${item.registrado_por}</p>
                <p><strong>Estatus:</strong> ${item.estatus}</p>
                <a href="${item.link_listin}" target="_blank" class="btn btn-primary">Ver PDF</a>
                <button class="btn btn-secondary" data-id='${item.id}'>
                  ${item.estatus === "ACTIVO" ? "Anular" : "Activar"}
                </button>
              </div>
            `
          )
          .join("");

        MySwal.fire({
          title: "Listines de Hoy",
          html: `
            <input type="text" id="search-listines" placeholder="Buscar..." class="swal2-input">
            <div id="listines-list" style="overflow-y: auto;">${content}</div>
          `,
          showConfirmButton: true,
          confirmButtonText: "Cerrar",
          didOpen: () => {
            const searchInput = document.getElementById("search-listines");
            searchInput.addEventListener("input", (event) => {
              const searchTerm = event.target.value.toLowerCase();
              const listinesDiv = document.getElementById("listines-list");
              listinesDiv.innerHTML = data
                .filter(
                  (item) =>
                    item.n_listin.toLowerCase().includes(searchTerm) ||
                    item.fecha.toLowerCase().includes(searchTerm) ||
                    item.tipo_de_servicio.toLowerCase().includes(searchTerm) ||
                    item.empresa.toLowerCase().includes(searchTerm)
                )
                .map(
                  (item) => `
                    <div style="margin: 10px; padding: 10px; border: 1px solid #ccc; background-color: ${item.estatus === "ACTIVO" ? "#0080001c" : "#ff00001a"};">
                      <p><strong>Número Listín:</strong> ${item.n_listin}</p>
                      <p><strong>Fecha:</strong> ${item.fecha}</p>
                      <p><strong>Tipo de Servicio:</strong> ${item.tipo_de_servicio}</p>
                      <p><strong>Unidad:</strong> ${item.n_unidad}</p>
                      <p><strong>Placa:</strong> ${item.placa}</p>
                      <p><strong>Número de Puestos:</strong> ${item.n_puestos}</p>
                      <p><strong>Destino:</strong> ${item.destino_final}</p>
                      <p><strong>Andén:</strong> ${item.anden}</p>
                      <p><strong>Hora de Salida:</strong> ${item.hora_salida}</p>
                      <p><strong>Ruta:</strong> ${item.ruta}</p>
                      <p><strong>Conductor 1:</strong> ${item.conductor_1}</p>
                      <p><strong>Cédula 1:</strong> ${item.cedula_1}</p>
                      <p><strong>Grado Licencia 1:</strong> ${item.grado_licencia_1}</p>
                      <p><strong>Fecha Vencimiento Licencia 1:</strong> ${item.fecha_vencimiento_licencia_1}</p>
                      <p><strong>Conductor 2:</strong> ${item.conductor_2}</p>
                      <p><strong>Cédula 2:</strong> ${item.cedula_2}</p>
                      <p><strong>Grado Licencia 2:</strong> ${item.grado_licencia_2}</p>
                      <p><strong>Fecha Vencimiento Licencia 2:</strong> ${item.fecha_vencimiento_licencia_2}</p>
                      <p><strong>Registrado por:</strong> ${item.registrado_por}</p>
                      <p><strong>Estatus:</strong> ${item.estatus}</p>
                      <a href="${item.link_listin}" target="_blank" class="btn btn-primary">Ver PDF</a>
                      <button class="btn btn-secondary" data-id='${item.id}'>
                        ${item.estatus === "ACTIVO" ? "Anular" : "Activar"}
                      </button>
                    </div>
                  `
                )
                .join("");
            });

            document.querySelectorAll(".btn-secondary").forEach((button) => {
              button.addEventListener("click", async (event) => {
                const listinId = event.currentTarget.getAttribute("data-id");
                const newStatus =
                  event.currentTarget.innerText === "Anular"
                    ? "ANULADO"
                    : "ACTIVO";

                try {
                  await axios.post(
                    `${process.env.BACKEND_URL}/api/updateListinStatus`,
                    { id: listinId, estatus: newStatus },
                    {
                      headers: {
                        Authorization: `Bearer ${store.token}`,
                        "Content-Type": "application/json",
                      },
                    }
                  );

                  MySwal.fire({
                    title: `Listín ${newStatus}`,
                    text: `El listín ha sido ${newStatus.toLowerCase()} exitosamente.`,
                    icon: "success",
                    confirmButtonText: "Cerrar",
                  });

                  fetchTodayListines(); // Recargar los listines después de actualizar el estatus
                } catch (error) {
                  MySwal.fire({
                    title: "Error",
                    text: "Hubo un error al actualizar el estatus del listín.",
                    icon: "error",
                    confirmButtonText: "Cerrar",
                  });
                }
              });
            });
          },
        });
      }
    } catch (error) {
      console.error("Error al obtener listines de hoy", error);
      MySwal.fire({
        title: "Error",
        html: `<p>Error al obtener listines de hoy. Por favor, inténtelo de nuevo más tarde.</p>`,
        icon: "error",
        confirmButtonText: "Cerrar",
      });
    }
  };

  const handlePreviewPDF = async () => {
    setSaving(true);
    MySwal.fire({
      title: "Generando vista previa",
      html: `<p style="text-align:center; margin:20px;">Por favor, espere...</p>`,
      allowOutsideClick: false,
      didOpen: () => {
        MySwal.showLoading();
      },
    });

    try {
      const pdfContent = (
        <Document>
          <Page style={styles.page} size="A4">
            <View style={styles.header}>
              <Image
                src="https://res.cloudinary.com/dirpdlfbu/image/upload/v1701880341/logo/cmyemflfryypewryq6ux.jpg"
                style={styles.logo}
              />
              <Image
                src="https://res.cloudinary.com/dirpdlfbu/image/upload/v1711324140/aeezebl5y4pzhcmgm2da.jpg"
                style={styles.logo2}
              />
            </View>
            <View style={styles.header}>
              <Text style={styles.letter2}>TERMINAL LA BANDERA</Text>
              <View>
                <Text
                  style={styles.letter2}
                >{`LISTIN Nº ${formData.numero_listin || "N/A"}`}</Text>
                <Text style={styles.letter2}>Rutas Interurbanas</Text>
              </View>
            </View>
            <View
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
                borderWidth: 1,
                borderColor: "#000",
              }}
            >
              <View>
                <Text
                  style={styles.letter}
                >{`Línea: ${formData.empresa}`}</Text>
                <Text
                  style={styles.letter}
                >{`Destino: ${formData.destino}`}</Text>
                <Text
                  style={styles.letter}
                >{`Unidad: ${formData.unidad}`}</Text>
                <Text style={styles.letter}>{`Placa: ${formData.placa}`}</Text>
              </View>

              <View>
                <Text style={styles.letter}>{`Anden: ${formData.anden}`}</Text>
                <Text
                  style={styles.letter}
                >{`Servicio: ${formData.tipo_servicio}`}</Text>
                <Text
                  style={styles.letter}
                >{`Conductor 1: ${formData.conductor_1}`}</Text>
                <Text
                  style={styles.letter}
                >{`Conductor 2: ${formData.conductor_2}`}</Text>
              </View>
              <View>
                <Text style={styles.letter}>{`Fecha: ${formData.fecha}`}</Text>
                <Text
                  style={styles.letter}
                >{`Hora de salida: ${formData.hora_salida}`}</Text>
                <Text
                  style={styles.letter}
                >{`Cédula 1: ${formData.cedula_1}`}</Text>
                <Text
                  style={styles.letter}
                >{`Cédula 2: ${formData.cedula_2}`}</Text>
              </View>
            </View>
            <View
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
                borderWidth: 1,
                borderColor: "#000",
              }}
            >
              <Text style={styles.letter3}>{`Ruta: ${formData.ruta}`}</Text>
            </View>
            <View style={{ marginTop: 10 }}></View>
            <View style={styles.table}>
              <View style={styles.tableRow}>
                <View style={styles.tableColHeaderNumber}>
                  <Text style={styles.tableCellHeader}>N°</Text>
                </View>
                <View style={styles.tableColHeaderOther}>
                  <Text style={styles.tableCellHeader}>Nombre y Apellido</Text>
                </View>
                <View style={styles.tableColHeaderOther2}>
                  <Text style={styles.tableCellHeader}>Cédula</Text>
                </View>
                <View style={styles.tableColHeaderOther2}>
                  <Text style={styles.tableCellHeader}>Destino</Text>
                </View>
                <View style={styles.tableColHeaderNumber}>
                  <Text style={styles.tableCellHeader}>N°</Text>
                </View>
                <View style={styles.tableColHeaderOther}>
                  <Text style={styles.tableCellHeader}>Nombre y Apellido</Text>
                </View>
                <View style={styles.tableColHeaderOther2}>
                  <Text style={styles.tableCellHeader}>Cédula</Text>
                </View>
                <View style={styles.tableColHeaderOther2}>
                  <Text style={styles.tableCellHeader}>Destino</Text>
                </View>
              </View>
              {[...Array(30)].map((_, index) => (
                <View key={index} style={styles.tableRow}>
                  <View style={styles.tableColNumber}>
                    <Text style={styles.tableCell}>{index * 2 + 1}</Text>
                  </View>
                  <View style={styles.tableColOther}>
                    <Text style={styles.tableCell}></Text>
                  </View>
                  <View style={styles.tableColOther2}>
                    <Text style={styles.tableCell}></Text>
                  </View>
                  <View style={styles.tableColOther2}>
                    <Text style={styles.tableCell}></Text>
                  </View>
                  <View style={styles.tableColNumber}>
                    <Text style={styles.tableCell}>{index * 2 + 2}</Text>
                  </View>
                  <View style={styles.tableColOther}>
                    <Text style={styles.tableCell}></Text>
                  </View>
                  <View style={styles.tableColOther2}>
                    <Text style={styles.tableCell}></Text>
                  </View>
                  <View style={styles.tableColOther2}>
                    <Text style={styles.tableCell}></Text>
                  </View>
                </View>
              ))}
            </View>
            <Text style={styles.bottomText2}>ORIGINAL: INTRAVIALCA</Text>
            <Text style={styles.bottomText}>
              El valor del listín es el 3% de la tarifa pagada por pasajero, de
              acuerdo a la publicación del Ministerio del Poder Popular para el
              Transporte, en la Gaceta Oficial de la República Bolivariana de
              Venezuela N° 42.462 de fecha 14/09/2022
            </Text>
          </Page>
        </Document>
      );

      const blob = await pdf(pdfContent).toBlob();

      const pdfUrl = URL.createObjectURL(blob);
      window.open(pdfUrl, "_blank");

      MySwal.close();

      MySwal.fire({
        title: "Vista previa generada",
        text: "La vista previa del PDF ha sido generada y abierta en una nueva pestaña.",
        icon: "success",
        confirmButtonText: "Cerrar",
      });
    } catch (error) {
      MySwal.fire({
        title: "Error",
        text: "Hubo un error al generar la vista previa del PDF.",
        icon: "error",
        confirmButtonText: "Cerrar",
      });
    } finally {
      setSaving(false);
    }
  };

  return (
    <div
      className="container-fluid contarinerGeneralUserHomejs"
      style={{ paddingTop: "30px" }}
    >
      <div className="container-fluid contaosijdasd-qw89d77asd223"></div>
      <h2
        className="asjdansdas-d4as6489text w-100"
        style={{ zIndex: "15", marginTop: "-17vh" }}
      >
        Registro de Listines
      </h2>
      <div className="container asklnduiashndaijsnxansxsaas">
        <form
          className="d-flex sd98f4e8wf4cwe987cwe984cwe"
          onSubmit={handleSubmit}
        >
          <div className="aoshduiahsdkasnmdasdas">
            <div className="input-group-login2">
              <input
                className="inputDeLogin"
                type="text"
                name="activacion"
                value={formData.activacion}
                onClick={showActivacionesModal}
                readOnly
              />
              <label>Programación de para hoy</label>
            </div>
            <div className="input-group-login2">
              <input
                className="inputDeLogin"
                type="text"
                name="tipo_servicio"
                value={formData.tipo_servicio}
                onChange={handleChange}
                required
              />
              <label>Tipo de Servicio</label>
            </div>
          </div>

          <div className="aoshduiahsdkasnmdasdas">
            <div className="input-group-login2">
              <input
                className="inputDeLogin"
                type="text"
                name="unidad"
                value={formData.unidad}
                onChange={handleChange}
                required
              />
              <label>Unidad</label>
            </div>
            <div className="input-group-login2">
              <input
                className="inputDeLogin"
                type="text"
                name="placa"
                value={formData.placa}
                onChange={handleChange}
                required
                style={{ textTransform: "uppercase" }}
              />
              <label>Placa</label>
            </div>
            <div className="input-group-login2">
              <input
                className="inputDeLogin"
                type="text"
                name="numero_puestos"
                value={formData.numero_puestos}
                onChange={handleChange}
                required
              />
              <label>Número de Puestos</label>
            </div>
            <div className="input-group-login2">
              <input
                className="inputDeLogin"
                type="text"
                name="destino"
                value={formData.destino}
                onChange={handleChange}
                required
              />
              <label>Destino</label>
            </div>
          </div>
          <div className="aoshduiahsdkasnmdasdas">
            <div className="input-group-login2">
              <textarea
                className="inputDeLogin"
                type="text"
                name="ruta"
                value={formData.ruta}
                onChange={handleChange}
                required
              />
              <label>Ruta</label>
            </div>
          </div>

          <div className="aoshduiahsdkasnmdasdas">
            <div className="input-group-login2">
              <input
                className="inputDeLogin"
                type="text"
                name="anden"
                value={formData.anden}
                onChange={handleChange}
                required
              />
              <label>Andén</label>
            </div>
            <div className="input-group-login2">
              <input
                className="inputDeLogin"
                type="time"
                name="hora_salida"
                value={formData.hora_salida}
                onChange={handleChange}
                required
              />
              <label>Hora de Salida</label>
            </div>

            <div className="input-group-login2">
              <input
                className="inputDeLogin"
                type="text"
                name="empresa"
                value={formData.empresa}
                onChange={handleChange}
                required
              />
              <label>Empresa</label>
            </div>
            <div className="input-group-login2">
              <input
                className="inputDeLogin"
                type="text"
                name="rif"
                value={formData.rif}
                onChange={handleChange}
                required
              />
              <label>RIF</label>
            </div>
          </div>

          <div className="aoshduiahsdkasnmdasdas">
            <div className="input-group-login2">
              <input
                className="inputDeLogin"
                type="text"
                name="conductor_1"
                value={formData.conductor_1}
                onChange={handleChange}
                required
              />
              <label>Conductor 1</label>
            </div>
            <div className="input-group-login2">
              <input
                className="inputDeLogin"
                type="text"
                name="cedula_1"
                value={formData.cedula_1}
                onChange={handleChange}
                required
              />
              <label>Cédula 1</label>
            </div>
            <div className="input-group-login2">
              <input
                className="inputDeLogin"
                type="text"
                name="grado_licencia_1"
                value={formData.grado_licencia_1}
                onChange={handleChange}
                required
              />
              <label>Grado Licencia 1</label>
            </div>
            <div className="input-group-login2">
              <input
                className="inputDeLogin"
                type="date"
                name="fecha_vencimiento_licencia_1"
                value={formData.fecha_vencimiento_licencia_1}
                onChange={handleChange}
                required
              />
              <label>Fecha Venc. Licencia 1</label>
            </div>
          </div>

          <div className="aoshduiahsdkasnmdasdas">
            <div className="input-group-login2">
              <input
                className="inputDeLogin"
                type="text"
                name="conductor_2"
                value={formData.conductor_2}
                onChange={handleChange}
              />
              <label>Conductor 2</label>
            </div>
            <div className="input-group-login2">
              <input
                className="inputDeLogin"
                type="text"
                name="cedula_2"
                value={formData.cedula_2}
                onChange={handleChange}
              />
              <label>Cédula 2</label>
            </div>
            <div className="input-group-login2">
              <input
                className="inputDeLogin"
                type="text"
                name="grado_licencia_2"
                value={formData.grado_licencia_2}
                onChange={handleChange}
              />
              <label>Grado Licencia 2</label>
            </div>
            <div className="input-group-login2">
              <input
                className="inputDeLogin"
                type="date"
                name="fecha_vencimiento_licencia_2"
                value={formData.fecha_vencimiento_licencia_2}
                onChange={handleChange}
              />
              <label>Fecha Venc. Licencia 2</label>
            </div>
          </div>

          <div className="w-100 asd9as-da9s84dx9a8qdw-qdw asdqw98d4qv895y4n9r84bvr98ebtnbtn">
            <button
              type="submit"
              className="btn buttonCargadeDatosDeAddDriver"
              style={{ marginBottom: "30px" }}
              disabled={saving}
            >
              <div className="d-flex">
                <p style={{ marginBottom: 0 }}>Guardar Datos</p>
                <i className="fa-solid fa-arrow-right flechaHomePresentation2"></i>
              </div>
            </button>
          </div>
        </form>
      </div>
      <div className="w-100 asd9as-da9s84dx9a8qdw-qdw asdqw98d4qv895y4n9r84bvr98ebtnbtn poja65s4d8a9s7d89errg98er7fqwd9849iu9ykj7asj">
        <button
          className="btn asd98q7wd65q1wd8xqw49x8e4wc"
          onClick={fetchTodayListines}
        >
          <i
            className="fa-solid fa-route"
            style={{ marginRight: "7px", fontSize: "20px" }}
          ></i>
          Ver Listines de Hoy Registrados
        </button>
      </div>
      {/* <div className="w-100 asd9as-da9s84dx9a8qdw-qdw asdqw98d4qv895y4n9r84bvr98ebtnbtn poja65s4d8a9s7d89errg98er7fqwd9849iu9ykj7asj">
        <button
          className="btn asd98q7wd65q1wd8xqw49x8e4wc"
          onClick={handlePreviewPDF}
        >
          <i
            className="fa-solid fa-route"
            style={{ marginRight: "7px", fontSize: "20px" }}
          ></i>
          Preview PDF
        </button>
      </div> */}
    </div>
  );
};
