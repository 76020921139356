import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import { Context } from "../../../store/appContext";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  pdf,
} from "@react-pdf/renderer";

const MySwal = withReactContent(Swal);

const tableStyles = StyleSheet.create({
  anuladoRow: {
    backgroundColor: "#ffcccc", // Rojo clarito
  },
  activoRow: {
    backgroundColor: "#ffffff", // Verde clarito
  },
  table: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    marginTop: 10,
  },
  table2: {
    marginTop: 10,
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row",
    width: "100%",
  },
  tableRow2: {
    flexDirection: "row",
  },
  tableColHeader: {
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    backgroundColor: "#f2f2f2",
    alignItems: "center",
    justifyContent: "center",
    width: "23%",
  },
  tableColHeader1: {
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    backgroundColor: "#f2f2f2",
    alignItems: "center",
    justifyContent: "center",
    width: "8%",
  },
  tableColHeader2: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "#f2f2f2",
    alignItems: "center",
    justifyContent: "center",
    width: "20%",
  },
  tableCol: {
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    justifyContent: "center",
    width: "23%",
  },
  tableCol1: {
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    justifyContent: "center",
    width: "8%",
    alignItems: "center",
  },
  tableCellHeader: {
    margin: 5,
    fontSize: 9,
    fontWeight: "bold",
    textAlign: "center",
  },
  tableCell: {
    margin: 4,
    fontSize: 9,
  },
});

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    borderBottomColor: "#000",
    borderBottomWidth: 1,
    alignItems: "center",
    height: 15,
    margin: 0,
    padding: 0,
    justifyContent: "center",
    textAlign: "center",
  },
  cell: {
    fontSize: 9,
    textAlign: "center",
    borderRightColor: "#000",
    borderRightWidth: 1,
    flex: 1,
  },
  header: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  header2: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  logo: {
    width: 90,
    height: 30,
  },
  logo2: {
    width: 100,
    height: 20,
  },
  page: {
    padding: 20,
  },
  letter: {
    fontSize: 7,
    padding: 5,
  },
  letter2: {
    fontSize: 10,
  },
  bottomText: {
    position: "absolute",
    bottom: 30,
    left: 20,
    right: 20,
    textAlign: "center",
    fontSize: 8,
  },
  bottomText2: {
    position: "absolute",
    bottom: 50,
    left: 20,
    right: 20,
    textAlign: "center",
    fontSize: 11,
    fontWeight: "bold",
  },
});

export const Cierre_control_diario = () => {
  const { store } = useContext(Context);
  const [listines, setListines] = useState([]);
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [user, setUser] = useState({
    firstName: "",
    lastName: "",
    greeting: "",
  });

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(
          `${process.env.BACKEND_URL}/api/protect`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setUser({
          firstName: response.data.first_name,
          lastName: response.data.last_name,
          greeting: response.data.greeting,
        });
      } catch (error) {
        if (error.response && error.response.status === 401) {
          localStorage.removeItem("token");
          alert("Su sesión ha expirado, por favor vuelva a iniciar sesión");
          window.location.reload();
        } else {
          console.log("Axios error:", error.message);
        }
      }
    };

    fetchUserData();
  }, [store.token]);

  useEffect(() => {
    fetchListines();
  }, []);

  const fetchListines = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        process.env.BACKEND_URL + "/api/getTodayListines",
        {
          headers: {
            Authorization: `Bearer ${store.token}`,
          },
        }
      );
      setListines(response.data);
    } catch (error) {
      console.error("Error fetching listines", error);
      MySwal.fire({
        title: "Error",
        text: "Hubo un error al obtener los listines.",
        icon: "error",
        confirmButtonText: "Cerrar",
      });
    } finally {
      setLoading(false);
    }
  };

  const fetchCierres = async () => {
    MySwal.fire({
      title: "Cargando...",
      html: `<p style="text-align:center; margin:20px;">Cargando cierres realizados...</p>`,
      allowOutsideClick: false,
      didOpen: () => {
        MySwal.showLoading();
      },
    });

    try {
      const response = await axios.get(
        process.env.BACKEND_URL + "/api/Cierre_RL_Usuario",
        {
          headers: {
            Authorization: `Bearer ${store.token}`,
          },
        }
      );

      const data = response.data;

      MySwal.close();

      if (!data || data.length === 0) {
        MySwal.fire({
          title: "Cierres Realizados",
          html: `<p style="text-align:center; margin:20px;">No se encontraron cierres.</p>`,
          confirmButtonText: "Cerrar",
        });
      } else {
        const content = data
          .map(
            (cierre) => `
              <div style="margin: 10px; padding: 10px; border: 1px solid #ccc;">
                <p><strong>Usuario:</strong> ${cierre.usuario}</p>
                <p><strong>Fecha y Hora de Registro:</strong> ${cierre.fecha_hora_registro}</p>
                <p><strong>Turno:</strong> ${cierre.turno}</p>
                <p><strong>Total Listines:</strong> ${cierre.total_n_listines}</p>
                <a href="${cierre.link_document}" target="_blank" class="btn btn-primary">
                  <i class="fa-regular fa-file-pdf"></i> Ver PDF
                </a>
              </div>
            `
          )
          .join("");

        MySwal.fire({
          title: "Cierres Realizados",
          html: `<div style="text-align:left; max-height:400px; overflow-y:auto;">${content}</div>`,
          confirmButtonText: "Cerrar",
        });
      }
    } catch (error) {
      console.error("Error fetching cierres", error);
      MySwal.fire({
        title: "Error",
        text: "Hubo un error al obtener los cierres.",
        icon: "error",
        confirmButtonText: "Cerrar",
      });
    }
  };

  const MyDocument = ({ listines }) => (
    <Document>
      <Page style={styles.page} size="A4">
        <View style={styles.header}>
          <Image
            src="https://res.cloudinary.com/dirpdlfbu/image/upload/v1701880341/logo/cmyemflfryypewryq6ux.jpg"
            style={styles.logo}
          />
          <Image
            src="https://res.cloudinary.com/dirpdlfbu/image/upload/v1711324140/aeezebl5y4pzhcmgm2da.jpg"
            style={styles.logo2}
          />
        </View>
        <View style={styles.header2}>
          <Text style={styles.letter2}>CIERRE LISTINES RUTAS LARGAS</Text>
        </View>
        <View style={tableStyles.table2}>
          <View style={tableStyles.tableRow2}>
            <View style={tableStyles.tableColHeader2}>
              <Text style={tableStyles.tableCellHeader}>Usuario:</Text>
            </View>
            <View style={tableStyles.tableColHeader2}>
              <Text style={tableStyles.tableCell}>
                {user.firstName} {user.lastName}
              </Text>
            </View>
          </View>
        </View>
        <View style={tableStyles.table}>
          <View style={tableStyles.tableRow}>
            <View style={tableStyles.tableColHeader1}>
              <Text style={tableStyles.tableCellHeader}>N° Listín</Text>
            </View>
            <View style={tableStyles.tableColHeader}>
              <Text style={tableStyles.tableCellHeader}>Fecha</Text>
            </View>
            <View style={tableStyles.tableColHeader}>
              <Text style={tableStyles.tableCellHeader}>Empresa</Text>
            </View>
            <View style={tableStyles.tableColHeader}>
              <Text style={tableStyles.tableCellHeader}>Destino</Text>
            </View>
            <View style={tableStyles.tableColHeader}>
              <Text style={tableStyles.tableCellHeader}>Registrado por</Text>
            </View>
          </View>
          {listines.map((item, index) => {
            const formattedDate = item.fecha.split("-").reverse().join("-");
            return (
              <View
                key={index}
                style={[
                  tableStyles.tableRow,
                  item.estatus === "ANULADO"
                    ? tableStyles.anuladoRow
                    : tableStyles.activoRow,
                ]}
              >
                <View style={tableStyles.tableCol1}>
                  <Text style={tableStyles.tableCell}>{item.n_listin}</Text>
                </View>
                <View style={tableStyles.tableCol}>
                  <Text style={tableStyles.tableCell}>{formattedDate}</Text>
                </View>
                <View style={tableStyles.tableCol}>
                  <Text style={tableStyles.tableCell}>{item.empresa}</Text>
                </View>
                <View style={tableStyles.tableCol}>
                  <Text style={tableStyles.tableCell}>
                    {item.destino_final}
                  </Text>
                </View>
                <View style={tableStyles.tableCol}>
                  <Text style={tableStyles.tableCell}>
                    {item.registrado_por}
                  </Text>
                </View>
              </View>
            );
          })}
        </View>
      </Page>
    </Document>
  );

  const handlePreview = async () => {
    MySwal.fire({
      title: "Generando PDF...",
      text: "Por favor, espere mientras se genera el PDF.",
      allowOutsideClick: false,
      didOpen: () => {
        MySwal.showLoading();
      },
    });

    const blob = await pdf(<MyDocument listines={listines} />).toBlob();
    const url = URL.createObjectURL(blob);
    MySwal.close();
    window.open(url, "_blank");
  };

  const handleCierre = async () => {
    if (listines.length === 0) {
      MySwal.fire({
        title: "No se puede realizar el cierre",
        text: "Registra algún listín para hacer el cierre.",
        icon: "warning",
        confirmButtonText: "Cerrar",
      });
      return;
    }

    const result = await MySwal.fire({
      title: "¿Está seguro?",
      text: "¿Está seguro que quiere cerrar el listín?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Sí, cerrar",
      cancelButtonText: "No, cancelar",
    });

    if (result.isConfirmed) {
      setSaving(true);
      MySwal.fire({
        title: "Cerrando listines...",
        text: "Por favor, espere mientras se cierra el listín.",
        allowOutsideClick: false,
        didOpen: () => {
          MySwal.showLoading();
        },
      });

      try {
        const blob = await pdf(<MyDocument listines={listines} />).toBlob();
        const pdfFormData = new FormData();
        pdfFormData.append("file", blob, "cierre_listines_rl.pdf");

        const uploadResponse = await axios.post(
          `https://api.cloudinary.com/v1_1/${process.env.CLOUDINARY_CLOUD_NAME}/raw/upload`,
          pdfFormData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
            params: {
              upload_preset: process.env.CLOUDINARY_UPLOAD_PRESET,
              folder: `LISTINES/CCS-TLB_rl`,
            },
          }
        );

        const linkDocument = uploadResponse.data.secure_url;

        const lastCierreResponse = await axios.get(
          `${process.env.BACKEND_URL}/api/getLastCierre`,
          {
            params: {
              terminal_ubication: store.terminal_ubication,
              type_route: "RL",
            },
            headers: {
              Authorization: `Bearer ${store.token}`,
            },
          }
        );

        const lastCierre = lastCierreResponse.data;
        let turno = "A";
        if (lastCierre && lastCierre.turno) {
          turno = String.fromCharCode(lastCierre.turno.charCodeAt(0) + 1);
        }

        await axios.post(
          `${process.env.BACKEND_URL}/api/registerCierreListines`,
          {
            turno,
            total_n_listines: listines.length,
            link_document: linkDocument,
          },
          {
            headers: {
              Authorization: `Bearer ${store.token}`,
              "Content-Type": "application/json",
            },
          }
        );

        MySwal.close();
        MySwal.fire({
          title: "Cierre exitoso",
          text: "El cierre de listines se ha realizado con éxito.",
          icon: "success",
          confirmButtonText: "Cerrar",
        });

        fetchListines();
      } catch (error) {
        console.error("Error al cerrar listines", error);
        MySwal.fire({
          title: "Error",
          text: "Hubo un error al cerrar los listines.",
          icon: "error",
          confirmButtonText: "Cerrar",
        });
      } finally {
        setSaving(false);
      }
    }
  };

  return (
    <div
      className="container-fluid contarinerGeneralUserHomejs"
      style={{ paddingTop: "30px", paddingBottom: "70px" }}
    >
      <div className="container-fluid contaosijdasd-qw89d77asd2"></div>
      <h2
        className="asjdansdas-d4as6489text w-100"
        style={{ zIndex: "15", marginTop: "-17vh" }}
      >
        Cierre Listines Rutas Largas
      </h2>
      <div className="container movimientoss9a87d89qwdqw98d4qw">
        <div className="aoshduiahsdkasnmdasdas">
          <button
            className="btn btn-primary"
            onClick={handlePreview}
            style={{
              height: "4vh",
              background: "rgba(128, 128, 128, 0.463)",
              margin: 0,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              border: "none",
            }}
            disabled={loading || saving}
          >
            Vista Cierre Actual
            <i className="fa-regular fa-eye" style={{ marginLeft: "8px" }}></i>
          </button>
          <button
            className="btn btn-primary"
            onClick={fetchCierres}
            style={{
              height: "4vh",
              background: "#151B26",
              margin: 0,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              border: "none",
            }}
            disabled={loading || saving}
          >
            Visualiza tus Cierres
            <i
              className="fa-solid fa-list-ul"
              style={{ marginLeft: "8px" }}
            ></i>
          </button>
          <button
            className="btn btn-primary"
            onClick={handleCierre}
            style={{
              minWidth: "180px",
              height: "4vh",
              background: "#24827C",
              margin: 0,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              border: "none",
            }}
            disabled={loading || saving}
          >
            Hacer Cierre
            <i
              className="fa-solid fa-cloud-arrow-down"
              style={{ marginLeft: "8px" }}
            ></i>
          </button>
        </div>
        <h5 style={{ textAlign: "center" }}>Listines Sin Cerrar</h5>
        <div className="table-responsive">
          <table>
            <thead>
              <tr className="aoshduishdihwe89f78w9e7we7we7fcw">
                <th>Número Listín</th>
                <th>Fecha</th>
                <th>Empresa</th>
                <th>Destino</th>
                <th>Registrado por</th>
              </tr>
            </thead>
            <tbody>
              {listines.map((item) => (
                <tr
                  key={item.id}
                  style={{
                    backgroundColor:
                      item.estatus === "ANULADO" ? "#ffcccc" : "transparent",
                  }}
                >
                  <td>{item.n_listin}</td>
                  <td>{item.fecha}</td>
                  <td>{item.empresa}</td>
                  <td>{item.destino_final}</td>
                  <td>{item.registrado_por}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
