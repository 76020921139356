import React, { useContext, useState, useEffect } from "react";
import axios from "axios";
import "../../../../styles/SolicitudActivaciones.css";
import { Context } from "../../../store/appContext";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import Big from "big.js";
import Select from "react-select";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFViewer,
  Image,
  pdf,
} from "@react-pdf/renderer";
import { PDFDownloadLink, BlobProvider } from "@react-pdf/renderer";

const tableStyles = StyleSheet.create({
  table: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  table2: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    marginTop: 10,
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row",
    backgroundColor: "#FFF",
  },
  tableColHeader: {
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    backgroundColor: "#f2f2f2",
    alignItems: "center",
    justifyContent: "center",
  },
  tableCol: {
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    alignItems: "center",
    justifyContent: "center",
  },
  tableCellHeader: {
    margin: 5,
    fontSize: 9,
    fontWeight: "bold",
  },
  tableCell: {
    margin: 4,
    fontSize: 9,
  },
});

tableStyles.tableColHeaderOthertaxi = {
  ...tableStyles.tableColHeader,
  width: "50%", // Mayor ancho para los demás
};

tableStyles.tableColOthertaxi = {
  ...tableStyles.tableCol,
  width: "50%", // Mayor ancho para los demás
};

// Modificar anchos específicos aquí
tableStyles.tableColHeaderNumber = {
  ...tableStyles.tableColHeader,
  width: "5%", // Menor ancho para "N°"
};

tableStyles.tableColHeaderOther = {
  ...tableStyles.tableColHeader,
  width: "22.5%", // Mayor ancho para los demás
};

tableStyles.tableColNumber = {
  ...tableStyles.tableCol,
  width: "5%", // Menor ancho para "N°"
};

tableStyles.tableColOther = {
  ...tableStyles.tableCol,
  width: "22.5%", // Mayor ancho para los demás
};

tableStyles.tableColHeaderOthertaxi2 = {
  ...tableStyles.tableColHeader,
  width: "33.3%", // Mayor ancho para los demás
};
tableStyles.tableColHeaderOthertaxi3 = {
  ...tableStyles.tableColHeader,
  width: "100%", // Mayor ancho para los demás
};

tableStyles.tableColOthertaxi2 = {
  ...tableStyles.tableCol,
  width: "33.3%", // Mayor ancho para los demás
};

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    borderBottomColor: "#000",
    borderBottomWidth: 1,
    alignItems: "center",
    height: 15,
    margin: 0,
    padding: 0,
    justifyContent: "center",
    textAlign: "center",
  },
  cell: {
    fontSize: 9,
    textAlign: "center",
    borderRightColor: "#000",
    borderRightWidth: 1,
    flex: 1,
  },
  cell2: {
    fontSize: 9,
    textAlign: "center",
    borderRightColor: "#000",
    borderRightWidth: 1,
    width: 20,
    borderLeftColor: "#000",
    borderLeftWidth: 1,
  },
  header: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  logo: {
    width: 90, // Ajusta el tamaño según tus necesidades
    height: 30, // Ajusta el tamaño según tus necesidades
  },
  logo2: {
    width: 100,
    height: 20,
  },
  page: {
    padding: 20, // Ajusta el valor del margen según tus necesidades
  },
  letter: {
    fontSize: 10,
    padding: 5,
  },
  letter2: {
    fontSize: 10,
  },
  ruta: {
    fontSize: 7,
    padding: 4,
  },
  bottomText: {
    position: "absolute",
    bottom: 0,
    left: 20, // Ajusta según sea necesario
    right: 20, // Ajusta según sea necesario
    textAlign: "center",
    bottom: 30,
    fontSize: 8,
  },
  bottomText2: {
    position: "absolute",
    bottom: 0,
    left: 20, // Ajusta según sea necesario
    right: 20, // Ajusta según sea necesario
    textAlign: "center",
    bottom: 50,
    fontSize: 11,
    fontWeight: "bold",
  },
});

const MySwal = withReactContent(Swal);

export const Elab_Listin_manual = ({ toggleLoading }) => {
  const [routeType, setRouteType] = useState("Listin");
  const [showPreview, setShowPreview] = useState(false);
  const [usdRate, setUsdRate] = useState(null);
  const [formData, setFormData] = useState({
    // Campos comunes
    fecha: "",
    placa: "",
    // Campos específicos de Listin
    numero_listin: "",
    condicion: "FIJO",
    empresa: "",
    codigo: "",
    unidad: "",
    tipo_servicio: "",
    numero_puestos: "",
    destino: "",
    cedula: "",
    conductor: "",
    precio_bs: "",
    precio_usd: "",
    total_general_cancelado: "",
    status: "APROBADO",
    registrado_por: "",
    // Campos específicos de Taxi
    linea: "",
    nombre_apellido: "",
    ticket1: "",
    formas_de_pago: [
      {
        forma_pago: "",
        total_a_cancelar: "",
        referencia: "",
      },
    ],
  });

  const [tipoServicio, setTipoServicio] = useState("");
  const [empresas, setEmpresas] = useState([]);
  const { actions, store } = useContext(Context);
  const [filtroEmpresas, setFiltroEmpresas] = useState([]);
  const [destinosPrecios, setDestinosPrecios] = useState([]);
  const [nextTicket, setNextTicket] = useState(null);
  const [datosListines, setDatosListines] = useState();
  const [datosListinesPorPuesto, setDatosListinesPorPuesto] = useState();
  const [datosTaxi, setDatosTaxi] = useState();
  const [taxistas, setTaxistas] = useState();
  const [lineasDisponibles, setLineasDisponibles] = useState([]);
  const [cedulasDisponibles, setCedulasDisponibles] = useState([]);
  const [nombresDisponibles, setNombresDisponibles] = useState([]);
  const [placasDisponibles, setPlacasDisponibles] = useState([]);

  const fetchTaxistas = async () => {
    try {
      const response = await axios.get(
        `${process.env.BACKEND_URL}/api/taxistas`
      );
      setTaxistas(response.data);
      const lineas = [...new Set(response.data.map((item) => item.linea))];
      setLineasDisponibles(lineas);
    } catch (error) {}
  };

  const fetchDatosListines = async () => {
    try {
      const response = await axios.get(
        `${process.env.BACKEND_URL}/api/listines_intravialca/buses`
      );
      setDatosListines(response.data);
    } catch (error) {}
  };
  const fetchDatosListinesPorPuesto = async () => {
    try {
      const response = await axios.get(
        `${process.env.BACKEND_URL}/api/listines_intravialca/por_puesto`
      );
      setDatosListinesPorPuesto(response.data);
    } catch (error) {}
  };
  const fetchDatosTaxi = async () => {
    try {
      const response = await axios.get(
        `${process.env.BACKEND_URL}/api/gettickets_intravialca`
      );
      setDatosTaxi(response.data);
    } catch (error) {}
  };

  useEffect(() => {
    fetchDatosListines();
    fetchDatosListinesPorPuesto();
    fetchDatosTaxi();
    fetchTaxistas();
  }, []);

  const changeListinStatus = async (listinId, currentStatus) => {
    const action = currentStatus === "APROBADO" ? "anular" : "aprobar";
    const newStatus = currentStatus === "APROBADO" ? "ANULADO" : "APROBADO";

    Swal.fire({
      title: `¿Seguro que quieres ${action} este listín?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sí, cambiar estado!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .post(
            `${process.env.BACKEND_URL}/api/change_listin_status/${listinId}`,
            {
              status: newStatus,
            },
            {
              headers: {
                Authorization: `Bearer ${store.token}`,
              },
            }
          )
          .then((response) => {
            fetchDatosListines();
            fetchDatosListinesPorPuesto();
            Swal.fire(
              "Actualizado!",
              `El estado del listín ha sido cambiado a ${newStatus}`,
              "success"
            );
          })
          .catch((error) => {
            console.error("Error al cambiar el estado del listín:", error);
            Swal.fire(
              "Error",
              "No se pudo cambiar el estado del listín",
              "error"
            );
          });
      }
    });
  };

  const displayListines = (data, title) => {
    if (!data || data.length === 0) {
      MySwal.fire({
        title: title,
        html: `<p style="text-align:center; margin:20px;">Cargando...</p>`,
        confirmButtonText: "Cerrar",
      });
    } else {
      const content = data
        .map(
          (item) => `
          <div style="margin: 10px; padding: 10px; border: 1px solid #ccc; justify-content: center; align-items: center; flex-direction: column; display: flex; background-color: ${item.status === "APROBADO" ? "#0080001c" : "#ff00001a"}">
            <p><strong>Número de Listín:</strong> ${item.numero_listin}</p>
            <p><strong>Fecha:</strong> ${item.fecha}</p>
            <p><strong>Empresa:</strong> ${item.empresa}</p>
            <p><strong>Unidad:</strong> ${item.unidad}</p>
            <div style="display: flex; justify-content: space-between; margin-top: 10px; width: 70%">
              <div style="cursor: pointer; font-size: 30px"><i class="fa-solid fa-info"></i></div>
              <div style="cursor: pointer; font-size: 30px" id="changeListinStatus-${item.id}" data-status="${item.status}"><i class="fa-solid fa-ban"></i></div>
              <a href="${item.codigo}" target="_blank" style="color: inherit; cursor: pointer; font-size: 30px">
                <i class="fa-solid fa-file-pdf"></i>
              </a>
            </div>
          </div>
          `
        )
        .join("");

      MySwal.fire({
        title: title,
        html: content,
        confirmButtonText: "Cerrar",
        didRender: () => {
          data.forEach((item) => {
            document
              .getElementById(`changeListinStatus-${item.id}`)
              .addEventListener("click", () =>
                changeListinStatus(item.id, item.status)
              );
          });
        },
      });
    }
  };

  const displayTicket = (data, title) => {
    if (!data || data.length === 0) {
      MySwal.fire({
        title: title,
        html: `<p style="text-align:center; margin:20px;">Cargando...</p>`,
        confirmButtonText: "Cerrar",
      });
    } else {
      const content = data
        .map(
          (item) => `
          <div style="margin: 10px; padding: 10px; border: 1px solid #ccc; justify-content: center; align-items: center; flex-direction: column; display: flex; background-color: ${item.status === "APROBADO" ? "#0080001c" : "#ff00001a"}">
            <p><strong>Número de Ticket:</strong> ${item.ticket1}</p>
            <p><strong>Fecha:</strong> ${item.fecha}</p>
            <p><strong>Taxista:</strong> ${item.nombre_apellido}</p>
            <p><strong>Placa:</strong> ${item.placa}</p>
            <div style="display: flex; justify-content: space-between; margin-top: 10px; width: 70%">
              <div style="cursor: pointer; font-size: 30px"><i class="fa-solid fa-info"></i></div>
              <div style="cursor: pointer; font-size: 30px" id="changeStatus-${item.id_registro}" data-status="${item.status}"><i class="fa-solid fa-ban"></i></div>
              <a href="${item.precio_usd}" target="_blank" style="color: inherit; cursor: pointer; font-size: 30px">
                <i class="fa-solid fa-file-pdf"></i>
              </a>
            </div>
          </div>
          `
        )
        .join("");

      MySwal.fire({
        title: title,
        html: content,
        confirmButtonText: "Cerrar",
        didRender: () => {
          data.forEach((item) => {
            document
              .getElementById(`changeStatus-${item.id_registro}`)
              .addEventListener("click", () =>
                changeTicketStatus(item.id_registro, item.status)
              );
          });
        },
      });
    }
  };

  const changeTicketStatus = async (ticketId, currentStatus) => {
    const action = currentStatus === "APROBADO" ? "anular" : "aprobar";
    const newStatus = currentStatus === "APROBADO" ? "ANULADO" : "APROBADO";

    Swal.fire({
      title: `¿Seguro que quieres ${action} este ticket?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sí, cambiar estado!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .post(
            `${process.env.BACKEND_URL}/api/change_ticket_status/${ticketId}`,
            {
              status: newStatus,
            },
            {
              headers: {
                Authorization: `Bearer ${store.token}`,
              },
            }
          )
          .then((response) => {
            fetchDatosTaxi();
            Swal.fire(
              "Actualizado!",
              `El estado del ticket ha sido cambiado a ${newStatus}`,
              "success"
            );
          })
          .catch((error) => {
            console.error("Error al cambiar el estado del ticket:", error);
            Swal.fire(
              "Error",
              "No se pudo cambiar el estado del ticket",
              "error"
            );
          });
      }
    });
  };

  const fetchNextTicket = async () => {
    try {
      const response = await axios.get(
        process.env.BACKEND_URL + "/api/get-next-ticket4"
      );
      const nextTicket = response.data.next_ticket;
      if (nextTicket) {
        setFormData((prevState) => ({
          ...prevState,
          ticket1: nextTicket.toString(),
        }));
      }
    } catch (error) {
      console.error("Error al obtener el próximo ticket4:", error);
    }
  };
  useEffect(() => {
    fetchNextTicket();
  }, []);

  const fetchNextListinNumber = async (tipoServicio) => {
    try {
      const response = await axios.get(
        `${process.env.BACKEND_URL}/api/next_listin_number`,
        {
          params: { tipo_servicio: tipoServicio },
        }
      );
      if (response.data && response.data.next_numero_listin) {
        setFormData((prevState) => ({
          ...prevState,
          numero_listin: response.data.next_numero_listin.toString(),
        }));
      }
    } catch (error) {
      console.error("Error al obtener el próximo número de listín:", error);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "No se pudo obtener el número de listín, intente de nuevo.",
      });
    }
  };

  useEffect(() => {
    const GetDestinosRutasCortas = async () => {
      try {
        const response = await axios.get(
          `${process.env.BACKEND_URL}/api/precios-destinos-rutas-cortas`
        );
        setDestinosPrecios(response.data); // Suponiendo que la API devuelve un array de empresas
      } catch (error) {
        console.error("Error al cargar los destinos:", error);
      }
    };

    GetDestinosRutasCortas();
  }, []);

  useEffect(() => {
    const cargarEmpresas = async () => {
      try {
        const response = await axios.get(
          `${process.env.BACKEND_URL}/api/empresas`
        );
        setEmpresas(response.data); // Suponiendo que la API devuelve un array de empresas
      } catch (error) {
        console.error("Error al cargar las empresas:", error);
      }
    };

    cargarEmpresas();
  }, []);

  useEffect(() => {
    const empresasFiltradas = empresas.filter(
      (empresa) => empresa.tipo_servicio === formData.tipo_servicio
    );
    setFiltroEmpresas(empresasFiltradas);
  }, [formData.tipo_servicio, empresas]);

  const abrirVentanaEmpresas = () => {
    MySwal.fire({
      title: (
        <h6>
          <i
            className="fa-solid fa-user-tie"
            style={{ marginRight: "7px", fontSize: "20px" }}
          ></i>
          Selecciona una Empresa
        </h6>
      ),
      html: `<input type="text" id="searchInput" class="swal2-input" placeholder="Buscar empresa...">
               <div id="empresaListContainer" style="max-height: 60vh"></div>`, // Contenedor para listar empresas
      showConfirmButton: false,
      showCancelButton: true,
      cancelButtonText: "Cerrar",
      didOpen: () => {
        document
          .getElementById("searchInput")
          .addEventListener("input", function (e) {
            const searchTerm = e.target.value.toLowerCase();
            filtrarEmpresas(searchTerm);
          });
        actualizarListaEmpresas(filtroEmpresas); // Aquí se utilizan las empresas ya filtradas por tipo de servicio
      },
    });
  };
  const abrirVentanaDestinos = () => {
    MySwal.fire({
      title: (
        <h6>
          <i
            className="fa-solid fa-route"
            style={{ marginRight: "7px", fontSize: "20px" }}
          ></i>
          Selecciona un Destino
        </h6>
      ),
      html: (
        <div>
          <input
            type="text"
            className="swal2-input"
            placeholder="Buscar destino..."
            id="searchDestino"
          />
          <div
            id="destinosContainer"
            style={{ maxHeight: "60vh", overflowY: "auto" }}
          >
            {destinosPrecios.map((destino, index) => (
              <div key={index}>
                <button
                  className="btn iasbduiqnwdqwjhndiquwhds"
                  onClick={() => {
                    setFormData((prevState) => ({
                      ...prevState,
                      destino: destino.destino,
                      precio_bs: destino.precio.toString(), // Asegúrate de convertir el precio a string si es necesario
                    }));
                    MySwal.close();
                  }}
                >
                  {destino.destino}
                </button>
                <div className="asdw-w9d84c9w8e4dcd2"></div>
              </div>
            ))}
          </div>
        </div>
      ),
      didOpen: () => {
        document
          .getElementById("searchDestino")
          .addEventListener("input", (e) => {
            const searchTerm = e.target.value.toLowerCase();
            const filteredDestinos = destinosPrecios.filter((destino) =>
              destino.destino.toLowerCase().includes(searchTerm)
            );
            const destinosContainer =
              document.getElementById("destinosContainer");
            destinosContainer.innerHTML = filteredDestinos
              .map(
                (destino, index) =>
                  `<div>
                    <button class="btn iasbduiqnwdqwjhndiquwhds" id="destino-${index}">
                      ${destino.destino}
                    </button>
                    <div class="asdw-w9d84c9w8e4dcd2"></div>
                  </div>`
              )
              .join("");

            // Asigna manejadores de eventos a los nuevos botones
            filteredDestinos.forEach((destino, index) => {
              document.getElementById(`destino-${index}`).onclick = () => {
                setFormData((prevState) => ({
                  ...prevState,
                  destino: destino.destino,
                  precio_bs: destino.precio.toString(),
                }));
                MySwal.close();
              };
            });
          });
      },
      showConfirmButton: false,
      showCancelButton: true,
      cancelButtonText: "Cerrar",
    });
  };

  useEffect(() => {
    const obtenerTasaDeCambio = async () => {
      try {
        const respuesta = await fetch(
          process.env.BACKEND_URL + "/api/tasa_dolar_actual"
        );

        if (
          !respuesta.ok ||
          !respuesta.headers.get("content-type")?.includes("application/json")
        ) {
          console.error("La respuesta del servidor no es un JSON válido.");

          setTimeout(() => {
            obtenerTasaDeCambio();
          }, 1000);

          return;
        }

        const datos = await respuesta.json();

        if (datos.tasa_dolar) {
          // Reemplazar los puntos por comas en el valor de la tasa
          const tasaFormateada = datos.tasa_dolar.replace(/\./g, ",");
          setUsdRate(tasaFormateada);
        } else {
          console.error(
            "La propiedad tasa_dolar no está presente en la respuesta del servidor."
          );

          setTimeout(() => {
            obtenerTasaDeCambio();
          }, 1000);
        }
      } catch (error) {
        console.error("Error al obtener la tasa de cambio:", error);

        setTimeout(() => {
          obtenerTasaDeCambio();
        }, 1000);
      }
    };

    obtenerTasaDeCambio();
  }, [usdRate]);

  const calcularPrecio = () => {
    const destinoSeleccionado = destinosPrecios.find(
      (destino) => destino.destino === formData.destino
    );
    if (destinoSeleccionado && formData.numero_puestos && usdRate) {
      let numAsientos = parseInt(formData.numero_puestos);

      if (numAsientos <= 9) numAsientos = 7;
      else if (numAsientos <= 33) numAsientos = 33;
      else if (numAsientos <= 48) numAsientos = 48;
      else numAsientos = 60;

      const tasaDolar = parseFloat(usdRate.replace(/,/g, "."));
      let precioFinal =
        destinoSeleccionado.precio * numAsientos * 0.03 * tasaDolar;

      if (formData.tipo_servicio == "POR PUESTO") {
        precioFinal = precioFinal + tasaDolar;
      }

      setFormData((prevState) => ({
        ...prevState,
        precio_bs: precioFinal.toFixed(2),
      }));
    }
  };

  useEffect(() => {
    if (formData.destino && formData.numero_puestos && usdRate) {
      calcularPrecio();
    }
  }, [formData.destino, formData.numero_puestos, usdRate]);

  const filtrarDestinos = (searchTerm) => {
    const terminoFiltrado = searchTerm.toLowerCase();
    const destinosFiltrados = destinosPrecios.filter((destino) =>
      destino.destino.toLowerCase().includes(terminoFiltrado)
    );
    actualizarListaDestinos(destinosFiltrados);
  };

  const actualizarListaDestinos = (destinos) => {
    const container = document.getElementById("destinoListContainer");
    container.innerHTML = ""; // Limpiar resultados anteriores
    destinos.forEach((destino, index) => {
      const button = document.createElement("button");
      button.innerText = destino.destino;
      button.classList.add("btn", "iasbduiqnwdqwjhndiquwhds");
      button.onclick = () => {
        setFormData((prevState) => ({
          ...prevState,
          destino: destino.destino,
          precio_bs: destino.precio.toString(), // Asegúrate de convertir el precio a string si es necesario
        }));
        MySwal.close();
      };
      container.appendChild(button);

      if (index < destinos.length - 1) {
        const divider = document.createElement("div");
        divider.classList.add("asdw-w9d84c9w8e4dcd2");
        container.appendChild(divider);
      }
    });
  };

  const resetForm = async () => {
    try {
      const response = await axios.get(
        `${process.env.BACKEND_URL}/api/get-next-listin-number`,
        {
          headers: {
            Authorization: `Bearer ${store.token}`,
          },
        }
      );
      setListinNumber(response.data.next_listin_number.toString()); // Asumiendo que la API devuelve el número directamente
      // Aquí puedes también resetear otros estados del formulario si es necesario
    } catch (error) {
      console.error("Error al obtener el próximo número de listín:", error);
    }
  };

  // Se modifica para hacer la petición después de seleccionar el tipo de servicio
  const mostrarOpcionesTipoServicio = () => {
    resetFormData();
    MySwal.fire({
      title: (
        <h6>
          <i
            className="fa-solid fa-route"
            style={{ marginRight: "7px", fontSize: "20px" }}
          ></i>
          Tipo de Servicio
        </h6>
      ),
      html: (
        <div>
          <button
            className="btn iasbduiqnwdqwjhndiquwhds"
            onClick={() => seleccionarTipoServicio("BUSES")}
          >
            BUSES
          </button>
          <div className="asdw-w9d84c9w8e4dcd2"></div>
          <button
            className="btn iasbduiqnwdqwjhndiquwhds"
            onClick={() => seleccionarTipoServicio("POR PUESTO")}
          >
            POR PUESTO
          </button>
        </div>
      ),
      showConfirmButton: false,
      showCancelButton: true,
      cancelButtonText: "Cerrar",
    });
  };

  const handlePreview = async () => {
    // Genera el blob del documento
    const blob = await pdf(<MyDocument formData={formData} />).toBlob();

    // Crea un URL del blob
    const url = URL.createObjectURL(blob);

    // Abre el PDF en una nueva pestaña
    window.open(url, "_blank");
  };

  const seleccionarTipoServicio = (tipo) => {
    resetFormData(); // Primero resetea el formulario
    setFormData((currentFormData) => ({
      ...currentFormData,
      tipo_servicio: tipo, // Luego establece el tipo de servicio
    }));
    Swal.close();
  };

  useEffect(
    () => {
      if (formData.tipo_servicio) {
        fetchNextListinNumber(formData.tipo_servicio);
      }
    },
    [formData.tipo_servicio],
    [formData.empresa]
  );

  // Función ajustada para cargar y mostrar opciones de empresa sin necesidad de un parámetro
  useEffect(() => {
    const empresasFiltradas = empresas.filter(
      (empresa) => empresa.tipo_servicio === formData.tipo_servicio
    );
    setFiltroEmpresas(empresasFiltradas);
  }, [formData.tipo_servicio, empresas]); // Escuchar cambios en tipo_servicio y en la lista de empresas

  // Simplificada para solo abrir la ventana emergente
  const mostrarOpcionesEmpresa = () => {
    if (!formData.tipo_servicio) {
      Swal.fire("Por favor, selecciona un tipo de servicio primero.");
      return;
    }
    abrirVentanaEmpresas();
    fetchNextListinNumber(formData.tipo_servicio);
  };

  // Función para abrir la ventana emergente con las empresas

  const filtrarEmpresas = (searchTerm) => {
    const resultadoFiltro = filtroEmpresas.filter((empresa) =>
      empresa.empresa.toLowerCase().includes(searchTerm)
    );
    actualizarListaEmpresas(resultadoFiltro);
  };

  const actualizarListaEmpresas = (filtroEmpresas) => {
    const container = document.getElementById("empresaListContainer");
    container.innerHTML = ""; // Limpiar resultados anteriores
    filtroEmpresas.forEach((empresa) => {
      const button = document.createElement("button");
      button.innerText = empresa.empresa;
      button.classList.add("btn", "iasbduiqnwdqwjhndiquwhds");
      button.onclick = () => {
        setFormData({ ...formData, empresa: empresa.empresa });
        MySwal.close();
      };
      container.appendChild(button);

      const divider = document.createElement("div");
      divider.classList.add("asdw-w9d84c9w8e4dcd2");
      container.appendChild(divider);
    });
  };

  const handlePaymentChange = (index, event) => {
    const updatedPayments = [...formData.formas_de_pago];
    updatedPayments[index][event.target.name] = event.target.value;
    setFormData({ ...formData, formas_de_pago: updatedPayments });
  };

  const addPaymentForm = () => {
    setFormData({
      ...formData,
      formas_de_pago: [
        ...formData.formas_de_pago,
        { forma_pago: "", total_a_cancelar: "", referencia: "" },
      ],
    });
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    // Cuando cambie el destino, actualizamos el precio automáticamente
    if (name === "destino") {
      // Busca el precio correspondiente al destino seleccionado
      const destinoSeleccionado = destinosPrecios.find(
        (destino) => destino.Destino === value
      );
      // Si encontramos un destino, actualizamos el precio_bs en el formulario
      if (destinoSeleccionado) {
        const precioRedondeado = new Big(destinoSeleccionado.precio);
        setFormData((prevState) => ({
          ...prevState,
          [name]: value,
          precio_bs: precioRedondeado
            .times(numAsientos)
            .times(tasaDolar)
            .toFixed(2),
        }));
      } else {
        // Si no hay un destino seleccionado (o no se encuentra el precio), solo actualiza el campo cambiado
        setFormData((prevState) => ({
          ...prevState,
          [name]: value,
        }));
      }
    } else {
      // Para cualquier otro cambio en el formulario, solo actualiza el campo cambiado
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const handleChange2 = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });

    // Llamar a actualizarPrecioBs si se modifican usdRate o routeType
    if (name === "usdRate" || name === "routeType") {
      actualizarPrecioBs();
    }

    if (name === "linea") {
      const cedulas = taxistas
        .filter((taxista) => taxista.linea === value)
        .map((taxista) => taxista.ci);
      setCedulasDisponibles([...new Set(cedulas)]);
      setNombresDisponibles([]);
      setPlacasDisponibles([]);
    } else if (name === "cedula") {
      const datosFiltrados = taxistas.filter((taxista) => taxista.ci === value);
      const nombres = datosFiltrados.map((taxista) => taxista.nombre_apellido);
      const placas = datosFiltrados.map((taxista) => taxista.placa);
      setNombresDisponibles(nombres);
      setPlacasDisponibles(placas);
    }
  };

  const resetFormData = () => {
    const now = new Date();
    const formattedDate = formatDate(now); // Asume que ya tienes esta función
    const formattedDateTime = formatDateTime(now); // Y esta también
    setFormData((prevFormData) => ({
      ...prevFormData,
      fecha: prevFormData.fecha || formattedDate,
      fecha_hora_registros:
        prevFormData.fecha_hora_registros || formattedDateTime,
      placa: "",
      numero_listin: "",
      condicion: "",
      empresa: "",
      codigo: "",
      unidad: "",
      tipo_servicio: "",
      numero_puestos: "",
      destino: "",
      cedula: "",
      conductor: "",
      precio_bs: "",
      precio_usd: "",
      total_general_cancelado: "",
      status: "APROBADO",
      registrado_por: "",
      linea: "",
      nombre_apellido: "",
      formas_de_pago: [],
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault(); // Prevenir el comportamiento predeterminado del formulario

    if (routeType == "LISTIN") {
      await fetchNextListinNumber(formData.tipo_servicio);
    }

    // Genera el blob del documento PDF
    const blob = await pdf(<MyDocument formData={formData} />).toBlob();

    // Prepara el formData para la subida del archivo a Cloudinary
    const uploadData = new FormData();
    uploadData.append("file", blob);
    uploadData.append("upload_preset", "ml_default");
    uploadData.append("NumeroListin", formData.numero_listin);
    uploadData.append("TipoServicio", formData.tipo_servicio);
    uploadData.append("RouteType", routeType);
    uploadData.append("TicketNumero", formData.ticket1);

    // Calcula la suma de todos los pagos
    const pagos = formData.formas_de_pago || [];
    const sumaPagos = pagos.reduce(
      (acc, current) => acc + (parseFloat(current.total_a_cancelar) || 0),
      0
    );

    // Verifica si la suma de pagos coincide con el total en BS
    if (sumaPagos !== parseFloat(formData.precio_bs)) {
      MySwal.fire({
        title: "Error",
        text: "La suma de los pagos no coincide con el total a pagar en BS.",
        icon: "error",
      });
      return;
    }
    toggleLoading(true);

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${store.token}`, // Incluye el token aquí
      },
    };

    // Envía el archivo a Cloudinary
    try {
      const uploadResponse = await axios.post(
        process.env.BACKEND_URL + "/api/upload_pdf2",
        uploadData,
        {
          headers: {
            "Content-Type": undefined, // Esto permite que el navegador establezca el Content-Type
          },
        }
      );
      const pdfUrl = uploadResponse.data.cloudinaryUrl;

      // Envía el formulario principal
      const url =
        routeType === "Listin" ? "/api/listines_rc" : "/api/ticket_taxi";
      const response = await axios.post(
        process.env.BACKEND_URL + url,
        {
          ...formData,
          codigo: pdfUrl,
          total_general_cancelado: sumaPagos,
        },
        config
      );

      // Obtiene el ID del registro creado para usarlo en las formas de pago
      const registroId =
        routeType === "Listin" ? response.data.id : response.data.id_registro;

      // Envía cada forma de pago individualmente
      for (const pago of formData.formas_de_pago) {
        await axios.post(process.env.BACKEND_URL + "/api/formas_de_pago", {
          tipo_registro: routeType.toLowerCase(),
          id_listin: routeType === "Listin" ? registroId : undefined,
          id_taxi: routeType === "Taxi" ? registroId : undefined,
          forma_pago: pago.forma_pago,
          total_a_cancelar: parseFloat(pago.total_a_cancelar),
          referencia: pago.referencia,
        });
      }

      MySwal.fire({
        title: "¡Éxito!",
        text: "Datos enviados correctamente",
        icon: "success",
      });
    } catch (error) {
      console.error(`Hubo un error al enviar los datos: ${error}`);
      MySwal.fire({
        title: "Error",
        text: "Hubo un error al enviar el formulario.",
        icon: "error",
      });
    } finally {
      resetFormData();
      toggleLoading(false);
      fetchDatosListines();
      fetchDatosListinesPorPuesto();
      fetchDatosTaxi();
      actualizarPrecioBs();
      fetchNextTicket();
    }
  };

  const sumaPagos = formData.formas_de_pago.reduce(
    (acc, current) => acc + (parseFloat(current.total_a_cancelar) || 0),
    0
  );

  function actualizarPrecioBs() {
    if (routeType === "Taxi" && usdRate) {
      const nuevoPrecioBs = parseFloat(usdRate.replace(/,/g, ".")) * 4;
      setFormData((prevState) => ({
        ...prevState,
        precio_bs: nuevoPrecioBs.toFixed(2),
      }));
    }
  }

  useEffect(() => {
    actualizarPrecioBs();
  }, [usdRate, routeType]);

  const formatDate = (date) => {
    let d = new Date(date),
      day = "" + d.getDate(),
      month = "" + (d.getMonth() + 1),
      year = d.getFullYear();

    if (day.length < 2) day = "0" + day;
    if (month.length < 2) month = "0" + month;

    return [day, month, year].join("/");
  };

  // Esta función formatea una fecha dada a "dd/mm/aaaa hh:mm:ss".
  const formatDateTime = (date) => {
    let d = new Date(date),
      day = "" + d.getDate(),
      month = "" + (d.getMonth() + 1),
      year = d.getFullYear(),
      hour = "" + d.getHours(),
      minute = "" + d.getMinutes(),
      second = "" + d.getSeconds();

    if (day.length < 2) day = "0" + day;
    if (month.length < 2) month = "0" + month;
    if (hour.length < 2) hour = "0" + hour;
    if (minute.length < 2) minute = "0" + minute;
    if (second.length < 2) second = "0" + second;

    return (
      [day, month, year].join("/") + " " + [hour, minute, second].join(":")
    );
  };

  useEffect(() => {
    // Obtén la fecha y hora actuales
    const now = new Date();

    // Formatea la fecha para el input de tipo "date"
    const formattedDate = [
      now.getFullYear(),
      ("0" + (now.getMonth() + 1)).slice(-2),
      ("0" + now.getDate()).slice(-2),
    ].join("-");

    // Formatea la fecha y hora para el input de tipo "datetime-local"
    const formattedDateTime = [
      formattedDate,
      ("0" + now.getHours()).slice(-2) +
        ":" +
        ("0" + now.getMinutes()).slice(-2),
    ].join("T");

    // Establece los valores iniciales de los campos "fecha" y "fecha_hora_registros"
    setFormData((prevFormData) => ({
      ...prevFormData,
      fecha: formattedDate, // Asegúrate de que este campo corresponde a un input de tipo "date"
      fecha_hora_registros: formattedDateTime, // Este es para un input de tipo "datetime-local", si lo usas
    }));
  }, []);

  // Calcular cuánto falta para alcanzar el total especificado en "total_cancelar_bs"
  const totalBs = parseFloat(formData.precio_bs) || 0; // Asegúrate de tener un valor numérico
  const restanteBs = totalBs - sumaPagos;

  const calcularRestanteDolares = () => {
    if (!usdRate) return;

    // Asegurarse de que usdRate es un número, reemplazando comas y convirtiéndolo a float
    const tasaDolar = parseFloat(usdRate.replace(/,/g, "."));

    // Calcula restante en dólares solo si tasaDolar es un número válido
    if (!isNaN(tasaDolar) && !isNaN(restanteBs)) {
      const restanteDolares = restanteBs / tasaDolar;
      return restanteDolares.toFixed(2); // Formatea a dos decimales
    }

    return "Error en los datos"; // Manejo de error si tasaDolar no es un número
  };

  const formatIsoDateTime = (isoDateTime) => {
    const dateTime = new Date(isoDateTime);
    const day = dateTime.getDate().toString().padStart(2, "0");
    const month = (dateTime.getMonth() + 1).toString().padStart(2, "0");
    const year = dateTime.getFullYear();
    const hours = dateTime.getHours().toString().padStart(2, "0");
    const minutes = dateTime.getMinutes().toString().padStart(2, "0");

    return `${day}/${month}/${year} ${hours}:${minutes}`;
  };

  const MyDocument = ({ formData }) => {
    return (
      <Document>
        <Page style={styles.page} size="A4">
          {/* Encabezado con dos logos */}
          <View style={styles.header}>
            <Image
              src="https://res.cloudinary.com/dirpdlfbu/image/upload/v1701880341/logo/cmyemflfryypewryq6ux.jpg"
              style={styles.logo}
            />
            <Image
              src="https://res.cloudinary.com/dirpdlfbu/image/upload/v1711324140/aeezebl5y4pzhcmgm2da.jpg"
              style={styles.logo2}
            />
          </View>
          <View style={styles.header}>
            <Text style={styles.letter2}>TERMINAL LA BANDERA</Text>
            <View>
              <Text
                style={styles.letter2}
              >{`LISTIN Nº ${formData.numero_listin}`}</Text>

              <Text style={styles.letter2}>Rutas Interurbanas</Text>
            </View>
          </View>

          {formData && (
            <View
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
                borderWidth: 1,
                borderColor: "#000",
              }}
            >
              {/* Primera columna */}
              <View>
                <Text
                  style={styles.letter}
                >{`Línea: ${formData.empresa}`}</Text>
                <Text
                  style={styles.letter}
                >{`Destino Final: ${formData.destino}`}</Text>
                <Text
                  style={styles.letter}
                >{`Número de Unidad: ${formData.unidad}`}</Text>
                <Text
                  style={styles.letter}
                >{`Número de Placa: ${formData.placa}`}</Text>
                <Text
                  style={styles.letter}
                >{`Número de Puestos: ${formData.numero_puestos}`}</Text>
              </View>

              {/* Segunda columna */}
              <View>
                {/* <Text
                  style={styles.letter}
                >{`Plataforma: ${formData.platform}`}</Text> */}
                <Text
                  style={styles.letter}
                >{`Fecha y hora: ${fechaYHora}`}</Text>
                <Text
                  style={styles.letter}
                >{`Servicio: ${formData.tipo_servicio}`}</Text>
                <Text
                  style={styles.letter}
                >{`Conductor: ${formData.conductor}`}</Text>
                <Text
                  style={styles.letter}
                >{`Cédula: ${formData.cedula}`}</Text>
                <Text
                  style={styles.letter}
                >{`Precio: ${formData.precio_bs}Bs`}</Text>
              </View>

              {/* Tercera columna */}
            </View>
          )}
          <View style={{ marginTop: 10 }}></View>
          <View style={tableStyles.table}>
            <View style={tableStyles.tableRow}>
              <View style={tableStyles.tableColHeaderNumber}>
                <Text style={tableStyles.tableCellHeader}>N°</Text>
              </View>
              <View style={tableStyles.tableColHeaderOther}>
                <Text style={tableStyles.tableCellHeader}>
                  Nombre y Apellido
                </Text>
              </View>
              <View style={tableStyles.tableColHeaderOther}>
                <Text style={tableStyles.tableCellHeader}>
                  Cédula Identidad
                </Text>
              </View>
              <View style={tableStyles.tableColHeaderNumber}>
                <Text style={tableStyles.tableCellHeader}>N°</Text>
              </View>
              <View style={tableStyles.tableColHeaderOther}>
                <Text style={tableStyles.tableCellHeader}>
                  Nombre y Apellido
                </Text>
              </View>
              <View style={tableStyles.tableColHeaderOther}>
                <Text style={tableStyles.tableCellHeader}>
                  Cédula Identidad
                </Text>
              </View>
            </View>
            {[...Array(30)].map(
              (
                _,
                index // Para crear 30 filas
              ) => (
                <View key={index} style={tableStyles.tableRow}>
                  <View style={tableStyles.tableColNumber}>
                    <Text style={tableStyles.tableCell}>{index * 2 + 1}</Text>
                  </View>
                  <View style={tableStyles.tableColOther}>
                    <Text style={tableStyles.tableCell}></Text>
                  </View>
                  <View style={tableStyles.tableColOther}>
                    <Text style={tableStyles.tableCell}></Text>
                  </View>
                  <View style={tableStyles.tableColNumber}>
                    <Text style={tableStyles.tableCell}>{index * 2 + 2}</Text>
                  </View>
                  <View style={tableStyles.tableColOther}>
                    <Text style={tableStyles.tableCell}></Text>
                  </View>
                  <View style={tableStyles.tableColOther}>
                    <Text style={tableStyles.tableCell}></Text>
                  </View>
                </View>
              )
            )}
          </View>

          <Text style={styles.bottomText2}>ORIGINAL: INTRAVIALCA</Text>

          <Text style={styles.bottomText}>
            El valor del listín es el 3% de la tarifa pagada por pasajero, de
            acuerdo a la publicación del Ministerio del Poder Popular para el
            Transporte, en la Gaceta Oficial de la República Bolivariana de
            Venezuela N° 42.462 de fecha 14/09/2022
          </Text>
        </Page>
        <Page style={styles.page} size="A4">
          {/* Encabezado con dos logos */}
          <View style={styles.header}>
            <Image
              src="https://res.cloudinary.com/dirpdlfbu/image/upload/v1701880341/logo/cmyemflfryypewryq6ux.jpg"
              style={styles.logo}
            />
            <Image
              src="https://res.cloudinary.com/dirpdlfbu/image/upload/v1711324140/aeezebl5y4pzhcmgm2da.jpg"
              style={styles.logo2}
            />
          </View>
          <View style={styles.header}>
            <Text style={styles.letter2}>TERMINAL LA BANDERA</Text>
            <View>
              <Text
                style={styles.letter2}
              >{`LISTIN Nº ${formData.numero_listin}`}</Text>

              <Text style={styles.letter2}>Rutas Interurbanas</Text>
            </View>
          </View>

          {formData && (
            <View
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
                borderWidth: 1,
                borderColor: "#000",
              }}
            >
              {/* Primera columna */}
              <View>
                <Text
                  style={styles.letter}
                >{`Línea: ${formData.empresa}`}</Text>
                <Text
                  style={styles.letter}
                >{`Destino Final: ${formData.destino}`}</Text>
                <Text
                  style={styles.letter}
                >{`Número de Unidad: ${formData.unidad}`}</Text>
                <Text
                  style={styles.letter}
                >{`Número de Placa: ${formData.placa}`}</Text>
                <Text
                  style={styles.letter}
                >{`Número de Puestos: ${formData.numero_puestos}`}</Text>
              </View>

              {/* Segunda columna */}
              <View>
                {/* <Text
                  style={styles.letter}
                >{`Plataforma: ${formData.platform}`}</Text> */}
                <Text
                  style={styles.letter}
                >{`Fecha y hora: ${fechaYHora}`}</Text>
                <Text
                  style={styles.letter}
                >{`Servicio: ${formData.tipo_servicio}`}</Text>
                <Text
                  style={styles.letter}
                >{`Conductor: ${formData.conductor}`}</Text>
                <Text
                  style={styles.letter}
                >{`Cédula: ${formData.cedula}`}</Text>
                <Text
                  style={styles.letter}
                >{`Precio: ${formData.precio_bs}Bs`}</Text>
              </View>

              {/* Tercera columna */}
            </View>
          )}
          <View style={{ marginTop: 10 }}></View>
          <View style={tableStyles.table}>
            <View style={tableStyles.tableRow}>
              <View style={tableStyles.tableColHeaderNumber}>
                <Text style={tableStyles.tableCellHeader}>N°</Text>
              </View>
              <View style={tableStyles.tableColHeaderOther}>
                <Text style={tableStyles.tableCellHeader}>
                  Nombre y Apellido
                </Text>
              </View>
              <View style={tableStyles.tableColHeaderOther}>
                <Text style={tableStyles.tableCellHeader}>
                  Cédula Identidad
                </Text>
              </View>
              <View style={tableStyles.tableColHeaderNumber}>
                <Text style={tableStyles.tableCellHeader}>N°</Text>
              </View>
              <View style={tableStyles.tableColHeaderOther}>
                <Text style={tableStyles.tableCellHeader}>
                  Nombre y Apellido
                </Text>
              </View>
              <View style={tableStyles.tableColHeaderOther}>
                <Text style={tableStyles.tableCellHeader}>
                  Cédula Identidad
                </Text>
              </View>
            </View>
            {[...Array(30)].map(
              (
                _,
                index // Para crear 30 filas
              ) => (
                <View key={index} style={tableStyles.tableRow}>
                  <View style={tableStyles.tableColNumber}>
                    <Text style={tableStyles.tableCell}>{index * 2 + 1}</Text>
                  </View>
                  <View style={tableStyles.tableColOther}>
                    <Text style={tableStyles.tableCell}></Text>
                  </View>
                  <View style={tableStyles.tableColOther}>
                    <Text style={tableStyles.tableCell}></Text>
                  </View>
                  <View style={tableStyles.tableColNumber}>
                    <Text style={tableStyles.tableCell}>{index * 2 + 2}</Text>
                  </View>
                  <View style={tableStyles.tableColOther}>
                    <Text style={tableStyles.tableCell}></Text>
                  </View>
                  <View style={tableStyles.tableColOther}>
                    <Text style={tableStyles.tableCell}></Text>
                  </View>
                </View>
              )
            )}
          </View>

          <Text style={styles.bottomText2}>COPIA: LÍNEA DE TRANSPORTE</Text>

          <Text style={styles.bottomText}>
            El valor del listín es el 3% de la tarifa pagada por pasajero, de
            acuerdo a la publicación del Ministerio del Poder Popular para el
            Transporte, en la Gaceta Oficial de la República Bolivariana de
            Venezuela N° 42.462 de fecha 14/09/2022
          </Text>
        </Page>
        <Page style={styles.page} size="A4">
          {/* Encabezado con dos logos */}
          <View style={styles.header}>
            <Image
              src="https://res.cloudinary.com/dirpdlfbu/image/upload/v1701880341/logo/cmyemflfryypewryq6ux.jpg"
              style={styles.logo}
            />
            <Image
              src="https://res.cloudinary.com/dirpdlfbu/image/upload/v1711324140/aeezebl5y4pzhcmgm2da.jpg"
              style={styles.logo2}
            />
          </View>
          <View style={styles.header}>
            <Text style={styles.letter2}>TERMINAL LA BANDERA</Text>
            <View>
              <Text
                style={styles.letter2}
              >{`LISTIN Nº ${formData.numero_listin}`}</Text>

              <Text style={styles.letter2}>Rutas Interurbanas</Text>
            </View>
          </View>

          {formData && (
            <View
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
                borderWidth: 1,
                borderColor: "#000",
              }}
            >
              {/* Primera columna */}
              <View>
                <Text
                  style={styles.letter}
                >{`Línea: ${formData.empresa}`}</Text>
                <Text
                  style={styles.letter}
                >{`Destino Final: ${formData.destino}`}</Text>
                <Text
                  style={styles.letter}
                >{`Número de Unidad: ${formData.unidad}`}</Text>
                <Text
                  style={styles.letter}
                >{`Número de Placa: ${formData.placa}`}</Text>
                <Text
                  style={styles.letter}
                >{`Número de Puestos: ${formData.numero_puestos}`}</Text>
              </View>

              {/* Segunda columna */}
              <View>
                {/* <Text
                  style={styles.letter}
                >{`Plataforma: ${formData.platform}`}</Text> */}
                <Text
                  style={styles.letter}
                >{`Fecha y hora: ${fechaYHora}`}</Text>
                <Text
                  style={styles.letter}
                >{`Servicio: ${formData.tipo_servicio}`}</Text>
                <Text
                  style={styles.letter}
                >{`Conductor: ${formData.conductor}`}</Text>
                <Text
                  style={styles.letter}
                >{`Cédula: ${formData.cedula}`}</Text>
                <Text
                  style={styles.letter}
                >{`Precio: ${formData.precio_bs}Bs`}</Text>
              </View>

              {/* Tercera columna */}
            </View>
          )}
          <View style={{ marginTop: 10 }}></View>
          <View style={tableStyles.table}>
            <View style={tableStyles.tableRow}>
              <View style={tableStyles.tableColHeaderNumber}>
                <Text style={tableStyles.tableCellHeader}>N°</Text>
              </View>
              <View style={tableStyles.tableColHeaderOther}>
                <Text style={tableStyles.tableCellHeader}>
                  Nombre y Apellido
                </Text>
              </View>
              <View style={tableStyles.tableColHeaderOther}>
                <Text style={tableStyles.tableCellHeader}>
                  Cédula Identidad
                </Text>
              </View>
              <View style={tableStyles.tableColHeaderNumber}>
                <Text style={tableStyles.tableCellHeader}>N°</Text>
              </View>
              <View style={tableStyles.tableColHeaderOther}>
                <Text style={tableStyles.tableCellHeader}>
                  Nombre y Apellido
                </Text>
              </View>
              <View style={tableStyles.tableColHeaderOther}>
                <Text style={tableStyles.tableCellHeader}>
                  Cédula Identidad
                </Text>
              </View>
            </View>
            {[...Array(30)].map(
              (
                _,
                index // Para crear 30 filas
              ) => (
                <View key={index} style={tableStyles.tableRow}>
                  <View style={tableStyles.tableColNumber}>
                    <Text style={tableStyles.tableCell}>{index * 2 + 1}</Text>
                  </View>
                  <View style={tableStyles.tableColOther}>
                    <Text style={tableStyles.tableCell}></Text>
                  </View>
                  <View style={tableStyles.tableColOther}>
                    <Text style={tableStyles.tableCell}></Text>
                  </View>
                  <View style={tableStyles.tableColNumber}>
                    <Text style={tableStyles.tableCell}>{index * 2 + 2}</Text>
                  </View>
                  <View style={tableStyles.tableColOther}>
                    <Text style={tableStyles.tableCell}></Text>
                  </View>
                  <View style={tableStyles.tableColOther}>
                    <Text style={tableStyles.tableCell}></Text>
                  </View>
                </View>
              )
            )}
          </View>

          <Text style={styles.bottomText2}>COPIA: INTT</Text>

          <Text style={styles.bottomText}>
            El valor del listín es el 3% de la tarifa pagada por pasajero, de
            acuerdo a la publicación del Ministerio del Poder Popular para el
            Transporte, en la Gaceta Oficial de la República Bolivariana de
            Venezuela N° 42.462 de fecha 14/09/2022
          </Text>
        </Page>
      </Document>
    );
  };

  const switchRouteType = (newRouteType) => {
    // Conserva los valores actuales de fecha y hora
    const currentFecha = formData.fecha;
    const currentFechaHoraRegistros = formData.fecha_hora_registros;

    setRouteType(newRouteType);

    setFormData((currentFormData) => ({
      // Aquí, restablece o actualiza los campos necesarios mientras conservas los valores de fecha
      ...currentFormData,
      fecha: currentFecha,
      fecha_hora_registros: currentFechaHoraRegistros,
      // Restablece o actualiza otros campos según sea necesario...
    }));
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      // Calcula la fecha y hora actuales cada vez que se ejecuta el intervalo.
      const now = new Date();

      // Formatea la fecha para el input de tipo "date".
      const formattedDate = [
        now.getFullYear(),
        ("0" + (now.getMonth() + 1)).slice(-2),
        ("0" + now.getDate()).slice(-2),
      ].join("-");

      // Formatea la fecha y hora para el input de tipo "datetime-local".
      const formattedDateTime = [
        formattedDate,
        ("0" + now.getHours()).slice(-2) +
          ":" +
          ("0" + now.getMinutes()).slice(-2),
      ].join("T");

      // Actualiza el estado con las nuevas fechas calculadas.
      setFormData((prevFormData) => ({
        ...prevFormData,
        fecha: formattedDate,
        fecha_hora_registros: formattedDateTime,
      }));
    }, 60000); // Actualiza cada minuto (60000 milisegundos).

    // Limpia el intervalo al desmontar el componente.
    return () => clearInterval(intervalId);
  }, []);

  return (
    <div
      className="container-fluid contarinerGeneralUserHomejs "
      style={{ paddingTop: "30px", paddingBottom: "70px" }}
    >
      <div className="container-fluid contaosijdasd-qw89d77asd223"></div>
      <h2
        className="asjdansdas-d4as6489text w-100"
        style={{ zIndex: "15", marginTop: "-17vh" }}
      >
        Registro de Listines
      </h2>
      <div className="container asklnduiashndaijsnxansxsaas">
        <form
          className="d-flex sd98f4e8wf4cwe987cwe984cwe"
          onSubmit={handleSubmit}
        >
          <div className="aoshduiahsdkasnmdasdas">
            <div className="input-group-login2">
              <input
                className="inputDeLogin"
                type="text"
                name="numero_listin"
                value={formData.numero_listin}
                onChange={handleChange}
                required
                readOnly
              />
              <label>Número Listín</label>
            </div>
            <div className="input-group-login2">
              <input
                className="inputDeLogin"
                type="date"
                name="fecha"
                value={formData.fecha}
                onChange={handleChange}
                required
                readOnly
                style={{ cursor: "pointer" }}
              />
              <label>Fecha</label>
            </div>
            <div className="input-group-login2">
              <input
                className="inputDeLogin"
                type="text"
                name="tipo_servicio"
                value={formData.tipo_servicio}
                onClick={mostrarOpcionesTipoServicio}
                onChange={handleChange}
                required
                readOnly
                style={{ cursor: "pointer" }}
              />
              <label>Activados (Preview Facil de localizar)</label>
            </div>
          </div>

          <div className="aoshduiahsdkasnmdasdas">
            <div className="input-group-login2">
              <input
                className="inputDeLogin"
                type="text"
                name="unidad"
                value={formData.unidad}
                onChange={handleChange}
                required
              />
              <label>Empresa</label>
            </div>
            <div className="input-group-login2">
              <input
                className="inputDeLogin"
                type="text"
                name="placa"
                value={formData.placa}
                onChange={handleChange}
                required
                style={{ textTransform: "uppercase" }}
              />
              <label>Destino Final</label>
            </div>
            <div className="input-group-login2">
              <input
                className="inputDeLogin"
                type="text"
                name="numero_puestos"
                value={formData.numero_puestos}
                onChange={handleChange}
                required
              />
              <label>N° Unidad</label>
            </div>
            <div className="input-group-login2">
              <input
                className="inputDeLogin"
                type="text"
                name="destino"
                value={formData.destino}
                readOnly // Haz el input de solo lectura para evitar edición manual
                onClick={abrirVentanaDestinos} // Abre la ventana emergente al hacer clic
                style={{ cursor: "pointer" }}
              />
              <label>Placa</label>
            </div>
          </div>

          <div className="aoshduiahsdkasnmdasdas">
            <div className="input-group-login2">
              <input
                className="inputDeLogin"
                type="text"
                name="unidad"
                value={formData.unidad}
                onChange={handleChange}
                required
              />
              <label>N° puestos</label>
            </div>
            <div className="input-group-login2">
              <input
                className="inputDeLogin"
                type="text"
                name="placa"
                value={formData.placa}
                onChange={handleChange}
                required
                style={{ textTransform: "uppercase" }}
              />
              <label>Anden</label>
            </div>
            <div className="input-group-login2">
              <input
                className="inputDeLogin"
                type="text"
                name="numero_puestos"
                value={formData.numero_puestos}
                onChange={handleChange}
                required
              />
              <label>Algo</label>
            </div>
          </div>

          <div className="aoshduiahsdkasnmdasdas">
            <div className="input-group-login2">
              <input
                className="inputDeLogin"
                type="text"
                name="cedula"
                value={formData.cedula}
                onChange={handleChange}
                required
              />
              <label>Conductor 1</label>
            </div>
            <div className="input-group-login2">
              <input
                className="inputDeLogin"
                type="text"
                name="conductor"
                value={formData.conductor}
                onChange={handleChange}
                required
              />
              <label>Cedula 1</label>
            </div>
            <div className="input-group-login2">
              <input
                className="inputDeLogin"
                type="text"
                name="precio_bs"
                value={formData.precio_bs}
                onChange={handleChange}
                required
                readOnly
                style={{ cursor: "auto" }}
              />
              <label>Conductor 2</label>
            </div>
            <div className="input-group-login2">
              <input
                className="inputDeLogin"
                type="text"
                name="precio_bs"
                value={formData.precio_bs}
                onChange={handleChange}
                required
                readOnly
                style={{ cursor: "auto" }}
              />
              <label>Cedula 2</label>
            </div>
          </div>

          <div className="w-100 asd9as-da9s84dx9a8qdw-qdw asdqw98d4qv895y4n9r84bvr98ebtnbtn">
            <button
              type="submit"
              className="btn buttonCargadeDatosDeAddDriver"
              style={{ marginBottom: "30px" }}
            >
              <div className="d-flex">
                <p style={{ marginBottom: 0 }}>Guardar Datos</p>
                <i className="fa-solid fa-arrow-right flechaHomePresentation2"></i>
              </div>
            </button>
          </div>
        </form>

        <div className="w-100 asd9as-da9s84dx9a8qdw-qdw asdqw98d4qv895y4n9r84bvr98ebtnbtn">
          <button className="btn secondary ioyu98jty7tr98v4er98f7er89w4d">
            <i
              className="fa-solid fa-car"
              style={{ marginRight: "7px", fontSize: "20px" }}
            ></i>
            Ver Listines
          </button>
        </div>
      </div>
    </div>
  );
};
