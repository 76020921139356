import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Page,
  Text,
  View,
  Document,
  PDFViewer,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";

// Estilos para el documento PDF, el viewer y el modal
const styles = StyleSheet.create({
  page: { flexDirection: "column", backgroundColor: "#FFF" },
  section: { margin: 10, padding: 10, flexGrow: 1 },
  section2: {
    margin: 1,
    padding: 1,
    flexGrow: 1,
    textAlign: "center",
    width: 400,
  },
  section3: { margin: 1, padding: 1, flexGrow: 1, maxWidth: 110 },
  viewer: { width: window.innerWidth - 200, height: window.innerHeight - 200 },
  modalOverlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 999999999,
  },
  page: {
    padding: 20, // Ajusta el valor del margen según tus necesidades
  },
  letter: {
    fontSize: 8,
    padding: 2,
  },
  letter99: {
    fontSize: 11,
    padding: 2,
    marginTop: 7,
  },
  header: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    textAlign: "center",
  },
  closeButton: {
    position: "absolute",
    top: 20,
    right: 20,
    padding: "10px 20px",
    background: "#f00",
    color: "#fff",
    border: "none",
    cursor: "pointer",
  },
  logo: {
    width: 120, // Ajusta el tamaño según tus necesidades
    height: 40, // Ajusta el tamaño según tus necesidades
  },
  logo2: {
    width: 130,
    height: 25,
  },
  sectionrow2: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
  },
  container: {
    flexDirection: "row",
    borderTopColor: "#000",
    borderTopWidth: 1,
    borderLeftColor: "#000",
    borderLeftWidth: 1,
    borderBottomColor: "#000",
    borderBottomWidth: 1,
    alignItems: "center",
    height: 30,
    margin: 0,
    padding: 0,
    justifyContent: "center",
    textAlign: "center",
  },
  container1: {
    flexDirection: "row",
    borderTopColor: "#000",
    borderTopWidth: 1,
    borderLeftColor: "#000",
    borderLeftWidth: 1,
    borderBottomColor: "#000",
    borderBottomWidth: 1,
    alignItems: "center",
    height: 30,
    margin: 0,
    padding: 0,
    justifyContent: "center",
    textAlign: "center",
    marginTop: 15,
  },
  container2: {
    flexDirection: "row",
    alignItems: "center",
    height: 30,
    margin: 0,
    padding: 0,
    justifyContent: "center",
    textAlign: "center",
    marginTop: 15,
  },
  cell: {
    fontSize: 9,
    textAlign: "center",
    borderColor: "#000",
    borderWidth: 1,
    flex: 1,
    height: 30,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: 5,
  },
  cell1: {
    fontSize: 9,
    textAlign: "center",
    flex: 1,
    height: 30,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: 5,
  },
  cell2: {
    fontSize: 9,
    textAlign: "center",
    borderColor: "#000",
    borderWidth: 1,
    flex: 1,
    height: 30,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: 5,
    paddingTop: 10,
  },
  linea: {
    width: 500,
    borderTopWidth: 1,
    borderTopColor: "#000",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: 10,
  },
});

export const AvisosCobro = () => {
  const [avisos, setAvisos] = useState([]);
  const [showPreview, setShowPreview] = useState(false);
  const [fecha, setFecha] = useState("");

  const buscarCorrelativos = () => {
    axios
      .get(`${process.env.BACKEND_URL}/api/aviso_cobro?fecha=${fecha}}`)
      .then((response) => {
        setAvisos(response.data);
      })
      .catch((error) => console.error("Error al buscar correlativos", error));
  };

  const MyDocument = () => (
    <Document>
      <Page size="LETTER" style={styles.page}>
        {avisos.map((aviso, index) => (
          <View key={index} style={styles.section}>
            <View style={styles.header}>
              <Image
                src="https://res.cloudinary.com/dirpdlfbu/image/upload/v1701880341/logo/cmyemflfryypewryq6ux.jpg"
                style={styles.logo}
              />
              <Image
                src="https://res.cloudinary.com/dirpdlfbu/image/upload/v1711324140/aeezebl5y4pzhcmgm2da.jpg"
                style={styles.logo2}
              />
            </View>
            <View style={{ textAlign: "center" }}>
              <Text style={styles.letter2}>AVISO DE COBRO</Text>
            </View>
            <View style={styles.header}>
              <View style={styles.section2}>
                <Text style={{ textAlign: "center" }}>{aviso.empresa}</Text>
                <Text style={{ textAlign: "center" }}> {aviso.rif} </Text>
              </View>
              <View style={styles.section3}>
                <Image
                  src={`https://quickchart.io/qr?text=${aviso.numero_listin}`}
                  style={{ height: "90", width: "90" }}
                />
              </View>
            </View>

            <View style={styles.container2}>
              <Text style={styles.cell1}>N° Listin: {aviso.numero_listin}</Text>
              <Text style={styles.cell1}>N° Unidad: {aviso.unidad}</Text>
              <View style={styles.cell1}>
                <Text>Destino Final:</Text>
                <Text>{aviso.destino_final}</Text>
              </View>
              <Text style={styles.cell1}>Taquilla N°: {aviso.codigo}</Text>
            </View>

            <View style={styles.container1}>
              <Text style={styles.cell}>Fecha del Viaje</Text>
              <Text style={styles.cell}>N° De Pasajeros</Text>
              <Text style={styles.cell}>Total Venta en Bs</Text>
              <Text style={styles.cell}>Listín (3%) en Bs</Text>
              <Text style={styles.cell}>Tasa de Salida en Bs</Text>
              <Text style={styles.cell}>Total a pagar en Bs</Text>
              <Text style={styles.cell}>Referencia en $****</Text>
            </View>

            {/* Datos de la primera mitad */}
            <View style={styles.container}>
              <Text style={styles.cell2}>{aviso.fecha1}</Text>
              <Text style={styles.cell2}>{aviso.total_personas}</Text>
              <Text style={styles.cell2}>{aviso.venta_bs}</Text>
              <Text style={styles.cell2}>{aviso.listin_3_por_ciento_bs}</Text>
              <Text style={styles.cell2}>{aviso.tasa_salida_bs}</Text>
              <Text style={styles.cell2}>{aviso.total_pagar_bs}</Text>
              <Text style={styles.cell2}>{aviso.total_pagar_usd}</Text>
            </View>
            <Text style={styles.letter99}>
              FIRMA RECIBIDO Y FECHA DEL RECIBIDO:
            </Text>
            <Text style={styles.letter}>
              *Incluye: {aviso.tercera_edad} Tercera EDad,{" "}
              {aviso.discapacitados} Discapacitados,
              {aviso.cortesias} Cortesías y {aviso.ninos_menos_4_anios} Niños
              hasta 4 años.
            </Text>
            <Text style={styles.letter}>
              **Con base a la tarifa de 0,01602949 (PTR) por boleto vendido.
            </Text>
            <Text style={styles.letter}>
              ***{aviso.valor_dolar_fecha} Bs/$, según tipo de cambio oficial
              publicado por el BCV para la fecha del viaje
            </Text>
            <View style={styles.linea}></View>

            {/* Aqui Recibo de Pago */}

            <View style={styles.header}>
              <Image
                src="https://res.cloudinary.com/dirpdlfbu/image/upload/v1701880341/logo/cmyemflfryypewryq6ux.jpg"
                style={styles.logo}
              />
              <Image
                src="https://res.cloudinary.com/dirpdlfbu/image/upload/v1711324140/aeezebl5y4pzhcmgm2da.jpg"
                style={styles.logo2}
              />
            </View>
            <View style={{ textAlign: "center" }}>
              <Text style={styles.letter2}>RECIBO DE PAGO</Text>
            </View>
            <View style={styles.header}>
              <View style={styles.section2}>
                <Text style={{ textAlign: "center" }}>{aviso.empresa}</Text>
                <Text style={{ textAlign: "center" }}> {aviso.rif} </Text>
              </View>
              <View style={styles.section3}>
                <Image
                  src={`https://quickchart.io/qr?text=${aviso.numero_listin}`}
                  style={{ height: "90", width: "90" }}
                />
              </View>
            </View>

            <View style={styles.container2}>
              <Text style={styles.cell1}>N° Listin: {aviso.numero_listin}</Text>
              <Text style={styles.cell1}>N° Unidad: {aviso.unidad}</Text>
              <View style={styles.cell1}>
                <Text>Destino Final:</Text>
                <Text>{aviso.destino_final}</Text>
              </View>
              <Text style={styles.cell1}>Taquilla N°: {aviso.codigo}</Text>
            </View>

            <View style={styles.container1}>
              <Text style={styles.cell}>Fecha del Viaje</Text>
              <Text style={styles.cell}>N° De Pasajeros</Text>
              <Text style={styles.cell}>Total Venta en Bs</Text>
              <Text style={styles.cell}>Listín (3%) en Bs</Text>
              <Text style={styles.cell}>Tasa de Salida en Bs</Text>
              <Text style={styles.cell}>Total a pagar en Bs</Text>
              <Text style={styles.cell}>Referencia en $****</Text>
            </View>

            {/* Datos de la primera mitad */}
            <View style={styles.container}>
              <Text style={styles.cell2}>{aviso.fecha1}</Text>
              <Text style={styles.cell2}>{aviso.total_personas}</Text>
              <Text style={styles.cell2}>{aviso.venta_bs}</Text>
              <Text style={styles.cell2}>{aviso.listin_3_por_ciento_bs}</Text>
              <Text style={styles.cell2}>{aviso.tasa_salida_bs}</Text>
              <Text style={styles.cell2}>{aviso.total_pagar_bs}</Text>
              <Text style={styles.cell2}>{aviso.total_pagar_usd}</Text>
            </View>
            <Text style={styles.letter99}>
              FIRMA RECIBIDO Y FECHA DEL RECIBIDO:
            </Text>
            <Text style={styles.letter}>
              *Incluye: {aviso.tercera_edad} Tercera EDad,{" "}
              {aviso.discapacitados} Discapacitados,
              {aviso.cortesias} Cortesías y {aviso.ninos_menos_4_anios} Niños
              hasta 4 años.
            </Text>
            <Text style={styles.letter}>
              **Con base a la tarifa de 0,01602949 (PTR) por boleto vendido.
            </Text>
            <Text style={styles.letter}>
              ***{aviso.valor_dolar_fecha} Bs/$, según tipo de cambio oficial
              publicado por el BCV para la fecha del viaje
            </Text>
          </View>
        ))}
      </Page>
    </Document>
  );

  return (
    <div>
      {/* <div className="aosniaosnxas123a2">
        <svg
          className="waves"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          viewBox="0 24 150 40"
          preserveAspectRatio="none"
          shapeRendering="auto"
        >
          <defs>
            <path
              id="gentle-wave"
              d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"
            />
          </defs>
          <g className="parallax">
            <use
              xlinkHref="#gentle-wave"
              x="48"
              y="-1"
              fill="rgba(255,255,255,0.40)"
            />
            <use
              xlinkHref="#gentle-wave"
              x="48"
              y="3"
              fill="rgba(255,255,255,0.35)"
            />
            <use
              xlinkHref="#gentle-wave"
              x="48"
              y="5"
              fill="rgba(255,255,255,0.25)"
            />
            <use
              xlinkHref="#gentle-wave"
              x="48"
              y="8"
              fill="rgba(255,255,255,0.20)"
            />
            <use
              xlinkHref="#gentle-wave"
              x="48"
              y="13"
              fill="rgba(255,255,255,0.15)"
            />
            <use
              xlinkHref="#gentle-wave"
              x="48"
              y="16"
              fill="rgba(255,255,255,0.95)"
            />
            <use
              xlinkHref="#gentle-wave"
              x="48"
              y="7"
              fill="rgba(242, 249, 255, 1)"
            />
          </g>
        </svg>
      </div>
      <div className="home-homesup" style={{ backdropFilter: "blur(10px)" }}>
        <div>
          <div
            className="circle-98765"
            style={{ marginTop: "100px", marginLeft: "40px" }}
          ></div>
          <div
            className="circle-987652"
            style={{ marginTop: "200px", marginLeft: "20px" }}
          ></div>
          <div
            className="circle-987651"
            style={{ marginTop: "400px", marginLeft: "80px" }}
          ></div>
          <div
            className="circle-9876512"
            style={{ marginTop: "200px", marginLeft: "150px" }}
          ></div>
          <div
            className="circle-9876513"
            style={{ marginTop: "70px", marginLeft: "50vw" }}
          ></div>
          <div
            className="circle-9876514"
            style={{ marginTop: "70px", marginLeft: "40vw" }}
          ></div>
        </div>
      </div>
      <div className="asdas9d87qdasdasdasd7as">
        <div className="container aosniaosnxas123a">
          <div style={{ zIndex: "20" }}>
            <div
              style={{
                zIndex: "9999",
                color: "white",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <h1>
                Creemos en ti, <br /> Creamos Contigo
              </h1>
              <div className="asda-sd4qw98d-qsd4asd">
                <p>
                  Una solución para la efectividad de las empresas operadoras de
                  transporte. Crea, transforma y optimiza la gestión del
                  transporte con nuestra plataforma tecnológica.
                </p>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginTop: "20px",
                zIndex: "1",
              }}
            ></div>
          </div>
        </div>
      </div> */}
      <div style={{ margin: "20px 0" }}>
        <input
          type="date"
          value={fecha}
          onChange={(e) => setFecha(e.target.value)}
        />
        <button onClick={buscarCorrelativos}>Buscar correlativos</button>
      </div>

      <button onClick={() => setShowPreview(true)}>Ver Preview del PDF</button>
      {showPreview && (
        <div style={styles.modalOverlay} onClick={() => setShowPreview(false)}>
          <PDFViewer style={styles.viewer} onClick={(e) => e.stopPropagation()}>
            <MyDocument />
          </PDFViewer>
          <button
            style={styles.closeButton}
            onClick={() => setShowPreview(false)}
          >
            Cerrar
          </button>
        </div>
      )}
    </div>
  );
};
